<div *ngFor="let section of menuPoints.sections; index as index" class="menu-box">
  <mat-expansion-panel
    #expPanels
    (opened)="setOpenedPanel(index)"
    *ngIf="
      checkPermission(section.hidden) &&
      (section.route !== '/charging' || environment.userChargingFeatureActive)
    "
    [expanded]="isExpanded(index)"
    class="exp-panel--sidebar"
    hideToggle
  >
    <mat-expansion-panel-header
      class="panel-header"
      collapsedHeight="45px"
      expandedHeight="45px"
      [attr.data-test]="section.testId"
    >
      <mat-panel-title
        [matTooltipPosition]="'right'"
        [matTooltip]="menuCollapsed ? (section.name | translate) : undefined"
        class="question-title"
      >
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="section.route"
          class="title-box"
          routerLinkActive="title-box--active-link"
        >
          <div class="item-with-icon">
            <eop-icon
              [vaylensFont]="true"
              [clickable]="true"
              [color]="IconColor.INHERIT"
              [identifier]="section.icon"
            ></eop-icon>
            <span *ngIf="!menuCollapsed" class="link-text--expanded body-l">
              {{ section.name | translate }}
            </span>
          </div>
        </a>
      </mat-panel-title>

      <mat-panel-description *ngIf="!menuCollapsed" class="expand-arrows link-text--expanded-flex">
        <eop-icon
          [identifier]="
            expPanels.expanded ? 'small-chevron-up-outlined' : 'small-chevron-down-outlined'
          "
          [vaylensFont]="true"
        ></eop-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ul class="on-card-box-shadow--inset">
      <ng-container *ngFor="let link of section.links">
        <li
          *ngIf="
            (link.checkIfInternalRole ? userHasInternalRole : checkPermission(link.hidden)) &&
            (link.route !== '/monitor/quicksight' || environment.quicksightFeatureActive)
          "
        >
          <eop-link-element
            (click)="sendAnalyticEvent(link.route)"
            (onLinkActivation)="onActivateLink(index)"
            [attr.data-test]="link.testId"
            [disabled]="!checkPermission(link.disabled)"
            [enableIconTooltip]="menuCollapsed"
            [linkIcon]="link.icon"
            [openInBlank]="link.openInBlank"
            [routePath]="link.route"
            [routerPathParams]="link.routeParams"
            [textKey]="link.name"
            [isExpanded]="!menuCollapsed"
          >
          </eop-link-element>
        </li>
      </ng-container>
    </ul>
  </mat-expansion-panel>
</div>
