import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { NumberFormatPipe } from './number-format.pipe';

export enum LocationTypes {
  CITY = 'city',
  COORDINATES = 'coordinates',
  LINE1 = 'line1',
  LINE2 = 'line2',
}

export interface ILocation {
  country?: string;
  city?: string;
  zipCode?: string;
  street?: string;
  latitude?: number;
  longitude?: number;
  supplementDE?: string;
  supplementEN?: string;
}

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'location',
})
export class LocationPipe implements PipeTransform {
  constructor(private myNumberFormat: NumberFormatPipe) {}

  public transform(location: ILocation | any, type: LocationTypes): string {
    let zipCode = location.zipCode;
    if (location.zip) {
      zipCode = location.zip; // backend compatibility
    }

    switch (type) {
      case LocationTypes.CITY: {
        return location.city;
      }
      /* Format: 45.345264 34.452445 */
      case LocationTypes.COORDINATES: {
        return (
          this.myNumberFormat.transform(location.latitude, 6, '.') +
          ' ' +
          this.myNumberFormat.transform(location.longitude, 6, '.')
        );
      }
      case LocationTypes.LINE1: {
        return this.hideNullValue(location.street);
      }
      case LocationTypes.LINE2: {
        return (
          (zipCode ? this.hideNullValue(zipCode) + ' ' : '') + this.hideNullValue(location.city)
        ).trim();
      }
    }
  }

  private hideNullValue(value: any) {
    return value ? value : '';
  }
}
