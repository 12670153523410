import { Pipe, PipeTransform } from '@angular/core';
import { DateConversionService } from '@shared/services/util';

@Pipe({
  name: 'userTimeUtcOffset',
})
export class UserTimeUtcOffsetPipe implements PipeTransform {
  constructor(private dateConversionService: DateConversionService) {}

  transform(time: string): string {
    return this.dateConversionService.utcTimeToUsersTime(time);
  }
}
