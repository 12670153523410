import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'eop-html-tooltip',
  templateUrl: './html-tooltip.component.html',
  styleUrls: ['./html-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlTooltipComponent {
  @Input() contentTemplate: TemplateRef<any>;
}
