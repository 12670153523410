import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eop-modal',
  templateUrl: './eop-modal.component.html',
  styleUrls: ['./eop-modal.component.scss'],
})
export class EopModalComponent {
  @Input()
  public isVisible: boolean = false;

  @Input()
  public width: string;

  @Input()
  public closeOnClickedBackdrop: boolean = false;

  @Output()
  public isVisibleChange = new EventEmitter<boolean>();
}
