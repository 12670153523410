import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconColor, IconSize } from '@widgets/eop-icon';

@Component({
  selector: 'eop-login-notification-header',
  templateUrl: './login-notification-header.component.html',
  styleUrls: ['./login-notification-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginNotificationHeaderComponent {
  @Input()
  amount: number;

  @Output()
  closeMessagesEvent = new EventEmitter<void>();

  readonly IconSize = IconSize;
  readonly IconColor = IconColor;

  closeMessages(): void {
    this.closeMessagesEvent.emit();
  }
}
