/*
 * Business Partner
 */
export interface BusinessPartnersPaging {
  paging: Paging;
  businessPartners: BusinessPartner[];
}

export interface Paging {
  page: number;
  size: number;
  totalItems: number;
  totalPages: number;
}

export interface PagingAlt {
  currentPage?: number;
  totalPages?: number;
  itemsPerPage?: number;
  totalItems?: number;
}

export interface BusinessPartner {
  id: string;
  name: string;
}

/*
 * Location details
 */
export interface LocationDetails {
  locations: LocationDetail[];
}

export interface LocationDetail {
  uuid: string;
  name: string;
  chargingStationCount?: number;
  description?: string;
}

/*
 * Combined location with charging stations
 */
export interface LocationWithChargingStations {
  uuid?: string;
  name?: string;
  description?: string;
  stations?: LocationChargingStation[];
}

export interface LocationWithChargingStationsUpdate {
  uuid?: string;
  name?: string;
  description?: string;
  chargingStationIds?: string[];
}

/*
 * Charging station to location assignment
 */
export interface LocationChargingStations {
  chargingStations: LocationChargingStation[];
}

export interface LocationChargingStation {
  id: string;
  serialNumber: string;
  address: GeoAddress;
}

export interface GeoAddress {
  country: string;
  zipCode: string;
  city: string;
  street: string;
  latitude?: number;
  longitude?: number;
  supplementLocal: string;
  supplementEN: string;
}

export interface StationsOfPartner {
  paging: PagingAlt;
  chargingStations: Array<Station>;
}

export interface Station {
  id: string;
  serialNumber: string;
  address: Address;
}

export interface Address {
  city: string;
  zipCode: string;
  country: string;
  street: string;
  latitude: number;
  longitude: number;
  supplementLocal: string;
  supplementEN: string;
}

export interface SearchStationsOfPartner {
  'business-partner-id'?: string;
  currentPage?: number;
  itemsPerPage?: number;
  search?: string;
  sortColumn?: string;
  sortOrder?: string;
  country?: string;
  zipCode?: string;
  city?: string;
  streetSearchList?: string[];
}

export interface RequestResponse<T> {
  type: ResponseEnum;
  payload: T;
}

export enum ResponseEnum {
  Initial = 'Initial',
  WaitingForResponse = 'WaitingForResponse',
  UpdateLocationError = 'UpdateLocationError',
  UpdateLocationSuccess = 'UpdateLocationSuccess',
}
