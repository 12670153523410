<div
  class="dropdown"
  [ngClass]="{ disabled: disabled, 'multi-selectable': multiSelect }"
  (clickOutside)="expanded = false"
>
  <!-- MultiSelect -->
  <div
    *ngIf="multiSelect"
    class="multi-select"
    [ngClass]="transparent ? 'transparent' : 'select'"
    (click)="disabled || !items[0] ? null : (expanded = !expanded)"
  >
    <span
      *ngIf="selectedItems[0]"
      [matTooltip]="selectedItems[0].name"
      class="body-m"
      [ngStyle]="{ 'line-height': '40px' }"
    >
      {{ selectedItems[0].name }}
      <label
        *ngIf="selectedItems[1] && !disableAdditionalCounter"
        class="light additionalCounter body-m"
      >
        +{{ selectedItems.length - 1 }}
      </label>
    </span>

    <span *ngIf="!selectedItems[0]" class="placeholder">
      {{ placeholderText }}
    </span>

    <eop-icon
      *ngIf="!expanded"
      identifier="small-chevron-down-filled"
      [vaylensFont]="true"
      (click)="$event.stopPropagation(); disabled || !items[0] ? null : (expanded = !expanded)"
    ></eop-icon>
    <eop-icon
      *ngIf="expanded"
      identifier="small-chevron-up-filled"
      [vaylensFont]="true"
      (click)="$event.stopPropagation(); disabled || !items[0] ? null : (expanded = !expanded)"
    ></eop-icon>
  </div>

  <!-- SingleSelect -->
  <div
    *ngIf="!multiSelect && !searchable"
    class="single-select body-m"
    [ngClass]="{
      transparent: transparent,
      select: !transparent,
      'select--error': error,
      up: expanded,
      down: !expanded
    }"
    (click)="disabled || !items[0] ? null : (expanded = !expanded)"
  >
    <span *ngIf="!selectedItem" class="placeholder">
      {{ placeholderText }}
    </span>
    <span *ngIf="selectedItem && itemTemplate">
      <ng-container
        *ngTemplateOutlet="
          itemTemplate;
          context: { itemName: selectedItem.name, itemData: selectedItem.data }
        "
      ></ng-container>
    </span>

    <span *ngIf="selectedItem && !itemTemplate" [matTooltip]="selectedItem.name">
      {{ selectedItem.name }}
    </span>
    <eop-icon
      *ngIf="!expanded"
      identifier="small-chevron-down-filled"
      [vaylensFont]="true"
      (click)="$event.stopPropagation(); disabled || !items[0] ? null : (expanded = !expanded)"
    ></eop-icon>
    <eop-icon
      *ngIf="expanded"
      identifier="small-chevron-up-filled"
      [vaylensFont]="true"
      (click)="$event.stopPropagation(); disabled || !items[0] ? null : (expanded = !expanded)"
    ></eop-icon>
  </div>

  <!-- SearchableSingleSelect -->
  <input
    *ngIf="!multiSelect && searchable"
    class="body-m searchable-single-select"
    [ngClass]="{ transparent: transparent, select: !transparent, up: expanded, down: !expanded }"
    (click)="disabled || !items[0] ? null : (expanded = !expanded)"
    [attr.disabled]="disabled ? '' : null"
    [(ngModel)]="value"
    [matTooltip]="value"
    (keyup)="filterItems($event.target.value, $event.key); expanded = true"
    (focus)="filterItems('', null); value = ''"
    [placeholder]="selectedItem ? selectedItem.name : placeholderText"
    (clickOutside)="value = selectedItem?.name"
  />

  <div
    *ngIf="expanded && filteredItems.length !== 0"
    class="expansion box-shadow-1"
    [ngClass]="transparent ? 'header-dropdown' : 'header'"
  >
    <ul>
      <li
        class="item default-item body-m"
        *ngIf="!multiSelect && selectedItem && singleSelectDeselactable && filter === ''"
        (click)="clickedItem(null)"
      >
        <p>
          <label class="body-m cursor-pointer">
            {{ 'GLOBAL.PLEASE_SELECT' | translate }}
          </label>
        </p>
      </li>
      <li
        *ngFor="let item of filteredItems"
        class="item selection-item body-m"
        [class.cursor-not-allowed]="isDisabled(item)"
        (click)="clickedItem(item)"
        [matTooltip]="item.name"
        [matTooltipShowDelay]="500"
      >
        <p class="wrapper--item-template">
          <input
            type="checkbox"
            *ngIf="multiSelect"
            [checked]="isSelected(item)"
            [disabled]="isDisabled(item)"
          />
          <label class="body-m">
            <span
              *ngTemplateOutlet="
                itemTemplate;
                context: { itemName: item.name, itemData: item.data }
              "
            ></span>
            <span
              *ngIf="!itemTemplate"
              class="cursor-pointer body-m"
              [class.cursor-not-allowed]="isDisabled(item)"
            >
              {{ item.name }}
            </span>
          </label>
        </p>
      </li>
    </ul>
  </div>
</div>
