<div *ngFor="let item of componentData.items">
  <div class="item" (click)="onItemClick(item)" [ngClass]="{ selected: item.selected }">
    {{ item.name }}
  </div>
</div>

<div *ngIf="isIndividualPeriod" class="date-picker-content">
  <div [class]="isInvalidFromDate ? 'date-picker date-picker--invalid' : 'date-picker'">
    <input
      matInput
      [min]="minDate"
      [max]="maxDate"
      [matDatepicker]="pickerFrom"
      [placeholder]="'GLOBAL.SELECT_DATE' | translate"
      class="input"
      (dateChange)="onDateSelectFrom($event)"
      [value]="selectedDateFrom"
    />

    <mat-datepicker-toggle matSuffix [for]="pickerFrom">
      <mat-icon matDatepickerToggleIcon>
        <eop-icon class="calendar-icon" [identifier]="'calendar-3'" [color]="IconColor.NORMAL">
        </eop-icon>
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #pickerFrom></mat-datepicker>
  </div>
  <div [class]="isInvalidToDate ? 'date-picker date-picker--invalid' : 'date-picker'">
    <input
      matInput
      [min]="minDate"
      [max]="maxDate"
      [matDatepicker]="pickerTo"
      [placeholder]="'GLOBAL.SELECT_DATE' | translate"
      class="input"
      (dateChange)="onDateSelectTo($event)"
      [value]="selectedDateTo"
    />
    <mat-datepicker-toggle matSuffix [for]="pickerTo">
      <mat-icon matDatepickerToggleIcon>
        <eop-icon class="calendar-icon" [identifier]="'calendar-3'" [color]="IconColor.NORMAL">
        </eop-icon
      ></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #pickerTo></mat-datepicker>
  </div>
</div>
