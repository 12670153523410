import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { OcppParameter } from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';
import { ChargingStation } from '@operate/charging-infrastructure-detail/model/charging-station.model';
import { IActionWithPayload, IAppState } from '@shared/store/models';

export enum ChargingInfraStructureDetailTypes {
  FETCH_ROAMING_FOR_CHARGING_STATION = '[Charging Infrastructure Detail] FETCH_ROAMING_FOR_CHARGING_STATION',
  FETCH_ROAMING_FOR_CHARGING_STATION_UNKNOWN = '[Charging Infrastructure Detail] FETCH_ROAMING_FOR_CHARGING_STATION_UNKNOWN',
  FETCH_ROAMING_FOR_CHARGING_STATION_INCONSISTENT = '[Charging Infrastructure Detail] FETCH_ROAMING_FOR_CHARGING_STATION_INCONSISTENT',
  FETCH_ROAMING_FOR_CHARGING_STATION_NOT_FOUND = '[Charging Infrastructure Detail] FETCH_ROAMING_FOR_CHARGING_STATION_NOT_FOUND',
  FETCH_ROAMING_FOR_CHARGING_STATION_UNPROCESSABLE = '[Charging Infrastructure Detail] FETCH_ROAMING_FOR_CHARGING_STATION_UNPROCESSABLE',
  GET_CAPABILITIES_CHARGING_STATION = '[Charging Infrastructure Detail] GET_CAPABILITIES_CHARGING_STATION',
  GET_CAPABILITIES_CHARGING_STATION_SUCCESS = '[Charging Infrastructure Detail] GET_CAPABILITIES_CHARGING_STATION_SUCCESS',
  GET_CAPABILITIES_CHARGING_STATION_ERROR = '[Charging Infrastructure Detail] GET_CAPABILITIES_CHARGING_STATION_ERROR',
  START_GET_CHARGE_POINT_STATUS = '[Charging Infrastructure Detail] START_GET_CHARGE_POINT_STATUS',
  START_GET_CHARGE_POINT_CONFIG = '[Charging Infrastructure Detail] START_GET_CHARGE_POINT_CONFIG',
  GET_OCPP_CONFIG_BY_CHARGE_POINT = '[Charging Infrastructure Detail] GET_OCPP_CONFIG_BY_CHARGE_POINT',
  GET_OCPP_CONFIG_BY_CHARGE_POINT_SUCCESS = '[Charging Infrastructure Detail] GET_OCPP_CONFIG_BY_CHARGE_POINT_SUCCESS',
  GET_OCPP_CONFIG_BY_CHARGE_POINT_NOT_FOUND = '[Charging Infrastructure Detail] GET_OCPP_CONFIG_BY_CHARGE_POINT_NOT_FOUND',
  START_UPDATE_OCPP_CONFIG_BY_CHARGE_POINT = '[Charging Infrastructure Detail] START_UPDATE_OCPP_CONFIG_BY_CHARGE_POINT',
  UPDATE_OCPP_CONFIG_BY_CHARGE_POINT = '[Charging Infrastructure Detail] UPDATE_OCPP_CONFIG_BY_CHARGE_POINT',
  UPDATE_OCPP_CONFIG_BY_CHARGE_POINT_SUCCESS = '[Charging Infrastructure Detail] UPDATE_OCPP_CONFIG_BY_CHARGE_POINT_SUCCESS',
  RESET_OCPP_CONFIG_CHANGE_RESULT = '[Charging Infrastructure Detail] RESET_OCPP_CONFIG_CHANGE_RESULT',
  START_REFRESH_OCPP_CONFIG_BY_CHARGE_POINT = '[Charging Infrastructure Detail] START_REFRESH_OCPP_CONFIG_BY_CHARGE_POINT',
  WAIT_REFRESH_OCPP_CONFIG_BY_CHARGE_POINT = '[Charging Infrastructure Detail] WAIT_REFRESH_OCPP_CONFIG_BY_CHARGE_POINT',
  REFRESH_OCPP_CONFIG_BY_CHARGE_POINT = '[Charging Infrastructure Detail] REFRESH_OCPP_CONFIG_BY_CHARGE_POINT',
  GET_ASSIGNMENTS = '[Charging Infrastructure Detail] GET_ASSIGNMENTS',
  START_LOAD_ASSIGNMENTS = '[Charging Infrastructure Detail] START_LOAD_ASSIGNMENTS',
  FINISH_LOAD_ASSIGNMENTS = '[Charging Infrastructure Detail] FINISH_LOAD_ASSIGNMENTS',
  GET_PRODUCT_ASSIGNMENTS_BY_CHARGING_STATION = '[Charging Infrastructure Detail] GET_PRODUCT_ASSIGNMENTS_BY_CHARGING_STATION',
  GET_PRODUCT_ASSIGNMENTS_BY_CHARGING_STATION_SUCCESS = '[Charging Infrastructure Detail] GET_PRODUCT_ASSIGNMENTS_BY_CHARGING_STATION_SUCCESS',
  GET_PRODUCT_ASSIGNMENTS_BY_CHARGING_STATION_ERROR = '[Charging Infrastructure Detail] GET_PRODUCT_ASSIGNMENTS_BY_CHARGING_STATION_ERROR',
  START_REFRESHING = '[Charging Infrastructure Detail] START_REFRESHING',
  START_LOADING_INDICATOR = '[Charging Infrastructure Detail] START_LOADING_INDICATOR',
  STOP_LOADING_INDICATOR = '[Charging Infrastructure Detail] STOP_LOADING_INDICATOR',
  GET_GROUP_ASSIGNMENTS_BY_CHARGE_POINT = '[Charging Infrastructure Detail] GET_GROUP_ASSIGNMENTS_BY_CHARGE_POINT',
  GET_GROUP_ASSIGNMENTS_BY_CHARGE_POINT_ERROR = '[Charging Infrastructure Detail] GET_GROUP_ASSIGNMENTS_BY_CHARGE_POINT_ERROR',
  GET_GROUP_ASSIGNMENTS_BY_CHARGE_POINT_SUCCESS = '[Charging Infrastructure Detail] GET_GROUP_ASSIGNMENTS_BY_CHARGE_POINT_SUCCESS',
  GET_LOCATION_ASSIGNMENTS_BY_CHARGE_POINT = '[Charging Infrastructure Detail] GET_LOCATION_ASSIGNMENTS_BY_CHARGE_POINT',
  GET_LOCATION_ASSIGNMENTS_BY_CHARGE_POINT_ERROR = '[Charging Infrastructure Detail] GET_LOCATION_ASSIGNMENTS_BY_CHARGE_POINT_ERROR',
  GET_LOCATION_ASSIGNMENTS_BY_CHARGE_POINT_SUCCESS = '[Charging Infrastructure Detail] GET_LOCATION_ASSIGNMENTS_BY_CHARGE_POINT_SUCCESS',
  TAB_CHANGED = '[Charging Infrastructure Detail] TAB_CHANGED',
  SET_FIRMWARE_FOR_CHARGING_STATION = '[Charging Infrastructure Detail] SET_FIRMWARE_FOR_CHARGING_STATION',
}

@Injectable()
export class ChargingInfrastructureDetailActions {
  constructor(private store: NgRedux<IAppState>) {}

  getCapabilitiesChargingStation(chargingStationUuid: string): IActionWithPayload<any> {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.GET_CAPABILITIES_CHARGING_STATION,
      payload: { chargingStationUuid },
    });
  }

  startLoadingIndicator(chargePortId: string) {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.START_LOADING_INDICATOR,
      payload: {
        chargePortId,
        source: 'status',
      },
    });
  }

  startRefreshingDetailsPage(chargingStationUuid: string, chargePortId: string) {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.START_REFRESHING,
      payload: {
        chargingStationUuid,
        chargePortId,
      },
    });
  }

  getOcppConfiguration(
    chargingStationUuid: string,
    chargePortUuid: string,
    parameterName: string,
    page: number,
    itemsPerPage: number
  ): IActionWithPayload<any> {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.GET_OCPP_CONFIG_BY_CHARGE_POINT,
      payload: {
        chargingStationUuid,
        chargePortUuid,
        parameterName,
        page,
        itemsPerPage,
      },
    });
  }

  updateOcppConfiguration(
    chargingStationUuid: string,
    chargePortUuid: string,
    parameter: OcppParameter
  ): IActionWithPayload<any> {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.START_UPDATE_OCPP_CONFIG_BY_CHARGE_POINT,
      payload: { chargingStationUuid, chargePortUuid, parameter },
    });
  }

  resetOcppConfigurationResult(): IActionWithPayload<any> {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.RESET_OCPP_CONFIG_CHANGE_RESULT,
    });
  }

  refreshOcppConfiguration(
    chargingStationUuid: string,
    chargePortUuid: string,
    parameterName: string,
    page: number,
    itemsPerPage: number
  ): IActionWithPayload<any> {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.START_REFRESH_OCPP_CONFIG_BY_CHARGE_POINT,
      payload: {
        chargingStationUuid,
        chargePortUuid,
        parameterName,
        page,
        itemsPerPage,
      },
    });
  }

  getProductAssignments(chargingStation: ChargingStation) {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.GET_PRODUCT_ASSIGNMENTS_BY_CHARGING_STATION,
      payload: chargingStation,
    });
  }

  getGroupAssignments(chargePointId: string) {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.GET_GROUP_ASSIGNMENTS_BY_CHARGE_POINT,
      payload: chargePointId,
    });
  }

  getAssignments(chargingStation: ChargingStation) {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.GET_ASSIGNMENTS,
      payload: chargingStation,
    });
  }

  onTabChanged(chargingStationUuid: string, chargePortId: string) {
    return this.store.dispatch({
      type: ChargingInfraStructureDetailTypes.TAB_CHANGED,
      payload: { chargingStationUuid, chargePortId },
    });
  }
}
