import { IconCellData } from '@widgets/innogy-data-table';

export interface ClearingHouseTariffsState {
  selectedClearingHouse: ClearingHousePartner;
  clearingHouseList: ClearingHousePartner[];
  allPartners: ClearingHousePartner[];
  firstLevelActiveTab: ClearingHouseTab;
  secondLevelActiveTab: TariffTab;
  loadingTableData: boolean;
  tableData: TariffsTableData;
  loadingRowDetails: boolean;
  expandedRowDetails: ExpandedRowDetail;
  filter: ClearingHouseTariffsFilter;
}

export type ClearingHouseTariff = ClearingHouseDefaultTariff | ClearingHouseIndividualTariff;

export interface ClearingHouseDefaultTariff {
  tariffId: string;
  name: string;
  activationDate: string;
  archivingDate: string;
  businessPartnerType: PartnerType;
  roamingOfferType: RoamingOfferType;
  state: TariffStatus;
  clhMarginGroup: string;
}

export interface ClearingHouseIndividualTariff {
  tariffId: string;
  name: string;
  activationDate: string;
  archivingDate: string;
  businessPartnerType: PartnerType;
  roamingOfferType: RoamingOfferType;
  state: TariffStatus;
  cpoPartnerIds: string[];
  empPartnerIds: string[];
}

export interface ExpandedRowDetail {
  tariffId: string;
  name: string;
  businessPartnerType: PartnerType;
  roamingOfferType: RoamingOfferType;
  // currency ISO 4217
  currency: string;
  fixedRoamingOfferAc: string;
  fixedRoamingOfferDc: string;
  cpoPartnerIds: string[];
  empPartnerIds: string[];
  clhMarginGroup: string;
  minimumPrices: TariffPrice[];
  prices: TariffPrice[];
}

export interface TariffPrice {
  priceType: PriceType;
  delay?: number;
  offset?: PriceOffset;
  currentType: CurrentType;
  value: number;
}

export interface DefaultDataTableRow {
  name: string;
  tariffId: string;
  clhMarginGroup: {
    text: any;
    chipColor: string;
  };
  roaming: {
    text: any;
    chipColor: string;
  };
  roamingOfferType: {
    text: any;
    chipColor: string;
  };
  validity:
    | string
    | {
        text: string;
        chipColor: string;
      };
  state: IconCellData[];
  customActionEnabled: boolean;
  deletable: boolean;
  editable: boolean;
}

export interface IndividualDataTableRow {
  name: string;
  tariffId: string;
  emps: string[];
  cpos: string[];
  roamingOfferType: {
    text: any;
    chipColor: string;
  };
  validity:
    | string
    | {
        text: string;
        chipColor: string;
      };
  state: IconCellData[];
  customActionEnabled: boolean;
  deletable: boolean;
  editable: boolean;
}

export enum PriceType {
  CHARGING_SESSION = 'CHARGING_SESSION',
  CHARGING_KWH = 'CHARGING_KWH',
  CHARGING_TIME = 'CHARGING_TIME',
}

export enum PriceOffset {
  NONE = 'NONE',
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}

export enum CurrentType {
  AC = 'AC',
  DC = 'DC',
}

export enum PartnerType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum RoamingOfferType {
  NONE = 'NONE',
  FIXED = 'FIXED',
  DYNAMIC = 'DYNAMIC',
}

export enum RoamingType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface ClearingHousePartner {
  businessPartnerId: string;
  name: string;
}

export interface ClearingHouseTariffsFilter {
  status?: TariffStatus | TariffStatus[];
  cpoId?: string;
  empId?: string;
}

export interface TariffsTableData {
  paging: Paging;
  tariffs: ClearingHouseTariff[];
}

export interface Paging {
  currentPage?: number;
  totalPages?: number;
  itemsPerPage?: number;
  totalItems?: number;
}

export interface ActivateTariffRequest {
  tariffId: string;
  bpId: string;
  startDate: string;
  endDate: string;
  unlimited: boolean;
  currentState: TariffStatus;
}

export interface DeleteTariffRequest {
  bpId: string;
  tariffId: string;
}

export enum ClearingHouseTab {
  Default,
  Individual,
}

export enum ClearingHouseTariffType {
  DEFAULT = 'DEFAULT',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum TariffTab {
  Active,
  Archived,
}

export enum TariffStatus {
  Draft = 'DRAFT',
  Planned = 'PLANNED',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum CurrencyItem {
  CHF = 'CHF (CHF)',
  CZK = 'CZK (Kč)',
  DKK = 'DKK (kr)',
  EUR = 'EUR (€)',
  GBP = 'GBP (£)',
  RON = 'RON (lei)',
  HUF = 'HUF (Ft)',
  NOK = 'NOK (kr)',
  PLN = 'PLN (zł)',
  SEK = 'SEK (kr)',
}

export enum ClhMarginGroup {
  DEFAULT = 'DEFAULT',
  NO_FORCED_BLOCKING_FEE = 'NO_FORCED_BLOCKING_FEE',
}

export interface PriceComponent {
  priceType: PriceType;
  offset?: PriceOffset;
  currentType: CurrentType;
  value: number;
  delay?: number;
}

export interface PriceTypeChange {
  newType?: PriceType;
  oldType?: PriceType;
}

export interface FixedRoamingOffers {
  roamingOfferIds: string[];
}

export interface CreateDefaultClhTariffRequest {
  name: string;
  currency: string;
  businessPartnerType: RoamingType;
  clhMarginGroup: string;
  roamingOfferType: RoamingOfferType;
  fixedRoamingOfferAc: string;
  fixedRoamingOfferDc: string;
  minimumPrices: ClhMinimumPrice[];
  prices: PriceComponent[];
}

export interface CreateIndividualClhTariffRequest {
  name: string;
  currency: string;
  empPartnerIds: string[];
  cpoPartnerIds: string[];
  roamingOfferType: RoamingOfferType;
  fixedRoamingOfferAc: string;
  fixedRoamingOfferDc: string;
  minimumPrices: ClhMinimumPrice[];
  prices: PriceComponent[];
}

export interface ClhMinimumPrice {
  priceType: PriceType;
  currentType: CurrentType;
  value: number;
  delay: number;
}
