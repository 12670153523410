<div class="entry" *ngIf="message">
  <section>
    <label>{{ message.announcements[userLanguage].subject }}</label>
  </section>
  <section>
    <div class="message" *ngIf="message.announcements">
      {{ message.announcements[userLanguage].message }}
    </div>
  </section>
  <a
    *ngIf="message.link"
    [href]="message.link"
    class="link"
    target="_blank"
    rel="noopener noreferrer"
    >{{ message.alternativeText }}</a
  >
</div>
