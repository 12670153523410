export interface QuickSearchResult {
  chargepointResults: number;
  chargingStationResults: number;
  searchResults: SearchResult[];
}

export interface SearchResult {
  category: string;
  value: string;
  stationUuid?: string;
  installationStatus: ChargingStationState;
}

export enum SearchCategoryEnum {
  CHARGEPOINT = 'CHARGEPOINT',
  CHARGINGSTATION = 'CHARGINGSTATION',
}

export enum ChargingStationState {
  PLANNED = 'PLANNED',
  IBET = 'IBET',
  ABET = 'ABET',
}
