import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { GroupComponent } from '@widgets/group/group.component';
import { Group, IconDisableMode } from '@widgets/group/group.interface';
import { PageEvent } from '@widgets/pagination/pagination.model';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'innogy-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsComponent implements AfterViewInit, OnChanges, OnDestroy {
  readonly ITEMS_AMOUNT_BEFORE_SHOWING_PAGING = 30;
  readonly DEBOUNCE_TIME_FOR_SEARCH_IN_MS = 500;
  readonly MIN_CHARACTER_BEFORE_SEARCH = 3;

  private unsubscribe$ = new Subject<void>();

  @Input() title: string;
  @Input() titleInfoTooltip: string;
  @Input() groups: Group[];
  @Input() iconDisableMode: IconDisableMode = IconDisableMode.HIDE;
  @Input() hideControls: boolean = false;
  @Input() selectedGroup: Group;
  @Input() editMode = false;
  @Input() sheetEnabled = false;
  @Input() sheetDisabledTooltip = '';
  @Input() sheetVisible = false;
  @Input() createLabel: string;
  @Input() disableCreate = false;
  @Input() disableCreateTooltip: string;
  @Input() pleaseCreateTooltip: string;
  @Input() totalItems: number;
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() searchForText: string;
  @Input() loadingData: boolean = false;

  @Output() addGroupClicked = new EventEmitter();
  @Output() editGroupClicked = new EventEmitter();
  @Output() deleteGroupClicked = new EventEmitter();
  @Output() sheetDownloadClicked = new EventEmitter();
  @Output() groupClicked = new EventEmitter();
  @Output() editModeChange = new EventEmitter<boolean>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() searchChange = new EventEmitter<string>();

  @ViewChildren(GroupComponent)
  groupList: QueryList<GroupComponent>;

  selectedGroupId = '';

  pageSizeOptions = [15, 30, 60, 90, 180];

  searchValue: string;
  searchValueDebounced: string;
  private searchWithDebounce$: Subject<string> = new Subject<string>();

  readonly IconSize = IconSize;
  readonly IconColor = IconColor;
  readonly IconWeight = IconWeight;
  readonly ButtonColor = ButtonColor;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    // Trigger initial group preselection after the view has been initialized
    this.detectSelectionChange();

    // If the list of groups is updated together with the selectedGroup attribute
    // the change detection has to be triggered within the groupList change
    // to properly set the selected group, otherwise the selection will be lost
    this.groupList.changes.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.detectSelectionChange();
    });

    this.searchWithDebounce$
      .pipe(debounceTime(this.DEBOUNCE_TIME_FOR_SEARCH_IN_MS), takeUntil(this.unsubscribe$))
      .subscribe(searchText => {
        this.searchValueDebounced = searchText;
        this.searchChange.emit(searchText);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedGroupChange: SimpleChange = changes['selectedGroup'];
    if (selectedGroupChange && !selectedGroupChange.firstChange) {
      this.setSelectedGroup(this.selectedGroup);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.editModeChange.emit(this.editMode);
    this.changeDetectorRef.detectChanges();
  }

  addGroup(): void {
    this.addGroupClicked.emit();
  }

  editGroup(group: Group): void {
    this.toggleEditMode();
    this.editGroupClicked.emit(group);
  }

  deleteGroup(group: Group): void {
    this.deleteGroupClicked.emit(group);
  }

  downloadSheet(): void {
    this.sheetDownloadClicked.emit();
  }

  selectGroup(group: Group): void {
    this.setSelectedGroup(group);
    this.groupClicked.emit(group);
  }

  changePage(pageEvent: PageEvent): void {
    this.pageChange.emit(pageEvent);
  }

  search(searchTerm: string, skipDebouncing: boolean): void {
    const trimmedValue = searchTerm.trim();
    this.searchValue = trimmedValue;
    if (trimmedValue !== '' && trimmedValue.length < this.MIN_CHARACTER_BEFORE_SEARCH) return;
    if (skipDebouncing) {
      this.searchChange.emit(trimmedValue);
    } else {
      this.searchWithDebounce$.next(searchTerm);
    }
  }

  get showPaginator(): boolean {
    return this.totalItems && this.totalItems > this.ITEMS_AMOUNT_BEFORE_SHOWING_PAGING;
  }

  get noGroupsLoaded(): boolean {
    return !this.groups || this.groups.length === 0;
  }

  get searchPossible(): boolean {
    return (
      !this.loadingData &&
      this.searchValue !== undefined &&
      this.searchValue !== null &&
      this.searchValue.length >= this.MIN_CHARACTER_BEFORE_SEARCH
    );
  }

  private setSelectedGroup(group: Group): void {
    const selected = this.findGroup(this.selectedGroupId);
    if (selected) {
      selected.selected = false;
      this.selectedGroupId = '';
    }

    if (group) {
      const toSelect = this.findGroup(group.id);
      if (toSelect) {
        toSelect.selected = true;
        this.selectedGroupId = group.id;
      }
    }
  }

  private findGroup(id: string) {
    const groupFound =
      this.groupList &&
      this.groupList.toArray().find(elem => {
        return elem['group']['id'] === id;
      });
    return groupFound ? groupFound : null;
  }

  private detectSelectionChange(): void {
    if (this.selectedGroup) {
      this.setSelectedGroup(this.selectedGroup);
      this.changeDetectorRef.detectChanges();
    }
  }
}
