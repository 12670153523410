import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from '@widgets/snackbar';
import { NotificationLevel } from '../../shared/services/notification/notification-level.model';

@Injectable()
export class SnackbarService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  public openSuccessSnackbar(): void {
    this.openSnackbar(this.translate.instant('GLOBAL.OPERATION_SUCCESSFUL'));
  }

  public openSnackbar(message: string, level = NotificationLevel.SUCCESS, duration = 3000): void {
    let panelClass: string;
    switch (level) {
      case NotificationLevel.SUCCESS:
        panelClass = 'success-snackbar';
        break;
      case NotificationLevel.ERROR:
        panelClass = 'error-snackbar';
        break;
      case NotificationLevel.WARNING:
        panelClass = 'warning-snackbar';
        break;
      case NotificationLevel.INFO:
        panelClass = 'info-snackbar';
        break;
    }

    setTimeout(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: duration,
        data: { message: message },
        panelClass: [panelClass],
      });
    });
  }
}
