import { Injectable } from '@angular/core';
import {
  ChargePortNote,
  ChargingSessionInformation,
  IChargePointStatus,
} from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';
import { IActionWithPayload } from '@shared/store/models';
import { ProgressStates } from '@widgets/save-button';

export enum ChargePortActionTypes {
  SET_UNLOCK_BUTTON_STATE = '[Charge Point Action] SET_UNLOCK_BUTTON_STATE',
  RESET_UNLOCK_BUTTON_STATE = '[Charge Point Action] RESET_UNLOCK_BUTTON_STATE',
  SET_REBOOT_BUTTON_STATE = '[Charge Point Action] SET_REBOOT_BUTTON_STATE',
  RESET_REBOOT_BUTTON_STATE = '[Charge Point Action] RESET_REBOOT_BUTTON_STATE',
  SET_STOP_CHARGING_BUTTON_STATE = '[Charge Point Action] SET_STOP_CHARGING_BUTTON_STATE',
  RESET_STOP_CHARGING_BUTTON_STATE = '[Charge Point Action] RESET_STOP_CHARGING_BUTTON_STATE',
  SET_INITIATE_CHARGING_BUTTON_STATE = '[Charge Point Action] SET_INITIATE_CHARGING_BUTTON_STATE',
  RESET_INITIATE_CHARGING_BUTTON_STATE = '[Charge Point Action] RESET_INITIATE_CHARGING_BUTTON_STATE',
  START_FETCH_CHARGE_PORT_STATUS = '[Charge Point Action] START_FETCH_CHARGE_PORT_STATUS',
  FETCH_CHARGE_PORT_STATUS = '[Charge Point Action] FETCH_CHARGE_PORT_STATUS',
  FETCH_CHARGE_PORT_STATUS_SUCCESS = '[Charge Point Action] FETCH_CHARGE_PORT_STATUS_SUCCESS',
  FETCH_CHARGE_PORT_STATUS_FAILED = '[Charge Point Action] FETCH_CHARGE_PORT_STATUS_FAILED',
  FETCH_CHARGE_PORT_INFORMATION_SUCCESS = '[Charge Point Action] FETCH_CHARGE_PORT_INFORMATION_SUCCESS',
  FETCH_CHARGE_PORT_INFORMATION_FAILED = '[Charge Point Action] FETCH_CHARGE_PORT_INFORMATION_FAILED',
  SET_MDP_FLAG = '[Charge Point Action] SET_MDP_FLAG',
  FETCH_CHARGE_PORT_NOTES = '[Charge Point Action] FETCH_CHARGE_PORT_NOTES',
  FETCH_CHARGE_PORT_NOTES_SUCCESS = '[Charge Point Action] FETCH_CHARGE_PORT_NOTES_SUCCESS',
  FETCH_CHARGE_PORT_NOTES_FAILED = '[Charge Point Action] FETCH_CHARGE_PORT_NOTES_FAILED',
  FETCH_ERROR_RATE = '[Charge Point Action] FETCH_ERROR_RATE',
  FETCH_ERROR_RATE_SUCCESS = '[Charge Point Action] FETCH_ERROR_RATE_SUCCESS',
  FETCH_ERROR_RATE_ERROR = '[Charge Point Action] FETCH_ERROR_RATE_ERROR',
  FETCH_LAST_CHARGING_SESSION_STATUS = '[Charge Point Action] FETCH_LAST_CHARGING_SESSION_STATUS',
  FETCH_LAST_CHARGING_SESSION_STATUS_FAILED = '[Charge Point Action] FETCH_LAST_CHARGING_SESSION_STATUS_FAILED',
  FETCH_LAST_CHARGING_SESSION_STATUS_SUCCESS = '[Charge Point Action] FETCH_LAST_CHARGING_SESSION_STATUS_SUCCESS',
}

@Injectable()
export class ChargePortActions {
  public static createSetUnlockButtonStateAction = (
    payload: ProgressStates
  ): IActionWithPayload<ProgressStates> => ({
    type: ChargePortActionTypes.SET_UNLOCK_BUTTON_STATE,
    payload: payload,
  });

  public static createResetUnlockButtonStateAction = (): IActionWithPayload<void> => ({
    type: ChargePortActionTypes.RESET_UNLOCK_BUTTON_STATE,
  });

  public static createSetRebootButtonStateAction = (
    payload: ProgressStates
  ): IActionWithPayload<ProgressStates> => ({
    type: ChargePortActionTypes.SET_REBOOT_BUTTON_STATE,
    payload: payload,
  });

  public static createResetRebootButtonStateAction = (): IActionWithPayload<void> => ({
    type: ChargePortActionTypes.RESET_REBOOT_BUTTON_STATE,
  });

  public static createSetInitiateChargingButtonStateAction = (
    payload: ProgressStates
  ): IActionWithPayload<ProgressStates> => ({
    type: ChargePortActionTypes.SET_INITIATE_CHARGING_BUTTON_STATE,
    payload: payload,
  });

  public static createResetInitiateChargingButtonStateAction = (): IActionWithPayload<void> => ({
    type: ChargePortActionTypes.RESET_INITIATE_CHARGING_BUTTON_STATE,
  });

  public static createSetStopChargingButtonStateAction = (
    payload: ProgressStates
  ): IActionWithPayload<ProgressStates> => ({
    type: ChargePortActionTypes.SET_STOP_CHARGING_BUTTON_STATE,
    payload: payload,
  });

  public static createResetStopChargingButtonStateAction = (): IActionWithPayload<void> => ({
    type: ChargePortActionTypes.RESET_STOP_CHARGING_BUTTON_STATE,
  });

  public static createFetchChargePointStatus = (
    chargingStationUuid: string,
    chargePortId: string
  ): IActionWithPayload<any> => ({
    type: ChargePortActionTypes.START_FETCH_CHARGE_PORT_STATUS,
    payload: { chargingStationUuid, chargePortId },
  });

  public static createFetchChargePointStatusSuccess = (
    chargePortId: string,
    result: IChargePointStatus
  ): IActionWithPayload<any> => ({
    type: ChargePortActionTypes.FETCH_CHARGE_PORT_STATUS_SUCCESS,
    payload: { chargePortId, result },
  });

  public static createFetchChargePortNotes = (
    chargingStationUuid: string,
    chargePortUuid: string,
    chargePortId: string
  ): IActionWithPayload<any> => ({
    type: ChargePortActionTypes.FETCH_CHARGE_PORT_NOTES,
    payload: { chargingStationUuid, chargePortUuid, chargePortId },
  });

  public static createFetchChargePortNotesSuccess = (
    chargePortId: string,
    result: ChargePortNote[]
  ): IActionWithPayload<any> => ({
    type: ChargePortActionTypes.FETCH_CHARGE_PORT_NOTES_SUCCESS,
    payload: { chargePortId, result },
  });

  public static createFetchChargePortNotesFailed = (
    chargePortId: string
  ): IActionWithPayload<any> => ({
    type: ChargePortActionTypes.FETCH_CHARGE_PORT_NOTES_FAILED,
    payload: { chargePortId },
  });

  public static fetchErrorRate = (
    chargePortId: string,
    dateFrom: string,
    dateTo: string
  ): IActionWithPayload<any> => ({
    type: ChargePortActionTypes.FETCH_ERROR_RATE,
    payload: { chargePortId, dateFrom, dateTo },
  });

  public static createFetchLastChargingSessionStatusSuccess = (
    chargePortId: string,
    result: ChargingSessionInformation
  ): IActionWithPayload<any> => ({
    type: ChargePortActionTypes.FETCH_LAST_CHARGING_SESSION_STATUS_SUCCESS,
    payload: { chargePortId, result },
  });
}
