<div class="modal-header">
  <div>
    <ng-content></ng-content>
  </div>
  <eop-icon
    *ngIf="config.displayClosingButton && !hideClosingButton"
    [identifier]="'eoperate-ic-close'"
    [color]="IconColor.NORMAL"
    [clickable]="true"
    [dataTestId]="'close-modal'"
    [vaylensFont]="true"
    (click)="closeModal()"
  ></eop-icon>
</div>
