import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColor, IconSize } from '@widgets/eop-icon';

export enum ResultBannerState {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

@Component({
  selector: 'eop-result-banner',
  templateUrl: './result-banner.component.html',
  styleUrls: ['./result-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultBannerComponent {
  readonly IconSize = IconSize;
  readonly IconColor = IconColor;

  @Input() iconName = 'check-circle-filled';
  @Input() headline: string;
  @Input() message: string;
  @Input() state: ResultBannerState = ResultBannerState.SUCCESS;

  getIconColor(): IconColor {
    switch (this.state) {
      case ResultBannerState.SUCCESS:
        return IconColor.SUCCESS;
      case ResultBannerState.ERROR:
        return IconColor.ERROR;
      case ResultBannerState.WARNING:
        return IconColor.WARNING;
    }
  }
}
