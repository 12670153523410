import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { PermissionService } from '@shared/services/util';
import { Observable } from 'rxjs';

@Injectable()
export class AccessGuardPermissions implements CanActivate {
  constructor(private permissionService: PermissionService) {}

  public canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    for (const permission of route.data.permission) {
      if (this.permissionService.userHasPermission(permission)) {
        return true;
      }
    }
    return false;
  }
}
