import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 0, ellipsis = '...'): string {
    if (limit === 0 || !value) {
      return value;
    }
    if (limit > value.length) {
      limit = value.length;
      ellipsis = '';
    }
    const cutValue = value.substring(0, limit) + ellipsis;
    return cutValue;
  }
}
