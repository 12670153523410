import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { ModalCardContentComponent } from './modal-card-content/modal-card-content.component';
import { ModalCardFooterComponent } from './modal-card-footer/modal-card-footer.component';
import { ModalCardHeaderComponent } from './modal-card-header/modal-card-header.component';
import { ModalCardComponent } from './modal-card.component';

@NgModule({
  declarations: [
    ModalCardContentComponent,
    ModalCardFooterComponent,
    ModalCardHeaderComponent,
    ModalCardComponent,
  ],
  imports: [CommonModule, EopIconModule],
  exports: [
    ModalCardContentComponent,
    ModalCardFooterComponent,
    ModalCardHeaderComponent,
    ModalCardComponent,
  ],
})
export class ModalCardModule {}
