<div class="address-filter">
  <eop-address-element
    class="address-element"
    #country
    [placeholder]="'GLOBAL.COUNTRY' | translate"
    [values]="countries"
    [translationPrefix]="'GLOBAL.'"
    [disabled]="disableCountry"
    (selectionChange)="onCountryChange($event)"
  ></eop-address-element>
  <eop-address-city-zip-element
    class="address-element"
    #cityZip
    [placeholder]="
      ('GLOBAL.CITY' | translate) +
      ' ' +
      ('GLOBAL.OR' | translate) +
      ' ' +
      ('GLOBAL.ZIP' | translate)
    "
    [values]="cityZips"
    [disabled]="disableCityZip"
    (selectionChange)="onCityZipChange($event)"
  ></eop-address-city-zip-element>
  <eop-address-element
    class="address-element"
    #street
    [placeholder]="'GLOBAL.STREET' | translate"
    [values]="streets"
    [disabled]="disableSteet"
    (selectionChange)="onStreetChange($event)"
  ></eop-address-element>
</div>
