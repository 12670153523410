import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { SpinnerModule } from '@widgets/spinner/spinner.module';
import { MarkerPopupComponent } from './components/marker-popup/marker-popup.component';
import { MapComponent } from './map.component';

@NgModule({
  declarations: [MapComponent, MarkerPopupComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    EopIconModule,
    MatTooltipModule,
    RouterModule,
    SharedDirectivesModule,
    TranslateModule,
  ],
  exports: [MapComponent],
})
export class MapModule {}
