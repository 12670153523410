import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSize, IconWeight } from '@widgets/eop-icon';
import { Location } from './location-cell.model';

@Component({
  selector: 'location-cell',
  templateUrl: './location-cell.component.html',
  styleUrls: ['./location-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationCellComponent {
  @Input()
  location: Location;
  readonly IconWeight = IconWeight;
  readonly IconSize = IconSize;
}
