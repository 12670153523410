import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { MiniButtonModule } from '@widgets/mini-button/mini-button.module';
import { InputEditModeComponent } from './input-edit-mode.component';

@NgModule({
  declarations: [InputEditModeComponent],
  exports: [InputEditModeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, EopIconModule, MiniButtonModule],
})
export class InputEditModeModule {}
