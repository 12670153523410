<div class="flex-wrapper">
  <ng-content select=".back-button"></ng-content>
  <h2 class="headline">
    <div class="title">
      <span>{{ title && boldTitle ? title + ' ' : title }}</span>
      <span *ngIf="boldTitle && !shouldBoldTitleCrop" class="title-bold end-crop">{{
        boldTitle
      }}</span>
      <span
        *ngIf="boldTitle && shouldBoldTitleCrop"
        class="title-bold"
        [eopDigitsCrop]="boldTitle"
        [matTooltip]="boldTitle"
        [matTooltipPosition]="'above'"
      ></span>
    </div>
  </h2>
  <ng-content></ng-content>
</div>
