import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IconColor } from '@widgets/eop-icon';
import { ACTUAL_MODAL_CONFIG } from '../../modal.service';
import { ModalConfig } from '../../modalConfig';
import { ModalRef } from '../../modalRef';

/**
 * This Component represent the Header Section in the Modal Dialog.
 * It will automatically display the closing X if configured.
 */
@Component({
  selector: 'emob-modal-card-header',
  templateUrl: './modal-card-header.component.html',
  styleUrls: ['./modal-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCardHeaderComponent {
  @Input() hideClosingButton: boolean;
  readonly IconColor = IconColor;
  /**
   * This should never be called directly by the API users
   * @ignore
   */
  constructor(
    @Inject(ACTUAL_MODAL_CONFIG) public readonly config: ModalConfig,
    @Inject(ModalRef) private readonly modalRef: ModalRef
  ) {}

  /**
   * Closes the modal dialog and starts closing animation...
   * see {@link ModalRef#close}
   */
  public closeModal(): void {
    this.modalRef.close();
  }
}
