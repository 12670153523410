import { Language } from '@auth/login';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { ILanguageData, ILanguagesData, ITranslationsData } from './translation.model';

const defaultTranslations = require('../../../assets/i18n/translations-data.json');

export function createTranslateLoader(languageService: LanguageService) {
  return new TranslateCustomLoader(languageService);
}

export class TranslateCustomLoader implements TranslateLoader {
  constructor(private languageService: LanguageService) {}

  getTranslation(lang: Language): Observable<ILanguageData> {
    return this.languageService.loadLanguagesFromCMMCached().pipe(
      switchMap(translationData =>
        this.languageService.cmmTranslationsHasChangedSince(translationData.timestampIsoFormat)
      ),
      switchMap(reloadNeeded => {
        if (reloadNeeded) this.languageService.removeCachedLanguage();
        return this.languageService.loadLanguagesFromCMMCached();
      }),
      map((translations: ITranslationsData) =>
        this.extractSpecifiedLanguage(lang, translations.languagesData)
      )
    );
  }

  /**
   * 1. Fallback is the english translation (if a translation for certain language is missing)
   * 2. Fallback is the value maintained in 'translations-data.json' (if cmm is not reachable)
   */
  private extractSpecifiedLanguage(lang: Language, cmmLanguages: ILanguagesData): ILanguageData {
    const languages = this.overrideDefaultTranslations(defaultTranslations, cmmLanguages);
    const englishTranslations = Object.keys(languages.en);
    const langKeys = !languages[lang] ? [] : Object.keys(languages[lang]);

    if (!languages[lang]) {
      languages[lang] = {};
    }

    [...englishTranslations, ...langKeys].forEach((element: string) => {
      if (lang === Language.Empty) {
        languages[lang][element] = element;
      } else if (!languages[lang][element]) {
        languages[lang][element] = languages.en[element];
      }
    });

    return languages[lang];
  }

  private overrideDefaultTranslations(
    defaultLanguage: ILanguagesData,
    language: ILanguagesData
  ): ILanguagesData {
    const mergedLanguagesData: ILanguagesData = {};
    const mergedKeys = [...Object.keys(defaultLanguage), ...Object.keys(language)];
    mergedKeys.forEach(
      key => (mergedLanguagesData[key] = Object.assign({}, defaultLanguage[key], language[key]))
    );
    return mergedLanguagesData;
  }
}
