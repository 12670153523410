<form [formGroup]="form">
  <input
    #input
    type="text"
    spellcheck="false"
    matInput
    formControlName="country"
    autocomplete="off"
    [matAutocomplete]="auto"
    [placeholder]="'GLOBAL.COUNTRY' | translate"
    (input)="onInput(input.value)"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    (optionSelected)="onSelect($event.option.value)"
  >
    <mat-option
      *ngFor="let value of filteredCountries | translatedSort"
      class="item"
      [value]="value"
    >
      {{ 'GLOBAL.' + value | translate }}
    </mat-option>
  </mat-autocomplete>
</form>
