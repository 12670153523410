import { Component, HostBinding, Input } from '@angular/core';

// If gird range is adjusted $maxColumnCount in .scss must match the maximum too
export type ColumnRange = 1 | 2 | 3 | 4 | 5 | 6;

@Component({
  selector: 'eop-grid-list',
  template: '<ng-content></ng-content>',
  styleUrls: ['./grid-list.component.scss'],
})
export class GridListComponent {
  @Input() columns: ColumnRange = 3;
  /**
   * This will switch from 3 column layout to 2 column layout at certain break point
   *
   * Attention: columns input will be ignored if set to true
   */
  @Input() responsive = false;
  @HostBinding('class') get classes(): string {
    return (
      (this.responsive ? 'responsive-grid-list-' : 'grid-list-') +
      Math.max(Math.min(this.columns, 6), 1)
    );
  }
}
