import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { IconSize, IconWeight } from '@widgets/eop-icon';
import { Subscription } from 'rxjs';
import { PopoverRef } from '../../classes/popover-ref';
import { Item } from '../../services/filter-frontend.model';
import { FilterInputComponent } from '../filter-input.component';

@Component({
  selector: 'eop-status-cascading-select',
  templateUrl: './status-cascading-select.component.html',
  styleUrls: ['./status-cascading-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCascadingSelectComponent extends FilterInputComponent {
  @Input()
  key: string[];

  subscription = new Subscription();

  parentItems: Item[] = [];
  subItems: Item[] = [];
  subItemsFiltered: Item[] = [];

  readonly IconWeight = IconWeight;
  readonly IconSize = IconSize;

  constructor(@Optional() private popoverRef: PopoverRef) {
    super(popoverRef);
    this.parentItems = this.componentData.items.filter(it => !it.parent);
    this.subItems = this.componentData.items.filter(it => it.parent);
    this.subItemsFiltered = this.componentData.items.filter(
      it => it.parent && this.parentItems.find(pit => pit.id === it.parent).selected
    );
  }

  onParentItemClick(item: Item) {
    const items = this.remapItemById(this.parentItems, item);

    this.parentItems = items;
    this.subItems = this.subItems.map(si =>
      si.parent === item.id
        ? {
            ...si,
            selected: false,
          }
        : si
    );
    this.items = this.parentItems.concat(this.subItems);
    this.subItemsFiltered = this.items.filter(
      it => it.parent && this.parentItems.find(pit => pit.id === it.parent).selected
    );
    this.emitValues();
  }

  onSubItemClick(item: Item) {
    const items = this.remapItemById(this.subItems, item);
    this.subItems = items;
    this.items = this.parentItems.concat(this.subItems);
    this.emitValues();
  }

  emitValues() {
    this.componentData.items = this.parentItems.concat(this.subItems);
    if (this.popoverRef) {
      this.popoverRef.callback(this.componentData);
    }
    this.itemClick.emit(this.componentData);
  }

  private remapItemById(items: Item[], item: Item): Item[] {
    return items.map(i => {
      if (item.id === i.id) {
        return {
          ...item,
          selected: !i.selected,
        };
      }
      return i;
    });
  }
}
