<input
  #input
  [disabled]="disabled"
  type="text"
  autocomplete="off"
  [placeholder]="placeholder"
  spellcheck="false"
  matInput
  [value]="selectedValueString"
  (input)="onInput(input.value)"
  [matAutocomplete]="auto"
/>
<mat-autocomplete
  #auto="matAutocomplete"
  autoActiveFirstOption
  (optionSelected)="onSelect($event.option.value)"
>
  <mat-option
    *ngFor="let value of (filteredCities | sort).concat(filteredZips | sort)"
    class="item"
    [value]="value"
  >
    {{ value }}
  </mat-option>
</mat-autocomplete>
