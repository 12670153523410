import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorsHandler implements ErrorHandler {
  handleError(error: Error): void {
    if (this.isServerError(error)) {
      console.error('(UNHANDLED) SERVER ERROR: ', error);
    } else {
      console.error('CLIENT ERROR:', error);
    }
  }

  private isServerError(error: Error): boolean {
    return error instanceof HttpErrorResponse;
  }
}
