import { Injectable } from '@angular/core';
import { Language } from '@auth/login';
import { Library, Loader } from '@googlemaps/js-api-loader';
import { LanguageService } from '@shared/translate/language.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapLoaderService {
  private userLanguage: Language;
  private loader: Loader;

  constructor(private languageService: LanguageService) {
    this.userLanguage = this.languageService.getUserLanguageWithFallback();
    this.loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'],
      language: this.userLanguage.toString(),
    });
  }

  initMapLoader() {
    this.loader.importLibrary('maps').catch(error => {
      console.error(error);
    });
  }

  importLibrary(name: Library): Promise<any> {
    return this.loader.importLibrary(name);
  }
}
