import { NgReduxRouter, NgReduxRouterModule } from '@angular-redux/router';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ContentModule } from '@content/content.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GlobalErrorsHandler } from '@shared/errors/global-errors-handler';
import { BlobErrorHttpInterceptor } from '@shared/interceptors/blob-error-http-interceptor';
import { LogoutIfNotAuthenticatedInterceptor } from '@shared/interceptors/logout-if-not-authenticated-interceptor';
import { UrlEncodingInterceptor } from '@shared/interceptors/url-encoding.interceptor';
import { WithCredentialsInterceptor } from '@shared/interceptors/with-credentials-interceptor';
import { metaReducers } from '@shared/ngrx-meta-reducer/ngrx-meta.functions';
import { SharedModule } from '@shared/shared.module';
import { RootEpics } from '@shared/store/epics/root.epics';
import { IAppState } from '@shared/store/models';
import { OldStoreModule } from '@shared/store/module';
import { rootReducers } from '@shared/store/reducers';
import { StoreManager } from '@shared/store/store.manager';
import { LanguageService } from '@shared/translate/language.service';
import { createTranslateLoader } from '@shared/translate/translate-custom.loader';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { ModalModule } from '@widgets/modal/modal.module';
import { WidgetModule } from '@widgets/widgets.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { environment } from '../environments/environment';
import { AccessGuardLogged } from './app.access.guards.logged';
import { AccessGuardPermissions } from './app.access.guards.permissions';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    InlineSVGModule.forRoot({ baseUrl: '/' }),
    RouterModule.forRoot(ROUTES),
    SharedModule,
    EopIconModule,
    WidgetModule,
    ContentModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [LanguageService],
      },
    }),
    NgReduxModule,
    NgReduxRouterModule.forRoot(),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    OldStoreModule,
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    AccessGuardPermissions,
    AccessGuardLogged,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorsHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutIfNotAuthenticatedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlEncodingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManager,
    },
  ],
})
export class AppModule {
  constructor(
    public ngRedux: NgRedux<IAppState>,
    public ngReduxRouter: NgReduxRouter,
    public rootEpics: RootEpics,
    private storeManager: StoreManager
  ) {
    const rootEpic = this.rootEpics.createRootEpics();
    const store = this.storeManager.configureStore(rootReducers, rootEpic);
    ngRedux.provideStore(store);
    ngReduxRouter.initialize();
  }
}
