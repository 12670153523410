import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { ChipLiteColor } from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';

export interface TitleChip {
  text: string;
  chipColor: ChipLiteColor;
}

export enum PaddingSize {
  NORMAL,
  MEDIUM,
  BIG,
}

export enum TitleSize {
  MINI,
  SMALL,
  MEDIUM,
  BIG,
}

@Component({
  selector: 'eop-toggle-box',
  templateUrl: './toggle-box.component.html',
  styleUrls: ['./toggle-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleBoxComponent {
  @Input() title: string;
  @Input() titleSize = TitleSize.SMALL;
  @Input() titleChip: TitleChip;
  @Input() description = '';
  @Input() infoText: string;
  @Input() hasContent = false;
  @Input() hideContentLine = false;
  @Input() hasGrayBackground = false;
  @Input() paddingSize = PaddingSize.NORMAL;
  @Input() activated = false;
  @Input() toggleTooltip = '';
  @Input() showDisabledToggle = false;
  @Input() disabled = false;
  @Input() titleError = false;
  @Input() editMode = true;
  @Output() change = new EventEmitter<boolean>();

  protected readonly IconColor = IconColor;
  protected readonly IconWeight = IconWeight;
  protected readonly TitleSize = TitleSize;
  protected readonly PaddingSize = PaddingSize;
}
