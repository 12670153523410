import { AnimationEvent } from '@angular/animations';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  HostBinding,
  HostListener,
  NgZone,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { modalContainerAnimations } from './modal-container.animation';

/**
 * This is just for internal purposes.
 * It describes the opened modal dialog itself.
 * @ignore
 */
@Component({
  animations: [modalContainerAnimations.dialogContainer],
  selector: 'emob-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContainerComponent extends BasePortalOutlet {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet!: CdkPortalOutlet;

  private readonly _animationDone$ = new EventEmitter<AnimationEvent>();

  @HostBinding('@dialogContainer') public _state: 'void' | 'enter' | 'exit' = 'enter';

  @HostListener('@dialogContainer.done', ['$event'])
  public onAnimationDone(event: AnimationEvent): void {
    this._animationDone$.emit(event);
  }

  public get animationDone$(): Observable<AnimationEvent> {
    return this._animationDone$.asObservable();
  }

  constructor(private readonly zone: NgZone) {
    super();
  }

  attachTemplatePortal<C>(portal?: TemplatePortal<C>): never {
    throw new Error(
      'Currently we do not support templete references. Please use a proper component instance for a Modal Dialog'
    );
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  /** Starts the dialog exit animation. */
  _startExitAnimation(): void {
    this.zone.run(() => {
      this._state = 'exit';
    });
  }
}
