import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import * as d3 from 'd3';
import { Arc, DefaultArcObject } from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export interface PieChartData {
  label: string;
  count: number;
}

export interface LabelWithTooltip {
  label: string;
  tooltip: string;
}

interface InfoIcon {
  id: number;
  label: string;
  tooltip: string;
}

interface ArcData {
  data: PieChartData;
  endAxle: number;
  index: number;
  padAngle: number;
  startAngle: number;
  value: number;
  innerRadius: number;
  outerRadius: number;
  endAngle: number;
}

@Component({
  selector: 'eop-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.Emulated,
})
export class PieChartComponent implements AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() legendTitle: string;
  @Input() itemLabel: string;
  @Input() itemsLabel: string;
  @Input() dataSet: PieChartData[] = [];
  infoIcons: InfoIcon[] = [];
  @Input() set labelsWithTooltip(items: LabelWithTooltip[]) {
    this.infoIcons = items.map((it, index) => {
      return {
        ...it,
        id: index,
      };
    });
  }

  IconColor = IconColor;
  IconWeight = IconWeight;

  id: string = crypto.randomUUID();

  width = 400;
  height = 400;

  // legend dimensions
  private legendRectSize = 20; // defines the size of the colored circles in legend
  private legendSpacing = 30; // defines spacing between circles

  private svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

  private radius: number;

  private arc: Arc<any, DefaultArcObject> | Arc<any, ArcData> | any;
  private pie:
    | d3.Pie<
        any,
        | number
        | {
            valueOf(): number;
          }
      >
    | any;
  private color: d3.ScaleOrdinal<string, any, number>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe(event => {
        this.scaleChartSize();
      });
  }

  ngAfterViewInit() {
    this.initSvg();
    this.drawChart(this.dataSet.reverse());
    // draw middle circle with dropshadow on top
    this.svg
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 45)
      .attr('fill', '#FFFFFF')
      .attr('filter', 'url(#dropshadow)');

    let wrapperBox: any;
    if ((d3.select(`.svg-chart-wrapper-${this.id}`).node() as any).getBBox) {
      wrapperBox = (d3.select(`.svg-chart-wrapper-${this.id}`).node() as any).getBBox();
      this.width = wrapperBox.width;
      this.height = wrapperBox.height;
    }
    d3.select(`#pie-chart-${this.id}`)
      .attr('width', this.width)
      .attr('height', this.height + 60);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initSvg() {
    this.svg = d3
      .select(`.pie-chart-wrapper-${this.id}`)
      .append('svg')
      .attr('id', `pie-chart-${this.id}`);

    this.radius = Math.min(this.width, this.height) / 2;

    this.color = d3Scale
      .scaleOrdinal()
      .range([
        'pie-color-0',
        'pie-color-1',
        'pie-color-2',
        'pie-color-3',
        'pie-color-4',
        'pie-color-5',
        'pie-color-6',
        'pie-color-7',
        'pie-color-8',
        'pie-color-9',
        'pie-color-10',
        'pie-color-11',
        'pie-color-12',
        'pie-color-13',
        'pie-color-14',
      ]);

    this.arc = d3Shape
      .arc()
      .outerRadius(this.radius - 10)
      .innerRadius(this.radius - 160);

    this.pie = d3Shape
      .pie()
      .sort((d: PieChartData | any) => d.count)
      .value((d: PieChartData | any) => d.count);

    this.svg = d3
      .select(`svg#pie-chart-${this.id}`)
      .append('g')
      .attr('class', `svg-chart-wrapper-${this.id}`)
      .attr('transform', 'translate(' + this.width / 2 + ',' + (this.height / 2 + 30) + ')');
  }

  private drawChart(data: PieChartData[]) {
    const g = this.svg
      .selectAll('.arc')
      .data(this.pie(data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    const path = g
      .append('path')
      .attr('d', this.arc)
      .attr('class', (d: ArcData) => this.color(d.data.label));

    this.dropShadow();
    this.setLegend();
    const tooltip = this.appendTooltip();
    this.setTooltipMouseEvents(tooltip, path);
    this.setInitialAnimation(path);
    this.addTitles();
    this.scaleChartSize();
  }

  private scaleChartSize() {
    const parentWidth = document.getElementsByTagName('eop-pie-chart')[0]?.clientWidth;
    const svgWidth = document
      .getElementsByClassName(`svg-chart-wrapper-${this.id}`)[0]
      .getClientRects()[0]?.width;
    const percentageScale = parseInt(parentWidth?.toFixed(0)) / parseInt(svgWidth?.toFixed(0));
    if (percentageScale < 1) {
      d3.select(`#pie-chart-${this.id}`).attr(
        'style',
        `transform: scale(${percentageScale}); transform-origin: left`
      );
    }
    if (percentageScale > 1) {
      d3.select(`#pie-chart-${this.id}`).attr(
        'style',
        `transform: scale(1); transform-origin: left`
      );
    }
  }

  private appendTooltip(): d3.Selection<HTMLDivElement, unknown, HTMLElement, any> {
    const tooltip = d3
      .select(`.pie-chart-wrapper-${this.id}`)
      .append('div')
      .attr('class', 'tooltip');
    tooltip.append('div').attr('class', 'label');
    return tooltip;
  }

  private setTooltipMouseEvents(
    tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
    path: d3.Selection<SVGPathElement, unknown, SVGGElement, unknown>
  ) {
    tooltip.style('display', 'none');

    // mouse event handlers are attached to path so they need to come after its definition
    path.on('mouseover', (mouseEvent: MouseEvent, d: ArcData) => {
      tooltip.select('.label').html(d.data.label + ' (' + this.getLabel(d.data.count) + ')'); // set current label
      tooltip.style('display', 'block');
      tooltip.style('position', 'absolute'); // set display
      tooltip
        .style('top', mouseEvent.offsetY - 10 + 'px')
        .style('left', mouseEvent.offsetX + 10 + 'px');
    });

    path.on('mouseout', (mouseEvent: MouseEvent, d: ArcData) => {
      tooltip.style('display', 'none'); // hide tooltip for that element
    });

    path.on('mousemove', (mouseEvent: MouseEvent, d: ArcData) => {
      tooltip
        .style('top', mouseEvent.offsetY - 10 + 'px')
        .style('left', mouseEvent.offsetX + 10 + 'px');
    });
  }

  private setInitialAnimation(path: d3.Selection<SVGPathElement, unknown, SVGGElement, unknown>) {
    const self = this;
    path
      .transition()
      .duration(2000)
      .attrTween('d', (d: ArcData | any) => {
        const interpolate = d3.interpolate(path, d);
        path = interpolate(0);
        return t => self.arc(interpolate(t));
      });
  }

  private setLegend() {
    const self = this;
    // define legend
    const legendWrapper = d3
      .select(`.svg-chart-wrapper-${this.id}`)
      .append('g')
      .attr('class', 'legend-wrapper');

    const legend = legendWrapper
      .selectAll('.legend') // selecting elements with class 'legend'
      .data(this.color.domain().reverse()) // refers to an array of labels from our dataSet
      .enter() // creates placeholder
      .append('g') // replace placeholders with g elements
      .attr('class', 'legend') // each g is given a legend class
      .attr('transform', (d, i) => {
        const height = self.legendRectSize + self.legendSpacing + 25; // height of element is the height of the colored circles plus the spacing
        const offset = height * self.color.domain().length; // vertical offset of the entire legend = height of a single element
        const vert = i * height - offset + 250; // the top of the element is hifted up or down from the center using the offset defiend earlier and the index of the current element 'i'
        return 'translate(' + 285 + ',' + (-200 - vert) / 2 + ')'; //return css translate
      });

    // adding colored circles to legend
    legend
      .append('rect') // append rectangle circles to legend
      .attr('width', self.legendRectSize) // width of rect size is defined above
      .attr('height', self.legendRectSize) // height of rect size is defined above
      .attr('rx', 15)
      .attr('class', self.color);

    let legendPercentageSummary = 0;
    const total = d3.sum(
      self.dataSet.map(d => {
        return d.count;
      })
    );

    // adding text to legend
    legend
      .append('g')
      .attr('class', `legend-item-wrapper-${this.id}`)
      .append('text')
      .attr('class', (d: string, i: number) => {
        if (self.isLabelTooltipEnabled(d)) {
          return 'text-with-icon-' + i;
        }
        return 'text-without-icon';
      })
      .attr('x', self.legendRectSize + self.legendSpacing - 20)
      .attr('y', self.legendRectSize - self.legendSpacing + 25)
      .attr('font-weight', 700)
      .text(d => {
        const currentValue = self.dataSet.find(data => data.label === d).count;
        let percent = Math.round(1000 * (currentValue / total)) / 10;
        legendPercentageSummary = legendPercentageSummary + percent;
        percent =
          100 - legendPercentageSummary < 0
            ? Math.round(10 * (percent + (100 - legendPercentageSummary))) / 10
            : percent; //it decreases last value by difference when sum is not 100%
        return `${percent}% `;
      })
      .append('tspan')
      .attr('font-weight', 300)
      .text(d => {
        return d;
      });

    this.setLegendItemTooltips();
  }

  private setLegendItemTooltips() {
    const self = this;

    const infoIconTooltip = d3
      .select(`.pie-chart-wrapper-${this.id}`)
      .append('div')
      .attr('class', 'legend-tooltip');
    infoIconTooltip.style('display', 'none');
    infoIconTooltip.append('div').attr('class', 'legend-tooltip-label');

    d3.selectAll(`.legend-item-wrapper-${this.id}`)
      .append('foreignObject')
      .attr('width', 30)
      .attr('height', 30)
      .attr('x', (d: string, i: number, nodes: any[]) => {
        if (self.isLabelTooltipEnabled(d)) {
          const elementBox = (d3.selectAll('.text-with-icon-' + i).node() as any).getBBox();
          return elementBox.width + self.legendSpacing + 8;
        }
      })
      .attr('y', self.legendRectSize - self.legendSpacing + 8)
      .append('xhtml:div')
      .attr('class', 'tooltip-info-icon')
      .on('mouseover', (mouseEvent: MouseEvent, d: string) => {
        const tooltipText = self.infoIcons.find(i => i.label === d).tooltip;

        var x =
          mouseEvent.pageX -
          document.getElementsByClassName(`pie-chart-wrapper-${self.id}`)[0].getBoundingClientRect()
            .x +
          10;
        var y =
          mouseEvent.pageY -
          document.getElementsByClassName(`pie-chart-wrapper-${self.id}`)[0].getBoundingClientRect()
            .y +
          10;

        infoIconTooltip.select('.legend-tooltip-label').html(tooltipText); // set current label
        infoIconTooltip.style('display', 'block');
        infoIconTooltip.style('position', 'absolute'); // set display
        infoIconTooltip.style('top', y + 5 + 'px').style('left', x - 100 + 'px');
      })
      .on('mouseout', (mouseEvent: MouseEvent, d: ArcData) => {
        infoIconTooltip.style('display', 'none'); // hide tooltip for that element
      })
      .on('mousemove', (mouseEvent: MouseEvent, d: ArcData) => {
        var x =
          mouseEvent.pageX -
          document.getElementsByClassName(`pie-chart-wrapper-${self.id}`)[0].getBoundingClientRect()
            .x +
          10;
        var y =
          mouseEvent.pageY -
          document.getElementsByClassName(`pie-chart-wrapper-${self.id}`)[0].getBoundingClientRect()
            .y +
          10;

        infoIconTooltip.style('top', y + 5 + 'px').style('left', x - 100 + 'px');
      })
      .html((d: string, i: number) => {
        if (self.isLabelTooltipEnabled(d)) {
          return d3
            .select(
              `.chart-tooltip-icon-${self.infoIcons.findIndex(icon => icon.label === d)}-${self.id}`
            )
            .html();
        }
      });
  }

  private addTitles() {
    const self = this;
    d3.select(`#pie-chart-${this.id}`)
      .append('text')
      .attr('x', 0)
      .attr('y', 10)
      .attr('text-anchor', 'left')
      .style('font-size', '14px')
      .attr('font-weight', 700)
      .text(self.title.toUpperCase());

    d3.select(`#pie-chart-${this.id}`)
      .append('text')
      .attr('x', 485)
      .attr('y', 10)
      .attr('text-anchor', 'left')
      .style('font-size', '14px')
      .attr('font-weight', 700)
      .text(self.legendTitle.toUpperCase());
  }

  private dropShadow() {
    let reposition = 1; // adjust to prevent clipping
    let scaleBoundingBox = 4; // adjust to prevent clipping
    let angle = -0.5 * Math.PI; // angle of the offset, measured from the right, clockwise in radians
    let distance = 3; // how far the shadow is from object
    let blur = 3; // ammount of Gausian blur
    let shadowColor = '#003152';
    let shadowOpacity = 0.4;

    const dropShadow = this.svg
      .append('filter')
      .attr('id', 'dropshadow')
      .attr('x', (1 - scaleBoundingBox) / 2 + reposition * Math.cos(angle))
      .attr('y', (1 - scaleBoundingBox) / 2 - reposition * Math.sin(angle))
      .attr('width', scaleBoundingBox)
      .attr('height', scaleBoundingBox)
      .attr('filterUnits', 'objectBoundingBox');
    dropShadow
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', blur)
      .attr('result', 'blur');
    dropShadow
      .append('feOffset')
      .attr('in', 'blur')
      .attr('dx', distance * Math.cos(angle))
      .attr('dy', distance * -Math.sin(angle))
      .attr('result', 'offsetBlur');
    dropShadow
      .append('feFlood')
      .attr('in', 'offsetBlur')
      .attr('flood-color', shadowColor)
      .attr('flood-opacity', shadowOpacity)
      .attr('result', 'offsetColor');
    dropShadow
      .append('feComposite')
      .attr('in', 'offsetColor')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in')
      .attr('result', 'offsetBlur');

    const feMerge = dropShadow.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  }

  private getLabel(amount: number): string {
    return `${amount} ${amount > 1 ? this.itemsLabel : this.itemLabel}`;
  }

  private isLabelTooltipEnabled(dataLabelToCheck: string): boolean {
    return !!this.infoIcons.find(it => it.label === dataLabelToCheck);
  }
}
