import { Platform } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { DateConversionService } from '@shared/services/util';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

const enum MatDatepickerDisplayFormats {
  DATE_LABEL = 'l',
}

@Injectable()
export class DateFormatAdapter extends NativeDateAdapter {
  private dateFormat: string;
  private dateFormatUppercase: string;
  private userLocale: string;
  constructor(
    private dateConversionService: DateConversionService,
    private datepipe: DatePipe,
    private platform: Platform
  ) {
    super('de-DE', platform);
    combineLatest([
      this.dateConversionService.datePattern$,
      this.dateConversionService.settingValueList$,
    ])
      .pipe(filter((settings, list) => settings !== null && list !== null))
      .subscribe(() => {
        this.dateFormat = this.dateConversionService.getDateFormatFromUser(false);
        this.dateFormatUppercase = this.dateConversionService.getDateFormatFromUser(true);
      });
    this.dateConversionService.userLocale$.pipe(filter(val => val !== null)).subscribe(locale => {
      this.setLocale(locale);
      this.userLocale = locale;
    });
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === MatDatepickerDisplayFormats.DATE_LABEL) {
      return this.datepipe.transform(date, this.dateFormat);
    } else {
      return date.toLocaleDateString(this.userLocale, { month: 'short', year: 'numeric' });
    }
  }

  parse(value: any): Date | null {
    if (!value) {
      return null;
    }

    if (typeof value === 'number') {
      return new Date(value);
    }

    const momentDate = moment(value, this.dateFormatUppercase);
    return momentDate.isValid() ? momentDate.toDate() : new Date(Date.parse(value));
  }
}
