import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@widgets/snackbar/snackbar.service';

@Directive({
  selector: '[eopDigitsCrop]',
})
export class DigitsCropDirective implements OnChanges {
  /*
   * original text, required
   */
  @Input('eopDigitsCrop') eopDigitsCropText;

  /*
   * length in digits after cropping, optional
   */
  @Input() totalDigits: number = 15;

  /*
   * length in digits of prefix, optional
   */
  @Input() prefixDigits: number = 6;

  /*
   * Internal Span element used to handle selection and copy events
   */
  private textHolderSpanElement: HTMLSpanElement;

  /*
   * Identifier in the mouse event for the right click
   */
  private readonly RIGHT_CLICK_EVENT_IDENTIFIER = 2;

  // This needs to be done with the mousedown because of the mainly problems with the mozilla
  // See: https://getkt.com/blog/how-to-interact-with-the-clipboard-using-javascript/#execCommand
  @HostListener('mousedown', ['$event']) rightClickEventHandler(event: MouseEvent): void {
    if (event.button !== this.RIGHT_CLICK_EVENT_IDENTIFIER) return;
    navigator.clipboard.writeText(this.eopDigitsCropText);
    this.snackbar.openSnackbar(this.translate.instant('GLOBAL.TEXT_COPIED'));
  }

  constructor(
    private el: ElementRef,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    this.createTextHolderSpanElement();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.eopDigitsCropText && this.totalDigits) {
      this.textHolderSpanElement.textContent = this.cropText(
        this.eopDigitsCropText,
        this.totalDigits
      );
    }
  }

  private cropText(text: string, totalDigits: number): string {
    if (text && totalDigits && text.length > totalDigits) {
      const prefix = text.slice(0, this.prefixDigits);
      const suffixStart = text.length - (totalDigits - (this.prefixDigits + 3));
      const suffix = text.slice(suffixStart);
      return `${prefix}...${suffix}`;
    } else {
      return text;
    }
  }

  private createTextHolderSpanElement(): void {
    const input = document.createElement('span');
    this.textHolderSpanElement = this.el.nativeElement.appendChild(input);
    this.textHolderSpanElement.classList.add('eop-digits-crop--text-holder');
  }
}
