export enum ETimePeriod {
  LAST_HOUR = 'LAST_HOUR',
  LAST_4_HOURS = 'LAST_4_HOURS',
  LAST_12_HOURS = 'LAST_12_HOURS',
  LAST_24_HOURS = 'LAST_24_HOURS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_14_DAYS = 'LAST_14_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_60_DAYS = 'LAST_60_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  INDIVIDUAL_TIME_PERIOD = 'INDIVID_TIME_PERIOD',
}
