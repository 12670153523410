import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translatedSort',
})
export class TranslatedSortPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  transform(value: Array<string>): Array<string> {
    if (!value) {
      return null;
    }
    return value.sort((a, b) =>
      this.translate.instant('GLOBAL.' + a).localeCompare(this.translate.instant('GLOBAL.' + b))
    );
  }
}
