import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'innogy-box-custom-action',
  templateUrl: './box-custom-action.component.html',
  styleUrls: ['./box-custom-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxCustomActionComponent {
  @Input()
  label: string;

  @Input()
  disabled = false;

  @Output()
  actionClicked = new EventEmitter<void>();
}
