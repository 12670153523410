<div class="pie-chart-component">
  <div class="pie-chart-wrapper-{{ id }} pie-chart-wrapper"></div>
</div>

<ng-container *ngFor="let item of infoIcons">
  <eop-icon
    [class]="'chart-tooltip-icon chart-tooltip-icon-' + item.id + '-' + id"
    [vaylensFont]="true"
    [color]="IconColor.NORMAL"
    [identifier]="'info-circle-outlined'"
  ></eop-icon>
</ng-container>
