import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IUserLogin } from '@auth/login';
import { IAppState } from '@shared/store/models';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(private _store: NgRedux<IAppState>) {}

  getDecimalSeparator(): string {
    if (!this._store.getState().backendData.userLogin) {
      return;
    }
    const decimalSeparatorKey =
      this._store.getState().backendData.userLogin.settings.decimalSeparator;
    const settingValueList = this._store.getState().backendData.settingValueList;

    const decimalSeparatorValue = settingValueList.numberFormats.find(elem => {
      return elem.key === decimalSeparatorKey;
    });

    return decimalSeparatorValue ? decimalSeparatorValue.value : '.';
  }

  hasFrontendSessionStoreEnabled(): boolean {
    return this._store.getState().backendData.userLogin.settings.storeFrontendSession;
  }

  getAddress2(supplementLocal: string, supplementEN: string): { text: string; tooltip: string } {
    let tooltip = '';
    if (supplementLocal && supplementLocal !== '') {
      tooltip =
        supplementEN && supplementEN !== ''
          ? supplementLocal + ' | ' + supplementEN
          : supplementLocal;
    } else if (supplementEN && supplementEN !== '') {
      tooltip = supplementEN;
    }

    return {
      text:
        supplementLocal && supplementLocal !== ''
          ? supplementLocal
          : supplementEN && supplementEN !== ''
          ? supplementEN
          : '',
      tooltip,
    };
  }

  getUserLogin(): IUserLogin {
    return this._store.getState().backendData?.userLogin;
  }
}
