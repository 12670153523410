import { Pipe, PipeTransform } from '@angular/core';
import {
  EmaidGroupSimple,
  PlatformRoamingType,
} from '@manage/access-control/data/access-control.model';
import { TranslateService } from '@ngx-translate/core';
import { IChipsData } from '@widgets/innogy-chips/chipsData.interface';

@Pipe({
  name: 'roamingChip',
})
export class RoamingChipPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(emaidGroup: EmaidGroupSimple): IChipsData[] {
    const chips: IChipsData[] = [];
    if (emaidGroup && emaidGroup.platformRoamingType !== PlatformRoamingType.NONE) {
      chips.push({
        name: this.translate.instant('ACCESS_CONTROL.ROAMING_CHIP'),
        value: this.translate.instant('ACCESS_CONTROL.EMAID_GROUP_ROAMING_HELP_SHORT'),
      });
    }
    if (emaidGroup && emaidGroup.externalRoaming) {
      chips.push({
        name: this.translate.instant('ACCESS_CONTROL.ROAMING_PLUS_CHIP'),
        value: this.translate.instant('ACCESS_CONTROL.ROAMING_PLUS_HELP_TEXT_EMAID'),
      });
    }
    return chips;
  }
}
