import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { take } from 'rxjs/operators';
import { SharedRefService } from '../shared-ref-service/shared-ref-service.service';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { DEFAULT_MODAL_CONFIG, ModalConfig } from './modalConfig';
import { ModalRef } from './modalRef';

/**
 * This Injection token is filled with the data property passed to the [modal open call]{@link ModalService#open}.
 * You can inject this token in your child component to share data between those two component instances
 */
// tslint:disable-next-line:no-any
export const MODAL_DATA = new InjectionToken<any>('MODAL_DATA');

/**
 * This is currently just for internal use
 * @ignore
 */
export const ACTUAL_MODAL_CONFIG = new InjectionToken<ModalConfig>('MODAL_CONFIG');

/**
 * The ModalService provides the function to open a ModalDialog
 * with a certain component passed to it. This mechanism seems
 * to be the most light-weighted and performant one.
 */
@Injectable({ providedIn: 'root' })
export class ModalService {
  /**
   * This should never be called by any of the api users
   * @ignore
   */
  constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
    @Optional()
    @Inject(DEFAULT_MODAL_CONFIG)
    private readonly defaultConfig: ModalConfig,
    private readonly activeModalService: SharedRefService
  ) {}

  /**
   * Use this method to open a Modal Dialog.
   * It will covers the complete screen with a semi transparent black background (aka. backdrop).
   * Additionally it will blur the background in modern browsers when you use the {@Link ModalActiveDirective}.
   *
   * @param componentRef ClassReference to the component you want to instantiate inside the modal dialog
   * @param config optional values to overwrite the [default configuration]{@link DEFAULT_MODAL_CONFIG} and used
   * to fill the {@link MODAL_DATA} InjectionToken.
   * @returns can be used to interact with the open modal dialog instance.
   */
  public open<T>(
    componentRef: ComponentType<T>,
    config?: ModalConfig,
    closeExistingModals?: boolean
  ): ModalRef<T> {
    const modalConfig = { ...(this.defaultConfig || new ModalConfig()), ...config };
    const overlayRef = this.createOverlay(modalConfig);
    const dialogContainer = this.attachModalContainer(overlayRef, modalConfig);
    const dialogRef: ModalRef<T> = this._attachDialogContent(
      componentRef,
      dialogContainer,
      overlayRef,
      modalConfig
    );
    if (closeExistingModals) {
      this.closeAllModals();
    }
    if (!modalConfig.disableBackdropCloseAction) {
      overlayRef
        .backdropClick()
        .pipe(take(1))
        .subscribe(() => dialogRef.close());
    }
    this.activeModalService.openModal(dialogRef);
    return dialogRef;
  }

  /**
   * Use this method to close all modals
   */
  public closeAllModals(): void {
    this.activeModalService.closeAllModals();
  }

  private createOverlay(config: ModalConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachModalContainer(
    overlayRef: OverlayRef,
    config: ModalConfig
  ): ModalContainerComponent {
    const injector = new PortalInjector(this.injector, new WeakMap([[MODAL_DATA, config]]));
    const modalPortal = new ComponentPortal(ModalContainerComponent, null, injector);
    const modalRef = overlayRef.attach<ModalContainerComponent>(modalPortal);
    return modalRef.instance;
  }

  private createInjector<T>(
    config: ModalConfig,
    dialogRef: ModalRef<T>,
    dialogContainer: ModalContainerComponent
  ): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(ModalContainerComponent, dialogContainer);
    injectionTokens.set(MODAL_DATA, config.data);
    injectionTokens.set(ModalRef, dialogRef);
    injectionTokens.set(ACTUAL_MODAL_CONFIG, config);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private _attachDialogContent<T>(
    componentRef: ComponentType<T>,
    dialogContainer: ModalContainerComponent,
    overlayRef: OverlayRef,
    config: ModalConfig
  ): ModalRef<T> {
    const dialogRef = new ModalRef<T>(overlayRef, dialogContainer, this.activeModalService);
    const injector = this.createInjector(config, dialogRef, dialogContainer);
    const contentRef = dialogContainer.attachComponentPortal<T>(
      new ComponentPortal(componentRef, undefined, injector)
    );
    dialogRef.componentInstance = contentRef.instance;
    return dialogRef;
  }

  private getOverlayConfig(config: ModalConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy,
    });

    return overlayConfig;
  }
}
