import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * @ignore
 */
const animationBody = [
  state('void, exit', style({ opacity: 0, transform: 'scale(0.6)' })),
  state('enter', style({ transform: 'none' })),
  transition(
    '* => enter',
    animate('400ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 }))
  ),
  transition(
    '* => void, * => exit',
    animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'scale(0.6)' }))
  ),
];

/**
 * this is just used internal for the {@link ModelContainerComponent}
 * @ignore
 */
export const modalContainerAnimations: {
  readonly dialogContainer: AnimationTriggerMetadata;
} = {
  dialogContainer: trigger('dialogContainer', animationBody),
};
