import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DropdownItem } from '../dropdown-type';
import { dropdownAnimations } from '../dropdown.animation';
import { OptionComponent } from '../option/option.component';
import { DropdownOverlayContentDirective } from './dropdown-overlay-content.directive';

/**
 * @internal
 *
 * This component represents the overlay and is used to pass some properties to the
 * {@link DropdownOverlayDirective}
 */
@Component({
  selector: 'emob-dropdown-overlay',
  templateUrl: './dropdown-overlay.component.html',
  styleUrls: ['./dropdown-overlay.component.scss'],
  exportAs: 'emobDropdownOverlay',
  animations: [dropdownAnimations.overlay],
})
export class DropdownOverlayComponent {
  @Output() public selectedItemChange = new EventEmitter<DropdownItem>();

  @ViewChild('root', { static: true }) rootTemplate!: TemplateRef<string>;

  @ContentChild(DropdownOverlayContentDirective, { static: true })
  content!: DropdownOverlayContentDirective;

  @ContentChildren(OptionComponent, { descendants: true })
  options: QueryList<OptionComponent> = new QueryList<OptionComponent>();

  @ContentChild(CdkVirtualScrollViewport, { descendants: true })
  viewport!: CdkVirtualScrollViewport;

  @Input() items!: DropdownItem[];

  @Input() activeItemIndex: number | null = null;

  optionsClick$(): Observable<{ item: DropdownItem; index: number }> {
    return this.options.changes.pipe(
      switchMap((options: OptionComponent[]) => {
        const clicks$ = options.map((option: OptionComponent) => option.click$);
        return merge(...clicks$);
      })
    );
  }
}
