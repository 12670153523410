import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from '@auth/login';
import { EmssHtmlPageService, PermissionService } from '@shared/services/util';
import { LanguageService } from '@shared/translate/language.service';
import { Observable } from 'rxjs';
import { WikiComponent } from '../wiki.component';

@Component({
  selector: 'eop-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManualComponent extends WikiComponent implements OnInit {
  isPublic: boolean;

  constructor(
    private readonly elementRef: ElementRef,
    public readonly route: ActivatedRoute,
    public readonly cdr: ChangeDetectorRef,
    public readonly permissionService: PermissionService,
    public readonly languageService: LanguageService,
    public readonly emssHtmlPageService: EmssHtmlPageService,
    @Inject(DOCUMENT) public readonly document: Document,
    private router: Router
  ) {
    super(
      elementRef,
      route,
      cdr,
      permissionService,
      languageService,
      emssHtmlPageService,
      document
    );
  }

  ngOnInit(): void {
    this.isPublic = this.router.url.indexOf('/content/userManualPublic') !== -1;
    super.ngOnInit();
  }

  getHtmlPage$(): Observable<string> {
    if (!this.isPublic) return super.getHtmlPage$();
    const language = this.languageService.userHasLanguage(Language.German) ? 'de' : 'en';
    return this.emssHtmlPageService.getHtmlPage(this.pageType, language);
  }

  checkPermissions(document: Document): void {
    if (!this.isPublic) return super.checkPermissions(document);
    const notPublicElements: HTMLElement[] = Array.from(
      document.querySelectorAll('div[data-not-public]')
    );

    notPublicElements.forEach(element => (element.style.display = 'none'));
  }
}
