import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'eop-link-element',
  templateUrl: 'link-element.component.html',
  styleUrls: ['link-element.component.scss'],
})
export class LinkElementComponent implements OnInit, OnDestroy, OnChanges {
  private unsubscribe$ = new Subject<void>();

  @Input() textKey: string;
  @Input() disabled: boolean;
  @Input() isHidden: boolean;
  @Input() routePath: string;
  @Input() routerPathParams: any;
  @Input() linkIcon: string;
  @Input() enableIconTooltip: boolean;
  @Input() openInBlank = false;
  @Input() isExpanded: boolean;

  @Output()
  linkClick: EventEmitter<any> = new EventEmitter();
  @Output()
  onLinkActivation: EventEmitter<void> = new EventEmitter<void>();

  isActive: boolean = false;

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.isActive = this.isLinkActive(this.routePath);
        if (this.isActive) {
          this.onLinkActivation.emit();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.routePath && changes.routePath.firstChange) {
      this.isActive = this.isLinkActive(changes.routePath.currentValue);
      if (this.isActive) {
        this.onLinkActivation.emit();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  linkClicked() {
    if (!this.disabled) {
      this.linkClick.emit();
    }
  }

  isLinkActive(url: string): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url || baseUrl.includes(url);
  }
}
