<div class="filter-wrapper" *ngIf="chipsData.length > 0">
  <div class="filter-header" [ngClass]="{ collapsed: !expandedFilter }">
    <div class="icon-with-label">
      <eop-icon
        [identifier]="'eoperate-ic-filter'"
        [color]="IconColor.NORMAL"
        [weight]="IconWeight.BOLD"
        [size]="IconSize.SMALL"
        [vaylensFont]="true"
      ></eop-icon>
      <label class="filter-label">{{ 'GLOBAL.FILTER' | translate }}</label>
    </div>

    <eop-icon
      class="icon-action"
      (click)="onToggleFilter()"
      [identifier]="expandedFilter ? 'chevron-up-outlined' : 'chevron-down-outlined'"
      [color]="IconColor.NORMAL"
      [size]="IconSize.NORMAL"
      [vaylensFont]="true"
    ></eop-icon>
  </div>
  <div class="filter-content" [class]="{ hide: !expandedFilter }">
    <div class="filter-footer">
      <div class="chips">
        <eop-innogy-chips-lite
          *ngFor="let chip of chipsData"
          [text]="chip.chipName"
          [chipColor]="ChipLiteColor.DEFAULT"
          [chipSize]="ChipLiteSize.BIG"
          [rightIcon]="'close-outlined'"
          (rightIconClicked)="onChipRemoved(chip)"
          class="chip"
        ></eop-innogy-chips-lite>
      </div>
    </div>
  </div>
</div>
