import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * This card component is the structural component
 * taking care of proper positioning in the content area
 * of the modal dialog. This component will just project
 * {@Link ModelCardHeaderComponent}, {@Link ModelCardContentComponent} & {@Link ModelCardFooterComponent}
 * and ignores everything else.
 */
@Component({
  selector: 'emob-modal-card',
  templateUrl: './modal-card.component.html',
  styleUrls: ['./modal-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCardComponent {}
