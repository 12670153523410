import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'eop-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }
}
