import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { ChipColor } from '@widgets/innogy-chips/chipColor';

export interface ValueWithLink {
  value: string;
  link: string;
  navigationExtras: NavigationExtras;
}

export interface ValueWithAdditionalTooltip {
  value: string;
  additionalTooltipText: string;
}

@Component({
  selector: 'form-element',
  templateUrl: 'form-element.component.html',
  styleUrls: ['form-element.component.scss'],
})
export class FormElementComponent {
  IconWeight = IconWeight;
  IconSize = IconSize;
  IconColor = IconColor;

  _values: (ValueWithAdditionalTooltip | string | number | boolean)[] = [];

  @Input()
  label: string;
  @Input()
  set values(val: (ValueWithAdditionalTooltip | string | number | boolean)[]) {
    if (!val) {
      this._values = [];
    }
    this._values = val.filter(value => !!value);
  }
  @Input()
  valuesWithLink: ValueWithLink[];
  @Input()
  classWidth: string;
  @Input()
  mandatory: boolean;
  @Input()
  iconIdentifier: string;
  @Input()
  iconWeight: IconWeight;
  @Input()
  iconSize: IconSize;
  @Input()
  iconText: string;
  @Input()
  infoTextColor: IconColor;
  @Input()
  iconTooltip: string;
  @Input()
  iconTextColor: IconColor;
  @Input()
  disabled: boolean;
  @Input()
  showWaitIcon: boolean;
  @Input()
  infoText: string;
  @Input()
  contentTemplate: TemplateRef<any>;
  @Input()
  headerChipValue: string;
  @Input()
  headerChipColor = ChipColor.DEFAULT;
  @Input()
  headerChipTooltipText: string;
  @Input()
  chipValue: string;
  @Input()
  chipColor = ChipColor.DEFAULT;
  @Input()
  chipTooltipText: string;
  @Input()
  chipEmphasized = true;
  @Input()
  hideTextOverflow = false;
  @Input()
  slashSeparatedValues = false;
  @Input()
  breakLines = false;
  @Output()
  iconClicked: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  iconClick() {
    this.iconClicked.emit();
  }

  navigateRoute(valueWithLink: ValueWithLink) {
    this.router.navigate([valueWithLink.link], valueWithLink.navigationExtras);
  }
}
