import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChargingStationConnectorType } from '@operate/charging-infrastructure-detail/model/charging-station.model';

/**
 * List of domains to give a scenario to identify required icon
 */
export enum IconDomain {
  connector = 'connector',
  status = 'status',
  hardwareState = 'hardwareState',
}

/**
 * List of required return value type of an icon
 * id is identifier for icon, which is widely used as input for eop-icon component or tab component
 * tooltip is tooltip for icon to give an text expaination for icon
 */
export enum ReturnValueTypeEnum {
  id = 'id',
  tooltip = 'tooltip',
}

@Pipe({
  name: 'iconUtil',
})
export class IconUtilPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    value: string | boolean,
    domain: IconDomain,
    returnValueType: ReturnValueTypeEnum
  ): string {
    switch (returnValueType) {
      case ReturnValueTypeEnum.id: {
        switch (domain) {
          case IconDomain.connector:
            return this.getIconByConnectorType(<string>value);
          case IconDomain.hardwareState:
            return this.getIconIdByHardwareState(<boolean>value);
          case IconDomain.status:
            return 'Status';
          default:
            return '';
        }
      }
      case ReturnValueTypeEnum.tooltip: {
        switch (domain) {
          case IconDomain.hardwareState: {
            return this.getTooltipByHardwareState(value);
          }
          default:
            return '';
        }
      }
    }
  }

  private getIconByConnectorType(value: string): string {
    switch (<string>value) {
      case ChargingStationConnectorType.G105:
        return 'Type-B';
      case ChargingStationConnectorType.CCS1:
      case ChargingStationConnectorType.CCS2:
        return 'Type-C';
      case ChargingStationConnectorType.CEE_63A:
      case ChargingStationConnectorType.CEE_125A:
        return 'cee';
      case ChargingStationConnectorType.POWERLOCK_400A:
      case ChargingStationConnectorType.POWERLOCK_630A:
        return 'powerlock';
      case ChargingStationConnectorType.CTYPE1:
        return 'type-1';
      case ChargingStationConnectorType.GB_T_AC:
      case ChargingStationConnectorType.GB_T_DC:
        return 'Type-A';
      default:
        return 'Type-A';
    }
  }

  private getIconIdByHardwareState(value: boolean): string {
    switch (value) {
      case null:
        return 'question-help-circle';
      case undefined:
        return 'question-help-circle';
      case true:
        return 'lock-2';
      case false:
        return 'lock-unlock-1';
      default:
        return '';
    }
  }

  private getTooltipByHardwareState(value: string | boolean): string {
    switch (value) {
      case null:
        return this.translate.instant('GLOBAL.UNKNOWN');
      case undefined:
        return this.translate.instant('GLOBAL.UNKNOWN');
      case true:
        return this.translate.instant('GLOBAL.CLOSED');
      case false:
        return this.translate.instant('GLOBAL.OPENED');
      default:
        return '';
    }
  }
}
