import { NotificationLevel } from '@shared/services/notification';

export interface INotification {
  notificationLevel?: NotificationLevel;
  message?: string;
  traceId?: string;
  reloadButton?: boolean;
}

export enum EWaitResponseType {
  FINISH = 'FINISH',
  FINISH_WITH_ERROR = 'FINISH_WITH_ERROR',
  WAITING = 'WAITING',
}

export interface ISessionData {
  rehydrationCompleted: boolean;
  notification?: INotification;
  messagesShown: boolean;
  sidebarCollapsed?: boolean;
  waitLogin?: EWaitResponseType;
  unauthNavigatedPage?: string;
  hasNewMessages?: boolean;
}
