<mat-accordion displayMode="flat" class="toc-accordion" #acc [style.width.px]="accordionWidth">
  <mat-expansion-panel
    #expPanels
    *ngFor="let h1Link of headerLinks"
    [attr.fragid]="h1Link.id"
    [disabled]="!h1Link.subLinks || h1Link.subLinks.length <= 0"
    hideToggle
  >
    <mat-expansion-panel-header class="panel-header">
      <mat-panel-title
        [routerLink]="url"
        [fragment]="h1Link.id"
        [ngClass]="{ 'select-color': h1Link.active, 'unselect-color': !h1Link.active }"
        (click)="onTocItemClick(h1Link.id)"
      >
        {{ h1Link.name }}</mat-panel-title
      >
      <mat-panel-description *ngIf="!expPanels.disabled">
        <eop-icon
          [identifier]="
            expPanels.expanded ? 'small-chevron-up-outlined' : 'small-chevron-down-outlined'
          "
          [vaylensFont]="true"
        ></eop-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <a
      *ngFor="let h2Link of h1Link.subLinks"
      [routerLink]="url"
      [fragment]="h2Link.id"
      [ngClass]="{ 'select-color': h2Link.active, 'unselect-color': !h2Link.active }"
      (click)="onTocItemClick(h2Link.id)"
      >{{ h2Link.name }}</a
    >
  </mat-expansion-panel>
</mat-accordion>
