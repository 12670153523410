import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.requestShouldNotBeHandled(request)) {
      return next.handle(request);
    } else {
      return next.handle(request.clone({ withCredentials: true }));
    }
  }

  private requestShouldNotBeHandled(request: HttpRequest<any>): boolean {
    return this.isRequestToCmmSystem(request) || this.isRequestToGoogle(request);
  }

  private isRequestToCmmSystem(request: HttpRequest<any>): boolean {
    return request.url.includes('/cmm/api/');
  }

  private isRequestToGoogle(request: HttpRequest<any>): boolean {
    return request.url.includes('maps.google.com');
  }
}
