import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconColor } from '@widgets/eop-icon';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleButtonComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent implements ControlValueAccessor {
  public leftActive: boolean;
  public rightActive: boolean;

  @Input()
  public leftIcon: string;

  @Input()
  public leftTooltip: string;

  @Input()
  public rightIcon: string;

  @Input()
  public rightTooltip: string;

  @Input()
  public leftText: string;

  @Input()
  public rightText: string;

  @Input()
  public leftValue: any = false;

  @Input()
  public rightValue: any = true;

  // TODO: remove [value] and use [ngModel] instead
  /**
   * @deprecated component implements ControlValueAccessor, use ngModel instead
   */
  @Input()
  public set value(value: any) {
    this.writeValue(value);
  }

  @Input()
  public disabled: boolean = false;

  @Output()
  public select: EventEmitter<any> = new EventEmitter<any>();

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (value: any) => void = () => {};

  readonly IconColor = IconColor;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  writeValue(value: any): void {
    switch (value) {
      case this.leftValue:
        this.leftActive = true;
        this.rightActive = false;
        break;
      case this.rightValue:
        this.rightActive = true;
        this.leftActive = false;
        break;
      default:
        this.leftActive = false;
        this.rightActive = false;
        if (!this.leftValue || !this.rightValue) {
          console.warn(
            'One or both of the values have not been set. Make sure to set both leftValue and rightValue!'
          );
        }
    }
    this.changeDetectorRef.detectChanges();
  }

  public onLeftClick(): void {
    if (!this.disabled && !this.leftActive) {
      this.writeValue(this.leftValue);
      this.onChangeCallback(this.leftValue);
      this.onTouchedCallback();
      this.select.emit(this.leftValue);
    }
  }

  public onRightClick(): void {
    if (!this.disabled && !this.rightActive) {
      this.writeValue(this.rightValue);
      this.onChangeCallback(this.rightValue);
      this.onTouchedCallback();
      this.select.emit(this.rightValue);
    }
  }
}
