export interface Lg2wanParameterState {
  subsettings: Subsettings;
  constraints: Constraints;
}

export enum Lg2wanParameterAliasWithoutAuthenticationType {
  WITHOUT_AUTH = 'WITHOUT_AUTH',
  MAX_CURRENT_EVSE = 'MAX_CURRENT_EVSE',
  BACKEND_CHECK = 'BACKEND_CHECK',
  STATUS_PUSH_INTERVAL = 'STATUS_PUSH_INTERVAL',
  CURRENT_WITHOUT_AUTH = 'CURRENT_WITHOUT_AUTH',
  PUK = 'PUK',

  PREFERRED_GSM = 'PREFERRED_GSM',
  MAX_CURRENT_STATION = 'MAX_CURRENT_STATION',
  TERMINAL_IP = 'TERMINAL_IP',
  STATIC_IP = 'STATIC_IP',
  PROVIDER_MODE = 'PROVIDER_MODE',
  DEVICE_GROUP = 'DEVICE_GROUP',
  LG2LAN_ACTIVE = 'LG2LAN_ACTIVE',
  LOCAL_MGMT = 'LOCAL_MGMT',
  IPT_ACTIVE = 'IPT_ACTIVE',
  GFI_AVAILABLE = 'GFI_AVAILABLE',
  PROX_AVAILABLE = 'PROX_AVAILABLE',
  MAX_VOLTAGE = 'MAX_VOLTAGE',
  MAX_PHASES = 'MAX_PHASES',
}

export enum AuthenticationTypeParameter {
  RFID = 'RFID',
  RFID_PLUS = 'RFID_PLUS',
  LAN = 'LAN',
  PNC = 'PNC',
  ID_BOX = 'ID_BOX',
  REMOTE = 'REMOTE',
  _15118 = '_15118',
  _15118PRE = '_15118PRE',
}

export type Lg2wanParameterAlias =
  | Lg2wanParameterAliasWithoutAuthenticationType
  | AuthenticationTypeParameter;
export const Lg2wanParameterAlias = {
  ...Lg2wanParameterAliasWithoutAuthenticationType,
  ...AuthenticationTypeParameter,
};

export enum ValueType {
  UNKNOWN = 'UNKNOWN',
  STRING_PARAMETER = 'STRING_PARAMETER',
  DECIMAL_PARAMETER = 'DECIMAL_PARAMETER',
  CHOICE_PARAMETER = 'CHOICE_PARAMETER',
  BOOLEAN_PARAMETER = 'BOOLEAN_PARAMETER',
}

export enum ConstraintType {
  MIN = 'MIN',
  MAX = 'MAX',
  POSSIBLE_VALUES = 'POSSIBLE_VALUES',
  MAX_LENGTH = 'MAX_LENGTH',
  MIN_LENGTH = 'MIN_LENGTH',
}

export interface Lg2wanParameterMap {
  devices: Map<string, Lg2wanParameter[]>;
}

export interface Lg2wanParameter {
  alias: Lg2wanParameterAlias;
  value: string;
  type: ValueType;
}

export interface Subsettings {
  station: DeviceSubsettings;
  evses: DeviceSubsettings[];
}

export interface DeviceSubsettings {
  id: string;
  settings: DeviceSettingEntry;
}

export interface DeviceSettingEntry {
  [key: string]: string;
}

export interface Constraints {
  station: DeviceConstraints;
  evses: DeviceConstraints[];
}

export interface DeviceConstraints {
  id: string;
  settings: ConstraintForDevice[];
}

export interface ConstraintForDevice {
  parameterAlias: Lg2wanParameterAlias;
  type: ValueType;
  defaultValue?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  choices?: { [name: string]: string };
  acceptZero?: boolean;
  deviceConfigValue?: { [gateway: string]: string };
}

export interface Constraint {
  type: ConstraintType;
  values: string | string[];
}
