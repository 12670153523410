import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from './dropdown-type';

/**
 * @internal
 *
 * This pipe is used to filter the itemlist in the dropdown by a passed predicate.
 * Currently this is just used internally and there is no plan to expose this
 * API publicly.
 */
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: DropdownItem[], searchTerm: string): DropdownItem[] {
    if (!items || !searchTerm) {
      return items;
    }
    return items.filter(item => item.name.toLowerCase().includes(String(searchTerm).toLowerCase()));
  }
}
