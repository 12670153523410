import { NgModule } from '@angular/core';
import { AccessControlActions } from '@manage/access-control/store/access-control.actions';
import { LoadmgtActions } from '@manage/load-management/store/load-management.actions';
import { ChargePortActions } from '@operate/charging-infrastructure-detail/store/actions/charge-port.actions';
import { ChargingInfrastructureDetailActions } from '@operate/charging-infrastructure-detail/store/actions/charging-infrastructure-detail.actions';
import { BackendDataActions, SessionDataActions } from './index';
import { UserFilterOptionsActions } from './user-filter-options.actions';

@NgModule({
  providers: [
    LoadmgtActions,
    SessionDataActions,
    BackendDataActions,
    ChargePortActions,
    AccessControlActions,
    UserFilterOptionsActions,
    ChargingInfrastructureDetailActions,
  ],
})
export class ActionsModule {}
