import { Injectable } from '@angular/core';
import { CenterToPoiButton } from '@widgets/map/data/map.model';

@Injectable({
  providedIn: 'root',
})
export class MapButtonService {
  setupCenterMapButton(
    centerButtonElement: HTMLButtonElement,
    settings: CenterToPoiButton
  ): HTMLButtonElement {
    centerButtonElement.style.backgroundColor = '#fff';
    centerButtonElement.style.border = '2px solid #fff';
    centerButtonElement.style.borderRadius = '3px';
    centerButtonElement.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    centerButtonElement.style.color = 'rgb(25,25,25)';
    centerButtonElement.style.cursor = 'pointer';
    centerButtonElement.style.fontFamily = 'NeueHaasDisplay, Arial, sans-serif';
    centerButtonElement.style.fontSize = '16px';
    centerButtonElement.style.lineHeight = '38px';
    centerButtonElement.style.margin = '10px';
    centerButtonElement.style.padding = '0 5px';
    centerButtonElement.style.textAlign = 'center';
    centerButtonElement.style.minWidth = '40px';
    centerButtonElement.style.display = 'flex';
    centerButtonElement.style.alignItems = 'center';
    centerButtonElement.style.justifyContent = 'center';
    centerButtonElement.title = settings.title;
    centerButtonElement.appendChild(document.createElement('img'));
    const img = centerButtonElement.getElementsByTagName('img')[0];
    img.src = settings.iconPath;
    img.width = settings.centerToPoiIconWidth;
    return centerButtonElement;
  }
}
