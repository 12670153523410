import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EPermissions } from '@auth/login';
import { isParameterFiltered } from '@shared/http/http-param-filter';
import { environment } from 'environments/environment';
import { omitBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CitySuggestion,
  CitySuggestionsResponse,
  StreetSuggestion,
  StreetSuggestionsResponse,
} from '../model/suggestions.model';

export enum StationStatus {
  IBET = 'IBET',
  PLANNED = 'PLANNED',
}

@Injectable({
  providedIn: 'root',
})
export class SuggestService {
  constructor(private http: HttpClient) {}

  getCountries(
    permission: EPermissions,
    partnerId: string,
    locationId: string,
    onlyIbetStations: boolean,
    countryIso3: boolean
  ): Observable<string[]> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          permission,
          partnerId,
          locationId,
          installationStatus: onlyIbetStations ? 'IBET' : null,
          iso3Format: countryIso3,
        },
        p => isParameterFiltered(p)
      ),
    });

    return this.http.get<string[]>(environment.bffEopSearch + '/suggestions/countries', {
      params,
    });
  }

  getCitySuggestions(
    permission: EPermissions,
    search: string,
    partnerId: string,
    locationId: string,
    country: string,
    onlyIbetStations: boolean
  ): Observable<CitySuggestion[]> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          search,
          permission,
          partnerId,
          locationId,
          country,
          installationStatus: onlyIbetStations ? 'IBET' : null,
        },
        p => isParameterFiltered(p)
      ),
    });

    return this.http
      .get<CitySuggestionsResponse>(environment.bffEopSearch + '/suggestions/cities-zips', {
        params,
      })
      .pipe(map(response => response.suggestions));
  }

  getStreetSuggestions(
    permission: EPermissions,
    search: string,
    partnerId: string,
    locationId: string,
    country: string,
    city: string,
    zipCode: string,
    onlyIbetStations: boolean
  ): Observable<StreetSuggestion[]> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          search,
          permission,
          partnerId,
          locationId,
          country,
          city,
          zipCode,
          installationStatus: onlyIbetStations ? StationStatus.IBET : null, // if nothing is send bff-eop-search for return all
        },
        p => isParameterFiltered(p)
      ),
    });

    return this.http
      .get<StreetSuggestionsResponse>(environment.bffEopSearch + '/suggestions/streets', {
        params,
      })
      .pipe(map(response => response.suggestions));
  }
}
