import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'eop-image-placeholder',
  templateUrl: './eop-image-placeholder.component.html',
  styleUrls: ['./eop-image-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePlaceholderComponent {
  @Input()
  image: string;

  @Input()
  text: string;

  @Input()
  width: string;
}
