<div class="content" [ngClass]="{ 'full-width': fullContentWidth }">
  <ng-content> </ng-content>
</div>
<eop-icon
  class="icon"
  [identifier]="iconIdentifier"
  [vaylensFont]="true"
  [weight]="iconWeight"
  [color]="iconColor"
  [matTooltip]="infoText"
  [matTooltipPosition]="infoTextTooltipPosition"
  [vaylensFont]="true"
></eop-icon>
