import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyFieldPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || !value.trim()) {
      return '-';
    } else {
      return value;
    }
  }
}
