import { IBackendData } from '@shared/store/data/backend-data.model';
import { cloneDeep } from 'lodash-es';
import { BackendDataActions } from '../actions/backend-data.actions';
import { IActionWithPayload } from '../models';

const INITIAL_STATE: IBackendData = {
  userLogin: null,
  settingValueList: {},
};

export function backendDataReducer(
  state: IBackendData = INITIAL_STATE,
  action: IActionWithPayload<any>
) {
  switch (action.type) {
    case BackendDataActions.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        userLogin: {
          ...action.payload,
          id: parseInt(action.payload.id as string, 10),
        },
      });

    case BackendDataActions.SET_USER_SETTINGS:
      const userLoginCopy = cloneDeep(state.userLogin);
      userLoginCopy.settings = Object.assign({}, userLoginCopy.settings, action.payload);
      return Object.assign({}, state, { userLogin: userLoginCopy });

    case BackendDataActions.SAVE_SETTINGS_VALUE_LIST:
      return Object.assign({}, state, { settingValueList: action.payload });

    default:
      return state;
  }
}
