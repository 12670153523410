import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { EPermissions } from '@auth/login';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
} from '@shared/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '@shared/google-analytics/google-analytics.service';
import { PermissionService } from '@shared/services/util';
import { isEmpty } from 'lodash-es';

import { MenuPoints } from '@shared/components/header/menu-points.model';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { environment } from 'environments/environment';

@Component({
  selector: 'eop-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuGroupComponent implements OnInit {
  @Input() menuPoints: MenuPoints;
  @Input() menuCollapsed: boolean;
  @Input() activeGroupName: string;

  @Output() activeGroupChange: EventEmitter<string> = new EventEmitter<string>();

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;
  readonly environment = environment;
  panelIndex = 0;

  userHasInternalRole: boolean;

  constructor(
    private permissionService: PermissionService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.userHasInternalRole = this.permissionService.userHasInternalRole();
  }

  checkPermission(permissions: EPermissions[]): boolean {
    if (isEmpty(permissions)) return true;
    const permissionsArray = [];
    permissions.forEach(permission => {
      permissionsArray.push(this.hasPermission(permission));
    });
    return permissionsArray.includes(true);
  }

  private hasPermission(permission: EPermissions): boolean {
    return this.permissionService.userHasPermission(permission);
  }

  sendAnalyticEvent(route: string) {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.SIDE_MENU,
      GoogleAnalyticsAction.NAVIGATE,
      route
    );
  }
  onActivateLink(panelIndexToExpand: number) {
    this.setOpenedPanel(panelIndexToExpand);
  }

  setOpenedPanel(index: number): void {
    this.panelIndex = index;
    const groupName = this.menuPoints.sections[index].name;
    if (this.activeGroupName !== groupName) {
      this.activeGroupChange.emit(this.menuPoints.sections[index].name);
    }
  }

  isExpanded(index: number): boolean {
    return (
      this.panelIndex === index && this.menuPoints.sections[index].name === this.activeGroupName
    );
  }
}
