import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChipColor } from '@widgets/innogy-chips/chipColor';

export enum TextBoxType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}
@Component({
  selector: 'text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextBoxComponent implements OnChanges {
  @Input()
  text: string;
  @Input()
  boldText: string;
  @Input()
  texts: { boldText: string; text: string; boldTextInChip?: boolean; chipColor?: ChipColor }[] = [];
  @Input()
  type: TextBoxType = TextBoxType.INFO;

  protected readonly ChipColor = ChipColor;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.text?.currentValue || changes?.boldText?.currentValue) {
      this.texts.push({
        boldText: changes?.boldText?.currentValue,
        text: changes?.text?.currentValue,
        boldTextInChip: changes?.boldTextInChip?.currentValue ?? false,
        chipColor: changes?.chipColor?.currentValue ?? ChipColor.DEFAULT_NEW,
      });
    }
  }
}
