import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { InnogyChipsComponent } from './innogy-chips.component';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatChipsModule,
    EopIconModule,
    SharedDirectivesModule,
    MatTooltipModule,
  ],
  declarations: [InnogyChipsComponent, TruncatePipe],
  exports: [InnogyChipsComponent],
})
export class InnogyChipsModule {}
