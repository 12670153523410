import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly maxLengthOfHistory = 6;
  private previousStationDetails = '';
  urlHistory: string[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams
      .pipe(
        filter(params => (params as any).activeTab),
        tap(() => this.relevantRouteChange(this.router.url))
      )
      .subscribe(() => this.handleRouteHistory(router.url));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => this.relevantRouteChange(event.url))
      )
      .subscribe((event: NavigationEnd) => {
        this.handleRouteHistory(event.urlAfterRedirects);
      });
  }

  private handleRouteHistory(url: string) {
    this.urlHistory.unshift(url);
    if (this.urlHistory.length > this.maxLengthOfHistory) {
      this.urlHistory.pop();
    }
  }

  navigateBack(fallbackUrl: string) {
    this.urlHistory.shift(); // remove currently visited site
    const previousSite = this.urlHistory.shift();
    const lastUrl = previousSite ? previousSite.split('?')[0] : fallbackUrl;
    const extras = previousSite ? this.createNavigationParams(previousSite) : undefined;
    this.router.navigate([lastUrl], extras);
  }

  private createNavigationParams(url: string): NavigationExtras {
    let extras: NavigationExtras = {};
    const urlParams = new URLSearchParams(url?.split('?')[1]);
    urlParams.forEach((parameter: string, key: string) => {
      extras = {
        queryParams: {
          ...extras.queryParams,
          [key]: parameter,
        },
      };
    });
    return extras;
  }

  /**
   * e.g. when switching tabs within charging station details page
   */
  private relevantRouteChange(url: string): void {
    if (url.includes('uuid') && url.includes('chargingInfrastructureDetail')) {
      if (url.substring(0, url.indexOf('&')) === this.previousStationDetails) {
        this.urlHistory.shift(); // replace history item
      } else {
        this.previousStationDetails = url.substring(0, url.indexOf('&'));
      }
    } else {
      this.previousStationDetails = '';
    }
  }
}
