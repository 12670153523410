import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EPermissions } from '@auth/login';
import { TranslateService } from '@ngx-translate/core';
import { Icon, IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { SuggestService } from '../services/suggest.service';

@Component({
  selector: 'eop-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss'],
})
export class CountriesComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('input', { read: MatAutocompleteTrigger }) autoCompleteTrigger: MatAutocompleteTrigger;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private triggerSearch$: Subject<void> = new Subject<void>();

  @Input() permission: EPermissions;
  @Input() partnerId: string;
  @Input() locationId: string;
  @Input() onlyIbetStations: boolean;
  @Input() countryIso3: boolean;

  @Output()
  selectionChange: EventEmitter<string> = new EventEmitter<string>();

  get country(): AbstractControl {
    return this.form.get('country');
  }

  form: FormGroup;
  countryIcon: Icon = {
    identifier: 'earth-1',
    size: IconSize.SMALL,
    weight: IconWeight.BOLD,
  };
  countries: string[];
  filteredCountries: string[];

  readonly IconColor = IconColor;

  constructor(
    private translate: TranslateService,
    private suggestService: SuggestService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.form = this.formBuilder.group({ country: null });
    this.triggerSearch$
      .pipe(
        switchMap(() =>
          this.suggestService
            .getCountries(
              this.permission,
              this.partnerId,
              this.locationId,
              this.onlyIbetStations,
              this.countryIso3
            )
            .pipe(
              catchError(() => {
                return [];
              })
            )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(response => {
        this.countries = response;
        this.filteredCountries = response;
      });
    this.triggerSearch$.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.partnerId || changes.locationId) {
      this.triggerSearch$.next();
    }
  }

  onInput(input: string) {
    if (input === '') {
      this.reset();
    }
    this.filteredCountries = this.countries.filter(
      value =>
        this.translate
          .instant('GLOBAL.' + value)
          .toLowerCase()
          .includes(input.toLowerCase()) || value.toLowerCase().includes(input.toLowerCase())
    );
  }

  onSelect(value: string) {
    this.filteredCountries = this.countries;
    this.country.setValue(this.translate.instant('GLOBAL.' + value));
    this.selectionChange.emit(value);
  }

  reset(emitChange = true) {
    this.country.reset();
    this.country.enable();
    this.filteredCountries = this.countries;
    if (emitChange) this.selectionChange.emit(null);
  }

  private scrollEvent = (event: Event): void => {
    if (this.autoCompleteTrigger.panelOpen) this.autoCompleteTrigger.updatePosition();
  };
}
