import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum ModalBannerIcon {
  SUCCESS = 'general_success',
  ERROR = 'general_error',
  WARNING = 'general_warning',
  INFO = 'general_info',
  SUCCESS_STATION = 'station_success',
  WARNING_STATION = 'station_warning',
  ERROR_STATION = 'station_error',
  INFO_STATION = 'station_info',
}

@Component({
  selector: 'eop-modal-banner',
  templateUrl: './modal-banner.component.html',
  styleUrls: ['./modal-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBannerComponent {
  @Input()
  icon: ModalBannerIcon;
  @Input()
  message: string;
  @Input()
  additionalMessage: string;
}
