import { Directive, TemplateRef } from '@angular/core';

/**
 * @internal
 *
 * This component holds the templateref passed to the overlay
 */
@Directive({
  selector: '[emobDropdownOverlayContent]',
})
export class DropdownOverlayContentDirective {
  constructor(public tpl: TemplateRef<string>) {}
}
