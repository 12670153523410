import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private translate: TranslateService) {}

  errorForControl(controlKey: string, form: FormGroup): string {
    const errors = form.get(controlKey).errors;
    if (errors && Object.keys(errors).length > 0) {
      let errorCode = Object.keys(errors)[0];
      let values = {};
      switch (errorCode) {
        case 'minlength':
          values = { value: errors[errorCode].requiredLength };
          errorCode = 'MIN_LENGTH_IS';
          break;
        case 'maxlength':
          values = { value: errors[errorCode].requiredLength };
          errorCode = 'MAX_LENGTH_IS';
          break;
        case 'min':
          values = { value: errors[errorCode].min };
          errorCode = 'MIN_VALUE_IS';
          break;
        case 'max':
          values = { value: errors[errorCode].max };
          errorCode = 'MAX_VALUE_IS';
          break;
        case 'greaterZeroValidator':
          values = { value: errors[errorCode].greaterZeroValidator };
          errorCode = 'GREATER_ZERO';
          break;
        case 'noWhiteSpacesValidation':
          errorCode = 'VALID_MIN_' + errors[errorCode].requiredLength;
          break;
        case 'pattern':
          const format = errors[errorCode].format;
          errorCode = format ? format : 'INVALID_FORMAT';
          break;
        case 'invalidFileType':
          values = { value: errors[errorCode].allowedFileType };
          errorCode = 'INVALID_FILE_TYPE';
          break;
        case 'invalidHexadecimalColorCode':
          errorCode = 'INVALID_HEXADECIMAL_COLOR_CODE';
          break;
      }
      return this.translate.instant('VALIDATORS.' + errorCode.toUpperCase(), values);
    }
    return '';
  }
}
