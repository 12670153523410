import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor, IconSize } from '@widgets/eop-icon';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { ConfirmDialogModel } from '../dialog.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  protected readonly ButtonColor = ButtonColor;
  protected readonly IconSize = IconSize;
  protected readonly IconColor = IconColor;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private sharedRefService: SharedRefService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
    this.sharedRefService.closeDialog();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    this.sharedRefService.closeDialog();
  }
}
