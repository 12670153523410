import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterInputComponent } from '@widgets/filter/specific/components/filter-input.component';
import { FilterContainerConfiguration } from '@widgets/filter/specific/filter-container.component';
import {
  ComponentData,
  Item,
  SpecificFilterConfig,
} from '@widgets/filter/specific/services/filter-frontend.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterUtilService {
  constructor(private translate: TranslateService) {}

  buildComponentData(
    defaultType: ComponentType<FilterInputComponent>,
    defaultTitle: string,
    defaultServerKey: string[],
    items: any[],
    translationPrefix?: string,
    config?: SpecificFilterConfig,
    serverKeyIndex?: number,
    itemTemplate?: TemplateRef<any>,
    dataList?: any[],
    reverseNameAndId?: boolean
  ): ComponentData {
    return {
      type: defaultType,
      title: defaultTitle,
      items: items.map((item, index) => {
        const name =
          typeof item === 'object'
            ? reverseNameAndId
              ? item.id + ' - ' + this.ifNotNull(item.name)
              : this.ifNotNull(item.name) + ' - ' + item.id
            : translationPrefix
            ? this.translate.instant(
                translationPrefix + (translationPrefix.endsWith('_') ? '' : '.') + item
              )
            : item;
        return this.buildItem(
          typeof item === 'object' ? item.id : item,
          config,
          serverKeyIndex ? serverKeyIndex : 0,
          name,
          name,
          defaultServerKey,
          dataList && dataList[index] ? dataList[index] : null
        );
      }),
      itemTemplate: itemTemplate,
    };
  }

  buildComponentDataSimple(
    defaultType: ComponentType<FilterInputComponent>,
    defaultTitle: string,
    defaultServerKey: string[],
    items: { id: string; name: string }[]
  ): ComponentData {
    return {
      type: defaultType,
      title: defaultTitle,
      items: items.map(item => {
        const name = item.id + ' - ' + this.ifNotNull(item.name);
        return this.buildItem(item.id, undefined, 0, name, name, defaultServerKey, null);
      }),
      itemTemplate: undefined,
    };
  }

  private ifNotNull(text: string): string {
    return text != null ? text : '';
  }

  // Use at your own risk
  public buildFilterContainerConfiguration(config: {
    type: ComponentType<FilterInputComponent>;
    title: string;
    serverKey: string;
    items: Array<Item> | Array<SimpleFilterItem> | Array<string>;
    preselectStringArray?: string;
    translationPrefixStringArray?: string;
  }): FilterContainerConfiguration {
    if (!config.items || config.items.length === 0) {
      return {
        type: config.type,
        title: this.translate.instant(config.title),
        data$: of<ComponentData>({
          items: [],
        }),
      };
    }

    let harmonizedItems: Array<Item>;
    if (typeof config.items[0] === 'string') {
      harmonizedItems = this.buildItemsFromStringArray(
        config.serverKey,
        <Array<string>>config.items
      );
      if (config.preselectStringArray && config.preselectStringArray.trim() !== '') {
        harmonizedItems.forEach(item => {
          item.selected = item.name === config.preselectStringArray;
        });
      }
      if (
        config.translationPrefixStringArray &&
        config.translationPrefixStringArray.trim() !== ''
      ) {
        harmonizedItems.forEach(item => {
          const key = item.name;
          item.name = this.translate.instant(config.translationPrefixStringArray + '.' + key);
          item.chipValue = this.translate.instant(config.translationPrefixStringArray + '.' + key);
        });
      }
    } else if ((<Item>config.items[0]).id) {
      harmonizedItems = <Array<Item>>config.items;
    } else {
      harmonizedItems = this.buildItemsFromSimpleFilterItems(
        config.serverKey,
        <Array<SimpleFilterItem>>config.items
      );
    }

    return {
      type: config.type,
      title: this.translate.instant(config.title),
      data$: of<ComponentData>({
        items: harmonizedItems,
      }),
    };
  }

  private buildItemsFromStringArray(
    serverKey: string,
    list: Array<string>,
    translationPrefix?: string
  ): Array<Item> {
    const wrappedList: Array<SimpleFilterItem> = [];
    list.forEach(entry => {
      wrappedList.push({ name: translationPrefix ? translationPrefix + '.' + entry : entry });
    });
    return this.buildItemsFromSimpleFilterItems(serverKey, wrappedList);
  }

  private buildItemsFromSimpleFilterItems(
    serverKey: string,
    list: Array<SimpleFilterItem>
  ): Array<Item> {
    const items: Array<Item> = [];
    list.forEach(entry => {
      items.push(
        this.buildItemForConfiguration(
          entry.name,
          entry.name,
          { [serverKey]: entry.data ? entry.data : entry.name },
          !!entry.selected
        )
      );
    });
    return items;
  }

  private buildItemForConfiguration(id: string, name: string, data: any, selected?: boolean): Item {
    return {
      id: id,
      name: name,
      selected: !!selected,
      value: data,
      chipValue: name,
    };
  }

  private buildItem(
    item: string,
    config: SpecificFilterConfig,
    keyIndex: number,
    name: string,
    chipValue: string,
    defaultServerKey: string[],
    data: any
  ): Item {
    const keys = config && config.serverKeys ? config.serverKeys : defaultServerKey;
    const preselect = config && config.preselect ? config.preselect : {};
    return {
      id: item,
      selected: !!(
        preselect &&
        preselect[keys[keyIndex]] &&
        preselect[keys[keyIndex]].find(p => p === item)
      ),
      name: name,
      chipValue: chipValue,
      value: {
        [keys[keyIndex] ? keys[keyIndex] : defaultServerKey[keyIndex]]: item,
      },
      data: data,
    };
  }
}

export class SimpleFilterItem {
  name: string;
  selected?: boolean;
  data?: any;

  constructor(name: string, selected?: boolean, data?: any) {
    this.name = name;
    if (selected) this.selected = selected;
    if (data) this.data = data;
  }
}
