import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

export const cmmHtmlLoadError = 'Could not load';

@Injectable({
  providedIn: 'root',
})
export class EmssHtmlPageService {
  private htmlPagesGwPath = '/html';

  constructor(private http: HttpClient) {}

  getHtmlPage(page: string, language: string): Observable<string> {
    const regex = new RegExp(`="img/B2B_PORTAL`, 'g');
    return this.http
      .get(
        `${environment.cmmUrl}${this.htmlPagesGwPath}/${page}?applicationId=B2B_PORTAL&language=${language}`,
        { responseType: 'text' }
      )
      .pipe(
        timeout(5000),
        map(text => text.replace(regex, `="${environment.cmmUrl}/html/img/B2B_PORTAL`)),
        catchError(error => {
          return of(cmmHtmlLoadError);
        })
      );
  }
}
