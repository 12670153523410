import { NgModule } from '@angular/core';
import { DigitsCropDirective } from '@shared/directives/digits-crop/digits-crop.directive';
import { DisableControlDirective } from '@shared/directives/disable-control/disable-control.directive';
import { DragAndDropUploadDirective } from '@shared/directives/drag-and-drop-upload/drag-and-drop-upload.directive';
import { HtmlTooltipDirective } from './html-tooltip/html-tooltip.directive';

@NgModule({
  declarations: [
    DisableControlDirective,
    DigitsCropDirective,
    DragAndDropUploadDirective,
    HtmlTooltipDirective,
  ],
  exports: [
    DisableControlDirective,
    DigitsCropDirective,
    DragAndDropUploadDirective,
    HtmlTooltipDirective,
  ],
})
export class SharedDirectivesModule {}
