import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import { IconSize, IconWeight } from '@widgets/eop-icon';
import { Subscription } from 'rxjs';
import { PopoverRef } from '../../classes/popover-ref';
import { Item } from '../../services/filter-frontend.model';
import { FilterInputComponent } from '../filter-input.component';

@Component({
  selector: 'eop-singleselect',
  templateUrl: './singleselect.component.html',
  styleUrls: ['./singleselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleselectComponent extends FilterInputComponent implements OnDestroy {
  @Input()
  key: string[];

  subscription = new Subscription();

  vcr: ViewContainerRef;

  readonly IconWeight = IconWeight;
  readonly IconSize = IconSize;

  constructor(@Optional() private popoverRef: PopoverRef) {
    super(popoverRef);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onItemClick(item: Item) {
    this.componentData.items = this.componentData.items.map(i => {
      if (item === i) {
        return {
          ...item,
          selected: !item.selected,
        };
      }
      return {
        ...i,
        selected: false,
      };
    });

    this.itemClick.emit(this.componentData);
    if (this.popoverRef) {
      this.popoverRef.callback(this.componentData);
      this.popoverRef.close();
    }
  }
}
