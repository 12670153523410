import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ModalActiveDirective } from './modal-active-directive/modal-active.directive';
import { ModalCardModule } from './modal-card/modal-card.module';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { ModalService } from './modal.service';
import { DEFAULT_MODAL_CONFIG, ModalConfig } from './modalConfig';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [ModalCardModule, ModalActiveDirective],
  declarations: [ModalContainerComponent, ModalActiveDirective],
})
export class ModalModule {
  static forRoot(): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [ModalService],
    };
  }

  static forChild(defaultModalConfig?: ModalConfig): ModuleWithProviders<ModalModule> {
    return !!defaultModalConfig
      ? {
          ngModule: ModalModule,
          providers: [
            ModalService,
            { provide: DEFAULT_MODAL_CONFIG, useValue: defaultModalConfig },
          ],
        }
      : {
          ngModule: ModalModule,
          providers: [ModalService],
        };
  }
}
