<div *ngIf="value != null" (click)="toggleInputType()" class="visibility-icon">
  <eop-icon
    [identifier]="identifier"
    [size]="IconSize.SMALL"
    [tooltip]="(passwordVisible ? 'GLOBAL.PASSWORD_HIDE' : 'GLOBAL.PASSWORD_SHOW') | translate"
  ></eop-icon>
</div>
<div class="value">
  {{ value == null ? (value | empty) : passwordVisible ? value : hide(value) }}
</div>
