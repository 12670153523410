import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { PageHeadlineComponent } from '.';

@NgModule({
  imports: [CommonModule, SharedDirectivesModule, EopIconModule, MatTooltipModule],
  declarations: [PageHeadlineComponent],
  exports: [PageHeadlineComponent],
})
export class PageHeadlineModule {}
