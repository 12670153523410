import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';

export interface ToggleButton {
  icon: string;
  iconWeight: IconWeight;
  tooltip?: string;
  text: string;
  value: string | number;
  disabled?: boolean;
}

@Component({
  selector: 'eop-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonToggleGroupComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonToggleGroupComponent implements ControlValueAccessor {
  IconWeight = IconWeight;
  IconSize = IconSize;
  IconColor = IconColor;
  @Input() useBlueButtons = true;
  @Input() buttonGroup: ToggleButton[];
  @Input() selectedValue: string | number;
  @Output() onButtonGroupChange: EventEmitter<MatButtonToggleChange> =
    new EventEmitter<MatButtonToggleChange>();

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (value: any) => void = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(value: string | number): void {
    if (value) {
      this.selectedValue = value;
    }
    this.cdr.detectChanges();
  }

  onGroupChange(event: MatButtonToggleChange) {
    this.selectedValue = event.value;
    this.writeValue(event.value);
    this.onChangeCallback(event.value);
    this.onTouchedCallback();
    this.onButtonGroupChange.emit(event);
  }
}
