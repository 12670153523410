import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HighLightedColor } from '@shared/components/header/quick-access/components/quick-access-entry/quick-access-entry.component';
import { IconWeight } from '@widgets/eop-icon';
import {
  ChargingStationState,
  QuickSearchResult,
  SearchCategoryEnum,
  SearchResult,
} from '../../data/quick-access.model';
import { TitleSize } from '../quick-access-entry-title/quick-access-entry-title.model';

@Component({
  selector: 'eop-quick-access-results',
  templateUrl: './quick-access-results.component.html',
  styleUrls: ['./quick-access-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickAccessResultsComponent {
  @Output() resultClicked = new EventEmitter<SearchResult>();

  @Output() selectedResultChange = new EventEmitter<SearchResult>();

  @Input() searchInProgress: boolean = false;

  @Input('results')
  set results(value: QuickSearchResult) {
    this.searchResults = value;
    this.resultListEmpty = value.searchResults.length === 0;
    this.resultsLabel = this.getResultsLabel();
    this.chargePointsResults = value.searchResults
      .filter(elm => elm.category === SearchCategoryEnum.CHARGEPOINT)
      .sort(this.sortByValue);
    this.chargingStationsResults = value.searchResults
      .filter(elm => elm.category === SearchCategoryEnum.CHARGINGSTATION)
      .sort(this.sortByValue);
  }

  @Input()
  get selectedResult() {
    return this.currentlySelectedResult;
  }

  set selectedResult(result: SearchResult) {
    this.currentlySelectedResult = result;
    this.selectedResultChange.emit(this.currentlySelectedResult);
  }

  private readonly MAX_RESULTS = 10;

  private searchResults: QuickSearchResult;
  private currentlySelectedResult: SearchResult;
  chargePointsResults: SearchResult[] = [];
  chargingStationsResults: SearchResult[] = [];
  resultsLabel: string;
  resultListEmpty: boolean;
  ChargingStationState = ChargingStationState;

  TitleSize = TitleSize;
  IconWeight = IconWeight;

  HighLightedColor = HighLightedColor;

  constructor(private translate: TranslateService) {}

  onResultClicked(element: SearchResult): void {
    this.resultClicked.emit(element);
  }

  private getResultsLabel(): string {
    const total = this.searchResults.chargepointResults + this.searchResults.chargingStationResults;
    let labelResult =
      this.searchResults.searchResults.length + ' ' + this.translate.instant('GLOBAL.RESULTS');
    if (total > this.MAX_RESULTS) {
      labelResult = this.translate.instant('GLOBAL.MORE_RESULTS_THAN', { total: this.MAX_RESULTS });
    }

    return labelResult;
  }

  private sortByValue(a: SearchResult, b: SearchResult): number {
    if (!!a && !!a.value && !!b && !!b.value) {
      return a.value.localeCompare(b.value);
    }
    return 0;
  }
}
