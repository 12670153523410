<ng-container *ngIf="isVisible">
  <div class="modal-holder">
    <div
      class="modal-holder__backdrop"
      (click)="closeOnClickedBackdrop ? isVisibleChange.emit(false) : null"
    ></div>
    <div class="modal-holder__modal" [ngStyle]="{ width: width }">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
