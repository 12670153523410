import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { TabComponent, TabsComponent } from './index';

@NgModule({
  imports: [CommonModule, SharedDirectivesModule, EopIconModule, MatTooltipModule],
  declarations: [TabsComponent, TabComponent],
  exports: [TabsComponent, TabComponent],
})
export class TabsModule {}
