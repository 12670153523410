import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isParameterFiltered } from '@shared/http/http-param-filter';
import { environment } from 'environments/environment';
import { omitBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { QuickSearchResult } from '../data/quick-access.model';

@Injectable({
  providedIn: 'root',
})
export class QuickAccessService {
  constructor(private httpClient: HttpClient) {}

  quickSearch(predicate: string, limit: number = 15): Observable<QuickSearchResult> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          search: predicate,
          limit,
        },
        p => isParameterFiltered(p)
      ),
    });
    return this.httpClient.get<QuickSearchResult>(`${environment.bffEopSearch}/search`, { params });
  }
}
