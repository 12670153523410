<h3 class="headline">{{ data.title }}</h3>
<div class="message">
  <eop-icon
    [identifier]="data.icon"
    [weight]="IconWeight.REGULAR"
    [size]="IconSize.LARGE"
  ></eop-icon>
  <p [innerHTML]="data.message"></p>
</div>
<hr />
<div class="button-panel">
  <button
    [attr.data-test]="'cancel-button'"
    id="cancelButton"
    eopButton
    [color]="ButtonColor.TERTIARY"
    (click)="onCancel()"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button [attr.data-test]="'confirm-button'" id="confirmButton" eopButton (click)="onConfirm()">
    {{ 'GLOBAL.CONTINUE' | translate }}
  </button>
</div>
