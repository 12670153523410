import { Component, Input, OnInit } from '@angular/core';
import { IconColor, IconWeight } from '@widgets/eop-icon';

@Component({
  selector: 'boolean-cell',
  templateUrl: './boolean-cell.component.html',
  styleUrls: ['./boolean-cell.component.scss'],
})
export class BooleanCellComponent implements OnInit {
  @Input()
  public cellData: boolean;

  public color: IconColor = IconColor.DISABLED;

  readonly IconWeight = IconWeight;

  ngOnInit() {
    this.cellData ? (this.color = IconColor.SUCCESS) : (this.color = IconColor.DISABLED);
  }
}
