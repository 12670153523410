import { Routes } from '@angular/router';
import { AccessGuardLogged } from './app.access.guards.logged';

export const ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'manage',
    loadChildren: () => import('./manage/manage.module').then(m => m.ManageModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'monitor',
    loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'pay',
    loadChildren: () => import('./pay/pay.module').then(m => m.PayModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'clearing',
    loadChildren: () =>
      import('./clearing-house/clearing-house.module').then(m => m.ClearingHouseModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'businessPartner',
    loadChildren: () =>
      import('./business-partner/business-partner.module').then(m => m.BusinessPartnerModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'charging',
    loadChildren: () =>
      import('./user-charging/user-charging.module').then(m => m.UserChargingModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'operate',
    loadChildren: () => import('./operate/operate.module').then(m => m.OperateModule),
    canActivate: [AccessGuardLogged],
  },
  {
    path: 'content',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
  },
  { path: '**', redirectTo: 'monitor/dashboard' },
];
