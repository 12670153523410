import { Component, Input } from '@angular/core';

export enum InfoBoxType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}
@Component({
  selector: 'info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input()
  biggerText: string;
  @Input()
  text: string;
  @Input()
  smallerText: string;
  @Input()
  bulletPoints: string[];
  @Input()
  numberIcon: boolean;
  @Input()
  number: number;
  @Input()
  margin = false;
  @Input()
  type: InfoBoxType = InfoBoxType.INFO;
}
