import { WeekDay } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxWeekdayPickerComponent } from 'ngx-weekday-picker';

export interface WeekDayPickerOptions {
  weekStart?: WeekDay;
  daysNames?: string[];
  disabledList?: WeekDay[];
  selected?: WeekDay[] | WeekDay;
  dayWidth?: string;
  fontSize?: string;
  borderRadius?: string;
  borderWidth?: string;
  activeColor?: string;
  activeBgColor?: string;
  activeBorderColor?: string;
  inactiveColor?: string;
  inactiveBgColor?: string;
  inactiveBorderColor?: string;
  isMulti?: boolean;
}

@Component({
  selector: 'eop-week-day-picker',
  templateUrl: './week-day-picker.component.html',
  styleUrls: ['./week-day-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekDayPickerComponent implements OnChanges {
  @ViewChild('weekDayPicker') weekDayPicker: NgxWeekdayPickerComponent;
  private readonly initOptions: WeekDayPickerOptions = {
    daysNames: [
      this.translate.instant('GLOBAL.MONDAY').substring(0, 3),
      this.translate.instant('GLOBAL.TUESDAY').substring(0, 3),
      this.translate.instant('GLOBAL.WEDNESDAY').substring(0, 3),
      this.translate.instant('GLOBAL.THURSDAY').substring(0, 3),
      this.translate.instant('GLOBAL.FRIDAY').substring(0, 3),
      this.translate.instant('GLOBAL.SATURDAY').substring(0, 3),
      this.translate.instant('GLOBAL.SUNDAY').substring(0, 3),
    ],
    selected: [],
    disabledList: [],
    isMulti: true,
  };

  @Input() options: WeekDayPickerOptions = this.initOptions;
  @Input() readonly = false;
  @Output() selectionChanged = new EventEmitter<WeekDay | WeekDay[]>();

  private readonly basicOptions: WeekDayPickerOptions = {
    inactiveColor: '#4E4E5F',
    inactiveBgColor: '#FFF',
    inactiveBorderColor: 'transparent',
    activeBorderColor: 'transparent',
    activeBgColor: '#475EFF',
    activeColor: '#F5F6FF',
    dayWidth: '40px',
    borderRadius: '8px',
  };
  private readonly readonlyOptions: WeekDayPickerOptions = {
    inactiveColor: '#606076',
    inactiveBgColor: '#F1F1FA',
    inactiveBorderColor: 'transparent',
    activeBorderColor: 'transparent',
    activeBgColor: '#E0E4FF',
    activeColor: '#475EFF',
    dayWidth: '40px',
    borderRadius: '8px',
  };

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.options?.currentValue) {
      this.options = {
        ...this.initOptions,
        ...this.basicOptions,
        ...changes.options.currentValue,
      };
    }

    if (this.weekDayPicker && this.weekDayPicker.days) {
      this.weekDayPicker.days = this.weekDayPicker.days.map(d => {
        return {
          ...d,
          isDisabled:
            changes.options.currentValue.disabledList.filter((day: WeekDay) => day === d.day)
              .length > 0,
        };
      });
    }

    if (changes?.readonly?.currentValue && changes?.options?.currentValue) {
      this.options = {
        ...this.initOptions,
        ...this.readonlyOptions,
        ...changes.options.currentValue,
      };
    }
  }

  onSelectionChange(selection: WeekDay | WeekDay[]) {
    this.selectionChanged.emit(selection);
  }
}
