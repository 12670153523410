import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { IconColor, IconWeight } from '@widgets/eop-icon/eop-icon.component';

@Component({
  selector: 'eop-icon-with-tooltip',
  templateUrl: './eop-icon-with-tooltip.component.html',
  styleUrls: ['./eop-icon-with-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EopIconWithTooltipComponent {
  @Input() infoText: string;
  @Input() infoTextTooltipPosition: TooltipPosition = 'after';
  @Input() iconIdentifier: string;
  @Input() iconColor: IconColor;
  @Input() iconWeight: IconWeight;
  @Input() fullContentWidth: boolean;
}
