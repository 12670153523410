import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconColor, IconSize } from '@widgets/eop-icon';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { InfoDialogModel } from '../dialog.model';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  protected readonly IconColor = IconColor;
  protected readonly IconSize = IconSize;

  constructor(
    private dialogRef: MatDialogRef<InfoDialogComponent>,
    private sharedRefService: SharedRefService,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogModel
  ) {}

  onOk(): void {
    this.dialogRef.close(true);
    this.sharedRefService.closeDialog();
  }
}
