<div class="container">
  <div
    class="main-icon"
    [inlineSVG]="'assets/img/modal/' + icon + '.svg'"
    [forceEvalStyles]="true"
  ></div>
  <div class="headline">{{ message }}</div>
  <div class="caption">
    {{ additionalMessage }}
  </div>
</div>
