import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UriEncoderService } from '@shared/services/encoding';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlEncodingInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let httpParams = new HttpParams({ encoder: new UriEncoderService() });
    req.params.keys().forEach(key => {
      httpParams = httpParams.append(key, req.params.get(key));
    });

    return next.handle(req.clone({ params: httpParams }));
  }
}
