<div *ngFor="let item of componentData.items">
  <div
    *ngIf="!componentData?.itemTemplate; else itemTemplate"
    class="item"
    (click)="onItemClick(item)"
    [ngClass]="{ selected: item.selected }"
  >
    <eop-icon
      *ngIf="item.color"
      identifier="Status"
      [color]="item.color"
      [weight]="IconWeight.BOLD"
      [size]="IconSize.NORMAL"
    ></eop-icon>
    {{ item.name }}
  </div>
  <ng-template #itemTemplate>
    <div class="item" (click)="onItemClick(item)" [ngClass]="{ selected: item.selected }">
      <div
        *ngTemplateOutlet="
          componentData.itemTemplate;
          context: { itemName: item.name, itemData: item.data }
        "
      ></div>
    </div>
  </ng-template>
</div>
