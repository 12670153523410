<page-headline [title]="'HEADER.USER_MANUAL' | translate">
  <div *ngIf="isPublic" class="back-button">
    <eop-back-button [fallbackUrl]="'/auth/login'"></eop-back-button>
  </div>
</page-headline>

<div class="user-manual-content">
  <innogy-box class="doc">
    <div [innerHTML]="innerHTML | safeHtml"></div>
  </innogy-box>

  <eop-table-of-content
    class="toc"
    [headerLinks]="headerLinks"
    [linkInFocusId]="linkInFocusId"
  ></eop-table-of-content>
</div>
