<h3 class="headline">
  <div class="headline-icon-wrapper {{ data.titleIconColor ? data.titleIconColor : '' }}">
    <eop-icon
      [identifier]="data.titleIconIdentifier ? data.titleIconIdentifier : 'check-circle-filled'"
      [vaylensFont]="true"
      [color]="data.titleIconColor ? data.titleIconColor : IconColor.CLICKABLE"
      [size]="IconSize.LARGER"
    ></eop-icon>
  </div>
  <div>{{ data.headline ? data.headline : ('GLOBAL.CONFIRM' | translate) }}</div>
</h3>
<div *ngIf="data.message" class="message">
  <p [innerHTML]="data.message"></p>
</div>
<div *ngIf="data.info" class="info-box">
  <info-box [text]="data.info"> </info-box>
</div>
<hr />
<div class="button-panel">
  <button
    id="cancelButton"
    eopButton
    [color]="ButtonColor.TERTIARY"
    [attr.data-test]="'cancel-button'"
    (click)="onCancel()"
  >
    {{ (data.yesnoButtons ? 'GLOBAL.NEGATION' : 'GLOBAL.CANCEL') | translate }}
  </button>
  <button id="confirmButton" eopButton [attr.data-test]="'confirm-button'" (click)="onConfirm()">
    {{ (data.yesnoButtons ? 'GLOBAL.YES' : 'GLOBAL.CONTINUE') | translate }}
  </button>
</div>
