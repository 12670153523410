import { IconColor, IconWeight } from '@widgets/eop-icon';
import { ChipLiteColor } from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';

export interface Group {
  id?: string;
  name?: string;
  additionalDescription?: string;
  icon?: string;
  iconWeight?: IconWeight;
  description?: string;
  additionalIcons?: AdditionalIcon[]; //up to three icons displayable
  counter?: number;
  disabled?: boolean;
  editable?: boolean;
  notEditableReason?: string;
  deletable?: boolean;
  notDeletableReason?: string;
  chipData?: GroupChip[];
  containsExtraneousCPs?: boolean;
  emphasized?: boolean;
  tooltip?: string;
  vaylensFont?: boolean;
}

export interface GroupChip {
  value: string;
  color: ChipLiteColor;
  title?: string;
  emphasized?: boolean;
}

export interface AdditionalIcon {
  additionalIcon?: string;
  additionalIconColor?: IconColor;
  additionalIconTooltip?: string;
  matTooltipClass?: string;
}

export enum IconDisableMode {
  HIDE = 'HIDE',
  DISABLE = 'DISABLE',
}
