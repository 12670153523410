<div class="marker-popup" *ngIf="point">
  <a
    *ngIf="hasViewDetailPermission && point?.info?.isInternal"
    [routerLink]="'/operate/chargingInfrastructureDetail'"
    [queryParams]="{ uuid: point?.info?.uuid, chargingStation: point?.info?.id }"
  >
    <div class="body-bold-m" [eopDigitsCrop]="cropText ? point?.info?.id : ''">
      {{ cropText ? '' : point?.info?.id }}
    </div>
  </a>

  <div
    *ngIf="!hasViewDetailPermission || !point?.info?.isInternal"
    class="body-bold-m"
    [eopDigitsCrop]="cropText ? point?.info?.id : ''"
    [matTooltip]="cropText ? point?.info?.id : null"
    [matTooltipPositionAtOrigin]="true"
  >
    {{ cropText ? '' : point?.info?.id }}
  </div>
  <div class="charge_points">
    <div *ngFor="let chargePoint of point?.chargePoints" class="charge_point">
      <ng-container [ngSwitch]="chargePoint.status">
        <!-- ############## Available ############## -->
        <ng-container *ngSwitchCase="EStatus[EStatus.AVAILABLE]">
          <ng-container [ngSwitch]="chargePoint.detailStatus">
            <eop-icon
              *ngSwitchCase="EDetailedStatus[EDetailedStatus.NONE]"
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.SUCCESS"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>

            <eop-icon
              *ngSwitchDefault
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.WARNING"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>
          </ng-container>
        </ng-container>

        <!-- ##############  OCCUPIED ############## -->

        <ng-container *ngSwitchCase="EStatus[EStatus.OCCUPIED]">
          <ng-container [ngSwitch]="chargePoint.detailStatus">
            <eop-icon
              *ngSwitchCase="EDetailedStatus[EDetailedStatus.OCCUPIED_CHARGING]"
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.INFO"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>

            <eop-icon
              *ngSwitchDefault
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.WARNING"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>
          </ng-container>
        </ng-container>

        <!-- ##############  UNKNOWN ############## -->

        <ng-container *ngSwitchCase="EStatus[EStatus.UNKNOWN]">
          <ng-container [ngSwitch]="chargePoint.detailStatus">
            <eop-icon
              *ngSwitchCase="EDetailedStatus[EDetailedStatus.OUT_OF_SERVICE_INOPERATIVE]"
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.DISABLED"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>

            <eop-icon
              *ngSwitchCase="EDetailedStatus[EDetailedStatus.UNKNOWN_NEW]"
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.DISABLED"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>

            <eop-icon
              *ngSwitchCase="EDetailedStatus[EDetailedStatus.UNKNOWN_NOT_RELEVANT]"
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.DISABLED"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>

            <eop-icon
              *ngSwitchDefault
              class="status-icon"
              [identifier]="'Status'"
              [color]="IconColor.ERROR"
              [size]="IconSize.SMALL"
              [weight]="IconWeight.BOLD"
            ></eop-icon>
          </ng-container>
        </ng-container>

        <!-- ##############  OUT_OF_SERVICE ############## -->

        <ng-container *ngSwitchCase="EStatus[EStatus.OUT_OF_SERVICE]">
          <eop-icon
            class="status-icon"
            [identifier]="'Status'"
            [color]="IconColor.ERROR"
            [size]="IconSize.SMALL"
            [weight]="IconWeight.BOLD"
          ></eop-icon>
        </ng-container>
      </ng-container>

      <span
        *ngIf="hasViewDetailPermission && point?.info?.isInternal"
        [eopDigitsCrop]="cropText ? chargePoint?.name : ''"
        [matTooltip]="cropText ? chargePoint.name : ''"
        [matTooltipPositionAtOrigin]="true"
        [routerLink]="'/operate/chargingInfrastructureDetail'"
        [queryParams]="{
          uuid: point?.info?.uuid,
          chargePort: chargePoint?.name
        }"
        >{{ cropText ? '' : chargePoint?.name }}</span
      >
      <span
        class="charge-point--notClickable"
        *ngIf="!hasViewDetailPermission || !point?.info?.isInternal"
        [eopDigitsCrop]="cropText ? chargePoint?.name : ''"
        [matTooltip]="cropText ? chargePoint?.name : ''"
        [matTooltipPositionAtOrigin]="true"
        >{{ cropText ? '' : chargePoint?.name }}</span
      >
    </div>
  </div>
  <ng-container>
    <p class="marker-popup__content" *ngFor="let line of point?.info?.address">
      {{ line }}
    </p>
  </ng-container>
</div>
