import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DateConversionService } from '@shared/services/util';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'userDate',
})
export class UserDatePipe implements PipeTransform {
  constructor(private dateConvertionService: DateConversionService) {}

  public transform(value: any): string {
    const format = this.dateConvertionService.getDateFormatFromUser();
    //parse the passed value with timezone in the timestamp
    const momentDate = moment.parseZone(value);

    // If moment didn't understand the value, return it unformatted.
    if (!momentDate.isValid()) {
      return value;
    }

    // Otherwise, return the date formatted as requested.
    return momentDate.format(format);
  }
}
