<button
  eopButton
  [color]="ButtonColor.PRIMARY"
  (mouseover)="hover = true"
  (mouseout)="hover = false"
  [disabled]="disabled"
  *ngIf="progressState === ProgressStates.Begin || progressState === ProgressStates.Completed"
  [class.ripple]="!disabled && progressState === ProgressStates.Begin"
  [class.bounce]="!disabled && progressState === ProgressStates.Completed"
  (click)="emitButtonClicked()"
>
  <eop-icon *ngIf="icon" [identifier]="icon" [weight]="IconWeight.LIGHT"></eop-icon>
  <span *ngIf="progressState === ProgressStates.Begin"> {{ buttonText }} </span>
  <div class="check-element" *ngIf="progressState === ProgressStates.Completed">
    <svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <polygon
          fill="#fff"
          points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49"
        />
      </g>
    </svg>
  </div>
</button>
<spinner *ngIf="progressState === ProgressStates.Sent" [spinnerSize]="42"></spinner>
