import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IconSize } from '@widgets/eop-icon';

export default interface IButtonProps {
  color: ButtonColor;
  dataTestId: string;
  leftIcon?: string;
  rightIcon?: string;
  iconButton?: boolean;
  compact?: boolean;
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  GHOST = 'ghost',
  OUTLINE = 'outline',
  OUTLINE_GREY = 'outline-grey',
  ERROR = 'error',
}

@Component({
  selector: 'button[eopButton]',
  templateUrl: './eop-button.component.html',
  styleUrls: ['./eop-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EopButtonComponent implements IButtonProps {
  protected readonly IconSize = IconSize;

  private _compact: boolean;
  private _condensed: boolean;
  private _fullWidth: boolean;
  private _iconButton: boolean;

  @Input() color: ButtonColor = ButtonColor.PRIMARY;
  @Input() dataTestId: string;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() hidden: boolean;
  @Input() tooltip: string;
  @Input()
  set compact(value: boolean) {
    this._compact = value !== null && `${value}` !== 'false';
  }
  @Input()
  set condensed(value: boolean) {
    this._condensed = value !== null && `${value}` !== 'false';
  }
  @Input()
  set fullWidth(value: boolean) {
    this._fullWidth = value !== null && `${value}` !== 'false';
  }
  @Input() set iconButton(value: boolean) {
    this._iconButton = value !== null && `${value}` !== 'false';
  }

  @HostBinding('class.eop-button')
  _eopButton = true;

  @HostBinding('class.eop-button--primary')
  get primary(): boolean {
    return this.color === ButtonColor.PRIMARY;
  }

  @HostBinding('class.eop-button--secondary')
  get secondary(): boolean {
    return this.color === ButtonColor.SECONDARY;
  }

  @HostBinding('class.eop-button--outline')
  get outline(): boolean {
    return this.color === ButtonColor.OUTLINE;
  }

  @HostBinding('class.eop-button--outline-grey')
  get outlineGrey(): boolean {
    return this.color === ButtonColor.OUTLINE_GREY;
  }

  @HostBinding('class.eop-button--tertiary')
  get tertiary(): boolean {
    return this.color === ButtonColor.TERTIARY;
  }

  @HostBinding('class.eop-button--ghost')
  get ghost(): boolean {
    return this.color === ButtonColor.GHOST;
  }

  @HostBinding('class.eop-button--error')
  get error(): boolean {
    return this.color === ButtonColor.ERROR;
  }

  @HostBinding('class.eop-button--compact')
  get isCompact(): boolean {
    return this._compact;
  }

  @HostBinding('class.eop-button--icon-button')
  get isIconButton(): boolean {
    return this._iconButton;
  }

  @HostBinding('class.eop-button--compact-icon-button')
  get isCompactIconButton(): boolean {
    return this.isIconButton && this.isCompact;
  }

  get iconSize(): IconSize {
    return this.isCompact ? IconSize.SMALL : IconSize.NORMAL;
  }

  @HostBinding('class.eop-button--condensed')
  get isCondensed(): boolean {
    return this._condensed;
  }

  @HostBinding('class.eop-button--full-width')
  get isFullWidth(): boolean {
    return this._fullWidth;
  }
}
