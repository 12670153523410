import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconColor } from '@widgets/eop-icon';

export interface SelectValue {
  value: string;
  emitAddressChange: boolean;
}

@Component({
  selector: 'eop-address-element',
  templateUrl: './address-element.component.html',
  styleUrls: ['./address-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressElementComponent implements OnInit, OnChanges {
  @Input()
  placeholder: string = '';

  @Input()
  values: Array<string>;

  @Input()
  translationPrefix: string = '';

  @Input()
  disabled = false;

  @Output()
  selectionChange: EventEmitter<SelectValue> = new EventEmitter<SelectValue>();

  filteredValues: Array<string>;
  selectedValue: string;

  readonly IconColor = IconColor;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.filteredValues = this.values;
  }

  ngOnChanges(change: SimpleChanges) {
    this.filteredValues = this.values;
  }

  onInput(input: string) {
    if (input === '') {
      this.onSelect(null);
    }
    this.filteredValues = this.values.filter(
      value =>
        this.translate
          .instant(this.translationPrefix + value)
          .toLowerCase()
          .includes(input.toLowerCase()) || value.toLowerCase().includes(input.toLowerCase())
    );
  }

  onSelect(value: string, emitAddressChange = true) {
    this.selectedValue = value;
    this.selectionChange.emit({ value, emitAddressChange });
  }

  reset() {
    this.filteredValues = this.values;
    this.selectedValue = null;
    this.selectionChange.emit({ value: this.selectedValue, emitAddressChange: true });
  }
}
