import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * The LoadingIndicatorSpinnerComponent is a loading indicator, that is animated
 * as long as it is attached to the dom. You propably want to use it
 * in combination with an *ngIf or similar to attach this component dynamically to the dom.
 * Do notice that the animation is slightly different in IE due to some unsupported properties.
 *
 * <example-url>https://frontend.gitlab-pages.innogy.com/emob-toolkit/emob-toolkit-demo/#/loading-indicator</example-url>
 */
@Component({
  selector: 'emob-loading-indicator-spinner',
  templateUrl: './loading-indicator-spinner.component.html',
  styleUrls: ['./loading-indicator-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorSpinnerComponent {}
