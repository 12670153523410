import { Injectable } from '@angular/core';
import { combineEpics } from 'redux-observable';
import { BackendDataEpics } from './backend-data.epics';

@Injectable()
export class RootEpics {
  constructor(private backendDataEpics: BackendDataEpics) {}

  public createRootEpics() {
    return combineEpics(
      this.backendDataEpics.login$,
      this.backendDataEpics.logout$,
      this.backendDataEpics.logoutSuccess$,
      this.backendDataEpics.startLogin$,
      this.backendDataEpics.fetchUserSettingsValueList$
    );
  }
}
