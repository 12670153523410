import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { IUserLogin } from '@auth/login';
import { AuthenticationService } from '@shared/services/auth';
import { BackendDataActions, SessionDataActions } from '@shared/store/actions';
import { IAppState } from '@shared/store/models';
import { isEmpty } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AccessGuardLogged implements CanActivate {
  constructor(
    private store: NgRedux<IAppState>,
    private authService: AuthenticationService,
    private backendDataActions: BackendDataActions,
    private router: Router,
    private sessionDataActions: SessionDataActions
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.checkLoginInStore(state);
  }

  private checkLoginInStore(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(['backendData', 'userLogin']).pipe(
      take(1),
      switchMap((userLogin: IUserLogin) => {
        if (isEmpty(userLogin)) {
          return this.checkLogginFromBackend$().pipe(
            map(userLoginData => {
              if (isEmpty(userLoginData)) {
                return this.router.parseUrl('/auth/login');
              } else {
                return true;
              }
            })
          );
        } else {
          return this.navigateLoggedIn(state);
        }
      }),
      catchError(err => {
        return this.redirectToAuthPage(state);
      })
    );
  }

  private checkLogginFromBackend$(): Observable<IUserLogin> {
    // Login user if he has a valid session
    return this.authService.getCurrentUserLogin().pipe(
      take(1),
      tap(userLogin => {
        this.addUserToStore(userLogin);
      })
    );
  }

  private addUserToStore(userLogin: IUserLogin): void {
    this.backendDataActions.loginExternal(userLogin);
  }

  private redirectToAuthPage(state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const currentUrl = state.url.split('/');
    if (currentUrl.includes('auth')) {
      return of(true);
    }
    this.saveUnauthNavigatedPageToStore(state.url);
    return of(this.router.parseUrl('/auth/login'));
  }

  private navigateLoggedIn(state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const currentUrl = state.url.split('/');
    if (currentUrl.includes('auth')) {
      return of(this.router.parseUrl('/monitor/dashboard'));
    }
    return of(true);
  }

  private saveUnauthNavigatedPageToStore(url: string | null = null): void {
    this.sessionDataActions.saveUnauthNavigatedPage(url);
  }
}
