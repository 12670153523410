<div class="box">
  <eop-icon
    [identifier]="iconName"
    [clickable]="true"
    [size]="IconSize.NORMAL"
    [color]="color"
    [weight]="IconWeight.BOLD"
    [dataTestId]="testId"
  ></eop-icon>
</div>
