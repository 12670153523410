<input
  #input
  [disabled]="disabled"
  type="text"
  autocomplete="off"
  [placeholder]="placeholder"
  spellcheck="false"
  matInput
  [value]="selectedValue ? (translationPrefix + selectedValue | translate) : null"
  (input)="onInput(input.value)"
  [matAutocomplete]="auto"
/>
<mat-autocomplete
  #auto="matAutocomplete"
  autoActiveFirstOption
  (optionSelected)="onSelect($event.option.value)"
>
  <mat-option *ngFor="let value of filteredValues | translatedSort" class="item" [value]="value">
    {{ translationPrefix + value | translate }}
  </mat-option>
</mat-autocomplete>
