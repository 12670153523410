import { Injectable, Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'myNumberFormat' })
export class NumberFormatPipe implements PipeTransform {
  transform(
    value: number | string,
    fractionSize: number = 2,
    DECIMAL_SEPARATOR: string = ',',
    THOUSANDS_SEPARATOR: string = '.'
  ): string {
    if (typeof value === 'undefined' || value === null) {
      return '';
    }
    let [integer, fraction = ''] = value.toString().split('.');

    if (fraction) {
      fraction = fractionSize > 0 ? (fraction + PADDING).toString().substring(0, fractionSize) : '';
    } else {
      fraction = '';
      for (let i = 0; i < fractionSize; i++) {
        fraction += '0';
      }
    }

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR);

    return integer + DECIMAL_SEPARATOR + fraction;
  }

  parse(
    value: string,
    fractionSize: number = 2,
    DECIMAL_SEPARATOR: string = ',',
    THOUSANDS_SEPARATOR: string = '.'
  ): string {
    let [integer, fraction = ''] = (value || '').split(DECIMAL_SEPARATOR);

    if (!fraction) {
      fraction =
        parseInt(fraction, 10) > 0 && fractionSize > 0
          ? (fraction + PADDING).substring(0, fractionSize)
          : '';
    }
    integer = integer.replace(new RegExp(THOUSANDS_SEPARATOR, 'g'), '');

    return integer + DECIMAL_SEPARATOR + fraction;
  }
}
