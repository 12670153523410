import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';

export interface PopoverCloseEvent<T> {
  type: 'backdropClick' | 'close';
  data: T;
}

export type PopoverContent = TemplateRef<any> | Type<any> | string;

export class PopoverRef<T = any> {
  private afterClosedSubject$ = new Subject<PopoverCloseEvent<T>>();
  afterClosed$ = this.afterClosedSubject$.asObservable();

  constructor(
    public overlay: OverlayRef,
    public content: PopoverContent,
    public data: T,
    public vcr: T,
    public callback?: T
  ) {
    overlay.backdropClick().subscribe(() => this._close('backdropClick', data));
  }

  close(data?: T) {
    this._close('close', data);
  }

  private _close(type, data) {
    this.overlay.dispose();
    this.afterClosedSubject$.next({
      type,
      data,
    });
    this.afterClosedSubject$.complete();
  }
}
