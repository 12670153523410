<form [formGroup]="form">
  <input
    #input
    type="text"
    spellcheck="false"
    matInput
    formControlName="city"
    autocomplete="off"
    [matAutocomplete]="auto"
    [placeholder]="
      ('GLOBAL.CITY' | translate) +
      ' ' +
      ('GLOBAL.OR' | translate) +
      ' ' +
      ('GLOBAL.ZIP' | translate)
    "
    (input)="onInput(input.value)"
    (focus)="onFocus(input.value)"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    (optionSelected)="onSelect($event.option.value)"
  >
    <mat-option *ngIf="noResults && !loading && !searchInputIsEmpty" class="item" disabled>
      {{ 'GLOBAL.NO_ENTRIES' | translate }}
    </mat-option>
    <mat-option *ngIf="loading && !searchInputIsEmpty" class="item" disabled>
      {{ 'GLOBAL.LOADING_DATA' | translate }}
    </mat-option>
    <div *ngIf="!loading && !searchInputIsEmpty">
      <mat-option *ngFor="let value of suggestions" class="item" [value]="value">
        {{ value.suggestion }}
      </mat-option>
    </div>
  </mat-autocomplete>
</form>
