<div class="charging-station-assignments">
  <innogy-box [padding]="false" [margin]="false" class="box-assignments">
    <div class="heading-s headline">
      {{ 'PORTAL_USERS_LOC.LOCATIONS' | translate }}
    </div>
    <div id="locationAssignmentsTable" class="table">
      <app-innogy-data-table
        [tableConfig]="tableConfigLocationAssignments"
        [dataSource]="tableDataLocationAssignments"
        [contentPlaceholder]="tableContentPlaceholderLocationAssignments"
      >
      </app-innogy-data-table>
    </div>
  </innogy-box>

  <innogy-box [padding]="false" [margin]="false" class="box-assignments">
    <div class="heading-s headline">
      {{ 'CHARG_INF_DETAIL.GROUPS' | translate }}
    </div>
    <div id="groupAssignmentsTable" class="table">
      <app-innogy-data-table
        [tableConfig]="tableConfigGroupAssignments"
        [dataSource]="tableDataGroupAssignments"
        [contentPlaceholder]="tableContentPlaceholderGroupAssignments"
      >
      </app-innogy-data-table>
    </div>
  </innogy-box>

  <innogy-box [padding]="false" [margin]="false" class="box-assignments">
    <div class="heading-s headline">
      {{ 'CHARG_INF_DETAIL.PRODUCTS' | translate }}
    </div>
    <div id="productsAssignmentsTable" class="table">
      <app-innogy-data-table
        [tableConfig]="tableConfigProductAssignments"
        [dataSource]="tableDataProductAssignments"
        [contentPlaceholder]="tableContentPlaceholderProductAssignments"
      >
      </app-innogy-data-table>
    </div>
  </innogy-box>
</div>
