import { InjectionToken } from '@angular/core';

/**
 * This class describes the configuration of a modal dialog.
 * It comes with default values which are also used for the default
 * configuration.
 */
// tslint:disable:no-inferrable-types
// tslint:disable-next-line:no-any
export class ModalConfig<T = any> {
  /**
   * this property is added to the panel and can be used for custom styling
   */
  panelClass?: string;
  /**
   * This property disables the click listener to the modal backdrop.
   * Default is true
   */
  disableBackdropCloseAction?: boolean = true;
  /**
   * Property for displaying the closing 'X' in the modal card header.
   * This property just works out of the box if you make use of the [ModalCardHeaderComponent]{@link ModalCardHeaderComponent}
   */
  displayClosingButton?: boolean = true;
  /**
   * this property is added to the background and can be used for custom styling
   */
  backdropClass?: string = 'emob-dark-backdrop';
  /**
   * The data passed to this property will be used to fill the [Modal Data Injection Token]{@link MODAL_DATA}.
   * You can inject this token in the component passed to the Modal
   * to share data between the parent component and the modal child component.
   */
  data?: T | null = null;
}

/**
 * This Injection token is used as Default configuration for the Modal itself. It will be merged
 * with the configuration properties passed to [modal open call]{@link ModalService#open}.
 * You can provide this injection Token on module level and component level to configure
 * default behavior on this levels. Do notice that you need to provide a new instance of
 * the ModalService to have different configurations.
 */
export const DEFAULT_MODAL_CONFIG = new InjectionToken<ModalConfig>('MODAL_CONFIG');
