<div class="container {{ 'state-' + state }}">
  <eop-icon
    [identifier]="'check-circle-filled'"
    [vaylensFont]="true"
    [color]="getIconColor()"
    [size]="IconSize.LITTLE_MONSTER"
  ></eop-icon>
  <div class="messages">
    <div class="heading-m">
      {{ headline }}
    </div>
    <div class="body-l">
      {{ message }}
    </div>
  </div>
</div>
