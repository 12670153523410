<div class="group-member">
  <innogy-box>
    <innogy-box-headline>
      <eop-loading-results
        [label]="group?.name"
        [count]="group?.groupMembers?.paging?.totalItems"
        [countText]="headlineLabel"
        [loading]="!group?.groupMembers"
      >
      </eop-loading-results>
    </innogy-box-headline>
    <div *ngIf="group?.groupMembers; else spinner">
      <app-innogy-data-table
        [dataSource]="tableData"
        [tableConfig]="tableConfig"
        (requireNewData)="onRequireNewData($event.page)"
      >
      </app-innogy-data-table>
    </div>
  </innogy-box>
</div>

<ng-template #spinner>
  <div class="spinner">
    <spinner></spinner>
  </div>
</ng-template>
