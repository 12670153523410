export class DataTableRow {
  constructor(
    public id: string,
    public data: { [key: string]: any },
    public disabled: boolean = false,
    public highlightedColor: HighlightedColor = HighlightedColor.NONE,
    /**
     * use expandable for option to expand certain row,
     * or use expandable in table config
     */
    public expandable: boolean = false
  ) {}
}

export enum HighlightedColor {
  NONE = 'none',
  INFO = 'info',
  WARNING = 'warning',
}
