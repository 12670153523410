import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { PageType } from '@content/wiki/data/wiki.model';
import { EmssHtmlPageService, PermissionService } from '@shared/services/util';
import { SessionDataActions } from '@shared/store/actions';
import { LanguageService } from '@shared/translate/language.service';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WikiComponent } from '../wiki.component';

@Component({
  selector: 'eop-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent extends WikiComponent implements OnInit, AfterViewInit {
  @ViewChildren(MatExpansionPanel)
  expPanels: QueryList<MatExpansionPanel>;

  queryIndex = -1;
  expandLastReleaseNotes: boolean;

  constructor(
    private readonly elementRef: ElementRef,
    public readonly route: ActivatedRoute,
    public readonly cdr: ChangeDetectorRef,
    public readonly permissionService: PermissionService,
    public readonly languageService: LanguageService,
    public readonly emssHtmlPageService: EmssHtmlPageService,
    private readonly sessionDataActions: SessionDataActions,
    @Inject(DOCUMENT) public readonly document: Document
  ) {
    super(
      elementRef,
      route,
      cdr,
      permissionService,
      languageService,
      emssHtmlPageService,
      document
    );
    this.pageType = PageType.FAQ;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.expandLastReleaseNotes) {
        this.expPanels.first.expanded = true;
        this.sessionDataActions.closeMessages();
      }
    }, 400);
  }

  public processDocument(): void {
    this.getHtmlPage$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((doc: string) => this.updateDocument(doc));
  }

  public updateDocument(doc: string): void {
    const htmlParser = new DOMParser();
    const document = htmlParser.parseFromString(doc, 'text/html');
    super.checkPermissions(document);
    super.transformHtmlToObject();
    this.headerLinks = super.createLinks(document);
    this.cdr.detectChanges();
    super.scrollIntoView();
  }

  public subscribeRouteFragment(): void {
    combineLatest([this.route.fragment, this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.fragment = data[0];
        if (data[1].queryIndex) {
          this.queryIndex = Number(data[1].queryIndex);
          setTimeout(() => this.replay$.next(data[0]), 400);
        } else {
          this.queryIndex = -1;
          this.replay$.next(data[0]);
        }
        if (data[1].expandReleaseNotes === 'true') {
          this.expandLastReleaseNotes = true;
        }
      });
  }
}
