import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { PopoverRef } from '../classes/popover-ref';
import { ComponentData, Item } from '../services/filter-frontend.model';

@Component({
  selector: 'eop-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterInputComponent {
  @Input()
  items: Item[];

  @Output()
  itemClick = new EventEmitter<ComponentData>();

  componentData: ComponentData;

  constructor(@Optional() popoverRef?: PopoverRef) {
    if (popoverRef) {
      this.componentData = popoverRef.data;
    }
  }
}
