import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { EWaitResponseType, INotification } from '../data/session-data.model';
import { IActionWithPayload, IAppState } from '../models';

@Injectable()
export class SessionDataActions {
  public static REHYDRATION_COMPLETED: string = 'REHYDRATION_COMPLETE';
  public static SHOW_TOP_NOTIFICATION: string = 'SHOW_TOP_NOTIFICATION';
  public static CLOSE_TOP_NOTIFICATION: string = 'CLOSE_TOP_NOTIFICATION';

  public static COLLAPSE_SIDEBAR: string = 'COLLAPSE_SIDEBAR';
  public static EXPAND_SIDEBAR: string = 'EXPAND_SIDEBAR';

  public static WAIT_LOGIN: string = 'WAIT_LOGIN';

  public static UNAUTH_NAVIGATED_PAGE: string = 'UNAUTH_NAVIGATED_PAGE';

  public static OPEN_MESSAGES: string = 'OPEN_MESSAGES';
  public static CLOSE_MESSAGES: string = 'CLOSE_MESSAGES';
  public static TOGGLE_MESSAGES: string = 'TOGGLE_MESSAGES';

  constructor(private store: NgRedux<IAppState>) {}

  public static rehydrationCompleted(): IActionWithPayload<boolean> {
    return { type: SessionDataActions.REHYDRATION_COMPLETED, payload: true };
  }

  public showNotification(notification: INotification): IActionWithPayload<INotification> {
    return this.store.dispatch({
      type: SessionDataActions.SHOW_TOP_NOTIFICATION,
      payload: notification,
    });
  }

  public closeNotification(): IActionWithPayload<INotification> {
    return this.store.dispatch({
      type: SessionDataActions.CLOSE_TOP_NOTIFICATION,
    });
  }

  public openMessages(): IActionWithPayload<void> {
    return this.store.dispatch({
      type: SessionDataActions.OPEN_MESSAGES,
    });
  }

  public closeMessages(): IActionWithPayload<void> {
    return this.store.dispatch({
      type: SessionDataActions.CLOSE_MESSAGES,
    });
  }

  public toggleMessages(): IActionWithPayload<void> {
    return this.store.dispatch({
      type: SessionDataActions.TOGGLE_MESSAGES,
    });
  }

  public collapseSidebar() {
    return this.store.dispatch({
      type: SessionDataActions.COLLAPSE_SIDEBAR,
    });
  }

  public expandSidebar() {
    return this.store.dispatch({
      type: SessionDataActions.EXPAND_SIDEBAR,
    });
  }

  public waitLogin(value: EWaitResponseType) {
    this.store.dispatch({
      type: SessionDataActions.WAIT_LOGIN,
      payload: value,
    });
  }

  public saveUnauthNavigatedPage(value: string) {
    this.store.dispatch({
      type: SessionDataActions.UNAUTH_NAVIGATED_PAGE,
      payload: value,
    });
  }
}
