import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { MiniButtonComponent } from './mini-button.component';

@NgModule({
  declarations: [MiniButtonComponent],
  imports: [CommonModule, EopIconModule],
  exports: [MiniButtonComponent],
})
export class MiniButtonModule {}
