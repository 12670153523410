import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Icon, IconColor } from '@widgets/eop-icon';
import { CityZip, CityZips } from '../data/filter.model';

@Component({
  selector: 'eop-address-city-zip-element',
  templateUrl: './address-city-zip-element.component.html',
  styleUrls: ['./address-city-zip-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressCityZipElementComponent implements OnInit, OnChanges {
  @Input()
  icon: Icon;

  @Input()
  placeholder: string = '';

  @Input()
  values: CityZips;

  @Input()
  disabled = false;

  @Output()
  selectionChange: EventEmitter<CityZip> = new EventEmitter<CityZip>();

  @ViewChild('input', { static: true })
  private input: ElementRef;

  filteredCities: Array<string>;
  filteredZips: Array<string>;
  selectedValueString = '';
  selectedValue: CityZip;

  readonly IconColor = IconColor;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.setDefaultValues();
    this.selectedValue = {
      city: null,
      zip: null,
    };
    this.selectedValueString = '';
  }

  ngOnChanges(change: SimpleChanges) {
    this.setDefaultValues();
  }

  onInput(input: string) {
    if (input === '') {
      this.selectedValue = { city: null, zip: null };
      this.selectionChange.emit(this.selectedValue);
      this.setDefaultValues();
    } else {
      const splitted = input.split('-');
      if (this.values.cities) {
        this.filteredCities = this.filterArray(this.values.cities, splitted[0]);
      }
      if (this.values.zips) {
        if (this.filteredCities && this.filteredCities.length > 0) {
          this.filteredZips = this.filterArray(this.values.zips, splitted[1] ? splitted[1] : null);
        } else {
          this.filteredZips = this.filterArray(this.values.zips, splitted[0]);
        }
      }
    }
  }

  filterArray(array, searchString) {
    return array.filter(elem => {
      return elem
        ? elem.toLowerCase().includes(searchString ? searchString.trim().toLowerCase() : false)
        : false;
    });
  }

  onSelect(value: string) {
    if (this.values.cities.includes(value)) {
      if (this.selectedValue.city === value) {
        this.selectedValue.city = null;
      } else {
        this.selectedValue.city = value;
      }
    } else if (this.values.zips.includes(value)) {
      if (this.selectedValue.zip === value) {
        this.selectedValue.zip = null;
      } else {
        this.selectedValue.zip = value;
      }
    }

    this.setSelectedValueString();
    this.selectionChange.emit(this.selectedValue);
    this.input.nativeElement.blur();
  }

  setSelectedValueString() {
    let selectedValueString = this.selectedValue.city ? this.selectedValue.city + ' - ' : '';
    selectedValueString += this.selectedValue.zip ? this.selectedValue.zip : '';

    this.selectedValueString = selectedValueString;
  }

  reset() {
    this.setDefaultValues();
    this.selectedValue = {
      city: null,
      zip: null,
    };
    this.selectedValueString = '';

    this.selectionChange.emit(this.selectedValue);
  }

  setDefaultValues() {
    this.filteredCities = this.values ? this.values.cities : [];
    this.filteredZips = this.values ? this.values.zips : [];
  }
}
