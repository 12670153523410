// Global Component or Specific Page
export enum GoogleAnalyticsCategory {
  PAGE_VISIT = 'PAGE_VISIT', // Used by the router
  QUICK_SEARCH = 'QUICK_SEARCH',
  QUICK_HELP = 'QUICK_HELP',
  SIDE_MENU = 'SIDE_MENU',
  DASHBOARD = 'DASHBOARD',
  OPENING_HOURS = 'OPENING_HOURS',
  CSV = 'CSV',
  TABLE = 'TABLE',
  CHARGING_INFRASTRUCTURE_DETAIL = 'CHARGING_INFRASTRUCTURE_DETAIL',
  EMAID = 'EMAID',
  PRICING_SET = 'PRICING_SET',
  PRICING_ASSIGNMENTS = 'PRICING_ASSIGNMENTS',
  CHARGE_RECORDS_CPO = 'CHARGE_RECORDS_CPO',
  CHARGE_RECORDS_EMP = 'CHARGE_RECORDS_EMP',
  CHARGING_INFRASTRUCTURE_OVERVIEW = 'CHARGING_INFRASTRUCTURE_OVERVIEW',
  PRODUCT_ASSIGNMENT = 'PRODUCT_ASSIGNMENT',
}

export enum GoogleAnalyticsAction {
  URL_CHANGED = 'URL_CHANGED', // Used by the router
  COLLAPSE = 'COLLAPSE', // When something opens/closes
  TAB_CLICK = 'TAB_CLICK',
  NAVIGATE = 'NAVIGATE', // Used by Side_Menu / Quick Search (label can be the url or a description)
  MAP = 'MAP', // Used when a map is opened by the user
  CREATE_EDIT = 'CREATE_EDIT', // With optional label success/error or extra info
  CREATE = 'CREATE', // With optional label success/error or extra info
  EDIT = 'EDIT', // With optional label success/error or extra info
  SEARCH = 'SEARCH',
  DOWNLOAD = 'DOWNLOAD',
  CONTROL = 'CONTROL',
  OVERVIEW = 'OVERVIEW',
  ASSIGN = 'ASSIGN',
}

export enum GoogleAnalyticsLabel {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  CHARGING_STATION = 'CHARGING_STATION',
  CHARGE_PORT = 'CHARGE_PORT',
  AVAILABLE = 'AVAILABLE',
  DETAILS = 'DETAILS',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export interface GoogleAnalyticsEventWrapper {
  event: Array<{
    eventInfo: {
      eventCategory: GoogleAnalyticsCategory;
      eventAction: GoogleAnalyticsAction;
      eventLabel: GoogleAnalyticsLabel | string;
    };
  }>;
}
