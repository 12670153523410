import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { FormService } from '@shared/services/util/form.service';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { ChipColor } from '@widgets/innogy-chips/chipColor';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Extract arguments of function
 */
export type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

/**
 * Creates an object like O. Optionally provide minimum set of properties P which the objects must share to conform
 */
type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>;

@Component({
  selector: 'eop-form-input-item',
  templateUrl: './form-input-item.component.html',
  styleUrls: ['./form-input-item.component.scss'],
})
export class FormInputItemComponent implements AfterViewInit, OnDestroy {
  @ViewChild('contentWrapper', { static: true }) contentWrapper: ElementRef;

  charactersLeft: number;
  inputElement: any;
  inputControl: AbstractControl;
  @Input()
  title: string;
  @Input()
  titleDisabled = false;
  @Input()
  required: boolean;
  @Input()
  errorMessage: string;
  @Input()
  additionalErrorMessage: string;
  @Input()
  customErrorMessageHasPriority: boolean;
  @Input()
  disabledMessage: string;
  @Input()
  warningMessage: string;
  @Input()
  successMessage: string;
  @Input()
  tooltipText: string;
  @Input()
  maxCharacters: number;
  @Input()
  loading: boolean;
  @Input()
  formControlValidate: string;
  @Input()
  additionalFormControlValidate: string;
  @Input()
  immediateValidation: boolean;
  @Input()
  isFullWidth = true;
  @Input()
  headerChipValue: string;
  @Input()
  headerChipColor = ChipColor.DEFAULT;
  @Input()
  headerChipTooltipText: string;
  @Output()
  clear: EventEmitter<any> = new EventEmitter<any>();
  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    if (this.formControlValidate) {
      this.validations(this.formControlValidate, false);
    }
    if (this.additionalFormControlValidate) {
      this.validations(this.additionalFormControlValidate, true);
    }
    if (this.maxCharacters && this.contentWrapper) {
      this.inputElement = this.contentWrapper.nativeElement.querySelector('input[type=text]');
      if (!this.inputElement) {
        this.inputElement = this.contentWrapper.nativeElement.querySelector('textarea');
      }
      if (!this.inputElement) {
        this.inputElement = this.contentWrapper.nativeElement.querySelector('input[type=password]');
      }
      this.cdr.detectChanges();
    }
  }

  private validations(formControlValue: string, isAddtional: boolean) {
    const parentFormGroup = <FormGroup>this.controlContainer.control;
    this.inputControl = parentFormGroup.get(formControlValue);
    // in some cases dropdown has empty value: { name: null, value: null }
    const isEmptyDropdown =
      this.inputControl.value?.value === null || this.inputControl.value?.length === 0;
    if (
      this.immediateValidation ||
      (isEmptyDropdown ? !!this.inputControl.value?.value : !!this.inputControl.value)
    ) {
      // Sometimes input field is already filled -> in this case we directly want to check for
      // possible validation errors
      this.errorMessage = this.formService.errorForControl(formControlValue, parentFormGroup);
    }
    const touchedChanged$ = this.extractTouchedChanges$(this.inputControl);
    merge(this.inputControl.valueChanges, touchedChanged$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.immediateValidation || this.inputControl.dirty || this.inputControl.touched) {
          // In general, we only show validation errors in case input field has been visited already
          if (
            this.inputControl.errors &&
            this.errorMessage &&
            this.customErrorMessageHasPriority &&
            this.inputControl.value
          ) {
            return;
          } else {
            isAddtional
              ? (this.additionalErrorMessage = this.formService.errorForControl(
                  formControlValue,
                  parentFormGroup
                ))
              : (this.errorMessage = this.formService.errorForControl(
                  formControlValue,
                  parentFormGroup
                ));
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clearInput() {
    if (this.inputElement && !this.inputElement.disabled) {
      this.inputElement.value = '';
      if (this.inputControl) {
        this.inputControl.markAsDirty();
        this.inputControl.setValue(this.inputElement.value);
      }
      this.clear.emit();
    }
  }

  /**
   * Extract a touched changed observable from an abstract control
   * @param control AbstractControl like object with markAsTouched method
   */
  extractTouchedChanges$ = (
    control: ObjectLike<AbstractControl, 'markAsTouched' | 'markAsUntouched'>
  ): Observable<boolean> => {
    const prevMarkAsTouched = control.markAsTouched.bind(control);
    const prevMarkAsUntouched = control.markAsUntouched.bind(control);

    const touchedChanges$ = new Subject<boolean>();

    function nextMarkAsTouched(...args: ArgumentsType<AbstractControl['markAsTouched']>) {
      prevMarkAsTouched(...args);
      touchedChanges$.next(true);
    }

    function nextMarkAsUntouched(...args: ArgumentsType<AbstractControl['markAsUntouched']>) {
      prevMarkAsUntouched(...args);
      touchedChanges$.next(false);
    }

    control.markAsTouched = nextMarkAsTouched;
    control.markAsUntouched = nextMarkAsUntouched;

    return touchedChanges$;
  };
}
