import { DialogRef } from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalRef } from '../modal/modalRef';

/**
 * This is for now just for internal use cases to determine wether a modal is open or not.
 * @ignore
 */
@Injectable({
  providedIn: 'root',
})
export class SharedRefService {
  /**
   * Number of currently opened modal dialogs.
   * Never less than 0
   */
  public modalCounter = 0;
  public dialogCounter = 0;

  allModalsRef: ModalRef[] = [];
  activeOverlayRef: OverlayRef = undefined;

  allDialogRef: DialogRef[] = [];

  private readonly allModals$ = new BehaviorSubject<boolean>(false);
  private readonly allDialogs$ = new BehaviorSubject<boolean>(false);

  get allModalRefs$(): Observable<boolean> {
    return this.allModals$.asObservable();
  }

  get allDialogRefs$(): Observable<boolean> {
    return this.allDialogs$.asObservable();
  }

  public openModal(modalRef: ModalRef): void {
    this.allModalsRef.push(modalRef);
    if (this.modalCounter === 0) {
      this.allModals$.next(true);
    }
    this.modalCounter++;
  }

  public openDialog(dialogRef): void {
    this.allDialogRef.push(dialogRef);
    if (this.dialogCounter === 0) {
      this.allDialogs$.next(true);
    }
    this.dialogCounter++;
  }

  public closeModal(): void {
    this.allModalsRef.pop();
    if (this.modalCounter > 0) {
      this.modalCounter--;
    }
    if (this.modalCounter === 0) {
      this.allModals$.next(false);
    }
  }

  public closeDialog(): void {
    this.allDialogRef.pop();
    if (this.dialogCounter > 0) {
      this.dialogCounter--;
    }
    if (this.dialogCounter === 0 && this.modalCounter === 0) {
      this.allDialogs$.next(false);
    }
  }

  public closeAllModals(): void {
    this.allModalsRef.forEach(modal => modal.closeWithoutAnimation());
    this.allModalsRef = [];
    this.modalCounter = 0;
    this.allModals$.next(false);
  }

  public storeOverlay(overlayRef: OverlayRef): void {
    this.activeOverlayRef = overlayRef;
  }

  public closeAllOverlays(expiredSession?: boolean) {
    if (this.activeOverlayRef && expiredSession) {
      this.activeOverlayRef.dispose();
    }
    if (this.activeOverlayRef && !expiredSession) {
      this.activeOverlayRef.detach();
    }
    this.activeOverlayRef = undefined;
  }
}
