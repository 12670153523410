<div
  *ngIf="!disabled"
  class="element"
  [ngClass]="classWidth"
  [class.error]="mandatory && _values.length === 0"
>
  <div class="header">
    <div class="label">
      {{ label + (mandatory ? ' *' : '') }}
      <app-innogy-chips
        *ngIf="headerChipValue"
        [chipsData]="[{ value: headerChipValue }]"
        [chipColor]="headerChipColor"
        [emphasized]="false"
        [matTooltip]="headerChipTooltipText"
        [matTooltipPosition]="'right'"
      >
      </app-innogy-chips>
    </div>
    <eop-icon
      *ngIf="infoText"
      class="icon"
      [size]="IconSize.SMALL"
      [color]="infoTextColor ? infoTextColor : IconColor.NORMAL"
      [identifier]="'info-circle-filled'"
      [tooltip]="infoText"
      [vaylensFont]="true"
    >
    </eop-icon>
  </div>
  <div class="form-element-content" [ngClass]="{ 'wrap-lines': breakLines }">
    <div *ngIf="contentTemplate">
      <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
    </div>
    <div class="form-element-content" *ngIf="!breakLines"><ng-content></ng-content></div>
    <div
      class="value-wrapper"
      [ngClass]="{
        value: value,
        'value--overflow-hidden': hideTextOverflow,
        'slash-separated': slashSeparatedValues
      }"
      *ngFor="let value of _values; let i = index; let length = count"
      [matTooltip]="
        value?.value
          ? value.value + ' (' + value.additionalTooltipText + ')'
          : hideTextOverflow
          ? value
          : null
      "
    >
      <p class="content-text">
        <span class="content-text-value body-l">{{ value.value ? value.value : value }}</span>
        <span class="slash-separator" *ngIf="i + 1 < length && length > 1 && slashSeparatedValues">
          /
        </span>
      </p>
    </div>
    <div *ngIf="mandatory && _values.length === 0">
      <p class="content-text">-</p>
    </div>

    <div class="value-with-link" *ngFor="let value of valuesWithLink">
      <a (click)="navigateRoute(value)">{{ value.value }}</a>
    </div>

    <div
      *ngIf="(iconIdentifier || iconText) && !showWaitIcon"
      class="icon-wrapper"
      [matTooltip]="iconTooltip"
      (click)="iconClick()"
    >
      <eop-icon
        *ngIf="iconIdentifier"
        class="icon"
        [style.cursor]="disabled ? 'default' : 'pointer'"
        [identifier]="iconIdentifier"
        [weight]="iconWeight"
        [size]="iconSize"
        [color]="iconTextColor"
      ></eop-icon>

      <div *ngIf="iconText" class="icon-text" [ngClass]="{ disabled: disabled }">
        {{ iconText }}
      </div>
    </div>

    <app-innogy-chips
      *ngIf="chipValue"
      [chipsData]="[{ value: chipValue }]"
      [chipColor]="chipColor"
      [emphasized]="chipEmphasized"
      [matTooltip]="chipTooltipText"
      [matTooltipPosition]="'right'"
    >
    </app-innogy-chips>
  </div>

  <div class="separation-line"></div>

  <div *ngIf="showWaitIcon">
    <spinner></spinner>
  </div>
</div>
