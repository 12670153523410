export enum ChipColor {
  // OLD
  DEFAULT = 'chip-color-default',
  SUCCESS = 'chip-color-success',
  ERROR = 'chip-color-error',
  WARNING = 'chip-color-warning',
  INFO = 'chip-color-info',
  // NEW
  DEFAULT_NEW = 'chip-color-default-new',
  SUCCESS_NEW = 'chip-color-success-new',
  ERROR_NEW = 'chip-color-error-new',
  WARNING_NEW = 'chip-color-warning-new',
  INFO_NEW = 'chip-color-info-new',
}
