<eop-icon
  *ngIf="location?.street"
  identifier="pin"
  [tooltip]="'CHARGING_STATION.ADDRESS_LINE' | translate"
  [size]="IconSize.SMALL"
  [weight]="IconWeight.BOLD"
></eop-icon>
<ul *ngIf="location?.street">
  <li>{{ location | location : 'line1' }}</li>
  <li>{{ location | location : 'line2' }}</li>
  <li>{{ 'GLOBAL.' + location.country | translate }}</li>
</ul>
