import { routerReducer } from '@angular-redux/router';
import { locationsOverviewReducer } from '@portal/location-management/store/locations-overview.reducer';
import { backendDataReducer } from '@shared/store/reducers/backend-data.reducer';
import { sessionDataReducer } from '@shared/store/reducers/session-data.reducer';
import { userFilterOptionsReducer } from '@shared/store/reducers/user-filter-options.reducer';
import { ReducersMapObject } from 'redux';

export const rootReducers: ReducersMapObject = {
  router: routerReducer,
  sessionData: sessionDataReducer,
  backendData: backendDataReducer,
  userFilterOptions: userFilterOptionsReducer,
  locations: locationsOverviewReducer,
};
