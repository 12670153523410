import { ComponentType } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DateConversionService,
  EDetailedStatusEnum,
  EStatusEnum,
  StatusService,
} from '@shared/services/util';
import { FilterUtilService } from '@widgets/filter/specific/services/filter-util.service';
import { environment } from 'environments/environment';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FilterInputComponent } from '../components/filter-input.component';
import { MultiselectComponent } from '../components/multiselect/multiselect.component';
import { SingleDateTimeSelectComponent } from '../components/single-date-time-select/single-date-time-select.component';
import { SingleselectComponent } from '../components/singleselect/singleselect.component';
import { StatusCascadingSelectComponent } from '../components/status-cascading-select/status-cascading-select.component';
import { ETimePeriod } from '../data/filter-container.model';
import {
  ComponentData,
  Item,
  SpecificFilterConfig,
  SpecificFilterDateRestrictions,
} from './filter-frontend.model';

@Injectable()
export class FilterBackendService {
  activationStatuses: string[];
  authenticationTypes: string[];
  authenticationModes: string[];
  protocols: string[];
  connectorTypes: string[];
  firmwareVersions: string[];
  accessibilities: string[];
  evseStatus: any[];
  evseDetailedStatus: any[];
  chargingStationSources: any[];
  chargingTypes: any[];
  manufacturers: string[];
  models: string[];

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private statusService: StatusService,
    private dateConversionService: DateConversionService,
    private filterUtilService: FilterUtilService
  ) {}

  // FILTER.AUTH_TYPE
  getAllAuthTypes$(url?: string, config?: SpecificFilterConfig): Observable<ComponentData> {
    const defaultServerKey = config && config.serverKeys ? config.serverKeys : ['auth-type'];
    const defaultType = MultiselectComponent;
    const defaultTitle = this.translate.instant('GLOBAL.AUTH_TYPES');
    const translationPrefix = 'GLOBAL_AUTH_SOURCES';

    return this.fetchLatestValues$(
      !!this.authenticationTypes,
      url,
      'authenticationTypes',
      this.authenticationTypes,
      defaultType,
      defaultTitle,
      defaultServerKey,
      config,
      translationPrefix
    );
  }

  // FILTER.PROTOCOL
  getProtocols$(url?: string, config?: SpecificFilterConfig): Observable<ComponentData> {
    const serverKey = config && config.serverKeys ? config.serverKeys : ['protocol-list'];
    const defaultType = MultiselectComponent;
    const defaultTitle = this.translate.instant('FILTER.PROTOCOL');

    return this.fetchLatestValues$(
      !!this.protocols,
      url,
      'protocols',
      this.protocols,
      defaultType,
      defaultTitle,
      serverKey,
      config
    );
  }

  createStaticListObservable$(
    list: any[],
    translationPrefix: string = 'GLOBAL',
    config?: SpecificFilterConfig,
    itemTemplate?: TemplateRef<any>,
    dataList?: any[]
  ): Observable<ComponentData> {
    return of(
      this.filterUtilService.buildComponentData(
        SingleselectComponent,
        '',
        [],
        list,
        translationPrefix,
        config,
        null,
        itemTemplate,
        dataList
      )
    );
  }

  // FILTER.CONNECTOR_TYPE
  getConnectorTypes$(
    url?: string,
    property?: string,
    config?: SpecificFilterConfig
  ): Observable<ComponentData> {
    const serverKey = config && config.serverKeys ? config.serverKeys : ['connector-type-list'];
    const defaultType = MultiselectComponent;
    const defaultTitle = this.translate.instant('GLOBAL.CONNECTOR_TYPE');
    const defaultProperty = 'connectors';

    return this.fetchLatestValues$(
      !!this.connectorTypes,
      url,
      property ? property : defaultProperty,
      this.connectorTypes,
      defaultType,
      defaultTitle,
      serverKey,
      config,
      'CHARG_INF_DETAIL.CONN_TYPE_'
    );
  }

  // FILTER.FIRMWARE_VERSION
  getFirmwareVersions$(url?: string, config?: SpecificFilterConfig): Observable<ComponentData> {
    const serverKey = config && config.serverKeys ? config.serverKeys : ['firmware-version-list'];
    const defaultType = MultiselectComponent;
    const defaultTitle = this.translate.instant('BASE_FILTER.VERSION_FW');

    return this.fetchLatestValues$(
      !!this.firmwareVersions,
      url,
      'firmwareVersions',
      this.firmwareVersions,
      defaultType,
      defaultTitle,
      serverKey,
      config
    );
  }

  // FILTER.CURRENT_TYPE
  getCurrentTypeItems$(config?: SpecificFilterConfig): Observable<ComponentData> {
    const defaultServerKey = ['current-type'];
    const defaultType = SingleselectComponent;
    const defaultTitle = this.translate.instant('FILTER.CURRENT_TYPE');

    return of(
      this.filterUtilService.buildComponentData(
        defaultType,
        defaultTitle,
        defaultServerKey,
        ['AC', 'DC'],
        null,
        config
      )
    );
  }

  //FILTER.CHARGING_TYPE
  getChargingTypes$(
    url = `${environment.bffChargingInfrastructureOperations}/infrastructure-overview/charging-types`,
    config?: SpecificFilterConfig
  ): Observable<ComponentData> {
    const serverKey = config && config.serverKeys ? config.serverKeys : ['current-type'];
    const defaultType = SingleselectComponent;
    const defaultTitle = this.translate.instant('FILTER.CURRENT_TYPE');

    return this.fetchLatestValues$(
      !!this.chargingTypes,
      url,
      'chargingTypes',
      this.chargingTypes,
      defaultType,
      defaultTitle,
      serverKey,
      config
    );
  }

  // FILTER.EVSE_STATUS
  getAllEvseStatus$(config?: SpecificFilterConfig): Observable<ComponentData> {
    this.evseStatus = [];
    this.evseDetailedStatus = [];
    Object.keys(EStatusEnum).forEach(value => this.evseStatus.push(value));
    Object.keys(EDetailedStatusEnum).forEach(value => this.evseDetailedStatus.push(value));
    return of(this.processAllEvseStatus(config));
  }

  // FILTER.INSTALLATION_STATUS
  getInstallationStatus$(config?: SpecificFilterConfig): Observable<ComponentData> {
    const defaultServerKey = ['installationStatus'];
    const defaultType = SingleselectComponent;
    const defaultTitle = this.translate.instant('FILTER.INSTALLATION_STATUS');

    return of(
      this.filterUtilService.buildComponentData(
        defaultType,
        defaultTitle,
        defaultServerKey,
        ['IBET', 'PLANNED', 'ABET'],
        'FILTER.INSTALLATION_STATUS',
        config
      )
    );
  }

  // FILTER.PERIOD
  getPeriodsOfTimeRecords$(
    config?: SpecificFilterConfig,
    items = [
      ETimePeriod.LAST_7_DAYS,
      ETimePeriod.LAST_30_DAYS,
      ETimePeriod.LAST_60_DAYS,
      ETimePeriod.LAST_90_DAYS,
      ETimePeriod.INDIVIDUAL_TIME_PERIOD,
    ],
    dateRestrictions?: SpecificFilterDateRestrictions
  ): Observable<ComponentData> {
    const serverKeys = config && config.serverKeys ? config.serverKeys : ['dateFrom', 'dateTo'];
    const defaultType = SingleDateTimeSelectComponent;
    const defaultTitle = this.translate.instant('GLOBAL.PERIOD_TIME');

    let dataList = [];
    const individIndex = items.findIndex(item => item === ETimePeriod.INDIVIDUAL_TIME_PERIOD);
    if (individIndex > -1) {
      dataList[individIndex] = dateRestrictions;
    }

    let componentData = this.filterUtilService.buildComponentData(
      defaultType,
      defaultTitle,
      serverKeys,
      items,
      'GLOBAL',
      config,
      undefined,
      undefined,
      dataList
    );

    componentData = this.preparePeriodOfTimeItems(config, serverKeys, componentData);

    return of(componentData);
  }

  getChargingStationSources$(
    url?: string,
    config?: SpecificFilterConfig
  ): Observable<ComponentData> {
    const serverKey = config && config.serverKeys ? config.serverKeys : ['station-source'];
    const defaultType = SingleselectComponent;
    const defaultTitle = this.translate.instant('GLOBAL.CHARGING_STATION_SOURCES');
    const translationPrefix = 'PLATFORM_SOURCE';

    return this.fetchLatestValues$(
      !!this.chargingStationSources,
      url,
      'stationSources',
      this.chargingStationSources,
      defaultType,
      defaultTitle,
      serverKey,
      config,
      translationPrefix
    );
  }

  getStationManufacturers$(url?: string, config?: SpecificFilterConfig): Observable<ComponentData> {
    const serverKey = config && config.serverKeys ? config.serverKeys : ['manufacturerList'];
    const defaultType = MultiselectComponent;
    const defaultTitle = this.translate.instant('BASE_FILTER.MANUFACTURERS');

    return this.fetchLatestValues$(
      !!this.manufacturers,
      url,
      'manufacturers',
      this.manufacturers,
      defaultType,
      defaultTitle,
      serverKey,
      config,
      null
    );
  }

  getStationModels$(url?: string, config?: SpecificFilterConfig): Observable<ComponentData> {
    const serverKey = config && config.serverKeys ? config.serverKeys : ['modelInfoList'];
    const defaultType = MultiselectComponent;
    const defaultTitle = this.translate.instant('BASE_FILTER.MODELS');

    return this.fetchLatestValues$(
      !!this.manufacturers,
      url,
      'modelInfos',
      this.models,
      defaultType,
      defaultTitle,
      serverKey,
      config,
      null
    );
  }

  // --- Utilities ------------------------------------------------------------
  private fetchLatestValues$<T>(
    useCache: boolean,
    url: string,
    property: string,
    member: any,
    defaultType: ComponentType<FilterInputComponent>,
    defaultTitle: string,
    defaultServerKey: string[],
    config?: SpecificFilterConfig,
    translationPrefix?: string
  ): Observable<ComponentData> {
    if (useCache) {
      return of(
        this.filterUtilService.buildComponentData(
          defaultType,
          defaultTitle,
          defaultServerKey,
          member,
          translationPrefix,
          config
        )
      );
    } else {
      return this.http.get<T>(url).pipe(
        map(result => result[property]),
        map(resp => {
          return resp.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
        }),
        tap(response => (member = response)),
        map(response =>
          this.filterUtilService.buildComponentData(
            defaultType,
            defaultTitle,
            defaultServerKey,
            response,
            translationPrefix,
            config
          )
        )
      );
    }
  }

  private processAllEvseStatus(config?: SpecificFilterConfig): ComponentData {
    const translationPrefixStatus = 'EVSESTATUS';
    const translationPrefixDtailedStatus = 'EVSEDETAILEDSTATUS';
    const titleStatus = 'FILTER.EVSE_STATUS';
    const titleDetailedStatus = 'GLOBAL.DETAIL_STATUS';
    const serverKeys =
      config && config.serverKeys ? config.serverKeys : ['status-list', 'detailed-status-list'];

    const defaultType = StatusCascadingSelectComponent;
    const defaultStatusTitle = this.translate.instant(titleStatus);
    const defaultDetailedStatusTitle = this.translate.instant(titleDetailedStatus);
    const statusParentItems = this.setColorStatus(
      this.filterUtilService.buildComponentData(
        defaultType,
        defaultStatusTitle,
        serverKeys,
        this.evseStatus,
        translationPrefixStatus,
        config
      ).items
    );

    const detailedStatusItems = this.setColorStatus(
      this.setParentStatus(
        this.filterUtilService.buildComponentData(
          defaultType,
          defaultDetailedStatusTitle,
          serverKeys,
          this.evseDetailedStatus,
          translationPrefixDtailedStatus,
          config,
          1
        ).items,
        statusParentItems
      )
    );
    return {
      items: statusParentItems.concat(detailedStatusItems),
      type: defaultType,
      title: defaultStatusTitle,
    };
  }

  private setColorStatus(items: Item[]): Item[] {
    return items.map(item => {
      const color = this.statusService.convertStatusIntoStatusType(
        <EStatusEnum>(item.parent ? item.parent : item.id),
        <EDetailedStatusEnum>(item.parent ? item.id : undefined)
      );
      return {
        ...item,
        color,
      };
    });
  }

  private setParentStatus(items: Item[], parents: Item[]): Item[] {
    return items.map(item => {
      if (item.id === EDetailedStatusEnum.NONE) {
        return { ...item, parent: EStatusEnum.OCCUPIED };
      }
      const parentFound = parents.find(parent => {
        return item.id.indexOf(parent.id) === 0;
      });
      return parentFound ? { ...item, parent: parentFound.id } : item;
    });
  }

  private preparePeriodOfTimeItems(
    config: SpecificFilterConfig,
    serverKeys: string[],
    componentData: ComponentData
  ): ComponentData {
    const preselect = this.setPreselect(config);
    const keyDateFrom = serverKeys[0];
    const keyDateTo = serverKeys[1];

    const period = this.setPeriod(preselect);

    const items = componentData.items.map(i => {
      const selected = i.id === period;
      let value = { [keyDateFrom]: '', [keyDateTo]: '' };
      let chipValue = i.name;
      if (selected) {
        const { dateFrom, dateTo } = this.dateConversionService.calculateDatesDependingOnPeriod(
          <ETimePeriod>i.id,
          preselect[keyDateFrom] ? preselect[keyDateFrom][0] : null,
          preselect[keyDateTo] ? preselect[keyDateTo][0] : null
        );
        value = { [keyDateFrom]: dateFrom, [keyDateTo]: dateTo };
        chipValue =
          i.id === ETimePeriod.INDIVIDUAL_TIME_PERIOD
            ? moment(dateFrom).format(this.dateConversionService.getDateFormatFromUser()) +
              ' - ' +
              moment(dateTo).format(this.dateConversionService.getDateFormatFromUser())
            : chipValue;
      }

      return {
        ...i,
        selected: selected,
        invalidDateMsg: this.translate.instant('CHARGE_RECORDS.INVALID_DATE'),
        value: value,
        chipValue: chipValue,
      };
    });

    return { ...componentData, items: items };
  }

  private setPreselect(config: SpecificFilterConfig):
    | string
    | {
        [key: string]: string[];
      } {
    return config && config.preselect ? config.preselect : null;
  }

  private setPeriod(
    preselect:
      | string
      | {
          [key: string]: string[];
        }
  ) {
    return preselect
      ? preselect instanceof Object
        ? ETimePeriod.INDIVIDUAL_TIME_PERIOD
        : preselect
      : null;
  }
}
