import { NgRedux } from '@angular-redux/store';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ISettings, Language } from '@auth/login';
import { AuthenticationService } from '@shared/services/auth';
import { BackendDataActions } from '@shared/store/actions';
import { IAppState } from '@shared/store/models';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';

export const isBrowserInGerman = (): boolean => {
  let lang = navigator.language || window.navigator.language;
  lang = lang.split('-')[0].toLowerCase();
  return lang === Language.German;
};

@Component({
  selector: 'eop-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuFooterComponent implements OnInit {
  showTranslationToggle = false;
  connectivityTesterUrl: string = environment.connectivityTesterUrl;

  @Input() menuCollapsed: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private backendDataActions: BackendDataActions,
    private store: NgRedux<IAppState>
  ) {}

  ngOnInit() {
    this.showTranslationToggle = !environment.production;
  }

  getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }

  toggleTranslation(activate: boolean) {
    if (activate) {
      const emptyLocale = { locale: Language.Empty };
      this.backendDataActions.setUserSettings(emptyLocale);
    } else {
      this.authenticationService
        .getSettingsUser(this.store.getState().backendData.userLogin.id)
        .pipe(take(1))
        .subscribe((settings: ISettings) => {
          const userLocale = { locale: settings.locale };
          this.backendDataActions.setUserSettings(userLocale);
        });
    }
  }
}
