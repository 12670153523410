import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { Observable, asyncScheduler, defer, fromEvent, merge } from 'rxjs';
import { filter, map, mapTo, subscribeOn, take } from 'rxjs/operators';

/**
 * @internal
 *
 * just uses for enhanced typings
 */
export interface PopoverParams<T> {
  origin: HTMLElement;
  content: TemplateRef<T>;
  vcr: ViewContainerRef;
}

/**
 * @internal
 *
 * Currently this wraps the Overlay Service of @anuglar/cdk .
 * For now there is no plan to expose this service to the public api.
 */
@Injectable()
export class PopoverService {
  // tslint:disable:no-any
  constructor(
    private readonly overlay: Overlay,
    @Optional()
    @Inject(DOCUMENT)
    private readonly _document: any,
    private sharedReferenceService: SharedRefService
  ) {}
  // tslint:enable:no-any

  /**
   * This method opens the overlay, registers keydown, focusout, overlayRef detachments & backdrop click
   * to close the opened overlay.
   * @param origin the element used to register the dom events
   * @param content The content of the opened overlay
   * @param vcr The element the overlay is attached to
   */
  open<T>({ origin, content, vcr }: PopoverParams<T>): OverlayRef {
    const overlayRef = this.overlay.create(this.getOverlayConfig(origin));

    const template = new TemplatePortal(content, vcr);
    overlayRef.attach(template);

    merge(
      overlayRef.detachments(),
      fromEvent<KeyboardEvent>(origin, 'keydown').pipe(
        filter((e: KeyboardEvent) => e.key === 'Esc' || e.key === 'Escape')
      ),
      fromEvent<FocusEvent>(origin, 'focusout', { capture: true }).pipe(
        map((e: FocusEvent) => {
          e.preventDefault();
          e.stopImmediatePropagation();
          return e;
        }),
        filter((e: FocusEvent) => !!e.relatedTarget),
        filter(event => {
          const isOnChild = Array.from(origin.children).some(
            element => event.relatedTarget === element
          );
          const isDropdown = (event.relatedTarget as HTMLElement).classList.contains('overlay');
          return !isOnChild && !isDropdown;
        })
      ),
      defer(() =>
        this.getOutsideClickStream$(overlayRef, origin).pipe(subscribeOn(asyncScheduler, 500))
      )
    )
      .pipe(take(1))
      .subscribe(() => {
        this.close(overlayRef);
      });

    this.sharedReferenceService.storeOverlay(overlayRef);
    return overlayRef;
  }

  private getOutsideClickStream$(overlayRef: OverlayRef, origin: HTMLElement): Observable<boolean> {
    return fromEvent<MouseEvent>(this._document, 'mousedown').pipe(
      filter((event: MouseEvent) => {
        const clickTarget = event.target as HTMLElement;
        const [childInput] = Array.from(origin.children);
        const isOptionElement =
          clickTarget && clickTarget.classList && clickTarget.classList.contains('option');
        const isScrollbar =
          clickTarget && clickTarget.classList && clickTarget.classList.contains('viewport');
        return (
          overlayRef.hasAttached() &&
          !!clickTarget &&
          clickTarget !== origin &&
          clickTarget !== childInput &&
          !isOptionElement &&
          !isScrollbar
        );
      }),
      mapTo(true)
    );
  }

  /**
   * Can be used to close the passed overlay
   */
  close(overlayRef: OverlayRef): void {
    this.sharedReferenceService.closeAllOverlays();
    overlayRef.detach();
  }

  private getOverlayConfig(origin: HTMLElement): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width: origin.offsetWidth,
      height: 180,
      maxHeight: 'calc(100vh -' + `origin.offsetTop)`,
      backdropClass: 'popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      panelClass: 'cdk-popover-panel',
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withLockedPosition()
      .withPush(true)
      .withPositions([
        {
          panelClass: 'custom-panel-1',
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          panelClass: 'custom-panel-2',
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          panelClass: 'custom-panel-3',
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);
    return positionStrategy;
  }
}
