import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { PipesModule } from '@shared/pipes/pipe.module';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { BoxModule } from '@widgets/box/box.module';
import { DropdownModule } from '@widgets/dropdown/dropdown.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { ImagePlaceholderModule } from '@widgets/eop-image-placeholder/eop-image-placeholder.module';
import { InnogyChipsLiteModule } from '@widgets/innogy-chips-lite/innogy-chips-lite.module';
import { InnogyChipsModule } from '@widgets/innogy-chips/innogy-chips.module';
import { InputEditModeModule } from '@widgets/input-edit-mode/input-edit-mode.module';
import { LoadingIndicatorModule } from '@widgets/loading-indicator/loading-indicator.module';
import { SpinnerModule } from '@widgets/spinner/spinner.module';
import { StatusSystemIconModule } from '@widgets/status-system-icon/status-system-icon.module';
import { PaginationModule } from '../pagination/pagination.module';
import { BulletStatusCellComponent } from './bullet-status-cell/bullet-status-cell.component';
import {
  BooleanCellComponent,
  ExpandingArrowCellComponent,
  IconCellComponent,
  InnogyDataTableComponent,
  LinkCellComponent,
  StatusCellComponent,
} from './index';
import { LocationCellComponent } from './location-cell/location-cell.component';

@NgModule({
  declarations: [
    ExpandingArrowCellComponent,
    BooleanCellComponent,
    LinkCellComponent,
    IconCellComponent,
    StatusCellComponent,
    BulletStatusCellComponent,
    InnogyDataTableComponent,
    LocationCellComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    TranslateModule,
    MatCheckboxModule,
    MatCardModule,
    MatTooltipModule,
    InnogyChipsModule,
    InnogyChipsLiteModule,
    StatusSystemIconModule,
    EopIconModule,
    BoxModule,
    ImagePlaceholderModule,
    SpinnerModule,
    InViewportModule,
    LoadingIndicatorModule,
    RouterModule,
    DropdownModule,
    SharedDirectivesModule,
    PipesModule,
    PaginationModule,
    ScrollingModule,
    MatRadioModule,
    InputEditModeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    ExpandingArrowCellComponent,
    BooleanCellComponent,
    LinkCellComponent,
    IconCellComponent,
    StatusCellComponent,
    BulletStatusCellComponent,
    InnogyDataTableComponent,
    InViewportModule,
  ],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class InnogyDataTableModule {}
