<div class="login-page">
  <div class="login-page-form">
    <div class="form-left-side">
      <div class="logo"></div>
      <h1 class="display-m" [innerHtml]="'LOGIN.HEADER_PART1_NEW' | translate"></h1>
      <h4 class="body-l">{{ 'LOGIN.HEADER_PART2' | translate }}</h4>
      <div class="support-row body-bold-m">
        <span>{{ 'LOGIN.HAVING_TROUBLE' | translate }}</span>
        <button
          eopButton
          [color]="ButtonColor.SECONDARY"
          compact
          condensed
          routerLink="/auth/support"
        >
          {{ 'LOGIN.SUPPORT' | translate }}
        </button>
      </div>
      <div class="link-wrapper body-bold-m">
        <a href="https://status.services-emobility.com" target="_blank" rel="noopener">{{
          'SYSTEM_STATUS.TITLE' | translate
        }}</a>
        <span class="vertical-divider"></span>
        <a routerLink="/content/userManualPublic">{{ 'WIKI.TITLE' | translate }}</a>
        <span class="vertical-divider"></span>
        <a href="{{ dataProtectionUrl }}" target="_blank" rel="noopener">{{
          'LOGIN.PRIVACY_POLICY' | translate
        }}</a>
        <span class="vertical-divider"></span>
        <a href="{{ legalNoticeUrl }}" target="_blank" rel="noopener">{{
          'IMPRESSUM.TITLE' | translate
        }}</a>
      </div>
    </div>
    <div class="form-right-side">
      <h3 class="heading-m">{{ 'LOGIN.TITLE' | translate }}</h3>
      <form class="main-form">
        <div class="input-with-label">
          <input
            type="text"
            id="username"
            name="username"
            class="body-l-input"
            [attr.data-test]="'login_input_username'"
            [(ngModel)]="username"
            [placeholder]="'GLOBAL.USERNAME' | translate"
            autocomplete="off"
            autofocus
          />
        </div>
        <div class="input-with-label">
          <input
            [type]="showPassword ? 'text' : 'password'"
            id="password"
            name="password"
            class="body-l-input"
            [attr.data-test]="'login_input_password'"
            [(ngModel)]="password"
            [placeholder]="'GLOBAL.PASSWORD' | translate"
            autocomplete="off"
          />
          <eop-icon
            [identifier]="showPassword ? 'show-filled' : 'show-outlined'"
            [tooltip]="(showPassword ? 'GLOBAL.PASSWORD_HIDE' : 'GLOBAL.PASSWORD_SHOW') | translate"
            [clickable]="true"
            [vaylensFont]="true"
            [color]="IconColor.NORMAL"
            (click)="showPassword = !showPassword"
          ></eop-icon>
        </div>
        <a class="body-bold-m primary" routerLink="/auth/passwordReset">{{
          'LOGIN.FORGET_PW' | translate
        }}</a>
        <save-button
          [(progressState)]="progressState"
          (progressStateChange)="login()"
          [buttonText]="'LOGIN.TITLE' | translate"
          [attr.data-test]="'login_button_save'"
        >
        </save-button>
      </form>
    </div>
  </div>
  <div class="login-page-footer-placeholder"></div>
</div>
