import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationService } from '@shared/services/navigation/navigation-service.service';
import { IconWeight } from '@widgets/eop-icon';

@Component({
  selector: 'eop-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  IconWeight = IconWeight;

  @Input()
  fallbackUrl: string;

  constructor(public navigationService: NavigationService) {}

  onClick() {
    this.navigationService.navigateBack(this.fallbackUrl);
  }
}
