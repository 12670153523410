import { Component, Input } from '@angular/core';

@Component({
  selector: 'validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent {
  @Input()
  message: string;
  @Input()
  styles: any;
  @Input()
  hideCloseBtn: boolean;
}
