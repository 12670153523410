<!-- Simple Icon -->
<div
  *ngIf="icon && !iconText && !vaylensButton"
  class="icon"
  [ngClass]="{
    'is-highlighted': isHighlighted,
    disabled: disabled,
    'inverted-colours': invertColours
  }"
  [attr.data-test]="dataTestId"
  (click)="disabled || actionClicked.emit($event)"
>
  <eop-icon
    [identifier]="icon"
    [vaylensFont]="vaylensFontIcon"
    [weight]="!vaylensFontIcon ? IconWeight.REGULAR : undefined"
    [clickable]="!disabled"
    [tooltip]="!iconText && label ? label : undefined"
    [color]="
      disabled ? IconColor.DISABLED : isNavigational ? IconColor.NAVIGATIONAL : IconColor.CLICKABLE
    "
    [dataTestId]="dataTestId + '-icon'"
  ></eop-icon>
</div>

<!-- Small Icon with text -->
<div
  *ngIf="iconText"
  class="wrapper"
  [ngClass]="{
    disabled: disabled,
    navigation: isNavigational,
    'is-highlighted': isHighlighted,
    'inverted-colours': invertColours,
    'right-icon-text': rightIconText
  }"
  [matTooltip]="!iconText && label ? label : undefined"
  [attr.data-test]="dataTestId"
  (click)="disabled || actionClicked.emit($event)"
>
  <div class="icon-text">{{ iconText }}</div>
  <div
    *ngIf="icon"
    class="icon icon--bold"
    [ngClass]="{ 'is-highlighted': isHighlighted, 'vaylens-icon': vaylensFontIcon }"
  >
    <eop-icon
      [identifier]="icon"
      [vaylensFont]="vaylensFontIcon"
      [weight]="!vaylensFontIcon ? IconWeight.BOLD : undefined"
      [size]="vaylensFontIcon ? IconSize.SMALL : IconSize.MINI"
      [clickable]="!disabled"
      [tooltip]="!iconText && label ? label : undefined"
      [color]="
        invertColours
          ? IconColor.WHITE
          : disabled
          ? IconColor.DISABLED
          : isNavigational
          ? IconColor.NAVIGATIONAL
          : IconColor.CLICKABLE
      "
      [dataTestId]="dataTestId + '-icon'"
    ></eop-icon>
  </div>
</div>

<!-- New vaylens design -->
<div *ngIf="icon && vaylensButton">
  <button
    eopButton
    [color]="ButtonColor.PRIMARY"
    [disabled]="disabled"
    [leftIcon]="icon"
    [attr.data-test]="dataTestId"
    (click)="disabled || actionClicked.emit($event)"
  >
    {{ label }}
  </button>
</div>
