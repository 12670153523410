import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IUserLogin } from '@auth/login';

@Component({
  selector: 'eop-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input()
  userProfile: IUserLogin;
}
