import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * This component represents the footer section in the Modal Dialog.
 * If used all passed elements will be right aligned in order.
 */
@Component({
  selector: 'emob-modal-card-footer',
  templateUrl: './modal-card-footer.component.html',
  styleUrls: ['./modal-card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCardFooterComponent {}
