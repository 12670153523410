import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import {
  ChipLiteColor,
  ChipLiteSize,
} from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';

export interface AgFilterChip {
  chipName: string;
  chipKey: string;
  chipValue: string | number | boolean;
}

@Component({
  selector: 'eop-ag-grid-filter-header',
  templateUrl: './ag-grid-filter-header.component.html',
  styleUrls: ['./ag-grid-filter-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridFilterHeaderComponent {
  protected readonly IconWeight = IconWeight;
  protected readonly IconSize = IconSize;
  protected readonly IconColor = IconColor;
  protected readonly ButtonColor = ButtonColor;
  protected readonly ChipLiteColor = ChipLiteColor;
  protected readonly ChipLiteSize = ChipLiteSize;

  @Input() chipsData: AgFilterChip[] = [];

  @Output() chipRemoved: EventEmitter<AgFilterChip> = new EventEmitter<AgFilterChip>();

  @Output() allChipsRemoved: EventEmitter<void> = new EventEmitter<void>();

  expandedFilter = true;

  onChipRemoved(chip: AgFilterChip): void {
    this.chipsData.filter(ch => ch.chipKey === chip.chipKey);
    this.chipRemoved.emit(chip);
  }

  onToggleFilter(): void {
    this.expandedFilter = !this.expandedFilter;
  }

  removeAllFilterAndTriggerSearch(): void {
    this.chipsData = [];
    this.allChipsRemoved.emit();
  }
}
