<div class="paginator-outer-container">
  <div class="paginator-container">
    <div class="paginator-page-size">
      <div class="items-per-page">{{ 'PAGINATOR.ITEMS_PER_PAGE' | translate }}:</div>

      <div *ngIf="_displayedPageSizeOptions.length > 1" class="paginator-page-size-select">
        <emob-dropdown
          [singleSelectDeselactable]="false"
          [items]="_displayedPageSizeOptions"
          [ngModel]="_findPageSizeInDisplayedOptions()"
          (ngModelChange)="_changePageSize($event.value)"
        >
        </emob-dropdown>
      </div>

      <div *ngIf="_displayedPageSizeOptions.length <= 1">
        {{ pageSize }}
      </div>
    </div>

    <div class="paginator-range-actions">
      <button
        mat-icon-button
        type="button"
        class="paginator-navigation-previous icon-button-small"
        (click)="previousPage()"
        [disabled]="_previousButtonsDisabled()"
      >
        <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      </button>

      <!-- Up to 5 pages -->
      <div class="paginator-range-actions" *ngIf="getNumberOfPages() <= 5">
        <div
          class="paginator-page cursor-pointer"
          *ngFor="let page of getPreviousPages()"
          (click)="selectPage(page + '')"
        >
          {{ page }}
        </div>
        <input
          type="text"
          class="paginator-page"
          [value]="_displayedPage"
          (change)="selectPage($event.target.value)"
        />
        <div
          class="paginator-page cursor-pointer"
          *ngFor="let page of getNextPages()"
          (click)="selectPage(page + '')"
        >
          {{ page }}
        </div>
      </div>

      <!-- More then 5 pages -->
      <div class="paginator-range-actions" *ngIf="getNumberOfPages() > 5">
        <ng-container *ngIf="pageIndex - 2 > 0">
          <div class="paginator-page cursor-pointer" (click)="selectPage(1 + '')">
            {{ 1 }}
          </div>
          <div class="paginator-page">...</div>
        </ng-container>

        <div
          *ngIf="pageIndex === 2"
          class="paginator-page cursor-pointer"
          (click)="selectPage(1 + '')"
        >
          {{ 1 }}
        </div>

        <div
          *ngIf="pageIndex > 0"
          class="paginator-page cursor-pointer"
          (click)="selectPage(pageIndex + '')"
        >
          {{ pageIndex }}
        </div>

        <input
          type="text"
          class="paginator-page"
          [value]="_displayedPage"
          (change)="selectPage($event.target.value)"
        />

        <div
          *ngIf="pageIndex < getNumberOfPages() - 1"
          class="paginator-page cursor-pointer"
          (click)="selectPage(pageIndex + 2 + '')"
        >
          {{ pageIndex + 2 }}
        </div>

        <div
          *ngIf="pageIndex === getNumberOfPages() - 3"
          class="paginator-page cursor-pointer"
          (click)="selectPage(getNumberOfPages() + '')"
        >
          {{ getNumberOfPages() }}
        </div>

        <ng-container *ngIf="pageIndex + 2 < getNumberOfPages() - 1">
          <div class="paginator-page">...</div>
          <div class="paginator-page cursor-pointer" (click)="selectPage(getNumberOfPages() + '')">
            {{ getNumberOfPages() }}
          </div>
        </ng-container>
      </div>

      <button
        mat-icon-button
        type="button"
        class="paginator-navigation-next icon-button-small"
        (click)="nextPage()"
        [disabled]="_nextButtonsDisabled()"
      >
        <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        </svg>
      </button>
    </div>
  </div>
</div>
