<div *ngIf="!cellData || cellData.length === 0">-</div>
<grid-list [columns]="1">
  <div *ngFor="let cell of cellData" class="status-bullet-point">
    <eop-icon
      *ngIf="cell.type"
      [identifier]="cell.outline ? 'Status-outline' : 'Status'"
      [color]="cell.type"
      [matTooltip]="cell.tooltip"
      [weight]="IconWeight.BOLD"
    ></eop-icon>
    <div class="text">{{ cell.text }}</div>
  </div>
</grid-list>
