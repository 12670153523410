import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class Item<T = Object> {
  name: string;
  data: T;
  constructor(name: string, data?: T) {
    this.name = name;
    if (data) this.data = data;
  }
}

/**
 * @deprecated Replaced by emob-dropdown from emob-toolkit
 */
@Component({
  selector: 'innogy-dropdown',
  templateUrl: 'innogy-dropdown.component.html',
  styleUrls: ['innogy-dropdown.component.scss'],
})
export class InnogyDropdownComponent implements AfterViewInit, OnChanges {
  @Input()
  public items: Item[] = [];

  @Input()
  public selectedItem: Item;

  @Input()
  public selectedItems: Item[] = [];

  @Input()
  public multiSelect: boolean = false;

  @Input()
  public singleSelectDeselactable: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public searchable: boolean = false;

  @Input()
  public itemTemplate: TemplateRef<any>;

  @Input()
  public transparent: boolean;

  @Input()
  public placeholderText: string = this.translate.instant('GLOBAL.PLEASE_SELECT');

  @Input()
  public disableAdditionalCounter: boolean = false;

  @Input()
  error: boolean = false;

  @Output()
  public selectedItemChange = new EventEmitter<Item>();

  @Output()
  public selectedItemsChange = new EventEmitter<Item[]>();

  public value: string;
  public expanded: boolean = false;
  public filter: string = '';
  public filteredItems: Item[] = [];

  constructor(public translate: TranslateService) {}

  ngAfterViewInit() {
    this.filteredItems = this.items;
  }

  public clickedItem(item: Item) {
    if (this.isDisabled(item)) return;
    if (!this.multiSelect) {
      this.expanded = false;
      if (this.selectedItem !== item) {
        this.selectedItem = item;
        this.selectedItemChange.emit(this.selectedItem);
      }
    } else {
      const index = this.selectedItems.indexOf(item);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item);
      }
      this.selectedItemChange.emit(item);
      this.selectedItemsChange.emit(this.selectedItems);
    }
  }

  public filterItems(text: string, key: string) {
    if (key === 'Enter') {
      this.clickedItem(this.filteredItems[0]);
    } else {
      this.filter = text;
      if (text !== '' && text !== null) {
        this.filteredItems = this.items.filter(item => {
          return item.name.toUpperCase().includes(text.toUpperCase());
        });
      } else {
        this.filteredItems = this.items;
      }
    }
  }

  public isSelected(item: Item): boolean {
    return this.selectedItems.includes(item);
  }

  public isDisabled(item: Item): boolean {
    return item && item.data && !!item.data['disabled'];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      this.filterItems(this.filter, null);
    }

    if (changes.selectedItem && changes.selectedItem.currentValue) {
      this.value = this.selectedItem.name;
    }
  }
}
