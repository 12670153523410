import { TypedAction } from '@ngrx/store/src/models';
import { NgrxMetaActions } from './ngrx-meta.actions';

export interface StoreResetOptions {
  action?: TypedAction<any>;
}

const defaultOptions: StoreResetOptions = {
  action: NgrxMetaActions.resetStore,
};

export function storeReset(options: StoreResetOptions) {
  const { action: actionType } = Object.assign({}, defaultOptions, options || {});
  return reducer => (state: any, action: TypedAction<any>) => {
    if (action.type === actionType.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
