import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAccountSettingValueLists,
  IPasswordAssignment,
  IPasswordUpdate,
  ISettings,
  IUserLogin,
} from '@auth/login';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<IUserLogin> {
    const credentials = username + ':' + password;
    const credentialsConverted = unescape(encodeURIComponent(credentials));
    const headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa(credentialsConverted));

    return this.http.request<IUserLogin>('GET', environment.backendUrlLoginAndLogout + '/login', {
      headers,
    });
  }

  logout(): Observable<Response> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get<Response>(environment.backendUrlLoginAndLogout + '/logout', {
      headers,
      responseType: <any>'text',
    });
  }

  getCurrentUserLogin(): Observable<IUserLogin> {
    return this.http.get<IUserLogin>(environment.backendUrlCAA + '/users/current-user');
  }

  getSettingValueList(): Observable<IAccountSettingValueLists> {
    return this.http.get<IAccountSettingValueLists>(
      environment.backendUrlCAA + '/values/account-setting'
    );
  }

  updateSettingsUser(userId: number, body: ISettings): Observable<ISettings> {
    return this.http.put<ISettings>(
      environment.backendUrlCAA + '/users/' + userId + '/settings',
      body
    );
  }

  getSettingsUser(userId: number): Observable<ISettings> {
    return this.http.get<ISettings>(environment.backendUrlCAA + '/users/' + userId + '/settings');
  }

  setNewUserPassword(
    userLogin: string,
    token: string,
    body: IPasswordAssignment
  ): Observable<Response> {
    return this.http.post<Response>(
      environment.backendUrlCAAPublic + '/users/' + userLogin + '/reset-password?token=' + token,
      body
    );
  }

  updateUserPassword(userId: number, body: IPasswordUpdate): Observable<Response> {
    return this.http.post<Response>(
      environment.backendUrlCAA + '/users/' + userId + '/password',
      body
    );
  }

  resetUserPassword(userLogin: string): Observable<Response> {
    return this.http.post<Response>(
      environment.backendUrlCAAPublic + '/users/' + userLogin + '/recover-password',
      {}
    );
  }
}
