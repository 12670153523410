import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FooterModule } from '@shared/components/footer/footer.module';
import { MenuFooterComponent } from '@shared/components/header/menu-footer/menu-footer.component';
import { MenuGroupComponent } from '@shared/components/header/menu-group/menu-group.component';
import { UserMenuComponent } from '@shared/components/header/user-menu/user-menu.component';
import { WidgetModule } from '@widgets/widgets.module';
import { GlobalHeaderComponent } from './global-header/global-header.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { LinkElementComponent } from './link-element/link-element.component';
import { QuickAccessModule } from './quick-access/quick-access.module';
import { ShowNotificationModule } from './show-notification/show-notification.module';
import { SimpleLinkElementComponent } from './simple-link-element/simple-link-element.component';

@NgModule({
  declarations: [
    GlobalHeaderComponent,
    HeaderMenuComponent,
    LinkElementComponent,
    UserMenuComponent,
    MenuFooterComponent,
    MenuGroupComponent,
    SimpleLinkElementComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    WidgetModule,
    MatMenuModule,
    RouterModule,
    QuickAccessModule,
    ShowNotificationModule,
    MatExpansionModule,
    FooterModule,
    MatTooltipModule,
  ],
  exports: [GlobalHeaderComponent, LinkElementComponent],
})
export class HeaderModule {}
