import { isArray, isEmpty, isNil, isString, isUndefined } from 'lodash-es';

export function isParameterFiltered(param: any): boolean {
  return (
    isNil(param) ||
    isUndefined(param) ||
    (isArray(param) && isEmpty(param)) ||
    (isString(param) && isEmpty(param.trim()))
  );
}

export function adjustArrayFormatForRequestParams(object: any) {
  if (!object) {
    return object;
  }
  Object.keys(object).forEach(k => (isArray(object[k]) ? (object[k] = object[k].join(',')) : null));
  return object;
}
