import { Pipe, PipeTransform } from '@angular/core';
import { IChipsData } from '@widgets/innogy-chips/chipsData.interface';
import { TariffTableRowPipe } from './tariff-table-row.pipe';
import { PriceComponent } from 'app/clearing-house/clearing-house-tariffs/data/clearing-house-tariffs.models';

@Pipe({
  name: 'pricesChip',
})
export class PricesChipPipe implements PipeTransform {
  constructor(private tariffTableRowPipe: TariffTableRowPipe) {}

  transform(priceComponents: PriceComponent[], currencyCode: string, locale: string): IChipsData[] {
    return priceComponents.map(priceComponent => {
      return {
        value: this.tariffTableRowPipe.transform(priceComponent, currencyCode, locale),
      };
    });
  }
}
