import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusSystemIconComponent } from './status-system-icon.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [StatusSystemIconComponent],
  exports: [StatusSystemIconComponent],
})
export class StatusSystemIconModule {}
