import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BackendErrorModel, ErrorResponse } from '@shared/errors/error.model';
import { SessionDataActions } from '@shared/store/actions';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { ErrorMessageService } from './error-message.service';
import { NotificationLevel } from './notification-level.model';

export interface ErrorLog {
  traceId: string;
  httpStatus: string;
  fault: string;
  faultType: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class TopNotificationService {
  constructor(
    private errorMessageService: ErrorMessageService,
    private sessionDataActions: SessionDataActions,
    private http: HttpClient,
    private router: Router
  ) {}

  showErrorInTopNotification(error: ErrorResponse): void {
    const message = this.errorMessageService.parseErrorIntoMessage(error);
    this.sendErrorToBackendForLogging(error.error);
    this.showTopNotification(message, NotificationLevel.ERROR, error.error?.traceId);
  }

  showTopNotification(message: string, level = NotificationLevel.ERROR, traceId = null): void {
    this.sessionDataActions.showNotification({
      notificationLevel: level,
      message: message,
      traceId: traceId,
    });
  }

  closeTopNotificiation(): void {
    this.sessionDataActions.closeNotification();
  }

  showTopNotificationWithReloadButton(message: string, level = NotificationLevel.WARNING): void {
    this.sessionDataActions.showNotification({
      message,
      notificationLevel: level,
      reloadButton: true,
    });
  }

  private sendErrorToBackendForLogging(error: BackendErrorModel): void {
    if (!error) return;
    const errorBody: ErrorLog = {
      traceId: error.traceId,
      httpStatus: error?.status?.toString(),
      fault: error.fault,
      faultType: error.faultType,
      url: this.router.url.split('?')[0],
    };
    this.http
      .post<Response>(environment.bffInnogyAdmin + '/events/log/error', errorBody)
      .pipe(take(1))
      .subscribe();
  }
}
