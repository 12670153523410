<div *ngFor="let item of componentData.items">
  <div class="item" (click)="onItemClick(item)">
    <input type="checkbox" id="{{ item.id }}" name="{{ item.id }}" [checked]="item.selected" />
    <label for="{{ item.id }}">
      <span>
        <eop-icon
          *ngIf="item.color"
          identifier="Status"
          [color]="item.color"
          [weight]="IconWeight.BOLD"
          [size]="IconSize.NORMAL"
        ></eop-icon>
        <span class="item-name"> {{ item.name }} </span>
      </span>
    </label>
  </div>
</div>
