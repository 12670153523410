<div class="wrapper">
  <div class="wrapper-items">
    <ng-container *ngFor="let parentItem of parentItems">
      <eop-icon
        [ngClass]="{ selectedStatus: parentItem.selected }"
        [identifier]="'Status'"
        [color]="parentItem.color"
        [weight]="IconWeight.BOLD"
        [size]="IconSize.LARGE"
        [clickable]="true"
        (click)="onParentItemClick(parentItem)"
        [tooltip]="parentItem.name"
      ></eop-icon>
    </ng-container>
  </div>
  <div class="sub-items-wrapper">
    <div *ngFor="let subItem of subItemsFiltered">
      <div>
        <input
          type="checkbox"
          id="{{ subItem.id }}"
          name="{{ subItem.id }}"
          [checked]="subItem.selected"
          (click)="onSubItemClick(subItem)"
        />
        <label for="{{ subItem.id }}">
          <span
            class="sub-item-wrapper"
            [matTooltip]="subItem.name"
            [matTooltipShowDelay]="1000"
            [matTooltipPositionAtOrigin]="true"
          >
            <eop-icon
              [identifier]="subItem.outline ? 'Status-outline' : 'Status'"
              [color]="subItem.color"
              [weight]="IconWeight.BOLD"
            ></eop-icon>
            <span class="sub-item-name"> {{ subItem.name }} </span>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
