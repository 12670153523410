<div class="address-filter">
  <eop-countries
    #country
    class="address-element"
    [permission]="permission"
    [partnerId]="partnerId"
    [locationId]="locationId"
    [onlyIbetStations]="onlyIbetStations"
    [countryIso3]="countryIso3"
    (selectionChange)="onCountryChange($event)"
  ></eop-countries>
  <eop-cities-and-zips
    #cityZip
    class="address-element"
    [permission]="permission"
    [partnerId]="partnerId"
    [locationId]="locationId"
    [country]="selectedAddress.country"
    [onlyIbetStations]="onlyIbetStations"
    (selectionChange)="onCityZipChange($event)"
  ></eop-cities-and-zips>
  <eop-streets
    #street
    class="address-element"
    [permission]="permission"
    [partnerId]="partnerId"
    [locationId]="locationId"
    [country]="selectedAddress.country"
    [city]="selectedAddress.city"
    [zipCode]="selectedAddress.zipCode"
    [onlyIbetStations]="onlyIbetStations"
    (selectionChange)="onStreetChange($event)"
  ></eop-streets>
</div>
