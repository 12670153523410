import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { DropdownItem } from '@widgets/dropdown/dropdown-type';
import { range } from 'lodash-es';

const DEFAULT_PAGE_SIZE = 15;

@Component({
  selector: 'eop-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit {
  private _initialized: boolean;

  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(value: number) {
    this._pageIndex = value;
    this._displayedPage = value + 1;
  }
  private _pageIndex = 0;

  @Input()
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
  }
  private _length = 0;

  @Input()
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
    this._updateDisplayedPageSizeOptions();
  }
  private _pageSize: number;

  @Input()
  get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }
  set pageSizeOptions(value: number[]) {
    this._pageSizeOptions = value || [];
    this._updateDisplayedPageSizeOptions();
  }
  private _pageSizeOptions: number[] = [];

  @Output() readonly page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  _displayedPageSizeOptions: DropdownItem[];
  _displayedPage: number = 1;

  ngOnInit() {
    this._initialized = true;
    this._updateDisplayedPageSizeOptions();
  }

  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex++;
    this._emitPageEvent(previousPageIndex);
  }

  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex--;
    this._emitPageEvent(previousPageIndex);
  }

  selectPage(inputString: string): void {
    if (
      inputString &&
      inputString !== '' &&
      parseInt(inputString, 10) > 0 &&
      parseInt(inputString, 10) <= this.getNumberOfPages()
    ) {
      const previousPageIndex = this.pageIndex;
      this.pageIndex = parseInt(inputString, 10) - 1;
      this._emitPageEvent(previousPageIndex);
    }
  }

  hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }

    return Math.ceil(this.length / this.pageSize);
  }

  getPreviousPages(): Array<number> {
    if (!this.hasPreviousPage()) {
      return [];
    }
    return range(1, this._displayedPage);
  }

  getNextPages(): Array<number> {
    if (!this.hasNextPage()) {
      return [];
    }
    return range(this._displayedPage + 1, this.getNumberOfPages() + 1);
  }

  _changePageSize(pageSize: number) {
    const startIndex = this.pageIndex * this.pageSize;
    const previousPageIndex = this.pageIndex;

    this.pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.pageSize = pageSize;
    this._emitPageEvent(previousPageIndex);
  }

  _findPageSizeInDisplayedOptions() {
    return this._displayedPageSizeOptions.find(option => option.value === this._pageSize);
  }

  _nextButtonsDisabled() {
    return !this.hasNextPage();
  }

  _previousButtonsDisabled() {
    return !this.hasPreviousPage();
  }

  _updateDisplayedPageSizeOptions() {
    if (!this._initialized) {
      return;
    }

    if (!this.pageSize) {
      this._pageSize =
        this.pageSizeOptions.length !== 0 ? this.pageSizeOptions[0] : DEFAULT_PAGE_SIZE;
    }

    const pageSizeOptions = [];
    this.pageSizeOptions.forEach(option => {
      const item: DropdownItem = {
        name: option + '',
        value: option,
      };
      pageSizeOptions.push(item);
    });

    this._displayedPageSizeOptions = pageSizeOptions;

    const filteredOptions = this._displayedPageSizeOptions.filter(option => {
      return option.value === this.pageSize;
    });
    if (filteredOptions.length === 0) {
      const item: DropdownItem = {
        name: this.pageSize + '',
        value: this.pageSize,
      };
      this._displayedPageSizeOptions.push(item);
    }

    this._displayedPageSizeOptions.sort((a, b) => a.value - b.value);
  }

  _emitPageEvent(previousPageIndex: number): void {
    const pageEvent: PageEvent = {
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    };
    this.page.emit(pageEvent);
  }
}
