import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TitleSize } from './quick-access-entry-title.model';

@Component({
  selector: 'eop-quick-access-entry-title',
  templateUrl: './quick-access-entry-title.component.html',
  styleUrls: ['./quick-access-entry-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickAccessEntryTitleComponent {
  @Input()
  label: string;

  @Input()
  size: TitleSize;

  TitleSize = TitleSize;
}
