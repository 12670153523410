<div class="label-part" *ngIf="title">
  <div class="label" [ngClass]="{ error: !!errorMessage, disabled: titleDisabled }">
    {{ title }} {{ required ? '*' : '' }}
    <app-innogy-chips
      *ngIf="headerChipValue"
      [chipsData]="[{ value: headerChipValue }]"
      [chipColor]="headerChipColor"
      [emphasized]="false"
      [matTooltip]="headerChipTooltipText"
      [matTooltipPosition]="'right'"
    >
    </app-innogy-chips>
  </div>

  <span
    *ngIf="maxCharacters && !inputElement?.disabled"
    [class.error]="0 > maxCharacters - inputElement?.value?.length"
    >{{ maxCharacters - inputElement?.value?.length }}</span
  >
  <eop-icon
    *ngIf="maxCharacters && !inputElement?.disabled"
    identifier="remove-circle"
    [weight]="IconWeight.BOLD"
    [color]="IconColor.NORMAL"
    (click)="clearInput()"
  ></eop-icon>
</div>
<div class="input-with-optional-tooltip">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div
    #contentWrapper
    [style.width]="isFullWidth ? '100%' : null"
    [style.visibility]="loading ? 'hidden' : ''"
  >
    <ng-content></ng-content>
  </div>
  <eop-icon
    *ngIf="tooltipText"
    class="tooltip-icon"
    [color]="IconColor.NORMAL"
    [identifier]="'info-circle-filled'"
    [tooltip]="tooltipText"
    [vaylensFont]="true"
  ></eop-icon>
</div>
<div class="message">
  <div
    *ngIf="disabledMessage && !warningMessage && !errorMessage && !successMessage"
    class="disabled-message"
  >
    {{ disabledMessage }}
  </div>
  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
  <div *ngIf="additionalErrorMessage" class="additional-error-message">
    {{ additionalErrorMessage }}
  </div>
  <div *ngIf="warningMessage && !errorMessage" class="warning-message">
    {{ warningMessage }}
  </div>
  <div *ngIf="successMessage && !warningMessage && !errorMessage" class="success-message">
    {{ successMessage }}
  </div>
</div>
