import { Component, Input } from '@angular/core';

@Component({
  selector: 'expanding-arrow-cell',
  templateUrl: './expanding-arrow-cell.component.html',
  styleUrls: ['./expanding-arrow-cell.component.scss'],
})
export class ExpandingArrowCellComponent {
  @Input()
  public cellData: number;

  @Input()
  public expansionRowExpanded: number;
}
