import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupMemberComponent } from '@shared/components/group-member/group-member.component';
import { BoxModule } from '@widgets/box/box.module';
import { InnogyDataTableModule } from '@widgets/innogy-data-table/innogy-data-table.module';
import { SpinnerModule } from '@widgets/spinner/spinner.module';
import { WidgetModule } from '@widgets/widgets.module';

@NgModule({
  imports: [BoxModule, InnogyDataTableModule, SpinnerModule, CommonModule, WidgetModule],
  declarations: [GroupMemberComponent],
  exports: [GroupMemberComponent],
})
export class GroupMemberModule {}
