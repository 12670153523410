import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LanguageService } from '@shared/translate/language.service';

@Component({
  selector: 'eop-loading-results',
  templateUrl: './loading-results.component.html',
  styleUrls: ['./loading-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingResultsComponent {
  @Input()
  label: string;

  @Input()
  count: number;

  @Input()
  totalCount: number;

  @Input()
  countText: string;

  @Input()
  loading: boolean;

  formatNumber = formatNumber;

  constructor(public languageService: LanguageService) {}
}
