<section
  [ngClass]="{
    'with-margin': margin,
    'with-padding': padding,
    transparent: transparent,
    'with-bigger-margin': biggerMargin
  }"
>
  <div
    class="head"
    [ngClass]="{
      'skip-first-border': hideFirstBorder,
      'small-header': smallHeader
    }"
  >
    <div class="headline">
      <ng-content select="innogy-box-headline"></ng-content>
    </div>
    <div class="actions">
      <ng-content select="innogy-box-action, innogy-box-custom-action"></ng-content>
    </div>
  </div>

  <div class="subheader">
    <ng-content select="innogy-box-subheader"></ng-content>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>

  <ng-content select="innogy-box-footer"></ng-content>
</section>
