import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddressCityZipElementComponent } from '@widgets/filter/address-new/address-city-zip-element/address-city-zip-element.component';
import {
  AddressElementComponent,
  SelectValue,
} from '@widgets/filter/address-new/address-element/address-element.component';
import {
  AddressDataRequest,
  AddressNew,
  CityZip,
  CityZips,
  Countries,
  Streets,
} from './data/filter.model';

@Component({
  selector: 'eop-address-new-filter',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressNewFilterComponent implements OnInit, OnChanges {
  @Input()
  countries: Countries;

  @Input()
  cityZips: CityZips;

  @Input()
  streets: Streets;

  @Output()
  requireNewCountries: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  requireNewCityZips: EventEmitter<AddressDataRequest> = new EventEmitter<AddressDataRequest>();

  @Output()
  requireNewStreets: EventEmitter<AddressDataRequest> = new EventEmitter<AddressDataRequest>();

  @Output()
  addressChange: EventEmitter<AddressNew> = new EventEmitter<AddressNew>();

  @ViewChild('country')
  country: AddressElementComponent;

  @ViewChild('cityZip')
  cityZip: AddressCityZipElementComponent;

  @ViewChild('street')
  street: AddressElementComponent;

  selectedAddress: AddressNew = {
    country: null,
    countryTranslation: null,
    city: null,
    zipCode: null,
    street: null,
  };

  disableCountry = false;
  disableCityZip = true;
  disableSteet = true;

  resetOnGoing = false;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    if (!this.countries || this.countries.length === 0) {
      this.requireNewCountries.emit();
    }
  }

  /**
   * Directly preselect country in case only one country is loaded
   */
  ngOnChanges(changes: SimpleChanges): void {
    const changedToSingleCountry = changes.countries?.currentValue?.length === 1;
    if (changedToSingleCountry) {
      console.debug('Preselecting country: ', this.countries[0]);
      const emitAddressChange = false;
      setTimeout(() => this.country.onSelect(this.countries[0], emitAddressChange));
    }
  }

  getAddressDataRequest(): AddressDataRequest {
    const addressDataRequest: AddressDataRequest = {
      country: this.selectedAddress.country,
      city: this.selectedAddress.city,
      zipCode: this.selectedAddress.zipCode,
    };
    return addressDataRequest;
  }

  onCountryChange(countrySelection: SelectValue) {
    this.disableCityZip = !countrySelection.value;
    this.selectedAddress.country = countrySelection.value;
    this.selectedAddress.countryTranslation = this.translate.instant(
      'GLOBAL.' + countrySelection.value
    );
    this.cityZips = {
      cities: [],
      zips: [],
    };
    this.streets = [];

    if (countrySelection.emitAddressChange) {
      this.emitNewAddress();
    }

    if (!!this.selectedAddress.country) {
      this.requireNewCityZips.emit(this.getAddressDataRequest());
    }
  }

  onCityZipChange(cityZip: CityZip) {
    this.disableCountry = !!cityZip.city || !!cityZip.zip;
    this.disableSteet = !(!!cityZip.city || !!cityZip.zip);

    this.selectedAddress.city = cityZip.city;
    this.selectedAddress.zipCode = cityZip.zip;

    this.emitNewAddress();

    if (!!this.selectedAddress.country) {
      if (this.selectedAddress.city ? !this.selectedAddress.zipCode : true) {
        this.cityZips = {
          cities: [],
          zips: [],
        };
        this.requireNewCityZips.emit(this.getAddressDataRequest());
      }

      if (this.selectedAddress.city || this.selectedAddress.zipCode) {
        this.streets = [];
        this.requireNewStreets.emit(this.getAddressDataRequest());
      }
    }
  }

  onStreetChange(street: SelectValue) {
    // On clearing country, cityZip and street, it reenables cityZip, but it should only when a country is set
    this.disableCityZip = !!street.value || !this.selectedAddress.country;
    this.selectedAddress.street = street.value;

    if (street.emitAddressChange) {
      this.emitNewAddress();
    }
  }

  emitNewAddress() {
    if (!this.resetOnGoing) {
      this.addressChange.emit({ ...this.selectedAddress });
    }
  }

  reset(type: 'country' | 'cityZip' | 'street') {
    this.resetOnGoing = true;
    switch (type) {
      case 'country':
        this.country.reset();
        this.cityZip.reset();
        this.street.reset();
        break;
      case 'cityZip':
        this.cityZip.reset();
        this.street.reset();
        break;
      case 'street':
        this.street.reset();
        break;
    }
    this.resetOnGoing = false;
    this.emitNewAddress();
  }
}
