import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { EPermissions } from '@auth/login';
import { TranslateService } from '@ngx-translate/core';
import { AddressV3, CityZip, StreetSelect } from '../address-new/data/filter.model';
import { CitiesAndZipsComponent } from './cities-and-zips/cities-and-zips.component';
import { CountriesComponent } from './countries/countries.component';
import { StreetsComponent } from './streets/streets.component';

@Component({
  selector: 'eop-address-with-suggest',
  templateUrl: './address-with-suggest.component.html',
  styleUrls: ['./address-with-suggest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressWithSuggestComponent {
  @Input() permission: EPermissions;
  @Input() partnerId: string;
  @Input() locationId: string;
  @Input() onlyIbetStations: boolean;
  @Input() countryIso3: boolean;

  @Output()
  addressChange: EventEmitter<AddressV3> = new EventEmitter<AddressV3>();

  @ViewChild('country')
  country: CountriesComponent;

  @ViewChild('cityZip')
  cityZip: CitiesAndZipsComponent;

  @ViewChild('street')
  street: StreetsComponent;

  selectedAddress: AddressV3 = {
    country: null,
    countryTranslation: null,
    city: null,
    zipCode: null,
    streetLabel: null,
    streetSearchList: [],
  };

  constructor(private translate: TranslateService) {}

  onCountryChange(country: string): void {
    this.selectedAddress.country = country;
    this.selectedAddress.countryTranslation = this.translate.instant('GLOBAL.' + country);
    this.emitAddress();
  }

  onCityZipChange(cityChange: CityZip): void {
    this.selectedAddress.city = cityChange.city;
    this.selectedAddress.zipCode = cityChange.zip;
    this.emitAddress();
  }

  onStreetChange(streetSelect: StreetSelect): void {
    this.selectedAddress.streetLabel = streetSelect.streetLabel;
    this.selectedAddress.streetSearchList = streetSelect.streetSearchList;
    this.emitAddress();
  }

  emitAddress(): void {
    this.addressChange.emit({ ...this.selectedAddress });
  }

  reset(type: 'country' | 'cityZip' | 'street', emitChange = true): void {
    switch (type) {
      case 'country':
        this.country.reset(false);
        this.resetCountry();
        break;
      case 'cityZip':
        this.cityZip.reset(false);
        this.resetCityZip();
        break;
      case 'street':
        this.street.reset(false);
        this.resetStreet();
        break;
    }
    if (emitChange) this.emitAddress();
  }

  private resetCountry(): void {
    this.selectedAddress.country = null;
    this.selectedAddress.countryTranslation = null;
  }

  private resetCityZip(): void {
    this.selectedAddress.city = null;
    this.selectedAddress.zipCode = null;
  }

  private resetStreet(): void {
    this.selectedAddress.streetLabel = null;
    this.selectedAddress.streetSearchList = [];
  }
}
