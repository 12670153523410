import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { IconWeight } from '@widgets/eop-icon';

@Component({
  selector: 'innogy-tab',
  templateUrl: 'tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  @Input()
  title = '';

  @Input()
  icon: string;

  @Input()
  iconWeight: IconWeight;

  @Input()
  disabled = false;

  @Input()
  active = false;

  @Input()
  showStatus = false;

  @Input()
  status: string;

  @Input()
  testId: string;

  @Input()
  vaylensFont = false;

  constructor(private cdr: ChangeDetectorRef) {}

  detectChanges(): void {
    this.cdr.detectChanges();
  }
}
