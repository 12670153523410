import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse } from '@shared/errors/error.model';
import {
  BusinessPartner,
  LocationChargingStation,
  LocationDetail,
  LocationWithChargingStationsUpdate,
  SearchStationsOfPartner,
  StationsOfPartner,
} from '../data/locations.data';

export const LocationsOverviewActions = createActionGroup({
  source: 'Locations Overview',
  events: {
    'Get Partners': emptyProps(),
    'Set Partners': props<{ partners: BusinessPartner[] }>(),
    'Select Partner': props<{ partner: BusinessPartner }>(),
    'Get Locations': props<{ partner: BusinessPartner }>(),
    'Set Locations': props<{ locations: LocationDetail[] }>(),
    'Get Stations By Location': props<{ location: LocationDetail }>(),
    'Set Stations': props<{ stations: LocationChargingStation[] }>(),
    'Get Stations For Group Edit': props<{ location: LocationDetail }>(),
    'Reset Stations For Group Edit': emptyProps(),
    'Set Stations For Group Edit': props<{ stations: LocationChargingStation[] }>(),
    'Reset Locations': emptyProps(),
    'Delete Location': props<{ location: LocationDetail }>(),
    'Delete Location Success': props<{ location: LocationDetail }>(),
    'Close Selected Location': emptyProps(),
    'Get All Partner Stations': props<{ parameter: SearchStationsOfPartner }>(),
    'Set All Partner Stations': props<{ stations: StationsOfPartner }>(),
    'Update Location Assignment': props<{ location: LocationWithChargingStationsUpdate }>(),
    'Update Location Assignment Success': props<{ location: LocationDetail }>(),
    'Update Location Assignment Error': props<{ error: ErrorResponse }>(),
    'Create Location': props<{ location: LocationWithChargingStationsUpdate }>(),
    'Reset Request': emptyProps(),
  },
});
