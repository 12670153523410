import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { DeleteDialogModel } from '../dialog.model';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  protected readonly ButtonColor = ButtonColor;

  constructor(
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    private sharedRefService: SharedRefService,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogModel
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
    this.sharedRefService.closeDialog();
  }

  onDelete(): void {
    this.dialogRef.close(true);
    this.sharedRefService.closeDialog();
  }
}
