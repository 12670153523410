<mat-expansion-panel
  #expPanels
  *ngIf="userLogin$ | async as user"
  (opened)="setOpenedPanel()"
  [expanded]="expanded"
  class="exp-panel--sidebar"
  hideToggle
>
  <mat-expansion-panel-header
    class="panel-header"
    collapsedHeight="45px"
    expandedHeight="45px"
    [attr.data-test]="'header-menu-profile'"
  >
    <mat-panel-title
      [matTooltipPosition]="'right'"
      [matTooltip]="menuCollapsed ? user.firstName + ' ' + user.lastName : undefined"
    >
      <a
        [routerLink]="'/profile/userProfile'"
        [routerLinkActive]="'title-box--active-link'"
        [routerLinkActiveOptions]="{ exact: false }"
        class="title-box"
      >
        <div class="item-with-icon" [routerLinkActive]="'active-link'">
          <eop-avatar [userProfile]="user"></eop-avatar>
          <span *ngIf="!menuCollapsed" class="link-text--expanded body-l">
            {{ user.firstName + ' ' + user.lastName }}
          </span>
        </div>
      </a>
    </mat-panel-title>

    <mat-panel-description *ngIf="!menuCollapsed" class="expand-arrows link-text--expanded-flex">
      <eop-icon
        [identifier]="
          expPanels.expanded ? 'small-chevron-up-outlined' : 'small-chevron-down-outlined'
        "
        [vaylensFont]="true"
      ></eop-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ul class="on-card-box-shadow--inset">
    <li>
      <eop-link-element
        [attr.data-test]="'header-menu-user-profile'"
        [disabled]="!hasPermissionForUserProfileManage()"
        [enableIconTooltip]="menuCollapsed"
        [linkIcon]="'profile-outlined'"
        [routePath]="'/profile/userProfile'"
        [textKey]="'HEADER.USER_PROFILE'"
        [isExpanded]="!menuCollapsed"
      >
      </eop-link-element>
    </li>
    <li>
      <eop-link-element
        [attr.data-test]="'header-menu-profile-user-logout'"
        [enableIconTooltip]="menuCollapsed"
        [linkIcon]="'logout-1-outlined'"
        [routePath]="'/monitor/dashboard'"
        [textKey]="'HEADER.LOGOUT'"
        [isExpanded]="!menuCollapsed"
        (click)="logout()"
      >
      </eop-link-element>
    </li>
  </ul>
</mat-expansion-panel>
