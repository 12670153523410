import { SessionDataActions } from '@shared/store/actions';
import { EWaitResponseType, ISessionData } from '../data/session-data.model';
import { IActionWithPayload } from '../models';

const INITIAL_STATE: ISessionData = {
  rehydrationCompleted: false,
  notification: null,
  messagesShown: false,
  sidebarCollapsed: null,
  waitLogin: EWaitResponseType.FINISH,
  unauthNavigatedPage: null,
  hasNewMessages: false,
};

export function sessionDataReducer(
  state: ISessionData = INITIAL_STATE,
  action: IActionWithPayload<any>
) {
  switch (action.type) {
    case SessionDataActions.REHYDRATION_COMPLETED:
      return Object.assign({}, state, { rehydrationCompleted: action.payload });
    case SessionDataActions.SHOW_TOP_NOTIFICATION:
      return Object.assign({}, state, { notification: action.payload });
    case SessionDataActions.CLOSE_TOP_NOTIFICATION:
      return Object.assign({}, state, { notification: null });
    case SessionDataActions.OPEN_MESSAGES:
      return Object.assign({}, state, { messagesShown: true, hasNewMessages: true });
    case SessionDataActions.CLOSE_MESSAGES:
      return Object.assign({}, state, { messagesShown: false, hasNewMessages: false });
    case SessionDataActions.TOGGLE_MESSAGES:
      return Object.assign({}, state, {
        messagesShown: !state.messagesShown,
        hasNewMessages: false,
      });
    case SessionDataActions.COLLAPSE_SIDEBAR:
      return Object.assign({}, state, { sidebarCollapsed: true });
    case SessionDataActions.EXPAND_SIDEBAR:
      return Object.assign({}, state, { sidebarCollapsed: false });
    case SessionDataActions.WAIT_LOGIN:
      return Object.assign({}, state, { waitLogin: action.payload });
    case SessionDataActions.UNAUTH_NAVIGATED_PAGE:
      return Object.assign({}, state, { unauthNavigatedPage: action.payload });
    default:
      return state;
  }
}
