import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { Group, IconDisableMode } from '@widgets/group/group.interface';
import {
  ChipLiteColor,
  ChipLiteSize,
} from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';

@Component({
  selector: 'innogy-group',
  templateUrl: 'group.component.html',
  styleUrls: ['group.component.scss'],
})
export class GroupComponent {
  @Input()
  group: Group;

  @Input()
  editMode = false;

  @Input()
  iconDisableMode: IconDisableMode = IconDisableMode.HIDE;

  @Output()
  editGroupClicked = new EventEmitter();

  @Output()
  deleteGroupClicked = new EventEmitter();

  @Output()
  groupClicked = new EventEmitter();

  selected = false;

  IconDisableModes = IconDisableMode;
  ChipLiteColor = ChipLiteColor;
  ChipLiteSize = ChipLiteSize;
  ButtonColor = ButtonColor;

  readonly IconColor = IconColor;
  readonly IconWeight = IconWeight;

  editGroup() {
    if (this.group.editable === false) return;
    this.editGroupClicked.emit(this.group);
  }

  deleteGroup() {
    if (this.group.deletable === false) return;
    this.deleteGroupClicked.emit(this.group);
  }

  select() {
    if (!this.group.disabled && this.editMode === false) {
      this.groupClicked.emit(this.group);
    }
  }

  get editIconTooltip(): string {
    return this.group.editable === false && this.group.notEditableReason
      ? this.group.notEditableReason
      : 'GLOBAL.EDIT';
  }

  get deleteIconTooltip(): string {
    return this.group.deletable === false && this.group.notDeletableReason
      ? this.group.notDeletableReason
      : 'GLOBAL.DELETE';
  }

  get isDeleteButtonDisabled(): boolean {
    return this.group.deletable === false && this.iconDisableMode === this.IconDisableModes.DISABLE;
  }

  get isDeleteEditDisabled(): boolean {
    return this.group.editable === false && this.iconDisableMode === this.IconDisableModes.DISABLE;
  }
}
