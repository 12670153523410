<span (click)="linkClicked()" [ngClass]="{ 'link-element--holder': true, disabled: disabled }">
  <a
    *ngIf="!openInBlank"
    class="router-link"
    [routerLink]="routePath"
    [queryParams]="routerPathParams"
    [class.router-link--active]="isActive"
    [class.router-link--expanded]="isExpanded"
  >
    <eop-icon
      *ngIf="linkIcon"
      [vaylensFont]="true"
      [identifier]="linkIcon"
      [clickable]="!disabled"
      [color]="IconColor.INHERIT"
      [tooltip]="enableIconTooltip && !disabled ? (textKey | translate) : undefined"
      [tooltipPosition]="'right'"
      class="link-element--icon"
    ></eop-icon>
    <span class="link-element--text body-l">
      {{ textKey | translate }}
    </span>
  </a>

  <a
    *ngIf="openInBlank"
    class="router-link"
    href="{{ routePath }}"
    target="_blank"
    rel="noopener"
    [class.router-link--active]="isActive"
    [class.router-link--expanded]="isExpanded"
  >
    <eop-icon
      *ngIf="linkIcon"
      [identifier]="linkIcon"
      [clickable]="!disabled"
      [color]="IconColor.INHERIT"
      [tooltip]="enableIconTooltip && !disabled ? (textKey | translate) : undefined"
      [tooltipPosition]="'right'"
      [vaylensFont]="true"
      class="link-element--icon"
    ></eop-icon>
    <span class="link-element--text body-l">
      {{ textKey | translate }}
    </span>
  </a>
</span>
