<div class="popover">
  <div class="popover-container">
    <div class="spinner" *ngIf="searchInProgress">
      <spinner [spinnerSize]="30"></spinner>
    </div>

    <div *ngIf="!searchInProgress">
      <eop-quick-access-entry-title
        [label]="resultsLabel"
        [size]="TitleSize.BIG"
      ></eop-quick-access-entry-title>

      <eop-quick-access-entry
        *ngIf="resultListEmpty"
        [disabled]="true"
        [iconIdentifier]="'search'"
        [label]="'GLOBAL.RESULTS_NONE' | translate"
      ></eop-quick-access-entry>

      <div class="result-entries-container">
        <eop-quick-access-entry-title
          *ngIf="chargingStationsResults.length > 0"
          [label]="'GLOBAL.CHARGING_STATIONS' | translate"
          [size]="TitleSize.SMALL"
        ></eop-quick-access-entry-title>

        <eop-quick-access-entry
          *ngFor="let chargingStation of chargingStationsResults | slice : 0 : 10"
          [iconIdentifier]="'clean-car-gas'"
          [iconWeight]="IconWeight.BOLD"
          [shouldLabelCrop]="true"
          [label]="chargingStation.value"
          [isSelected]="chargingStation.value === selectedResult?.value"
          [highlightedColor]="
            chargingStation.installationStatus === ChargingStationState.PLANNED
              ? HighLightedColor.INFO
              : chargingStation.installationStatus === ChargingStationState.ABET
              ? HighLightedColor.WARNING
              : HighLightedColor.NONE
          "
          (click)="onResultClicked(chargingStation)"
          (mouseover)="selectedResult = chargingStation"
        ></eop-quick-access-entry>

        <eop-quick-access-entry-title
          *ngIf="chargePointsResults.length > 0"
          [label]="'GLOBAL.CHARGE_POINTS' | translate"
          [size]="TitleSize.SMALL"
        ></eop-quick-access-entry-title>

        <eop-quick-access-entry
          *ngFor="let chargePoint of chargePointsResults | slice : 0 : 10"
          [iconIdentifier]="'charger'"
          [iconWeight]="IconWeight.BOLD"
          [label]="chargePoint.value"
          [shouldLabelCrop]="true"
          [isSelected]="chargePoint.value === selectedResult?.value"
          [highlightedColor]="
            chargePoint.installationStatus === ChargingStationState.PLANNED
              ? HighLightedColor.INFO
              : chargePoint.installationStatus === ChargingStationState.ABET
              ? HighLightedColor.WARNING
              : HighLightedColor.NONE
          "
          (click)="onResultClicked(chargePoint)"
          (mouseover)="selectedResult = chargePoint"
        ></eop-quick-access-entry>
      </div>
    </div>
  </div>
</div>
