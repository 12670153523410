import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IAppState } from '@shared/store/models';

export enum AccessControlActionTypes {
  ResetAccessControlStore = 'ResetAccessControlStore',
  FetchAIDPartner = 'FetchAIDPartner',
  FetchAIDPartnerSuccess = 'FetchAIDPartnerSuccess',
  FetchAIDPartnerFailure = 'FetchAIDPartnerFailure',
  FetchEMAIDPartner = 'FetchEMAIDPartner',
  FetchEMAIDPartnerSuccess = 'FetchEMAIDPartnerSuccess',
  FetchEMAIDPartnerFailure = 'FetchEMAIDPartnerFailure',
  SelectEMAIDGroup = 'SelectEMAIDGroup',
  UnselectEmaidGroup = 'UnselectEmaidGroup',
  SetEmaidGroupPaging = 'SetEmaidGroupPaging',
  SelectAIDGroup = 'SelectAIDGroup',
  UnselectAIDGroup = 'UnselectAIDGroup',
  SelectAIDPartner = 'SelectAIDPartner',
  SelectEMAIDPartner = 'SelectEMAIDPartner',
  FetchAIDGroups = 'FetchAIDGroups',
  FetchEMAIDGroups = 'FetchEMAIDGroups',
  DeleteAIDGroup = 'DeleteAIDGroup',
  DeleteAIDGroupSuccess = 'DeleteAIDGroupSuccess',
  DeleteAIDGroupFailure = 'DeleteAIDGroupFailure',
  DeleteEmaidGroup = 'DeleteEmaidGroup',
  DeleteEmaidGroupSuccess = 'DeleteEmaidGroupSuccess',
  DeleteEmaidGroupFailure = 'DeleteEmaidGroupFailure',
  FetchEMAIDConfig = 'FetchEMAIDConfig',
  FetchEMAIDConfigSuccess = 'FetchEMAIDConfigSuccess',
  FetchEMAIDConfigFailure = 'FetchEMAIDConfigFailure',
  FetchEMAIDGroupsSuccess = 'FetchEMAIDGroupsSuccess',
  FetchEMAIDGroupsFailure = 'FetchEMAIDGroupsFailure',
  RefreshEMAIDFilters = 'RefreshEMAIDFilters',
  FetchEMAIDFilters = 'FetchEMAIDFilters',
  FetchEMAIDFiltersSuccess = 'FetchEMAIDFiltersSuccess',
  FetchEMAIDFiltersFailure = 'FetchEMAIDFiltersFailure',
  FetchEMAIDFilterOptions = 'FetchEMAIDFilterOptions',
  FetchEMAIDFilterOptionsSuccess = 'FetchEMAIDFilterOptionsSuccess',
  FetchEMAIDFilterOptionsFailed = 'FetchEMAIDFilterOptionsFailed',
  CreateEMAIDFilter = 'CreateEMAIDFilter',
  DeleteEMAIDFilter = 'DeleteEMAIDFilter',
  SelectEMAIDFilter = 'SelectEMAIDFilter',
  UnselectEMAIDFilter = 'UnselectEMAIDFilter',
  UpdateSelectEMAIDFilter = 'UpdateSelectEMAIDFilter',
  CreateUpdateDeleteEMAIDFilterSuccess = 'CreateUpdateDeleteEMAIDFilterSuccess',
  CreateUpdateDeleteEMAIDFilterFailed = 'CreateUpdateDeleteEMAIDFilterFailed',
  FetchAIDGroupsSuccess = 'FetchAIDGroupsSuccess',
  FetchAIDGroupsFailure = 'FetchAIDGroupsFailure',
  FetchAIDRoamingStatusSuccess = 'FetchAIDRoamingStatusSuccess',
  FetchAIDRoamingStatusFailure = 'FetchAIDRoamingStatusFailure',
  FetchEMAIDConfigException_NotVisibleAID = 'FetchEMAIDConfigException_NotVisibleAID',
  SaveConfig = 'SaveConfig',
  SetAIDRoamingStatusSuccess = 'SetAIDRoamingStatusSuccess',
  SetAIDRoamingStatusFailure = 'SetAIDRoamingStatusFailure',
  SaveConfigSuccess = 'SaveConfigSuccess',
  SaveConfigFailure = 'SaveConfigFailure',
  ResetNotification = 'ResetNotification',
  SaveEmaidGroupConfig = 'SaveEmaidGroupConfig',
  SaveEmaidGroupConfigSuccess = 'SaveEmaidGroupConfigSuccess',
  SaveEmaidGroupConfigFailure = 'SaveEmaidGroupConfigFailure',
  SaveEmaidGroupConfigExceededLimit = 'SaveEmaidGroupConfigExceededLimit',
  SelectAIDTab = 'SelectAIDTab',
  SelectEMAIDTab = 'SelectEMAIDTab',
  FetchGroupMember = 'FetchGroupMember',
  FetchGroupMemberSuccess = 'FetchGroupMemberSuccess',
  SetAidGroup = 'SetAidGroup',
  FetchAIDRoamingStatus = 'FetchAIDRoamingStatus',
  SetAidSearchField = 'SetAidSearchField',
  SetAidPaging = 'SetAidPaging',
  ResetAidPaging = 'ResetAidPaging',
  ResetEmaidGroupPaging = 'ResetEmaidGroupPaging',
  SetEmaidGroupSearchField = 'SetEmaidGroupSearchField',
  FetchChargingNetworks = 'FetchChargingNetworks',
  FetchChargingNetworksSuccess = 'FetchChargingNetworksSuccess',
  FetchChargingNetworksFailed = 'FetchChargingNetworksFailed',
}

@Injectable()
export class AccessControlActions {
  constructor(private store: NgRedux<IAppState>) {}

  public dispatch(action: AccessControlActionTypes, payload?: any) {
    const type = action;
    return this.store.dispatch({
      type,
      payload,
    });
  }
}
