<div class="text-box {{ type }}">
  <p *ngFor="let text of texts" class="text-wrapper">
    <span
      class="bold-text"
      *ngIf="text.boldText && !text.boldTextInChip"
      [innerHtml]="text.boldText + ': '"
    ></span>
    <app-innogy-chips
      class="bold-text chip"
      *ngIf="text.boldText && text.boldTextInChip"
      [chipsData]="[{ value: text.boldText }]"
      [chipColor]="text.chipColor"
      [emphasized]="false"
    >
    </app-innogy-chips>
    <span class="info-text" *ngIf="text.text" [innerHtml]="text.text"></span>
  </p>
  <ng-content></ng-content>
</div>
