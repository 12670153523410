import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateConversionService } from '@shared/services/util';
import { IconColor } from '@widgets/eop-icon';
import { SnackbarService } from '@widgets/snackbar/snackbar.service';
import moment from 'moment';
import { PopoverRef } from '../../classes/popover-ref';
import { ETimePeriod } from '../../data/filter-container.model';
import { Item } from '../../services/filter-frontend.model';
import { FilterInputComponent } from '../filter-input.component';

@Component({
  selector: 'eop-single-date-time-select',
  templateUrl: './single-date-time-select.component.html',
  styleUrls: ['./single-date-time-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleDateTimeSelectComponent extends FilterInputComponent {
  readonly durationSnackbarInvalidDate = 5000; // ms

  @Input()
  invalidDateMsg: string;
  @Input()
  key: string[];

  minDate: Date;
  maxDate: Date;
  selectedDateFrom: Date;
  selectedDateTo: Date;
  isIndividualPeriod: boolean;

  keyDateFrom: string;
  keyDateTo: string;

  DATE_FROM_INDEX = 0;
  DATE_TO_INDEX = 1;

  isInvalidFromDate = false;
  isInvalidToDate = false;

  readonly IconColor = IconColor;

  constructor(
    private snackbarService: SnackbarService,
    @Optional() private popoverRef: PopoverRef,
    private dateConversionService: DateConversionService
  ) {
    super(popoverRef);
    this.invalidDateMsg = this.componentData.items[0].invalidDateMsg;
    this.keyDateFrom = Object.keys(this.componentData.items[0].value)[this.DATE_FROM_INDEX];
    this.keyDateTo = Object.keys(this.componentData.items[0].value)[this.DATE_TO_INDEX];

    this.isIndividualPeriod = !!this.componentData.items.filter(
      i => i.id === ETimePeriod.INDIVIDUAL_TIME_PERIOD && i.selected
    ).length;

    const individualPeriodSelection = this.componentData.items.find(
      i => i.id === ETimePeriod.INDIVIDUAL_TIME_PERIOD
    );
    const currentlySelectedDateFrom = individualPeriodSelection.value[this.keyDateFrom];
    const currentlySelectedDateTo = individualPeriodSelection.value[this.keyDateTo];
    this.selectedDateFrom =
      this.isIndividualPeriod && currentlySelectedDateFrom
        ? moment(currentlySelectedDateFrom).toDate()
        : null;
    this.selectedDateTo =
      this.isIndividualPeriod && currentlySelectedDateTo
        ? moment(currentlySelectedDateTo).toDate()
        : moment().startOf('day').toDate();
    const individualMinDate =
      individualPeriodSelection.data && individualPeriodSelection.data.minDate;
    const individualMaxDate =
      individualPeriodSelection.data && individualPeriodSelection.data.maxDate;
    this.minDate = individualMinDate ? moment(individualMinDate).toDate() : null;
    this.maxDate = individualMaxDate ? moment(individualMaxDate).toDate() : null;
  }

  onItemClick(item: Item) {
    this.componentData.items = this.componentData.items.map(it =>
      item === it ? { ...it, selected: !item.selected } : { ...it, selected: false }
    );

    if (item.id !== ETimePeriod.INDIVIDUAL_TIME_PERIOD) {
      this.isIndividualPeriod = false;
      this.selectedDateFrom = null;
      this.selectedDateTo = null;
      this.emitItems(item);
    } else {
      this.isIndividualPeriod = !item.selected;
      if (item.selected && this.selectedDateFrom && this.selectedDateTo) {
        this.emitItems(item);
      }
    }
  }

  emitItems(item: Item) {
    const { dateFrom, dateTo } = this.dateConversionService.calculateDatesDependingOnPeriod(
      <ETimePeriod>item.id,
      this.selectedDateFrom ? this.selectedDateFrom.toISOString() : null,
      this.selectedDateTo ? this.selectedDateTo.toISOString() : null
    );
    this.componentData.items = this.componentData.items.map(i => {
      if (i.id === item.id) {
        const chipValue =
          i.id === ETimePeriod.INDIVIDUAL_TIME_PERIOD
            ? moment(dateFrom).format(this.dateConversionService.getDateFormatFromUser()) +
              ' - ' +
              moment(dateTo).format(this.dateConversionService.getDateFormatFromUser())
            : i.name;
        return {
          ...i,
          chipValue,
          value: {
            [this.keyDateFrom]: dateFrom,
            [this.keyDateTo]: dateTo,
          },
        };
      }
      return {
        ...i,
        value: {
          [this.keyDateFrom]: '',
          [this.keyDateTo]: '',
        },
      };
    });

    this.itemClick.emit(this.componentData);
    if (this.popoverRef) {
      this.popoverRef.callback(this.componentData);
      if (item.id !== ETimePeriod.INDIVIDUAL_TIME_PERIOD) {
        this.popoverRef.close();
      }
    }
  }

  onDateSelectFrom(dateFrom: MatDatepickerInputEvent<Date>) {
    this.selectedDateFrom = dateFrom.value;
    if (
      this.selectedDateFrom &&
      this.selectedDateTo &&
      this.selectedDateFrom <= this.selectedDateTo &&
      (this.minDate ? this.minDate <= this.selectedDateFrom : true)
    ) {
      this.emitItems(
        this.componentData.items.find(i => i.id === ETimePeriod.INDIVIDUAL_TIME_PERIOD)
      );
      this.isInvalidFromDate = false;
    } else {
      if (this.selectedDateFrom && this.selectedDateTo) {
        this.snackbarService.openSnackbar(this.invalidDateMsg, 1, this.durationSnackbarInvalidDate);
        this.isInvalidFromDate = true;
      }
    }
  }

  onDateSelectTo(dateTo: MatDatepickerInputEvent<Date>) {
    this.selectedDateTo = dateTo.value;
    if (
      this.selectedDateFrom &&
      this.selectedDateTo &&
      this.selectedDateFrom <= this.selectedDateTo &&
      (this.maxDate ? this.selectedDateTo <= this.maxDate : true)
    ) {
      this.emitItems(
        this.componentData.items.find(i => i.id === ETimePeriod.INDIVIDUAL_TIME_PERIOD)
      );
      this.isInvalidToDate = false;
    } else {
      if (this.selectedDateFrom && this.selectedDateTo) {
        this.snackbarService.openSnackbar(this.invalidDateMsg, 1, this.durationSnackbarInvalidDate);
        this.isInvalidToDate = true;
      }
    }
  }
}
