import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'yearOnly',
})
export class YearOnlyPipe implements PipeTransform {
  public transform(value: any): string {
    const momentDate = moment.parseZone(value);
    return momentDate.isValid() ? momentDate.format('YYYY') : null;
  }
}
