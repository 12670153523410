<div class="popover" *ngIf="messagesShown$ | async">
  <div class="popover-container">
    <eop-login-notification-header
      [amount]="activeMessages?.length"
      (closeMessagesEvent)="closeMessages()"
    >
    </eop-login-notification-header>
    <div class="notification-entries-container">
      <eop-single-login-notification
        *ngFor="let message of activeMessages; last as last"
        [ngClass]="{ 'entry-bottom-border': !last }"
        [message]="message"
        [userLanguage]="userLanguage"
      >
      </eop-single-login-notification>
    </div>
  </div>
</div>
