<div class="wrapper" [ngStyle]="styles">
  <div class="error-msg">
    <a class="close-btn" *ngIf="!hideCloseBtn">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 85.04 85.04"
      >
        <path
          fill="#ffffff"
          shape-rendering="geometricPrecision"
          color-rendering="optimizeQuality"
          d="M42.51968,4.252A38.26773,38.26773,0,1,1,4.252,42.51968,38.31107,38.31107,0,0,1,42.51968,4.252m0-4.252A42.51969,42.51969,0,1,0,85.03937,42.51968,42.51968,42.51968,0,0,0,42.51968,0h0ZM56.06805,53.05365L45.53174,42.51678,56.06891,31.97955a2.12805,2.12805,0,1,0-3.009-3.01007L42.52228,39.50726,31.98645,28.97089a2.13226,2.13226,0,0,0-3.01544,3.0155L39.50726,42.52228,28.96967,53.06a2.128,2.128,0,1,0,3.00995,3.009L42.51678,45.53174l10.5368,10.53644A2.13157,2.13157,0,1,0,56.06805,53.05365Z"
        />
      </svg>
    </a>
    <p class="text">{{ message }}</p>
  </div>
</div>
