import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { EPermissions } from '@auth/login';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { AddressNewFilterComponent } from '@widgets/filter/address-new/address.component';
import {
  ChipLiteColor,
  ChipLiteSize,
} from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';
import {
  AddressDataRequest,
  AddressNew,
  AddressV3,
  CityZips,
  Countries,
  Streets,
} from './address-new/data/filter.model';
import { AddressWithSuggestComponent } from './address-with-suggest/address-with-suggest.component';
import { FilterContainerConfiguration } from './specific/filter-container.component';
import { ComponentData, FilterChip, ResultMap } from './specific/services/filter-frontend.model';

@Component({
  selector: 'eop-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input()
  specificFiltersConfig: FilterContainerConfiguration[];
  @Input('expanded')
  expandedFilter = true;
  @Input()
  addressPermission: EPermissions;
  @Input()
  partnerId: string;
  @Input()
  locationId: string;

  @Input()
  hideAddressFilter = false;
  @Input()
  addressFilterV3 = false;
  @Input()
  onlyIbetStations = false;
  @Input()
  countryIso3 = false;

  /**
   * @deprecated
   */
  @Input()
  countries: Countries;
  /**
   * @deprecated
   */
  @Input()
  cityZips: CityZips;
  /**
   * @deprecated
   */
  @Input()
  streets: Streets;

  /**
   * @deprecated
   */
  @Output()
  requireNewCountries: EventEmitter<null> = new EventEmitter<null>();
  /**
   * @deprecated
   */
  @Output()
  requireNewCityZips: EventEmitter<AddressDataRequest> = new EventEmitter<AddressDataRequest>();
  /**
   * @deprecated
   */
  @Output()
  requireNewStreets: EventEmitter<AddressDataRequest> = new EventEmitter<AddressDataRequest>();

  /**
   * @deprecated
   */
  @ViewChild('addressFilter')
  addressFilter: AddressNewFilterComponent;

  @ViewChild('addressWithSuggestFilter')
  addressWithSuggestFilter: AddressWithSuggestComponent;

  @Output()
  filter = new EventEmitter();

  chipsData: FilterChip[] = [];
  addressChips: FilterChip[] = [];
  specificChips: FilterChip[] = [];
  componentsData: ComponentData[] = [];
  /**
   * @deprecated
   */
  addressFilterResultNew: AddressNew = {
    country: null,
    city: null,
    zipCode: null,
    street: null,
  };
  addressFilterResultV3: AddressV3 = {
    country: null,
    city: null,
    zipCode: null,
    streetLabel: null,
    streetSearchList: [],
  };
  specificFilterResult: ResultMap;
  specificFilterCount = 0;
  somePreselection = false;

  constructor(private cdr: ChangeDetectorRef) {}

  IconColor = IconColor;
  IconWeight = IconWeight;
  IconSize = IconSize;
  ButtonColor = ButtonColor;
  ChipLiteColor = ChipLiteColor;
  ChipLiteSize = ChipLiteSize;

  onAddInitPreselect(data: ComponentData): void {
    this.specificFilterCount++;
    this.addPreselection(data);
    if (this.specificFilterCount === this.specificFiltersConfig.length && this.somePreselection) {
      this.runSpecificFilter();
    }
  }

  onAddPreselect(data: ComponentData): void {
    this.somePreselection = false;
    this.addPreselection(data);
    if (this.somePreselection) {
      this.runSpecificFilter();
    }
  }

  addPreselection(componentData: ComponentData): void {
    const someSelected = componentData.items.some(i => i.selected);
    if (componentData && componentData.items) {
      if (someSelected) {
        this.somePreselection = true;
        this.updateComponentsData(componentData);
      } else {
        //Clear specific filter
        this.componentsData = this.componentsData.filter(
          cd => cd.componentId !== componentData.componentId
        );
        this.specificChips = this.specificChips.filter(specificChip => {
          return specificChip.value.componentId !== componentData.componentId;
        });
        this.chipsData = this.chipsData.filter(specificChip => {
          return specificChip.value.componentId !== componentData.componentId;
        });
      }
    }
  }

  toggleFilter(): void {
    this.expandedFilter = !this.expandedFilter;
  }

  removeAllFilter(): void {
    this.addressFilter?.reset('country');
    this.addressWithSuggestFilter?.reset('country');
    this.addressWithSuggestFilter?.reset('cityZip');
    this.addressWithSuggestFilter?.reset('street');
    this.specificChips = [];
    this.specificFilterResult = [];
    this.chipsData = [];
    this.initCompData();
  }

  removeAllFilterAndTriggerSearch(): void {
    this.removeAllFilter();
    this.runFilter();
  }

  onSpecificFilter(componentData: ComponentData): void {
    this.updateComponentsData(componentData);
    this.runSpecificFilter();
  }

  runFilter(): void {
    if (!this.addressFilterV3) {
      this.filter.emit({ ...this.addressFilterResultNew, ...this.specificFilterResult });
    } else {
      /**
       * we need to update specific filter values
       * otherwise the first address changes after resetting the specific filters
       * will send the specific filter results that were selected before the reset
       */
      this.updateSpecificFilterFields();
      this.filter.emit({ ...this.addressFilterResultV3, ...this.specificFilterResult });
    }
  }

  onChipRemoved(chipItem: FilterChip): void {
    if (
      chipItem.value?.componentId === 'country' ||
      chipItem.value?.componentId === 'cityZip' ||
      chipItem.value?.componentId === 'street'
    ) {
      this.addressFilter?.reset(chipItem.value?.componentId);
      this.addressWithSuggestFilter?.reset(chipItem.value?.componentId);
    } else {
      this.specificChips = this.specificChips.filter(chip => chip !== chipItem);
      const itemAndComponentId = chipItem.value;
      this.componentsData = this.componentsData.map(compData => {
        if (compData.componentId === itemAndComponentId.componentId) {
          const items = compData.items.map(i => {
            return i === itemAndComponentId.item ? { ...i, selected: false } : i;
          });

          return {
            ...compData,
            items,
          };
        }

        return compData;
      });

      this.onSpecificFilter(this.componentsData[0]);
    }
  }

  updateComponentsData(componentData: ComponentData): void {
    this.componentsData = this.componentsData.filter(
      cd => cd.componentId !== componentData.componentId
    );
    this.componentsData.push(componentData);
    this.cdr.detectChanges();
  }

  runSpecificFilter(): void {
    this.updateSpecificFilterFields();
    this.chipsData = [...this.addressChips, ...this.specificChips].sort(
      (a: FilterChip, b: FilterChip) => a.chipName.localeCompare(b.chipName)
    );
    this.runFilter();
  }

  private updateSpecificFilterFields() {
    const componentsWithSomethingSelected = this.componentsData.filter(
      componentData => componentData.items.filter(item => item.selected).length > 0
    );

    this.specificFilterResult = {};
    this.specificChips = [];

    componentsWithSomethingSelected.forEach(comp => {
      const selectedItems = comp.items.filter(i => i.selected);
      const selectedValues = selectedItems.map(i => i.value);

      selectedItems.forEach(i => {
        this.specificChips.push({
          name: i.name,
          chipName: `${comp.title}: ${i.chipValue}`,
          value: {
            componentId: comp.componentId,
            item: i,
          },
        });
      });

      selectedValues.forEach(value => {
        const keyValArray = Object.entries(value);

        keyValArray.forEach(keyVal => {
          const key = keyVal[0];
          const val = keyVal[1];

          if (this.specificFilterResult[key]) {
            const resultVal = this.specificFilterResult[key];
            this.specificFilterResult[key] = [];
            this.specificFilterResult[key] = this.specificFilterResult[key]
              .concat(resultVal)
              .concat(val);
          } else {
            this.specificFilterResult[key] = val;
          }
        });
      });
    });
  }

  initCompData(): void {
    this.componentsData = this.componentsData.map(compData => {
      compData.items = compData.items.map(item => {
        return { ...item, selected: false };
      });
      return compData;
    });
  }

  trackByFn(index: number): number {
    return index;
  }

  resetAddress() {
    this.addressFilter?.reset('country');
    this.addressWithSuggestFilter?.reset('country');
    this.addressWithSuggestFilter?.reset('cityZip');
    this.addressWithSuggestFilter?.reset('street');
  }

  onAddressChangeV3(address: AddressV3) {
    this.addressFilterResultV3 = address;
    this.setAddressV3ChipsData();
    delete this.addressFilterResultV3.streetLabel;
    delete this.addressFilterResultV3.countryTranslation;
    this.runFilter();
  }

  setAddressV3ChipsData() {
    this.addressChips = [];
    if (this.addressFilterResultV3.country) {
      this.addressChips.push({
        value: { componentId: 'country' },
        name: this.addressFilterResultV3.countryTranslation,
        chipName: this.addressFilterResultV3.countryTranslation,
      });
    }
    if (this.addressFilterResultV3.city || this.addressFilterResultV3.zipCode) {
      const cityAndZipLabel = this.buildCityAndZipLabel();
      this.addressChips.push({
        value: { componentId: 'cityZip' },
        name: cityAndZipLabel,
        chipName: cityAndZipLabel,
      });
    }
    if (this.addressFilterResultV3.streetLabel) {
      this.addressChips.push({
        value: { componentId: 'street' },
        name: this.addressFilterResultV3.streetLabel,
        chipName: this.addressFilterResultV3.streetLabel,
      });
    }

    this.chipsData = this.addressChips.concat(this.specificChips);
  }

  private buildCityAndZipLabel() {
    const city = this.addressFilterResultV3.city;
    const zip = this.addressFilterResultV3.zipCode;
    let label = city ? city : '';
    label += city && zip ? ' - ' : '';
    label += zip ? zip : '';
    return label;
  }

  /**
   * @deprecated
   */
  onAddressChange(address: AddressNew) {
    this.addressFilterResultNew = address;
    this.setAddressNewChipsData();
    delete this.addressFilterResultNew.countryTranslation;
    this.runFilter();
  }

  /**
   * @deprecated
   */
  setAddressNewChipsData() {
    this.addressChips = [];
    if (this.addressFilterResultNew.country) {
      this.addressChips.push({
        value: { componentId: 'country' },
        name: this.addressFilterResultNew.countryTranslation,
        chipName: this.addressFilterResultNew.countryTranslation,
      });
    }
    if (this.addressFilterResultNew.city || this.addressFilterResultNew.zipCode) {
      let label = this.addressFilterResultNew.city ? this.addressFilterResultNew.city : '';
      label += this.addressFilterResultNew.city && this.addressFilterResultNew.zipCode ? ' - ' : '';
      label += this.addressFilterResultNew.zipCode ? this.addressFilterResultNew.zipCode : '';
      this.addressChips.push({ value: { componentId: 'cityZip' }, name: label, chipName: label });
    }
    if (this.addressFilterResultNew.street) {
      this.addressChips.push({
        value: { componentId: 'street' },
        name: this.addressFilterResultNew.street,
        chipName: this.addressFilterResultNew.street,
      });
    }

    this.chipsData = this.addressChips
      .concat(this.specificChips)
      .sort((a: FilterChip, b: FilterChip) => a.chipName.localeCompare(b.chipName));
  }

  /**
   * @deprecated
   */
  onRequireNewCountries() {
    this.requireNewCountries.emit();
  }

  /**
   * @deprecated
   */
  onRequireNewCityZips(addressDataRequest: AddressDataRequest) {
    this.requireNewCityZips.emit(addressDataRequest);
  }

  /**
   * @deprecated
   */
  onRequireNewStreets(addressDataRequest: AddressDataRequest) {
    this.requireNewStreets.emit(addressDataRequest);
  }
}
