<div class="links">
  <eop-simple-link-element
    [url]="connectivityTesterUrl"
    [isExpanded]="!menuCollapsed"
    [linkText]="'FOOTER.CONNECTIVITY_TESTER' | translate"
    [iconName]="'convert-circle-outlined'"
  ></eop-simple-link-element>
  <eop-simple-link-element
    [url]="'https://status.services-emobility.com'"
    [isExpanded]="!menuCollapsed"
    [linkText]="'SYSTEM_STATUS.TITLE' | translate"
    [iconName]="'chart-success-outlined'"
  ></eop-simple-link-element>
</div>
<div class="copyright">
  <img
    class="image"
    src="assets/img/copyright.svg"
    alt="Copyright"
    [matTooltip]="
      menuCollapsed ? ('GLOBAL.COPYRIGHT_VAYLENS' | translate : { year: getCurrentYear() }) : ''
    "
    [matTooltipPosition]="'right'"
  />
  <div *ngIf="!menuCollapsed" class="body-bold-m">
    {{ 'GLOBAL.COPYRIGHT_VAYLENS' | translate : { year: getCurrentYear() } }}
  </div>
</div>

<div *ngIf="showTranslationToggle" class="translation-toggle">
  <label>Translation Keys</label>
  <eop-toggle-switch (change)="toggleTranslation($event)"></eop-toggle-switch>
</div>
