import { Component } from '@angular/core';

/**
 * This component can be used to show a tooltip over an element.
 * The content of the tooltip must be provided through content projection.
 * You propably want to use it in combination with an *ngIf
 * or similar to attach this component dynamically to the dom.
 */
@Component({
  selector: 'emob-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {}
