import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColor } from '@widgets/eop-icon';

@Component({
  selector: 'eop-simple-link-element',
  templateUrl: './simple-link-element.component.html',
  styleUrls: ['./simple-link-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleLinkElementComponent {
  readonly IconColor = IconColor;

  @Input() url: string;
  @Input() isExpanded: boolean;
  @Input() linkText: string;
  @Input() iconName: string;
}
