import { Injectable } from '@angular/core';
import { BackendDataActions, SessionDataActions } from '@shared/store/actions';
import { IActionWithPayload, IAppState } from '@shared/store/models';
import {
  Reducer,
  ReducersMapObject,
  Store,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { createEpicMiddleware } from 'redux-observable-es6-compat';
import { PersistConfig, Persistor, persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StoreManager {
  private configured: boolean = false;
  private reducers: ReducersMapObject;
  private combinedReducer: Reducer;
  private epic$: BehaviorSubject<Epic>;

  private store: Store;
  private persistor: Persistor;

  public configureStore(initialReducers: ReducersMapObject, initialEpic: Epic): Store {
    this.reducers = { ...initialReducers };
    this.combinedReducer = this.combineToPersistReducer(this.reducers);
    this.epic$ = new BehaviorSubject(initialEpic);

    const rootReducer = (state, action) => {
      if (action.type === BackendDataActions.LOGOUT_SUCCESS) {
        state = {};
      }
      return this.combinedReducer(state, action);
    };

    const rootEpic$: Epic = (
      action$: ActionsObservable<IActionWithPayload<any>>,
      state$: StateObservable<IAppState>,
      dependencies: any
    ): Observable<Epic> => this.epic$.pipe(mergeMap(epic => epic(action$, state$, dependencies)));

    const epicMiddleware = createEpicMiddleware();
    this.store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(epicMiddleware)));
    epicMiddleware.run(rootEpic$);

    this.configured = true;

    this.persistTheStore();

    return this.store;
  }

  public addReducers(reducers: ReducersMapObject) {
    if (!this.configured) {
      throw new Error('StoreManager has not been configured!');
    }

    Object.entries(reducers).forEach(([key, reducer]) => {
      if (this.reducers[key]) {
        console.warn(`Reducer ${key} has already been registered`);
      } else {
        this.reducers[key] = reducer;
        this.combinedReducer = this.combineToPersistReducer(this.reducers);
        this.persistTheStore();
      }
    });
  }

  public addEpic(epic: Epic) {
    if (!this.configured) {
      throw new Error('StoreManager has not been configured!');
    }

    this.epic$.next(epic);
  }

  public clearPersistedSessionData(): void {
    this.persistor.purge();
    this.persistor.persist();
  }

  private combineToPersistReducer(reducers) {
    const persistConfig: PersistConfig = {
      key: 'root',
      storage: storageSession,
      whitelist: ['backendData', 'locations', 'userFilterOptions'],
    };
    return persistReducer(persistConfig, combineReducers(reducers));
  }

  private persistTheStore(): void {
    this.persistor = persistStore(this.store, null, () => {
      this.store.dispatch(SessionDataActions.rehydrationCompleted());
    });
  }
}
