<div
  class="loader"
  [style.width.px]="spinnerSize"
  #spinnerHolder
  [attr.data-test]="'loading-indicator'"
>
  <svg class="spinner" viewBox="25 25 50 50">
    <circle
      class="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      stroke-width="4"
      stroke-miterlimit="10"
    />
  </svg>
</div>
