import { select } from '@angular-redux/store';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from '@auth/login';
import { LoginMessagesEntry } from '@portal/login-messages/data/login-message.model';
import { Observable } from 'rxjs';
import { SessionDataActions } from '@shared/store/actions';

@Component({
  selector: 'eop-login-notification',
  templateUrl: './login-notification.component.html',
  styleUrls: ['./login-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginNotificationComponent {
  @Input()
  activeMessages: LoginMessagesEntry[];

  @Input()
  userLanguage: Language;

  @Output()
  markNotificationsSeen = new EventEmitter<void>();

  @select(['sessionData', 'messagesShown'])
  messagesShown$: Observable<boolean>;

  constructor(private sessionDataActions: SessionDataActions) {}

  closeMessages(): void {
    this.markNotificationsSeen.emit();
    this.sessionDataActions.closeMessages();
  }
}
