import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUserLogin } from '@auth/login';
import { TranslateService } from '@ngx-translate/core';
import { StorageBroadcastService } from '@shared/services/auth';
import { AuthenticationService } from '@shared/services/auth/authentication.service';
import { NotificationLevel, TopNotificationService } from '@shared/services/notification';
import { BackendDataActions, SessionDataActions } from '@shared/store/actions';
import { EWaitResponseType } from '@shared/store/data/session-data.model';
import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concat, map, switchMap } from 'rxjs/operators';
import { StoreManager } from '../store.manager';

@Injectable()
export class BackendDataEpics {
  constructor(
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private topNotificationService: TopNotificationService,
    private broadcastService: StorageBroadcastService,
    private storeManager: StoreManager,
    private router: Router
  ) {}

  public startLogin$ = (action$: ActionsObservable<any>) => {
    return action$.pipe(
      ofType(BackendDataActions.START_LOGIN),
      switchMap(action => [
        {
          type: BackendDataActions.LOGIN,
          payload: {
            username: action.payload.username,
            password: action.payload.password,
          },
        },
        {
          type: SessionDataActions.WAIT_LOGIN,
          payload: EWaitResponseType.WAITING,
        },
      ])
    );
  };

  public login$ = (action$: ActionsObservable<any>) => {
    return action$.pipe(
      ofType(BackendDataActions.LOGIN),
      switchMap(action => {
        return this.authenticationService
          .login(action.payload.username, action.payload.password)
          .pipe(
            switchMap((result: IUserLogin) => {
              this.broadcastService.broadcastLogin(result);
              return [
                {
                  type: BackendDataActions.LOGIN_SUCCESS,
                  payload: result,
                },
                {
                  type: SessionDataActions.WAIT_LOGIN,
                  payload: EWaitResponseType.FINISH,
                },
                {
                  type: SessionDataActions.CLOSE_TOP_NOTIFICATION,
                },
              ];
            }),
            catchError(errorResponse => {
              let message;
              const backendError = errorResponse.error;
              switch (backendError.error) {
                case '401': {
                  message = this.translate.instant('LOGIN.USERNAME_INCORRECT');
                  break;
                }
                case '404': {
                  message = this.translate.instant('LOGIN.ENDPOINT_NOT_FOUND');
                  break;
                }
                default: {
                  message = this.translate.instant('GLOBAL.LOGIN_FAIL_MSG');
                }
              }

              return of({
                type: SessionDataActions.SHOW_TOP_NOTIFICATION,
                payload: {
                  notificationLevel: NotificationLevel.ERROR,
                  message: message,
                },
              }).pipe(
                concat(
                  of({
                    type: SessionDataActions.WAIT_LOGIN,
                    payload: EWaitResponseType.FINISH_WITH_ERROR,
                  })
                )
              );
            })
          );
      })
    );
  };

  public logout$ = (action$: ActionsObservable<any>) => {
    return action$.pipe(
      ofType(BackendDataActions.LOGOUT),
      switchMap(() => {
        return this.authenticationService.logout().pipe(
          map(result => {
            this.broadcastService.broadcastLogout();
            this.storeManager.clearPersistedSessionData();

            return {
              type: BackendDataActions.LOGOUT_SUCCESS,
              payload: result,
            };
          }),
          catchError(error => {
            this.topNotificationService.showErrorInTopNotification(error);
            return of();
          })
        );
      })
    );
  };

  public logoutSuccess$ = (action$: ActionsObservable<any>) => {
    return action$.pipe(
      ofType(BackendDataActions.LOGOUT_SUCCESS),
      switchMap(action => {
        this.router.navigate(['/auth/login']);
        return of({ type: '' });
      })
    );
  };

  public fetchUserSettingsValueList$ = (action$: ActionsObservable<any>) => {
    return action$.pipe(
      ofType(BackendDataActions.LOGIN_SUCCESS),
      switchMap(action => {
        return this.authenticationService.getSettingValueList().pipe(
          map(result => {
            return {
              type: BackendDataActions.SAVE_SETTINGS_VALUE_LIST,
              payload: result,
            };
          }),
          catchError(error => {
            this.topNotificationService.showErrorInTopNotification(error);
            return of();
          })
        );
      })
    );
  };
}
