<div #chartWrapper class="normalized-stacked-bar-chart">
  <div class="stacked-bar-chart" id="stacked-bar-chart-{{ chartUuid }}"></div>
</div>

<emob-tooltip *ngIf="showTooltip">
  <div *ngIf="!tooltipTemplate">
    <h1>{{ tooltipContext.tooltipText }}</h1>
  </div>

  <div *ngTemplateOutlet="tooltipTemplate; context: tooltipContext"></div>
</emob-tooltip>
