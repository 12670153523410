<input
  #searchInput
  (click)="setPopoverVisibility(true)"
  (focus)="setPopoverVisibility(true)"
  (keydown)="onKeyDownWithinInput($event)"
  (keyup)="onKeyUpWithinInput($event)"
  [attr.data-test]="'header_search_input'"
  [matTooltipShowDelay]="1500"
  [matTooltip]="searchInput.value || searchPlaceholderText"
  [ngClass]="{ 'in-front': popoverVisible && minCharsReached }"
  [placeholder]="searchPlaceholderText"
  class="search-input input-field"
  type="text"
/>

<eop-quick-access-results
  *ngIf="popoverVisible && minCharsReached"
  [results]="currentResults"
  [searchInProgress]="searchInProgress"
  (resultClicked)="navigate($event)"
  [(selectedResult)]="selectedResult"
></eop-quick-access-results>

<div
  class="backdrop"
  *ngIf="popoverVisible && minCharsReached"
  (click)="setPopoverVisibility(false)"
></div>
