import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { ILanguagesData, IStatus } from './translation.model';

@Injectable({
  providedIn: 'root',
})
export class CmmService {
  timeoutRequestLanguageStatusFromCMM = 1000;
  timeoutRequestLanguagesFromCMM = 4000;
  newVersionAvailableStatusCode = '1';

  constructor(private http: HttpClient) {}

  getTranslations(): Observable<ILanguagesData> {
    return this.http
      .get<ILanguagesData>(environment.cmmUrl + '/text/download?applicationId=B2B_PORTAL')
      .pipe(timeout(this.timeoutRequestLanguagesFromCMM));
  }

  doTranslationsHaveChangedSince(timestampIsoFormat: string): Observable<boolean> {
    return this.http
      .get<IStatus>(
        environment.cmmUrl +
          '/text/status?datetime=' +
          timestampIsoFormat +
          '&applicationId=B2B_PORTAL'
      )
      .pipe(
        timeout(this.timeoutRequestLanguageStatusFromCMM),
        map((status: IStatus) => status.code === this.newVersionAvailableStatusCode)
      );
  }
}
