import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { InnogyChipsModule } from '@widgets/innogy-chips/innogy-chips.module';
import { FormInputItemComponent } from '.';

@NgModule({
  imports: [CommonModule, EopIconModule, MatProgressBarModule, InnogyChipsModule],
  declarations: [FormInputItemComponent],
  exports: [FormInputItemComponent],
})
export class FormInputItemModule {}
