import { Component, Input } from '@angular/core';
import { IconWeight } from '@widgets/eop-icon';
import { StatusCellData } from '../status-cell/status-cell.component';

@Component({
  selector: 'bullet-status-cell',
  templateUrl: './bullet-status-cell.component.html',
  styleUrls: ['./bullet-status-cell.component.scss'],
})
export class BulletStatusCellComponent {
  @Input()
  cellData: StatusCellData[];
  readonly IconWeight = IconWeight;
}
