import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EPermissions } from '@auth/login';
import { Icon, IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { CityZip } from '@widgets/filter/address-new/data/filter.model';
import { merge, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CitySuggestion } from '../model/suggestions.model';
import { SuggestService } from '../services/suggest.service';

@Component({
  selector: 'eop-cities-and-zips',
  templateUrl: './cities-and-zips.component.html',
  styleUrls: ['./cities-and-zips.component.scss'],
})
export class CitiesAndZipsComponent implements OnInit, OnDestroy {
  @ViewChild('input', { read: MatAutocompleteTrigger }) autoCompleteTrigger: MatAutocompleteTrigger;

  readonly DEBOUNCE_TIME_FOR_SEARCH_IN_MS = 200;
  readonly MIN_CHARACTER_BEFORE_SEARCH = 1;
  readonly IconColor = IconColor;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private searchWithDebounce$: Subject<string> = new Subject<string>();
  private directSearch$: Subject<string> = new Subject<string>();

  @Input() permission: EPermissions;
  @Input() partnerId: string;
  @Input() locationId: string;
  @Input() country: string;
  @Input() onlyIbetStations: boolean;

  @Output()
  selectionChange: EventEmitter<CityZip> = new EventEmitter<CityZip>();

  get city(): AbstractControl {
    return this.form.get('city');
  }

  form: FormGroup;
  suggestions: CitySuggestion[];
  cityZipIcon: Icon = {
    identifier: 'building-modern-2',
    size: IconSize.SMALL,
    weight: IconWeight.BOLD,
  };
  noResults = false;
  loading = false;
  searchInputIsEmpty = false;

  constructor(
    private suggestService: SuggestService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.form = this.formBuilder.group({ city: null });
    merge(
      this.directSearch$,
      this.searchWithDebounce$.pipe(debounceTime(this.DEBOUNCE_TIME_FOR_SEARCH_IN_MS))
    )
      .pipe(
        tap(search => {
          this.noResults = false;
          this.loading = search.length >= this.MIN_CHARACTER_BEFORE_SEARCH;
          this.searchInputIsEmpty = search.length === 0;
          this.cdr.detectChanges();
        }),
        filter(search => search.length >= this.MIN_CHARACTER_BEFORE_SEARCH),
        switchMap(search =>
          this.suggestService
            .getCitySuggestions(
              this.permission,
              search,
              this.partnerId,
              this.locationId,
              this.country,
              this.onlyIbetStations
            )
            .pipe(
              catchError(() => {
                this.loading = false;
                this.noResults = true;
                this.suggestions = [];
                this.cdr.detectChanges();
                return [];
              })
            )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(suggestions => {
        this.noResults = suggestions.length === 0;
        this.loading = false;
        this.suggestions = suggestions;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  onInput(input: string): void {
    const trimmedValue = input.trim();
    if (trimmedValue === '') {
      this.reset();
    }
    this.searchWithDebounce$.next(trimmedValue);
  }

  onFocus(input: string): void {
    if (input && !this.autoCompleteTrigger?.panelOpen) {
      const trimmedValue = input.trim();
      if (trimmedValue !== '') {
        this.directSearch$.next(trimmedValue);
      }
    }
  }

  onSelect(value: CitySuggestion): void {
    this.city.setValue(value.suggestion);
    this.emitChange(value.city, value.zipCode);
  }

  reset(emitChange = true): void {
    this.city.reset();
    this.suggestions = [];
    if (emitChange) this.emitChange(null, null);
  }

  private emitChange(city: string, zip: string) {
    this.selectionChange.emit({ city, zip });
  }

  private scrollEvent = (event: Event): void => {
    if (this.autoCompleteTrigger.panelOpen) this.autoCompleteTrigger.updatePosition();
  };
}
