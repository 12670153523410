import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconSize, IconWeight } from '@widgets/eop-icon';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { ConfirmDialogWithTitleIconTextModel } from '../dialog.model';

@Component({
  selector: 'eop-confirm-dialog-with-title-icon-text',
  templateUrl: './confirm-dialog-with-title-icon-text.component.html',
  styleUrls: ['./confirm-dialog-with-title-icon-text.component.scss'],
})
export class ConfirmDialogWithTitleIconText {
  readonly IconWeight = IconWeight;
  readonly IconSize = IconSize;
  readonly ButtonColor = ButtonColor;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogWithTitleIconText>,
    private sharedRefService: SharedRefService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogWithTitleIconTextModel
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
    this.sharedRefService.closeDialog();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    this.sharedRefService.closeDialog();
  }
}
