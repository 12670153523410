import { DOCUMENT, Location, PopStateEvent } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { Link } from '@content/wiki/data/wiki.model';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'eop-table-of-content',
  templateUrl: './table-of-content.component.html',
  styleUrls: ['./table-of-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableOfContentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input()
  headerLinks: Link[] = [];

  @Input()
  linkInFocusId: string;

  @ViewChild('acc', { static: true })
  acc: ElementRef;

  @ViewChildren(MatExpansionPanel)
  expPanels: QueryList<MatExpansionPanel>;

  locationSubscription: SubscriptionLike;
  accordionWidth: number;
  url: string;

  @HostListener('window:resize', ['$event.target'])
  onResize(e) {
    if (this.acc) {
      this.accordionWidth = this.acc.nativeElement.parentElement.clientWidth;
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private router: Router,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.url = this.router.url.split('#')[0];
    this.url = this.url.split('?')[0];
    this.handlePopstateEvent();

    this.renderer.listen(this.document.body, 'scroll', (event: Event) => {
      this.onWindowScroll();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.linkInFocusId && changes.linkInFocusId.currentValue) {
      this.onTocItemClick(changes.linkInFocusId.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    this.accordionWidth = this.acc.nativeElement.parentElement.clientWidth;
  }

  public ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

  onTocItemClick(linkId: string): void {
    this.headerLinks.forEach(link => {
      link.active = false;
      link.subLinks.forEach(li => {
        li.active = false;
        return li;
      });
      return link;
    });

    this.headerLinks.forEach(link => {
      if (link.id === linkId) {
        link.active = true;
      } else {
        link.active = false;
        link.subLinks.forEach(subLink => {
          subLink.active = subLink.id === linkId;
          return subLink;
        });
      }
      return link;
    });
  }

  onWindowScroll() {
    const scrollFromTop = this.document.body.scrollTop;
    if (scrollFromTop > 250) {
      this.acc.nativeElement.style.position = 'fixed';
      this.acc.nativeElement.style.top = '10px';
      this.acc.nativeElement.style.maxHeight = 'calc(100vh - 20px)';
    } else {
      this.acc.nativeElement.style.position = 'absolute';
      this.acc.nativeElement.style.top = 'unset';
      this.acc.nativeElement.style.maxHeight = `calc(100vh - ${270 - scrollFromTop}px)`;
    }
  }

  private handlePopstateEvent(): void {
    this.locationSubscription = this.location.subscribe((event: PopStateEvent) => {
      if (event.type === 'popstate') {
        const fragment = event.url.split('#')[1];
        this.onTocItemClick(fragment);
        let panelFound = this.searchExpansionPanel(fragment, this.expPanels);
        if (panelFound) {
          panelFound.open();
        } else {
          // Fragment is H2. Search the parent (H1)
          this.headerLinks.forEach(h1 => {
            const h2Found = h1.subLinks.find(h2 => {
              return h2.id === fragment;
            });
            if (h2Found) {
              panelFound = this.searchExpansionPanel(h1.id, this.expPanels);
              if (panelFound) {
                panelFound.open();
              }
            }
          });
        }
      }
    });
  }

  private searchExpansionPanel(
    id: string,
    panels: QueryList<MatExpansionPanel>
  ): MatExpansionPanel {
    return panels.find(elem => {
      return (
        elem['_viewContainerRef'].element.nativeElement.attributes.getNamedItem('fragid').value ===
        id
      );
    });
  }
}
