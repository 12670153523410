import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuardLogged } from 'app/app.access.guards.logged';
import { FaqComponent } from './wiki/components/faq/faq.component';
import { UserManualComponent } from './wiki/components/user-manual/user-manual.component';

const routes: Routes = [
  {
    path: 'userManual',
    component: UserManualComponent,
    canActivate: [AccessGuardLogged],
    data: {
      tabTitleKey: 'HEADER.USER_MANUAL',
    },
  },
  {
    path: 'userManualPublic',
    component: UserManualComponent,
    data: {
      tabTitleKey: 'HEADER.USER_MANUAL',
    },
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [AccessGuardLogged],
    data: {
      tabTitleKey: 'WIKI.FAQ',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}
