<div class="wizard-headline">
  <div class="headline-section">
    <eop-icon
      [identifier]="icon"
      [vaylensFont]="true"
      [weight]="IconWeight.LIGHT"
      [size]="IconSize.MONSTER"
      [color]="IconColor.NORMAL"
    ></eop-icon>
    <div class="body-bold-l">{{ headline ? headline : ('GLOBAL.WELCOME_WIZARD' | translate) }}</div>
    <div class="heading-m">{{ subline }}</div>
    <p *ngIf="mainText" class="text-body-l" [innerHtml]="mainText"></p>
    <ng-content *ngIf="!mainText"></ng-content>
  </div>
</div>
<div *ngIf="notes && notes.length > 0" class="wizard-info">
  <div class="info-section">
    <div class="sub-heading-m">
      {{ 'GLOBAL.PLEASE_CONSIDER_NOTES' | translate }}
    </div>
    <info-box
      *ngFor="let note of notes"
      [smallerText]="note.note ? note.note : note"
      [type]="note.type ? note.type : InfoBoxType.DEFAULT"
    ></info-box>
  </div>
</div>
