<div class="link-cell-wrapper">
  <!-- optional icon to display -->
  <div class="icon" *ngIf="icon && icon.identifier !== ''">
    <eop-icon
      [identifier]="icon.identifier"
      [weight]="icon.weight"
      [tooltip]="icon.tooltip"
      [vaylensFont]="icon.vaylensFont"
    ></eop-icon>
  </div>

  <!-- link if url is provided -->
  <a
    (click)="sendGoogleTrackingEvent()"
    [routerLink]="'/' + urlPart"
    [queryParams]="queryParams.queryParams"
    *ngIf="urlPart && !shouldTextCrop"
    class="dynamic-link highlighted-{{ highlightedColor }}"
    [matTooltip]="showTooltip ? text : undefined"
  >
    {{ text }}
  </a>

  <a
    (click)="sendGoogleTrackingEvent()"
    [routerLink]="'/' + urlPart"
    [queryParams]="queryParams.queryParams"
    *ngIf="urlPart && shouldTextCrop"
    class="dynamic-link highlighted-{{ highlightedColor }}"
    [eopDigitsCrop]="text"
    [matTooltip]="text"
  >
  </a>

  <!-- no url provided -->
  <span
    class="without-url"
    *ngIf="!urlPart && !shouldTextCrop"
    [matTooltip]="showTooltip ? text : undefined"
    >{{ text }}</span
  >

  <span
    class="without-url"
    *ngIf="!urlPart && shouldTextCrop"
    [eopDigitsCrop]="text"
    [matTooltip]="text"
    [matTooltipPositionAtOrigin]="true"
  ></span>
</div>
