import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSize } from '@widgets/eop-icon';

@Component({
  selector: 'eop-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputComponent {
  @Input()
  testId: string;

  @Input()
  value: string = '';

  passwordVisible = false;
  identifier = 'view-1';

  readonly IconSize = IconSize;

  toggleInputType() {
    this.passwordVisible = !this.passwordVisible;
    if (this.passwordVisible) {
      this.identifier = 'view-off';
    } else {
      this.identifier = 'view-1';
    }
  }

  hide(value: string): string {
    return '•'.repeat(value.length);
  }
}
