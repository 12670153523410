import { Injectable } from '@angular/core';
import LZString from 'lz-string';

@Injectable({
  providedIn: 'root',
})
export class CompressionService {
  compress(value: string): string {
    return LZString.compress(value);
  }

  decompress(value: string): string {
    return LZString.decompress(value);
  }
}
