import { EPermissions } from '@auth/login';

export interface Link {
  name: string;
  route: string;
  routeParams?: { [key: string]: string };
  testId: string;
  icon?: string;
  disabled?: EPermissions[];
  hidden?: EPermissions[];
  checkIfInternalRole?: boolean;
  openInBlank?: boolean;
}

export interface Section {
  name: string;
  route: string;
  testId: string;
  icon: string;
  hidden?: EPermissions[];
  links: Link[];
}
export type MenuPoints = {
  sections: Section[];
};
export const MENU_POINTS: MenuPoints = {
  sections: [
    {
      name: 'HEADER.MONITOR',
      route: '/monitor',
      testId: 'header-menu-monitor',
      icon: 'activity-outlined',
      links: [
        {
          route: '/monitor/dashboard',
          name: 'HEADER.DASHBOARD',
          testId: 'header-menu-monitor-dashboard',
          icon: 'activity-dashboard-outlined',
        },
        {
          route: '/monitor/chargingInfrastructure',
          routeParams: { activeTab: '0' },
          name: 'HEADER.CHARG_INFRASTR',
          testId: 'header-menu-monitor-charging-infrastructure',
          icon: 'eoperate-ic-charging-infrastructure',
          disabled: [EPermissions.VIEW_CHARGINGSTATION_MASTERDATA],
        },
        {
          route: '/monitor/chargeRecords',
          routeParams: { activeTab: '0' },
          name: 'HEADER.LOAD_DATA_CHARG_INF',
          testId: 'header-menu-monitor-charge-records',
          icon: 'eoperate-ic-charging-records',
          disabled: [EPermissions.VIEW_CHARGINGDATA_CPO, EPermissions.VIEW_CHARGINGDATA_EMP],
        },
        {
          route: '/monitor/quicksight',
          name: 'HEADER.QUICKSIGHT',
          testId: 'header-menu-monitor-quicksight',
          icon: 'eoperate-ic-graph',
          disabled: [EPermissions.VIEW_QUICKSIGHT_REPORTS],
        },
        {
          route: '/monitor/activationOverview',
          routeParams: { activeTab: '0' },
          name: 'HEADER.UNLOCK_MONITOR',
          testId: 'header-menu-monitor-activation-overview',
          icon: 'eoperate-ic-activity-overview',
          disabled: [EPermissions.VIEW_ACTIVATIONOVERVIEW],
          hidden: [EPermissions.VIEW_ACTIVATIONOVERVIEW],
        },
      ],
    },
    {
      name: 'HEADER.MANAGE',
      route: '/manage',
      testId: 'header-menu-manage',
      icon: 'eoperate-ic-manage ',
      hidden: [
        EPermissions.VIEW_OPENINGTIMES,
        EPermissions.VIEW_ALERTING_CLIENT,
        EPermissions.VIEW_LOADMANAGEMENT,
        EPermissions.VIEW_LOCAL_LOADMANAGEMENT,
        EPermissions.VIEW_PAYMENT_TERMINALS,
        EPermissions.VIEW_PROFILES,
        EPermissions.VIEW_AID,
        EPermissions.VIEW_EMAID_GROUPS,
        EPermissions.VIEW_CONTRACTS,
        EPermissions.VIEW_VOUCHER_CPO,
        EPermissions.VIEW_FIRMWARE,
      ],
      links: [
        {
          route: '/manage/openingHours',
          name: 'HEADER.MANAGE_OPENING_HOURS',
          testId: 'header-menu-manage-opening-hours',
          icon: 'eoperate-ic-opening-hours',
          disabled: [EPermissions.VIEW_OPENINGTIMES],
        },
        {
          route: '/manage/notifications',
          name: 'HEADER.NOTIFICATIONS',
          testId: 'header-menu-manage-notifications',
          icon: 'eoperate-ic-notifications',
          disabled: [EPermissions.VIEW_ALERTING_CLIENT],
        },
        {
          route: '/manage/loadManagement',
          routeParams: { activeTab: '0' },
          name: 'HEADER.LOAD_MANAGEMENT',
          testId: 'header-menu-manage-load-management',
          icon: 'eoperate-ic-load-management',
          disabled: [EPermissions.VIEW_LOADMANAGEMENT, EPermissions.VIEW_LOCAL_LOADMANAGEMENT],
        },
        {
          route: '/manage/ocppParameters',
          name: 'HEADER.OCPP_PARAMETERS',
          testId: 'header-menu-manage-ocpp-parameters',
          icon: 'ic-ocpp-parameters-outlined',
          hidden: [EPermissions.VIEW_OCPP_MASS_CONFIGURATION],
        },
        {
          route: '/manage/terminalManagement',
          name: 'HEADER.TERMINAL_MANAGEMENT',
          testId: 'header-menu-manage-terminal-management',
          icon: 'eoperate-ic-payment-terminal',
          disabled: [EPermissions.VIEW_PAYMENT_TERMINALS],
        },
        {
          hidden: [EPermissions.VIEW_PROFILES],
          route: '/manage/basicProfiles',
          name: 'HEADER.BASIC_PROFILES',
          testId: 'header-menu-manage-basic-profiles',
          icon: 'eoperate-ic-profiles',
        },
        {
          route: '/manage/accessControl',
          routeParams: { activeTab: '0' },
          name: 'HEADER.ACCESS_CONTROL',
          testId: 'header-menu-manage-access-control',
          icon: 'eoperate-ic-access-control',
          disabled: [EPermissions.VIEW_AID, EPermissions.VIEW_EMAID_GROUPS],
        },
        {
          route: '/manage/contracts',
          name: 'HEADER.CONTRACTS',
          testId: 'header-menu-manage-contracts',
          icon: 'eoperate-ic-emaid-rfid-management',
          disabled: [EPermissions.VIEW_CONTRACTS],
        },
        {
          route: '/manage/voucher',
          name: 'HEADER.VOUCHER',
          testId: 'header-menu-manage-voucher',
          icon: 'eoperate-ic-voucher-management',
          disabled: [EPermissions.VIEW_VOUCHER_CPO],
        },
        {
          hidden: [EPermissions.VIEW_FIRMWARE],
          route: '/manage/firmwareRollout',
          name: 'HEADER.FIRMWARE',
          testId: 'header-menu-manage-firmware',
          icon: 'eoperate-ic-firmware',
        },
      ],
    },
    {
      name: 'HEADER.PAY',
      route: '/pay',
      testId: 'header-menu-pay',
      icon: 'eoperate-ic-payments',
      hidden: [
        EPermissions.VIEW_CPO_TARIFFS,
        EPermissions.VIEW_CPO_TARIFF_ASSIGNMENT,
        EPermissions.VIEW_EMP_TARIFFS,
        EPermissions.VIEW_EMP_TARIFF_ASSIGNMENT,
        EPermissions.VIEW_DIRECT_PAYMENT_SETTINGS,
        EPermissions.VIEW_INCREASE_REVENUE,
      ],
      links: [
        {
          route: '/pay/pricingset',
          name: 'HEADER.PRICING_SETS_NEW',
          testId: 'header-menu-pay-pricing-sets',
          icon: 'eoperate-ic-manage-cpo-tariffs',
          hidden: [EPermissions.VIEW_CPO_TARIFFS],
        },
        {
          route: '/pay/chargepointAssignments',
          routeParams: { activeTab: '0' },
          name: 'HEADER.PRICE_CHARGE_ASSIGN_NEW',
          testId: 'header-menu-pay-chargepoint-assignment',
          icon: 'eoperate-ic-manage-cpo-tariffs-1',
          hidden: [EPermissions.VIEW_CPO_TARIFF_ASSIGNMENT],
        },
        {
          route: '/pay/subscription',
          name: 'HEADER.MANAGE_EV_TARIFFS',
          testId: 'header-menu-pay-subscription',
          icon: 'eoperate-ic-manage-ev-tariffs',
          hidden: [EPermissions.VIEW_EMP_TARIFFS],
        },
        {
          route: '/pay/empAssignments',
          routeParams: { activeTab: '0' },
          name: 'HEADER.ASSIGN_EV_TARIFFS',
          testId: 'header-menu-pay-emp-assignment',
          icon: 'eoperate-ic-assign-ev-tariffs',
          hidden: [EPermissions.VIEW_EMP_TARIFF_ASSIGNMENT],
        },
        {
          route: '/pay/mdp',
          name: 'HEADER.MDP',
          testId: 'header-menu-pay-mdp',
          icon: 'eoperate-ic-direct-payment',
          disabled: [EPermissions.VIEW_DIRECT_PAYMENT_SETTINGS],
        },
        {
          route: '/pay/increaseRevenue',
          name: 'HEADER.INCREASE_REVENUE',
          testId: 'header-menu-pay-increase-revenue',
          icon: 'eoperate-ic-increase-revenue',
          disabled: [EPermissions.VIEW_INCREASE_REVENUE],
        },
      ],
    },
    {
      name: 'HEADER.CLEARING_HOUSE',
      route: '/clearing',
      testId: 'header-menu-clearing',
      icon: 'eoperate-ic-clearing-house',
      hidden: [
        EPermissions.VIEW_CLH_TARIFFS,
        EPermissions.VIEW_ROAMING_OFFERS,
        EPermissions.VIEW_BDR,
        EPermissions.MANAGE_BDR_CORRECTION,
      ],
      links: [
        {
          route: '/clearing/clearinghouse-tariffs',
          name: 'HEADER.MANAGE_CLEARINGHOUSE_TARIFFS',
          testId: 'header-menu-clearing-clearinghouse-tariffs',
          icon: 'eoperate-ic-clearing-house',
          hidden: [EPermissions.VIEW_CLH_TARIFFS],
        },
        {
          route: '/clearing/roaming-offers',
          name: 'HEADER.MANAGE_ROAMING_OFFERS',
          testId: 'header-menu-clearing-roaming-offers',
          icon: 'eoperate-ic-manage-roaming-offers',
          hidden: [EPermissions.VIEW_ROAMING_OFFERS],
        },
        {
          route: '/clearing/bdr-management',
          name: 'HEADER.BDR_MANAGEMENT',
          testId: 'header-menu-clearing-bdr-management',
          icon: 'eoperate-ic-bdr-management',
          disabled: [EPermissions.VIEW_BDR, EPermissions.MANAGE_BDR_CORRECTION],
        },
      ],
    },
    {
      name: 'HEADER.PORTAL',
      route: '/portal',
      testId: 'header-menu-portal',
      icon: 'eoperate-ic-portal',
      hidden: [
        EPermissions.VIEW_CLIENT_USER_MANAGEMENT,
        EPermissions.VIEW_GLOBAL_USER_MANAGEMENT,
        EPermissions.VIEW_LOCATION_MANAGEMENT,
        EPermissions.MANAGE_CLIENT_USER_MANAGEMENT,
        EPermissions.MANAGE_GLOBAL_USER_MANAGEMENT,
        EPermissions.MANAGE_LOGIN_MESSAGES,
        EPermissions.MANAGE_POI_SERVICE_PLATFORM_PROV,
        EPermissions.VIEW_STATIONMANAGEMENT_MASTERDATA,
        EPermissions.VIEW_ROAMING_RELATIONSHIPS,
        EPermissions.MANAGE_ROAMING_RELATIONSHIPS,
      ],
      links: [
        {
          route: '/portal/portalUsers',
          routeParams: { activeTab: '0' },
          name: 'HEADER.USER_MGT',
          testId: 'header-menu-portal-user-management',
          icon: 'eoperate-ic-portal-user',
          disabled: [
            EPermissions.VIEW_CLIENT_USER_MANAGEMENT,
            EPermissions.VIEW_GLOBAL_USER_MANAGEMENT,
          ],
        },
        {
          route: '/portal/locations',
          name: 'HEADER.LOCATION_MGT',
          testId: 'header-menu-portal-locations',
          icon: 'eoperate-ic-location-management',
          disabled: [EPermissions.VIEW_LOCATION_MANAGEMENT],
        },
        {
          checkIfInternalRole: true,
          route: '/portal/clientAccountManagement',
          name: 'HEADER.CLIENT_ACCOUNT_MANAG',
          testId: 'header-menu-portal-client-accounts',
          icon: 'eoperate-ic-client-account-management',
          disabled: [
            EPermissions.MANAGE_CLIENT_USER_MANAGEMENT,
            EPermissions.MANAGE_GLOBAL_USER_MANAGEMENT,
          ],
        },
        {
          checkIfInternalRole: true,
          route: '/portal/loginMessages',
          name: 'HEADER.LOGIN_MESSAGES',
          testId: 'header-menu-portal-login-messages',
          icon: 'eoperate-ic-login-messages',
          disabled: [EPermissions.MANAGE_LOGIN_MESSAGES],
        },
        {
          checkIfInternalRole: true,
          route: '/portal/webservices',
          name: 'HEADER.WEBSERVICES',
          testId: 'header-menu-portal-webservices',
          icon: 'eoperate-ic-webservice-feed-endpoints',
          disabled: [EPermissions.MANAGE_POI_SERVICE_PLATFORM_PROV],
        },
        {
          checkIfInternalRole: true,
          route: '/portal/stationManagement',
          name: 'HEADER.STATION_MANAGEMENT',
          testId: 'header-menu-portal-station-management',
          icon: 'eoperate-ic-station-management',
          disabled: [EPermissions.VIEW_STATIONMANAGEMENT_MASTERDATA],
        },
        {
          checkIfInternalRole: true,
          route: '/portal/roamingRelationships',
          routeParams: { activeTab: '0' },
          name: 'HEADER.ROAMING_RELATIONSHIPS',
          testId: 'header-menu-portal-roaming-relationships',
          icon: 'eoperate-ic-roaming-relations',
          disabled: [
            EPermissions.VIEW_ROAMING_RELATIONSHIPS,
            EPermissions.MANAGE_ROAMING_RELATIONSHIPS,
          ],
        },
      ],
    },
    {
      name: 'HEADER.BUSINESS_PARTNER',
      route: '/businessPartner',
      testId: 'header-menu-business-partner',
      icon: 'eoperate-ic-business-partner',
      hidden: [EPermissions.VIEW_SUB_BUSINESS_PARTNER, EPermissions.VIEW_PRODUCT_ASSIGNMENT],
      links: [
        {
          route: '/businessPartner/subBusinessPartner',
          name: 'HEADER.MANAGE_BUSINESS_PARTNER',
          testId: 'header-menu-business-partner-manage-partners',
          icon: 'eoperate-ic-business-partner-management',
          disabled: [EPermissions.VIEW_SUB_BUSINESS_PARTNER],
        },
        {
          route: '/businessPartner/contractedProducts',
          name: 'HEADER.PRODUCT_ASSIGNMENT',
          testId: 'header-menu-business-partner-product-assignment',
          icon: 'eoperate-ic-assignment',
          disabled: [EPermissions.VIEW_PRODUCT_ASSIGNMENT],
        },
        {
          route: '/businessPartner/productVoucher',
          name: 'HEADER.PRODUCT_VOUCHER',
          testId: 'header-menu-business-partner-product-voucher',
          icon: 'eoperate-ic-voucher-management',
          hidden: [EPermissions.MANAGE_VOUCHERS],
        },
      ],
    },
    {
      name: 'HEADER.USER_CHARGING',
      route: '/charging',
      testId: 'header-menu-user-charging',
      icon: 'eoperate-ic-user-charging',
      hidden: [
        EPermissions.VIEW_USERCHARGING_CONFIGURATION,
        EPermissions.VIEW_CPO_USERCHARGING_TARIFFS,
        EPermissions.VIEW_INVITED_USERCHARGING_EVDRIVER,
      ],
      links: [
        {
          route: '/charging/configuration',
          name: 'HEADER.CONFIGURATION',
          testId: 'header-menu-user-charging-configuration',
          icon: 'eoperate-ic-configuration',
          disabled: [EPermissions.VIEW_USERCHARGING_CONFIGURATION],
        },
        {
          route: '/charging/pricing',
          name: 'HEADER.PRICING',
          testId: 'header-menu-user-charging-pricing',
          icon: 'eoperate-ic-uc-pricing',
          disabled: [EPermissions.VIEW_CPO_USERCHARGING_TARIFFS],
        },
        {
          route: '/charging/evDriver',
          name: 'HEADER.EV_DRIVER',
          testId: 'header-menu-user-charging-ev-driver',
          icon: 'eoperate-ic-ev-driver',
          hidden: [EPermissions.VIEW_INVITED_USERCHARGING_EVDRIVER],
        },
      ],
    },
    {
      name: 'HEADER.HELP',
      route: '/help',
      testId: 'header-menu-help',
      icon: 'help-outlined1',
      links: [
        {
          route: '/content/userManual',
          name: 'WIKI.TITLE',
          testId: 'header-menu-help-user-manual',
          icon: 'eoperate-ic-manual',
        },
        {
          route: '/content/faq',
          name: 'WIKI.FAQ',
          testId: 'header-menu-help-faq',
          icon: 'eoperate-ic-faq',
        },
      ],
    },
  ],
};
