<ng-container>
  <span class="multi-icons-wrapper">
    <span class="multiple-icon-cell" *ngFor="let icon of icons">
      <eop-icon
        [identifier]="icon.identifier"
        [color]="icon.color"
        [size]="icon.size"
        [weight]="icon.weight"
        [tooltip]="icon.tooltip ? icon.tooltip : undefined"
        [vaylensFont]="icon.vaylensFont"
      ></eop-icon>
      {{ icon.text }}
    </span>
  </span>
</ng-container>
