import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';

@Component({
  selector: 'eop-mini-button',
  templateUrl: './mini-button.component.html',
  styleUrls: ['./mini-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniButtonComponent {
  readonly IconSize = IconSize;
  readonly IconWeight = IconWeight;

  @Input() testId: string;
  @Input() iconName: string;
  @Input() color: IconColor;
}
