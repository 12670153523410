import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { EopButtonComponent } from './eop-button.component';

@NgModule({
  imports: [CommonModule, EopIconModule, MatTooltipModule],
  declarations: [EopButtonComponent],
  exports: [EopButtonComponent],
})
export class EopButtonModule {}
