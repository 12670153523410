import { UserFilterOptions } from '@shared/store/data/user-filter-options.model';
import { UserFilterOptionsActionTypes } from '../actions';
import { IActionWithPayload } from '../models';

export function userFilterOptionsReducer(
  state: UserFilterOptions = {},
  action: IActionWithPayload<UserFilterOptions>
) {
  switch (action.type) {
    case UserFilterOptionsActionTypes.SAVE_USER_FILTER_OPTIONS:
      return Object.assign({}, state, {
        ...action.payload,
      });
    case UserFilterOptionsActionTypes.RESET_USER_FILTER_OPTIONS:
      return Object.assign({});
    default:
      return state;
  }
}
