import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { InfoBoxType } from '@widgets/info-box/info-box.component';

@Component({
  selector: 'eop-wizard-first-page',
  templateUrl: './wizard-first-page.component.html',
  styleUrls: ['./wizard-first-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardFirstPageComponent {
  IconWeight = IconWeight;
  IconSize = IconSize;
  IconColor = IconColor;
  InfoBoxType = InfoBoxType;
  @Input() icon: string = 'share-outlined';
  @Input() headline: string;
  @Input() subline: string;
  @Input() mainText: string;
  @Input() notes: string[] | { note: string; type: InfoBoxType }[];
}
