import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  GroupAssignment,
  GroupType,
  LocationAssignment,
  ProductAssignment,
  StationsWithLocations,
} from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';
import { UserDatePipe } from '@shared/pipes';
import { AidNamePipe } from '@shared/pipes/aid-name.pipe';
import { Icon } from '@widgets/eop-icon';
import { ImagePlaceholderConfiguration } from '@widgets/eop-image-placeholder/eop-image-placeholder.interface';
import { ITableConfig, ITableData, IconCellData, LinkCellData } from '@widgets/innogy-data-table';

export interface ProductAssignmentInTable {
  name: string;
  scope: string;
}

export interface GroupAssignmentInTable {
  groupId: string;
  name: LinkCellData;
  area: GroupType;
  icon: IconCellData[];
  partnerId: string;
  scope: string;
  members: number;
}

export interface LocationAssignmentInTable {
  name: LinkCellData;
  description: string;
  contact: string;
  hotline: string;
  chargingStationCount: number;
}

export enum ProductScopeEnum {
  BUSINESS_PARTNER_PRODUCT = 'BUSINESS_PARTNER_PRODUCT',
  CHARGING_STATION_PRODUCT = 'CHARGING_STATION_PRODUCT',
}

@Component({
  selector: 'eop-charging-station-assignments-tables',
  templateUrl: './charging-station-assignments-tables.component.html',
  styleUrls: ['./charging-station-assignments-tables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChargingStationAssignmentsTablesComponent implements OnInit {
  @Input()
  set productAssignments(assignments: ProductAssignment[]) {
    this.productAssignmentsPrivate = assignments
      ? assignments.map(assignment => {
          return {
            ...assignment,
            scope: this.translate.instant('CHARG_INF.' + assignment.productScope),
            startDate: assignment.startDate
              ? this.userDatePipe.transform(assignment.startDate)
              : this.translate.instant('GLOBAL.NOT_AVAILABLE_SHORT'),
          };
        })
      : [];
    this.updateProductTable();
  }

  @Input()
  set groupAssignments(assignments: GroupAssignment[]) {
    this.groupAssignmentsPrivate = assignments
      ? assignments.map(assignment => {
          return {
            groupId: assignment.groupId,
            name: this.getGroupNameCell(assignment),
            area: assignment.type,
            icon: this.groupTypeToIconCellData(assignment.type),
            partnerId: assignment.businessPartnerId,
            members: assignment.groupDetailsAllowed ? assignment.members : null,
            scope: this.convertStringArrayToCommaSeparatedString(assignment.scope),
          };
        })
      : [];
    this.updateGroupTable();
  }

  @Input()
  set locationAssignments(assignments: StationsWithLocations) {
    this.locationAssignmentsPrivate =
      assignments && assignments.locations
        ? assignments.locations.map((assignment: LocationAssignment) => {
            return {
              name: {
                text: assignment.name,
                url: assignments.groupDetailsAllowed
                  ? 'portal/locations?cpoId=' + assignments.cpoId + '&groupId=' + assignment.uuid
                  : null,
                showTooltip: true,
              },
              description: assignment.description,
              contact: assignment.contact,
              hotline: assignment.hotline,
              chargingStationCount: assignment.chargingStationCount,
            };
          })
        : [];
    this.updateLocationTable();
  }

  tableConfigLocationAssignments: ITableConfig;
  tableDataLocationAssignments: ITableData;
  tableContentPlaceholderLocationAssignments: ImagePlaceholderConfiguration;

  tableConfigProductAssignments: ITableConfig;
  tableDataProductAssignments: ITableData;
  tableContentPlaceholderProductAssignments: ImagePlaceholderConfiguration;

  tableConfigGroupAssignments: ITableConfig;
  tableDataGroupAssignments: ITableData;
  tableContentPlaceholderGroupAssignments: ImagePlaceholderConfiguration;
  private productAssignmentsPrivate: ProductAssignmentInTable[];
  private groupAssignmentsPrivate: GroupAssignmentInTable[];
  private locationAssignmentsPrivate: LocationAssignmentInTable[];

  constructor(
    private translate: TranslateService,
    private aidNamePipe: AidNamePipe,
    private userDatePipe: UserDatePipe
  ) {}

  ngOnInit() {
    this.initTables();
  }

  private initTables() {
    const tableSetting = {
      expandable: false,
      sortable: false,
      searchable: false,
      deletable: false,
      nextDataSelection: 'none',
      checkable: false,
      showChipList: false,
    };
    const nameColumn = {
      id: 'name',
      value: this.translate.instant('GLOBAL.NAME'),
      visibleInHeader: true,
      visibleInDetails: false,
      type: 'text',
      sortable: false,
      showToolTip: true,
    };
    const groupNameColumn = {
      ...nameColumn,
      type: 'link',
    };
    const scopeColumn = {
      ...nameColumn,
      id: 'scope',
      value: this.translate.instant('CHARG_INF_DETAIL.SCOPE'),
    };
    const startDateColumn = {
      ...nameColumn,
      id: 'startDate',
      value: this.translate.instant('GLOBAL.START_DATE'),
    };
    const areaIconColumn = {
      ...nameColumn,
      id: 'icon',
      type: 'icon',
      value: this.translate.instant('CHARG_INF_DETAIL.AREA'),
    };
    const groupMembersColumn = {
      ...nameColumn,
      id: 'members',
      value: this.translate.instant('CHARG_INF_DETAIL.GROUP_MEMBERS'),
    };
    const nameLocationColumn = {
      ...nameColumn,
      type: 'link',
    };
    const descriptionColumn = {
      ...nameColumn,
      id: 'description',
      value: this.translate.instant('GLOBAL.DESCRIPTION'),
    };
    const contactColumn = {
      ...nameColumn,
      id: 'contact',
      value: this.translate.instant('GLOBAL.CONTACT'),
    };
    const hotlineColumn = {
      ...nameColumn,
      id: 'hotline',
      value: this.translate.instant('GLOBAL.HOTLINE'),
    };
    const chargingStationCountColumn = {
      ...nameColumn,
      id: 'chargingStationCount',
      value: this.translate.instant('GLOBAL.CHARGING_STATION_COUNT'),
    };

    this.tableConfigProductAssignments = {
      tableSettings: tableSetting,
      columns: [nameColumn, scopeColumn, startDateColumn],
    };
    this.tableConfigGroupAssignments = {
      tableSettings: tableSetting,
      columns: [groupNameColumn, areaIconColumn, groupMembersColumn, scopeColumn],
    };
    this.tableConfigLocationAssignments = {
      tableSettings: tableSetting,
      columns: [
        nameLocationColumn,
        descriptionColumn,
        contactColumn,
        hotlineColumn,
        chargingStationCountColumn,
      ],
    };
  }

  private updateProductTable() {
    const productTableData: ITableData = this.convertToProductTableData(
      this.productAssignmentsPrivate
    );
    this.tableDataProductAssignments = {
      paging: {
        ...productTableData.paging,
        totalItems: productTableData.paging.totalItems,
        itemsPerPage: productTableData.paging.itemsPerPage,
      },
      data: [...productTableData.data],
    };
    this.tableContentPlaceholderProductAssignments =
      this.productAssignmentsPrivate.length > 0
        ? undefined
        : {
            image: 'assets/img/a-placeholder-table.svg',
            text: this.translate.instant('CHARG_INF.PRODUCT_ASSIGNMENTS_PLACEHOLDER_TEXT'),
          };
  }

  private updateGroupTable() {
    const groupTableData: ITableData = this.convertToGroupTableData(this.groupAssignmentsPrivate);

    this.tableDataGroupAssignments = {
      paging: {
        ...groupTableData.paging,
        totalItems: groupTableData.paging.totalItems,
        itemsPerPage: groupTableData.paging.itemsPerPage,
      },
      data: [...groupTableData.data],
    };
    this.tableContentPlaceholderGroupAssignments =
      groupTableData && groupTableData.data && groupTableData.data.length > 0
        ? undefined
        : {
            image: 'assets/img/a-placeholder-table.svg',
            text: this.translate.instant('CHARG_INF.GROUP_ASSIGNMENTS_PLACEHOLDER_TEXT'),
          };
  }

  private updateLocationTable() {
    const locationTableData: ITableData = this.convertToLocationTableData(
      this.locationAssignmentsPrivate
    );

    this.tableDataLocationAssignments = {
      paging: {
        ...locationTableData.paging,
        totalItems: locationTableData.paging.totalItems,
        itemsPerPage: locationTableData.paging.itemsPerPage,
      },
      data: [...locationTableData.data],
    };
    this.tableContentPlaceholderLocationAssignments =
      locationTableData && locationTableData.data && locationTableData.data.length > 0
        ? undefined
        : {
            image: 'assets/img/a-placeholder-table.svg',
            text: this.translate.instant('CHARG_INF.LOCATION_ASSIGNMENTS_PLACEHOLDER_TEXT'),
          };
  }

  convertToProductTableData(productAssignmentsInTable: ProductAssignmentInTable[]): ITableData {
    const emptyTableData: ITableData = {
      paging: {
        currentPage: 0,
        totalItems: 0,
        itemsPerPage: 0,
        totalPages: 1,
      },
      data: [],
    };
    if (productAssignmentsInTable)
      return {
        ...emptyTableData,
        paging: {
          ...emptyTableData.paging,
          itemsPerPage: productAssignmentsInTable.length,
          totalItems: productAssignmentsInTable.length,
        },
        data: productAssignmentsInTable,
      };
    else return emptyTableData;
  }

  convertToGroupTableData(groupAssignmentInTables: GroupAssignmentInTable[]): ITableData {
    const emptyTableData: ITableData = {
      paging: {
        currentPage: 0,
        totalItems: 0,
        itemsPerPage: 0,
        totalPages: 1,
      },
      data: [],
    };
    if (groupAssignmentInTables)
      return {
        ...emptyTableData,
        paging: {
          ...emptyTableData.paging,
          itemsPerPage: groupAssignmentInTables.length,
          totalItems: groupAssignmentInTables.length,
        },
        data: groupAssignmentInTables,
      };
    else return emptyTableData;
  }

  convertToLocationTableData(locationAssignmentInTables: LocationAssignmentInTable[]): ITableData {
    const emptyTableData: ITableData = {
      paging: {
        currentPage: 0,
        totalItems: 0,
        itemsPerPage: 0,
        totalPages: 1,
      },
      data: [],
    };
    if (locationAssignmentInTables)
      return {
        ...emptyTableData,
        paging: {
          ...emptyTableData.paging,
          itemsPerPage: locationAssignmentInTables.length,
          totalItems: locationAssignmentInTables.length,
        },
        data: locationAssignmentInTables,
      };
    else return emptyTableData;
  }

  groupTypeToIconCellData(groupType: GroupType): IconCellData[] {
    const translatedType = this.translate.instant('CHARG_INF_DETAIL.' + groupType);
    const icon: Icon = {
      identifier: this.groupTypeToIconName(groupType),
      tooltip: translatedType,
    };
    return [
      {
        ...icon,
        text: translatedType,
      },
    ];
  }

  groupTypeToIconName(groupType: GroupType): string {
    switch (groupType) {
      case GroupType.OPENING_HOUR_GROUP:
        return 'time-clock-circle';
      case GroupType.NOTIFICATION_GROUP:
        return 'alert-triangle';
      case GroupType.CHARGEPORT_GROUP:
        return 'network-settings';
      case GroupType.LOAD_MANAGEMENT_GROUP:
        return 'gauge-dashboard-1';
    }
  }

  private getGroupNameCell(group: GroupAssignment): LinkCellData {
    return {
      text: this.buildGroupName(group),
      url: this.buildGroupUrl(group),
      showTooltip: true,
    };
  }

  buildGroupName(group: GroupAssignment): string {
    switch (group.type) {
      case GroupType.CHARGEPORT_GROUP:
        return this.aidNamePipe.transform(group);
      default:
        return group.groupName;
    }
  }

  buildGroupUrl(group: GroupAssignment): string {
    if (group.groupDetailsAllowed !== true) {
      return '';
    }
    const query = `?partnerId=${group.businessPartnerId}&groupId=${group.groupId}`;
    switch (group.type) {
      case GroupType.OPENING_HOUR_GROUP:
        return 'manage/openingHours' + query;
      case GroupType.NOTIFICATION_GROUP:
        return 'manage/notifications' + query;
      case GroupType.CHARGEPORT_GROUP:
        return 'manage/accessControl' + query;
      case GroupType.LOAD_MANAGEMENT_GROUP:
        return 'manage/loadManagement' + query + '&activeTab=0';
    }
  }

  convertStringArrayToCommaSeparatedString(scope: string[]): string {
    return scope.join(', ');
  }
}
