import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IActionWithPayload, IAppState } from '../../../shared/store/models';
import { FilterSet, RestrictionsSearchRequest } from '../data/load-management.model';

export enum LoadmgtActionTypes {
  SelectDynamicLmPartner = '[Loadmgt] SelectDynamicLmPartner',
  SelectLlmPartner = '[Loadmgt] SelectLlmPartner',
  SelectLlmLocation = '[Loadmgt] SelectLlmLocation',
  UpdateConfiguration = '[Loadmgt] UpdateConfiguration',
  CreateConfiguration = '[Loadmgt] CreateConfiguration',
  GroupShowClick = '[Loadmgt] GroupShowClick',
  GroupModalClosed = '[Loadmgt] GroupModalClosed',
  GroupClickForMonitor = '[Loadmgt] GroupClickForMonitor',
  DestroyComponent = '[Loadmgt] DestroyComponent',
  DestroyConfigurationComponent = '[Loadmgt] DestroyConfigurationComponent',
  DestroyMonitorComponent = '[Loadmgt] DestroyMonitorComponent',
  CancelConfigurationClick = '[Loadmgt] CancelConfigurationClick',
  SubmitConfigurationClick = '[Loadmgt] SubmitConfigurationClick',
  ResetNotification = '[Loadmgt] ResetNotification',
  GroupDeleteClick = '[Loadmgt] GroupDeleteClick',
  TabSelect = '[Loadmgt] TabSelect',
  SessionTableRowExpanded = '[Loadmgt] SessionTableRowExpanded',
  RefreshButtonClick = '[Loadmgt] RefreshButtonClick',
  TriggerOptimisationButtonClick = '[Loadmgt] TriggerOptimisationButtonClick',
  TriggerOptimisationSuccess = '[API] TriggerOptimisationSuccess',
  TriggerOptimisationFailure = '[API] TriggerOptimisationFailure',
  DeleteGroupSuccess = '[API] DeleteGroupSuccess',
  DeleteGroupFailure = '[API] DeleteGroupFailure',
  FetchLoadMgtConfigSuccess = '[API] FetchLoadMgtConfigSuccess',
  FetchLoadMgtConfigFailure = '[API] FetchLoadMgtConfigFailure',
  UpdateLoadMgtConfigSuccess = '[API] UpdateLoadMgtConfigSuccess',
  UpdateLoadMgtConfigFailure = '[API] UpdateLoadMgtConfigFailure',
  FetchLoadMonitorDataSuccess = '[API] FetchLoadMonitorDataSuccess',
  FetchLoadMonitorDataFailure = '[API] FetchLoadMonitorDataFailure',
  FetchRestrictionsMonitorData = '[API] FetchRestrictionsMonitorData',
  FetchRestrictionsMonitorDataSuccess = '[API] FetchRestrictionsMonitorDataSuccess',
  FetchRestrictionsMonitorDataFailure = '[API] FetchRestrictionsMonitorDataFailure',
  UpdateSearchRequestForRestrictionMonitor = '[API] UpdateSearchRequestForRestrictionMonitor',
  UpdateFilterSetNextPageOfRestrictionsMonitor = '[API] UpdateFilterSetNextPageOfRestrictionsMonitor',
  FetchSessionMonitorDataSuccess = '[API] FetchSessionMonitorDataSuccess',
  FetchSessionMonitorDataFailure = '[API] FetchSessionMonitorDataFailure',
  FetchLocalLmPartnersSuccess = '[API] FetchLocalLmPartnersSuccess',
  FetchLocalLmPartnersFailure = '[API] FetchLocalLmPartnersFailure',
  FetchDynamicLmPartnersSuccess = '[API] FetchDynamicLmPartnersSuccess',
  FetchDynamicLmPartnersFailure = '[API] FetchDynamicLmPartnersFailure',
  FetchDchConfigurationsSuccess = '[API] FetchDchConfigurationsSuccess',
  FetchDchConfigurationsFailure = '[API] FetchDchConfigurationsFailure',
  FetchCpGroupsSuccess = '[API] FetchCpGroupsSuccess',
  FetchCpGroupsFailure = '[API] FetchCpGroupsFailure',
  ExportRestrictionsAsCsv = '[API] ExportRestrictionsAsCsv',
  ExportRestrictionsAsCsvFinished = '[API] ExportRestrictionsAsCsvFinished',
  FetchLlmStations = '[API] FetchLlmStations',
  FetchLlmStationsSuccess = '[API] FetchLlmStationsSuccess',
  FetchLlmStationsFailure = '[API] FetchLlmStationsFailure',
}

@Injectable()
export class LoadmgtActions {
  constructor(private store: NgRedux<IAppState>) {}

  public static createFetchRestrictionsMonitorDataAction = (): IActionWithPayload<FilterSet> => ({
    type: LoadmgtActionTypes.FetchRestrictionsMonitorData,
  });

  public static createUpdateFilterSetNextPageAction = (): IActionWithPayload<FilterSet> => ({
    type: LoadmgtActionTypes.UpdateFilterSetNextPageOfRestrictionsMonitor,
  });

  public static createUpdateSearchRequestAction = (
    payload: RestrictionsSearchRequest
  ): IActionWithPayload<RestrictionsSearchRequest> => ({
    type: LoadmgtActionTypes.UpdateSearchRequestForRestrictionMonitor,
    payload: payload,
  });

  public static exportRestrictionsAsCsv = (): IActionWithPayload<RestrictionsSearchRequest> => ({
    type: LoadmgtActionTypes.ExportRestrictionsAsCsv,
  });

  public dispatch(action: LoadmgtActionTypes, payload?: any) {
    const type = action;
    return this.store.dispatch({
      type,
      payload,
    });
  }
}
