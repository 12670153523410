import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetModule } from '@widgets/widgets.module';
import { LoginNotificationHeaderComponent } from './components/login-notification-header/login-notification-header.component';
import { LoginNotificationComponent } from './components/login-notification/login-notification.component';
import { SingleLoginNotificationComponent } from './components/single-login-notification/single-login-notification.component';
import { ShowNotificationComponent } from './show-notification.component';

@NgModule({
  declarations: [
    ShowNotificationComponent,
    LoginNotificationComponent,
    SingleLoginNotificationComponent,
    LoginNotificationHeaderComponent,
  ],
  imports: [CommonModule, WidgetModule, TranslateModule],
  exports: [ShowNotificationComponent],
})
export class ShowNotificationModule {}
