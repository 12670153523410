import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSize, IconWeight } from '@widgets/eop-icon';

export enum HighLightedColor {
  NONE = 'none',
  INFO = 'info',
  WARNING = 'warning',
}

@Component({
  selector: 'eop-quick-access-entry',
  templateUrl: './quick-access-entry.component.html',
  styleUrls: ['./quick-access-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickAccessEntryComponent {
  @Input()
  label: string;

  @Input()
  iconIdentifier: string;

  @Input()
  iconWeight: IconWeight;

  @Input()
  isSelected: boolean;

  @Input()
  disabled: boolean;

  @Input()
  highlightedColor: HighLightedColor = HighLightedColor.NONE;

  @Input()
  shouldLabelCrop = false;

  readonly IconSize = IconSize;
}
