import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconWeight } from '@widgets/eop-icon';

@Component({
  selector: 'eop-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollToTopComponent implements OnInit {
  tooltip = '';
  windowScrolled = false;
  private MAX_SCROLLHEIGHT = 600;
  readonly IconWeight = IconWeight;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tooltip = this.translate.instant('GLOBAL.SCROLL_TO_TOP');
    this.renderer.listen(this.document.body, 'scroll', (event: Event) => {
      this.onWindowScroll();
    });
  }

  scrollToTop(): void {
    this.document.body.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  onWindowScroll() {
    if (this.document.body.scrollTop > this.MAX_SCROLLHEIGHT) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
    this.cd.markForCheck();
  }
}
