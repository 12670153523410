/**
 * @internal
 *
 * Enum for better typesafety
 */
export enum KeyEventEnum {
  ENTER = 'Enter',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  DOWN = 'Down',
  UP = 'Up',
}
