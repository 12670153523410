import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { TopNotificationService } from '../notification';

export interface AppVersion {
  build: string;
  configuration: string;
  revision: string;
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  isProduction = false;

  private previousVisitedUrl: string;
  private currentAppVersion: string;
  private currentBuildId: string;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private topNotificationService: TopNotificationService,
    private translateService: TranslateService
  ) {
    this.isProduction = environment.production;
  }

  listenToRouteChanges() {
    this.router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('?')[0];
        if (this.previousVisitedUrl !== url) {
          this.previousVisitedUrl = url;
          this.httpClient
            .get<AppVersion>('/app-version.json')
            .pipe(take(1))
            .subscribe(response => {
              if (!response || !response.version) return;
              const appVersion = this.getAppVersionWithoutPatchLevel(response.version);
              const hint = this.translateService.instant('GLOBAL.NEW_VERSION_HINT');
              this.handleCurrentAppVersion(appVersion, hint, response);
              this.currentAppVersion = appVersion;
              this.currentBuildId = response.build;
            });
        }
      }
    });
  }

  /**
   * Full version string is e.g. 20230306-150958-v23.3.0-1-g065529292-1205666
   * and this method will return only 23.3 [major].[minor]
   */
  private getAppVersionWithoutPatchLevel(rawVersion: string): string {
    const versions = rawVersion.split('-')[2].substring(1).split('.');
    return `${versions[0]}.${versions[1]}`;
  }

  private currentAppVersionHasChanged(appVersion: string): boolean {
    return this.currentAppVersion !== undefined && this.currentAppVersion !== appVersion;
  }

  private currentBuildIdHasChanged(buildId: string): boolean {
    return this.currentBuildId !== undefined && this.currentBuildId !== buildId;
  }

  private handleCurrentAppVersion(appVersion: string, hint: string, response: AppVersion) {
    if (this.isProduction && this.currentAppVersionHasChanged(appVersion)) {
      this.topNotificationService.showTopNotificationWithReloadButton(hint);
    }
    if (!this.isProduction && this.currentBuildIdHasChanged(response.build)) {
      this.topNotificationService.showTopNotificationWithReloadButton(hint);
    }
  }
}
