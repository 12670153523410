import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterInputComponent } from './components/filter-input.component';
import { ComponentData, SpecificFilterConfig } from './services/filter-frontend.model';
import { OverlayService } from './services/overlay.service';

export interface FilterContainerConfiguration {
  type?: ComponentType<FilterInputComponent>;
  title?: string;
  data$: Observable<ComponentData>;
  func?: string;
  service?: string;
  config?: SpecificFilterConfig;
}

@Component({
  selector: 'eop-specific-filter',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss'],
})
export class FilterContainerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('origin', { static: true })
  origin: ElementRef;

  @Input()
  set specificFilterConfig(c: FilterContainerConfiguration) {
    if (c) {
      const { title, type, data$ } = c;
      this.localData.title = title;
      this.localData.type = type;
      this.data$ = data$;
    }
  }

  @Input()
  componentId;

  @Input()
  componentsData: ComponentData[];

  @Output()
  filter = new EventEmitter<ComponentData>();

  @Output()
  addInitPreselect = new EventEmitter<ComponentData>();

  @Output()
  addPreselect = new EventEmitter<ComponentData>();

  private readonly unsubscribe$: Subject<void> = new Subject();
  localData: ComponentData = { items: null };

  title = '';
  data$: Observable<ComponentData>;

  IconSize = IconSize;
  IconWeight = IconWeight;

  open = false;

  readonly IconColor = IconColor;

  constructor(
    private overlaySvc: OverlayService,
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.data$.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
      this.setLocalData(d);
      this.addInitPreselect.emit(this.localData);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.specificFilterConfig && !changes.specificFilterConfig.firstChange) {
      this.data$.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
        this.setLocalData(d);
        this.addPreselect.emit(this.localData);
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleOpen() {
    this.open = !this.open;

    if (this.open) {
      this.show(this.origin);
    }
  }

  private show(origin: ElementRef) {
    const specificFilter = this.componentsData.find(
      compData => compData.componentId === this.componentId
    );
    const popoverRef = this.overlaySvc.open({
      content: this.localData.type,
      origin,
      width: 400,
      height: 500,
      data: specificFilter ? specificFilter : { ...this.localData },
      vcr: this.vcr,
      callback: this.callback.bind(this),
    });
    popoverRef.afterClosed$.subscribe(res => {
      this.open = false;
      this.overlaySvc.closeAllOverlays();
      this.cdr.detectChanges();
    });
  }

  private setLocalData(data: ComponentData): void {
    this.localData.componentId = this.componentId;
    this.localData.items = data.items;
    this.localData.title = this.localData.title ? this.localData.title : data.title;
    this.localData.type = this.localData.type ? this.localData.type : data.type;
    this.localData.itemTemplate = data.itemTemplate;
  }

  public callback(outputData: ComponentData) {
    this.filter.emit(outputData);
  }
}
