<div class="heading-m">{{ label | translate }}</div>
<div class="second-row">
  <ng-container *ngIf="totalCount && totalCount !== count; else simpleResult">
    <div class="body-bold-m">
      {{
        loading
          ? ('GLOBAL.LOADING_DATA' | translate)
          : formatNumber(count, languageService.getUserLanguageWithFallback(), '1.0') +
            ' ' +
            ('GLOBAL.OF' | translate) +
            ' ' +
            (totalCount | results)
      }}
    </div>
  </ng-container>
</div>

<ng-template #simpleResult>
  <div class="body-bold-m" *ngIf="!countText">
    {{ loading ? ('GLOBAL.LOADING_DATA' | translate) : (count | results) }}
  </div>
  <div class="body-bold-m" *ngIf="countText">
    {{ loading ? ('GLOBAL.LOADING_DATA' | translate) : count + ' ' + countText }}
  </div>
  <div class="loading-indicator">
    <spinner *ngIf="loading" [spinnerSize]="20"></spinner>
  </div>
</ng-template>
