<ngx-weekday-picker
  #weekDayPicker
  *ngIf="options.daysNames?.length > 0"
  [ngClass]="{ readonly: this.readonly }"
  [weekStart]="options.weekStart"
  [daysNames]="options.daysNames"
  [disabledList]="options.disabledList"
  [selected]="options.selected"
  [dayWidth]="options.dayWidth"
  [fontSize]="options.fontSize"
  [borderRadius]="options.borderRadius"
  [borderWidth]="options.borderWidth"
  [activeColor]="options.activeColor"
  [activeBgColor]="options.activeBgColor"
  [activeBorderColor]="options.activeBorderColor"
  [inactiveColor]="options.inactiveColor"
  [inactiveBgColor]="options.inactiveBgColor"
  [inactiveBorderColor]="options.inactiveBorderColor"
  [isMulti]="options?.isMulti"
  (selectedChanged)="onSelectionChange($event)"
></ngx-weekday-picker>
