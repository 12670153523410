<div
  class="entry highlighted--color--{{ highlightedColor }}"
  [class.entry--selected]="isSelected"
  [class.entry--disabled]="disabled"
>
  <eop-icon
    [identifier]="iconIdentifier"
    [weight]="iconWeight"
    [clickable]="false"
    [size]="IconSize.NORMAL"
  ></eop-icon>
  <label *ngIf="!shouldLabelCrop; else labelCroped">{{ label }}</label>
  <ng-template #labelCroped>
    <label [eopDigitsCrop]="label"></label>
  </ng-template>
</div>
