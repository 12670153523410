import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'eop-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input() activated = false;
  @Input() clickable = true;

  @Output() change = new EventEmitter<boolean>();

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (value: any) => void = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  onClick(): void {
    if (!this.clickable) return;
    this.activated = !this.activated;
    this.onChangeCallback(this.activated);
    this.onTouchedCallback();
    this.change.emit(this.activated);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.clickable = !isDisabled;
    this.cdr.detectChanges();
  }

  writeValue(value: boolean): void {
    this.activated = value;
    this.cdr.detectChanges();
  }
}
