import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipe.module';
import { WidgetModule } from '@widgets/widgets.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ContentRoutingModule } from './content-routing.module';
import { WikiModule } from './wiki/wiki.module';

@NgModule({
  imports: [
    ContentRoutingModule,
    CommonModule,
    WikiModule,
    PipesModule,
    TranslateModule,
    WidgetModule,
    InlineSVGModule,
  ],
  exports: [WikiModule],
})
export class ContentModule {}
