<page-headline [title]="'WIKI.FAQ' | translate"></page-headline>

<div class="faq-content">
  <innogy-box class="questions-box">
    <div *ngFor="let faqElement of faqElements">
      <div class="title" [innerHTML]="faqElement.title | safeHtml"></div>
      <mat-accordion displayMode="flat" class="faq-accordion" multi>
        <mat-expansion-panel
          #expPanels
          class="exp-panel"
          *ngFor="let question of faqElement.questions; let i = index"
          [expanded]="(i === queryIndex && faqElement.id === fragment) || !faqElement.isExpandable"
          [disabled]="!faqElement.isExpandable"
          hideToggle
        >
          <mat-expansion-panel-header class="panel-header">
            <mat-panel-title class="question-title" [innerHTML]="question.question">
            </mat-panel-title>
            <mat-panel-description *ngIf="faqElement.isExpandable">
              <eop-icon
                [identifier]="
                  expPanels.expanded ? 'small-chevron-up-outlined' : 'small-chevron-down-outlined'
                "
                [vaylensFont]="true"
              ></eop-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="eop__expansion-panel-content" [innerHTML]="question.answer | safeHtml"></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </innogy-box>

  <eop-table-of-content
    class="toc"
    [headerLinks]="headerLinks"
    [linkInFocusId]="linkInFocusId"
  ></eop-table-of-content>
</div>
