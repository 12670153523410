import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Time } from '@pay/pricing-charging-points/data/pricing-charging-points.data';

@Component({
  selector: 'eop-time-form',
  templateUrl: './time-form.component.html',
  styleUrls: ['./time-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFormComponent),
      multi: true,
    },
  ],
})
export class TimeFormComponent implements ControlValueAccessor {
  @Input()
  error: boolean;

  hour: string;
  minute: string;

  public disabled: boolean = false;

  private onChangeCallback: (value: Time) => void = () => {};
  private onTouchedCallback: () => void = () => {};

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  updateValue() {
    this.onTouchedCallback();
    this.onChangeCallback({
      minute: this.minute,
      hour: this.hour,
    });
  }

  onHourChange(event) {
    this.hour = event.target.value;
    this.updateValue();
  }

  onMinuteChange(event) {
    this.minute = event.target.value;
    this.updateValue();
  }

  registerOnChange(fn: (value: Time) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  writeValue(value: Time): void {
    if (value) {
      this.hour = value.hour;
      this.minute = value.minute;
    }
    this.changeDetectorRef.detectChanges();
  }
}
