import { Directive, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective implements OnChanges {
  // My excuse for this: https://github.com/angular/angular/issues/35330
  @Input() disableControl;
  ngOnChanges(changes) {
    if (changes.disableControl) {
      const action = this.disableControl ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }
  constructor(private ngControl: NgControl) {}
}
