import { Injectable } from '@angular/core';
import { EAuthenticationSource as ActivationAuthSources } from '@monitor/activation-overview/data/activation-overview.model';
import { EAuthenticationSource as ChargeRecordsAuthSources } from '@monitor/charge-records/data/charge-records.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationTypeParameter } from '@operate/charging-infrastructure-detail/components/charge-port/charge-port-lg2wan-parameter/model/chargepoint-lg2wan-parameter.model';
import {
  AuthenticationAndPaymentOptionsFromPoi,
  ChargingSessionAuthenticationSource,
} from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';
import { ChargingStationEvse } from '@operate/charging-infrastructure-detail/model/charging-station.model';
import { Icon, IconWeight } from '@widgets/eop-icon';
import { IconCellData } from '@widgets/innogy-data-table';

export enum AuthenticationTypes {
  NFC = 'NFC',
  PLUG_AND_CHARGE = 'PLUG_AND_CHARGE',
  REMOTE_AUTHENTICATION = 'REMOTE_AUTHENTICATION',
  NO_AUTHENTICATION = 'NO_AUTHENTICATION',
}

@Injectable({
  providedIn: 'root',
})
export class AuthTypeIconService {
  constructor(private translate: TranslateService) {}

  convertStationManagementAuthTypesIntoIcons(chargePort: ChargingStationEvse): IconCellData[] {
    let icons = [];
    if (chargePort.nfcRfidAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(AuthenticationTypes.NFC, 'GLOBAL_AUTH_TYPES', IconWeight.BOLD)
      );
    }
    if (chargePort.remoteAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(
          AuthenticationTypes.REMOTE_AUTHENTICATION,
          'GLOBAL_AUTH_TYPES',
          IconWeight.BOLD
        )
      );
    }
    if (chargePort.pncAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(
          AuthenticationTypes.PLUG_AND_CHARGE,
          'GLOBAL_AUTH_TYPES',
          IconWeight.BOLD
        )
      );
    }
    if (chargePort.noAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(
          AuthenticationTypes.NO_AUTHENTICATION,
          'GLOBAL_AUTH_TYPES',
          IconWeight.BOLD
        )
      );
    }
    return icons;
  }

  convertConfigAuthTypes(auth: AuthenticationAndPaymentOptionsFromPoi): IconCellData[] {
    let icons = [];
    if (auth.nfcRfidAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(AuthenticationTypes.NFC, 'GLOBAL_AUTH_TYPES', IconWeight.BOLD)
      );
    }
    if (auth.remoteAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(
          AuthenticationTypes.REMOTE_AUTHENTICATION,
          'GLOBAL_AUTH_TYPES',
          IconWeight.BOLD
        )
      );
    }
    if (auth.pncAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(
          AuthenticationTypes.PLUG_AND_CHARGE,
          'GLOBAL_AUTH_TYPES',
          IconWeight.BOLD
        )
      );
    }
    if (auth.noAuth) {
      icons.push(
        this.convertAuthTypeIntoIcon(
          AuthenticationTypes.NO_AUTHENTICATION,
          'GLOBAL_AUTH_TYPES',
          IconWeight.BOLD
        )
      );
    }
    return icons;
  }

  convertAuthTypeIntoIcon(
    authType?:
      | ActivationAuthSources
      | ChargeRecordsAuthSources
      | AuthenticationTypes
      | ChargingSessionAuthenticationSource
      | AuthenticationTypeParameter,
    translationPrefix?: string,
    defaultIconWeight = IconWeight.REGULAR
  ): Icon {
    let authTypeKey: string;
    if (translationPrefix) {
      authTypeKey = translationPrefix + '.' + authType;
    } else {
      authTypeKey = authType;
    }
    authTypeKey = authTypeKey.toUpperCase();

    switch (authType) {
      case ActivationAuthSources.REMOTE:
      case ChargeRecordsAuthSources.REMOTE:
      case AuthenticationTypeParameter.REMOTE:
      case AuthenticationTypes.REMOTE_AUTHENTICATION:
        return {
          identifier: 'wifi-lock',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.MOBILE:
      case ChargeRecordsAuthSources.MOBILE:
        return {
          identifier: 'mobile-phone',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.PAYONE:
      case ChargeRecordsAuthSources.PAYONE:
        return {
          identifier: 'credit-card-1',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.RFID:
      case ChargeRecordsAuthSources.RFID:
      case AuthenticationTypeParameter.RFID:
      case AuthenticationTypeParameter.RFID_PLUS:
      case AuthenticationTypes.NFC:
        return {
          identifier: 'wireless-payment-smartphone-1',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.PNC:
      case ChargeRecordsAuthSources.PNC:
      case AuthenticationTypeParameter.PNC:
      case AuthenticationTypes.PLUG_AND_CHARGE:
      case AuthenticationTypeParameter._15118:
      case AuthenticationTypeParameter._15118PRE:
        return {
          identifier: 'electric-car-cable-plugcharge',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.PILOT_SIGNAL:
      case ChargeRecordsAuthSources.PILOT_SIGNAL:
      case AuthenticationTypeParameter.ID_BOX:
        return {
          identifier: 'electric-car-cable-pilotsignal',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.NO_AUTH:
      case ChargeRecordsAuthSources.NO_AUTH:
      case AuthenticationTypes.NO_AUTHENTICATION:
        return {
          identifier: defaultIconWeight === IconWeight.REGULAR ? 'Unlock' : 'lock-unlock-1',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.OPERATION:
      case ChargeRecordsAuthSources.OPERATION:
        return {
          identifier: 'monitor',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.HUBJECT_CPO:
      case ChargeRecordsAuthSources.HUBJECT_EMP:
        return {
          identifier: 'hubject',
          weight: IconWeight.BOLD,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.OCPI_CPO:
      case ActivationAuthSources.OCPI_EMP:
      case ChargeRecordsAuthSources.OCPI_CPO:
      case ChargeRecordsAuthSources.OCPI_EMP:
        return {
          identifier: 'earth-1',
          weight: IconWeight.BOLD,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.LAN:
      case ChargeRecordsAuthSources.LAN:
      case AuthenticationTypeParameter.LAN:
        return {
          identifier: 'clean-car-gas',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.UNDEFINED:
      case ChargeRecordsAuthSources.UNDEFINED:
        return {
          identifier: 'question-circle',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ActivationAuthSources.HUBJECT_EMP:
      case ActivationAuthSources.OCPI_CPO:
      case ChargeRecordsAuthSources.HUBJECT_EMP:
      case ChargeRecordsAuthSources.OCPI_CPO:
        return {
          identifier: 'ecology-globe-hand',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
      case ChargeRecordsAuthSources.PAYMENT_TERMINAL:
      case ChargeRecordsAuthSources.PAYMENT_TERMINAL_STANDALONE:
        return {
          identifier: 'computer-chip-core-connect',
          weight: defaultIconWeight,
          tooltip: this.translate.instant(authTypeKey),
        };
    }

    return {
      identifier: 'question-circle',
      tooltip: authType ? this.translate.instant(authTypeKey) : '',
      weight: IconWeight.REGULAR,
    };
  }
}
