import { GroupState } from '@manage/access-control/store/reducer/group.reducer';
import { EDetailedStatusEnum, EStatusEnum } from '@shared/services/util';
import { ICsvData } from '@shared/sheets/models/sheets.model';
import { Group } from '@widgets/group/group.interface';
import { IconCellData } from '@widgets/innogy-data-table';
import * as fromConfiguration from '../store/reducer/configuration.reducer';
import * as fromContractGroups from '../store/reducer/contract-groups.reducer';
import * as fromPartner from '../store/reducer/partner.reducer';
import * as fromUi from '../store/reducer/ui.reducer';
import { Paging } from './filter.model';

export interface AccessControl {
  contractGroups: fromContractGroups.State;
  configuration: fromConfiguration.State;
  ui: fromUi.State;
  partner: fromPartner.State;
  group: GroupState;
}

export interface ActionPayloadAccessControlSelectGroup {
  groupId: string;
  groupName: string;
  partnerId: string;
}

export enum Direction {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface EmaidSearchParams {
  partnerId: string;
  emaid: string;
  group: string;
  description: string;
  reference: string;
  status: EmaidStatus;
  page: number;
  pageSize: number;
}

export interface ActionPayloadAccessControlDeleteGroup {
  partnerId: string;
  group: Group;
  isPublic: boolean;
}

export interface ActionPayloadAccessControlDeleteAid {
  partnerId: string;
  groupId: string;
}

export interface ActionPayloadAccessControlSaveConfigEam {
  groupId: string;
  partnerId: string;
  platformRoamingType: PlatformRoamingType;
  chargingNetworkIds: string[];
}

export interface EmaidConfig {
  id: string;
  name: string;
  assignedAIDs: AidGroup[];
  assignedButUnpermittedAIDs: AidGroup[];
  notAssignedAIDs: AidGroup[];
  isExternalRoamingEnabled: boolean;
  platformRoamingType: PlatformRoamingType;
  chargingNetworkIds: string[];
  allEmaidsHaveSubscription: boolean;
}

export interface AidGroupSimple {
  id: string;
  name: string;
}

export interface UpdateEmaidConfigMapping {
  assignedAIDs: AidGroup[];
}

export interface UpdateEmaidConfigFlags {
  platformRoamingType: PlatformRoamingType;
  chargingNetworkIds: string[];
  externalRoaming: boolean;
}

export interface UpdateEmaidConfig {
  id: string;
  assignedAIDs: AidGroup[];
  platformRoamingType: PlatformRoamingType;
  chargingNetworkIds: string[];
  externalRoaming: boolean;
}

export interface UpdateGroup {
  name: string;
  roaming?: boolean;
  members: string[];
}

export interface AidGroupFilter {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  searchField: string;
}

export interface EmaidGroupFilter {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  searchField: string;
}

export interface AidGroupResponse {
  groups: AidGroup[];
  paging: Paging;
}

export interface AidGroup {
  id: string;
  name: string;
  membersCount: number;
  platformRoamingType: PlatformRoamingType;
  chargingNetworkIds: string[];
  userOwned: boolean;
  defaultRole: boolean;
  modificationBlacklisted: boolean;
  containsForbiddenCPs: boolean;
  containsExtraneousCPs: boolean;
}

export interface AidGroupWithMembers extends AidGroup {
  members: AidGroupCp[];
}

export interface AidGroupCp {
  uuid: string;
  name: string;
}

export interface CreateEmaidGroup {
  name: string;
  emaidList: string[];
}

export interface EmaidGroupCsv extends ICsvData {
  paging: CsvPaging;
}

export interface CsvPaging {
  currentPage: number;
  itemsPerPage: number;
  nextPageAvailable: boolean;
}

export interface EmaidGroup {
  id: string;
  name: string;
  defaultGroup: boolean;
  isExternalRoaming: boolean;
  platformRoamingType: PlatformRoamingType;
  roamingNetworks: RoamingNetwork[];
  members: number;
}

export interface EmaidGroupUpdate {
  name: string;
  emaidList: string[];
}

export interface EmaidGroupSimple {
  id: string;
  name: string;
  platformRoamingType: PlatformRoamingType;
  externalRoaming: boolean;
}

export interface EmaidFilter {
  id?: string;
  platforms: string[];
  countries: string[];
  providers: IdNameValue[];
  currentTypes: string[];
}

export interface EmaidFilterRow {
  data: EmaidFilter;
  platform: string[];
  country: string[];
  currentType: string[];
  provider: string[];
}

export interface IdNameValue {
  id: number;
  name: string;
}

export interface NewPageableAIDGroupMember {
  paging: Paging;
  members: NewAIDGroupMember[];
}

export interface NewAIDGroupMember {
  chargePortId: string;
  chargingStationId: string;
  chargingStationUuid: string;
  address: AIDGroupMemberAddressTO;
}

export interface AIDGroupMemberAddressTO {
  country: string;
  city: string;
  zipCode: string;
  street: string;
  latitude: number;
  longitude: number;
  supplementLocal: string;
  supplementEN: string;
}

export interface EmaidUiGroup extends Group {
  isPublic: boolean;
}

export interface AIDConfiguration {
  groupId: string;
  groupName: string;
  platformRoaming: PlatformRoamingWithNetworks;
}

export interface PlatformRoamingWithNetworks {
  platformRoamingType: PlatformRoamingType;
  chargingNetworkIds: string[];
}

export interface RoamingNetwork {
  id: string;
  name: string;
  selected: boolean;
}

export interface RoamingNetworkDropdownItem {
  id: string;
  name: string;
  selected: boolean;
}

export interface ActionPayloadAccessControlFetchChargingNetworks {
  cpoId: string;
  empId: string;
}

export enum AccessControlUITab {
  ChargePointGroup,
  EmaidGroup,
}

export interface ChargingNetworkReponse {
  chargingNetworks: ChargingNetwork[];
}

export interface ChargingNetwork {
  id: string;
  name: string;
  type: ChargingNetworkType;
}

export enum ChargingNetworkType {
  PRIVATE_NETWORK = 'PRIVATE_NETWORK',
  USER_CHARGING = 'USER_CHARGING',
  EMARKETPLACE = 'EMARKETPLACE',
}

export interface RoamingNetworkTableItem {
  id: string;
  networkName: string;
  status: IconCellData;
}

export enum PlatformRoamingType {
  NONE = 'NONE',
  AUTO = 'AUTO',
  CUSTOM = 'CUSTOM',
}

export interface EmaidGroupsResponseWithPaging {
  contractGroups: EmaidGroup[];
  paging: Paging;
}

export interface AllEmaidGroups {
  contractGroups: EmaidGroupSimple[];
}

export enum SortColumn {
  PLATFORM = 'platform',
  COUNTRY = 'country',
  PROVIDER = 'provider',
  CURRENT_TYPE = 'currentType',
}

export interface PagedEmaids {
  emaids: Emaid[];
  paging: Paging;
}

export interface Emaid {
  emaid: string;
  description: string;
  customerReference: string;
  status: EmaidStatus;
  emaidGroupId: string;
  emaidGroupName: string;
  rfids: Rfid[];
  platformRoamingType: PlatformRoamingType;
  externalRoaming: boolean;
  modified: string;
  modifiedStatus: string;
  jobId: string;
  tariffId: string;
  tariffName: string;
}

export enum EmaidStatus {
  ALL = '-1',
  INITIAL = 'INITIAL',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export interface Rfid {
  rfid: string;
  validFrom: string;
  validTo: string;
}

export interface EmaidForCreateGroupTable {
  emaid: string;
  group: any;
  description: string;
  customerReference: string;
  tariff: string;
  status: string;
  access: any;
}

export interface SearchChargePointsParameters {
  currentPage?: number;
  itemsPerPage: number;
  sortColumn?: ChargePointParametersSortColumn;
  direction?: Direction;
  search?: string;
  businessPartnerId?: number;
  chargePointOperatorId?: string;
  locationId?: string;
  country?: string;
  zipCode?: string;
  city?: string;
  streetSearchList?: string[];
  statusList?: EStatusEnum[];
  detailStatusList?: EDetailedStatusEnum[];
  firmwareVersionList?: string[];
  connectorTypeList?: string[];
  protocolList?: string[];
  chargingType?: string;
  authenticationTypeList?: string[];
  stationSource?: string;
}

export enum ChargePointParametersSortColumn {
  CHARGE_POINT_NAME = 'CHARGE_POINT_NAME',
  CHARGING_STATION_NAME = 'CHARGING_STATION_NAME',
  ZIP_CODE = 'ZIP_CODE',
  CITY = 'CITY',
}

export interface ChargingStationsPaging {
  paging: Paging;
  chargingStations: ChargingStationSimple[];
}

export interface ChargingStationSimple {
  id: string;
  name: string;
  smartChargingActive?: boolean;
  address?: Address;
}

export interface Address {
  country: string;
  zipCode: string;
  city: string;
  street: string;
  supplementLocal: string;
  supplementEN: string;
}

export interface ChargePointSimple {
  id: string;
  name: string;
  chargingStation?: ChargingStationSimple;
}
