import { AfterViewInit, Component, inject, Input, ViewChild } from '@angular/core';
import { SessionDataActions } from '@shared/store/actions';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { QuickAccessComponent } from '../../header/quick-access/quick-access.component';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';

@Component({
  selector: 'eop-global-header',
  templateUrl: 'global-header.component.html',
  styleUrls: ['global-header.component.scss'],
})
export class GlobalHeaderComponent implements AfterViewInit {
  @ViewChild('searchBar')
  searchBar: QuickAccessComponent;

  @ViewChild(HeaderMenuComponent)
  menu: HeaderMenuComponent;

  @Input()
  isFullWidth = false;

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;

  private sessionDataActions: SessionDataActions = inject(SessionDataActions);

  ngAfterViewInit(): void {
    this.searchBar?.popoverVisible$?.subscribe((opened: boolean) => {
      if (opened) {
        this.sessionDataActions.closeMessages();
      }
    });
  }

  collapseMenu(): void {
    this.menu.collapseMenu();
  }
}
