<div *ngIf="!statusCellData">-</div>
<ng-container *ngFor="let cell of statusCellData; let isFirst = first">
  <div
    *ngIf="isFirst && multiple"
    class="minus"
    [style.width.px]="25 * (statusCellData.length - 1)"
  ></div>
  <div class="line">
    <div class="bubble" [matTooltip]="cell.tooltip">
      <eop-status-system-icon
        *ngIf="cell.useNewStatusIcons && cell.statusSystemIcon"
        [type]="cell.statusSystemIcon"
      ></eop-status-system-icon>
      <eop-icon
        *ngIf="cell.type != null && !cell.useNewStatusIcons"
        [identifier]="cell.outline ? 'Status-outline' : 'Status'"
        [color]="cell.type"
        [weight]="IconWeight.BOLD"
      ></eop-icon>
    </div>
    <div *ngIf="!multiple" class="text" [matTooltip]="cell.text">{{ cell.text }}</div>
  </div>
</ng-container>
