<span
  [matTooltip]="tooltip"
  [attr.data-test]="dataTestId"
  [class.eop-button__hidden]="hidden"
  [class.eop-button__wrapper__multi-icon]="leftIcon && rightIcon && !isIconButton"
  [class.eop-button__wrapper__with-icon]="(leftIcon && !rightIcon) || (!leftIcon && rightIcon)"
  class="eop-button__wrapper"
>
  <eop-icon
    *ngIf="leftIcon"
    [identifier]="leftIcon"
    [size]="iconSize"
    [class.eop-button__left-icon]="leftIcon"
    [vaylensFont]="true"
    dataTestId="{{ dataTestId }}-prefix"
  ></eop-icon>
  <span *ngIf="!isIconButton" class="content"><ng-content></ng-content></span>
  <eop-icon
    *ngIf="rightIcon && !isIconButton"
    [identifier]="rightIcon"
    [size]="iconSize"
    [class.eop-button__right-icon]="rightIcon"
    [vaylensFont]="true"
    dataTestId="{{ dataTestId }}-suffix"
  ></eop-icon>
</span>
