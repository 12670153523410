import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EopIconComponent } from './index';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [EopIconComponent],
  exports: [EopIconComponent],
})
export class EopIconModule {}
