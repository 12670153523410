import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Language } from '@auth/login';
import { LoginMessagesEntry } from '@portal/login-messages/data/login-message.model';

@Component({
  selector: 'eop-single-login-notification',
  templateUrl: './single-login-notification.component.html',
  styleUrls: ['./single-login-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLoginNotificationComponent implements OnChanges {
  @Input()
  message: LoginMessagesEntry;

  @Input()
  userLanguage: Language;
  Language = Language;

  ngOnChanges(): void {
    if (
      !['de', 'fr'].includes(this.userLanguage) ||
      (this.userLanguage === Language.French && !this.message.announcements['fr'].message)
    ) {
      this.userLanguage = Language.English;
    }
  }
}
