import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedRefService } from '../../shared-ref-service/shared-ref-service.service';

/**
 * This Directive will add the css class 'emob-modal-background-blur'
 * to the host element as long as the modal dialog is opened.
 * If you used the directive the element
 * will be blured by this class.
 */
@Directive({
  selector: '[emobModalActive]',
})
export class ModalActiveDirective implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  private readonly BLUR_CLASS = 'emob-modal-background-blur';

  /**
   * This should never be called directly by the api users
   * @ignore
   */
  constructor(
    private readonly sharedRefService: SharedRefService,
    private readonly hostElement: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.sharedRefService.allModalRefs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isModalActive: boolean) => {
        if (isModalActive) {
          this.renderer.addClass(this.hostElement.nativeElement, this.BLUR_CLASS);
        } else {
          this.renderer.removeClass(this.hostElement.nativeElement, this.BLUR_CLASS);
        }
      });

    this.sharedRefService.allDialogRefs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isDialogActive: boolean) => {
        if (isDialogActive) {
          this.renderer.addClass(this.hostElement.nativeElement, this.BLUR_CLASS);
        } else {
          this.renderer.removeClass(this.hostElement.nativeElement, this.BLUR_CLASS);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
