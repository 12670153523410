import { Component, Input } from '@angular/core';
import { Icon, IconColor } from '@widgets/eop-icon';

export interface IconCellData extends Icon {
  class?: string;
  text?: string;
  color?: IconColor;
}

@Component({
  selector: 'icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss'],
})
export class IconCellComponent {
  @Input()
  public icons: IconCellData[] = [];
}
