import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { GroupComponent } from '../../group/group.component';
import { Group, IconDisableMode } from '../../group/group.interface';

@Component({
  selector: 'eop-group-list',
  templateUrl: './eop-group-list.component.html',
  styleUrls: ['./eop-group-list.component.scss'],
})
export class GroupListComponent implements AfterViewInit, OnChanges {
  @Input()
  public groups: Group[];

  @Input()
  public iconDisableMode: IconDisableMode = IconDisableMode.HIDE;

  @Input()
  public selectedGroup: Group;

  @Output()
  public create = new EventEmitter<void>();

  @Output()
  public select = new EventEmitter<Group>();

  @Output()
  public edit = new EventEmitter<Group>();

  @Output()
  public delete = new EventEmitter();

  @ViewChildren(GroupComponent)
  groupList: QueryList<GroupComponent>;

  public editMode = false;

  public selectedGroupId = '';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    // Trigger initial group preselection after the view has been initialized
    this.detectSelectionChange();

    // If the list of groups is updated together with the selectedGroup attribute
    // the change detection has to be triggered within the groupList change
    // to properly set the selected group, otherwise the selection will be lost
    this.groupList.changes.subscribe(() => {
      this.detectSelectionChange();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedGroupChange: SimpleChange = changes['selectedGroup'];
    if (selectedGroupChange && !selectedGroupChange.firstChange) {
      this.setSelectedGroup(this.selectedGroup);
    }
  }

  public toggleEditMode() {
    this.editMode = !this.editMode;
  }

  public onCreate() {
    this.create.emit();
  }

  public onEditGroupClicked(group: Group) {
    this.edit.emit(group);
  }

  public onDeleteGroupClicked(group: Group) {
    this.delete.emit(group);
  }

  public onGroupClicked(group: Group) {
    this.setSelectedGroup(group);
    this.select.emit(group);
  }

  private setSelectedGroup(group: Group) {
    const selected = this.findGroup(this.selectedGroupId);
    if (selected) {
      selected.selected = false;
      this.selectedGroupId = '';
    }

    if (group) {
      const toSelect = this.findGroup(group.id);
      if (toSelect) {
        toSelect.selected = true;
        this.selectedGroupId = group.id;
      }
    }
  }

  private findGroup(id: string) {
    return this.groupList.toArray().find(elem => {
      return elem['group']['id'] === id;
    });
  }

  private detectSelectionChange() {
    if (this.selectedGroup) {
      this.setSelectedGroup(this.selectedGroup);
      this.changeDetectorRef.detectChanges();
    }
  }
}
