<div
  *ngIf="expansionRowExpanded === cellData; else showArrowDown"
  (click)="expansionRowExpanded = ''"
  [matTooltip]="'GLOBAL.COLLAPSE' | translate"
  [matTooltipShowDelay]="1000"
  class="expanding-arrow-wrapper"
>
  <eop-icon identifier="chevron-up-outlined" [vaylensFont]="true"></eop-icon>
</div>
<ng-template #showArrowDown>
  <div
    class="expanding-arrow-wrapper"
    [matTooltip]="'GLOBAL.EXPAND' | translate"
    [matTooltipShowDelay]="1000"
    (click)="expansionRowExpanded = cellData"
  >
    <eop-icon
      identifier="chevron-down-outlined"
      dataTestId="expand-row"
      [vaylensFont]="true"
    ></eop-icon>
  </div>
</ng-template>
