<div
  class="file-upload-container"
  [ngStyle]="
    width && height ? { width: width?.toString() + 'px', height: height?.toString() + 'px' } : {}
  "
  dragAndDropUpload
  (click)="loading || disabled ? null : fileInput.click()"
  (onFileDropped)="loading || disabled ? null : onSelectFile($event)"
>
  <input
    #fileInput
    hidden
    type="file"
    [accept]="fileType"
    (change)="onSelectFile($event.target.files[0])"
    (click)="fileInputClick($event.target)"
  />
  <spinner *ngIf="loading"></spinner>
  <eop-icon
    *ngIf="!loading"
    identifier="zip-file-upload"
    [weight]="IconWeight.LIGHT"
    [size]="IconSize.LARGE"
  ></eop-icon>
  <label *ngIf="!loading">{{ 'GENERAL.DRAG_AND_DROP' | translate }}</label>
</div>
