import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * This Component represents the content area in the Modal Dialog.
 * It takes care of proper positioning.
 */
@Component({
  selector: 'emob-modal-card-content',
  templateUrl: './modal-card-content.component.html',
  styleUrls: ['./modal-card-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCardContentComponent {}
