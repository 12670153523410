<div
  [attr.data-test]="'scroll-to-top-btn'"
  [class.show-scroll]="windowScrolled"
  class="scroll scroll-button box-shadow-tool-tip"
>
  <eop-icon
    (click)="scrollToTop()"
    [identifier]="'arrow-up-2'"
    [weight]="IconWeight.BOLD"
    [tooltip]="tooltip"
  ></eop-icon>
</div>
