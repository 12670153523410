import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { EPermissions } from '@auth/login';
import { IAppState } from '@shared/store/models';
import { Capability } from '@operate/charging-infrastructure-detail/model/capability.model';
import { ChargingStationCapability } from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private store: NgRedux<IAppState>) {}

  getCurrentUserId(): number {
    return this.store.getState().backendData.userLogin.id;
  }

  userHasPermission(permission: EPermissions): boolean {
    return (
      this.store.getState().backendData.userLogin !== null &&
      this.store.getState().backendData.userLogin.permissions &&
      this.checkAllPermissions(permission)
    );
  }

  userHasInternalRole(): boolean {
    return this.store.getState().backendData.userLogin.internalRole;
  }

  chargingStationHasCapabilityAndIsPermitted(
    chargingStationCapabilities: Capability[],
    capability: ChargingStationCapability
  ): boolean {
    const capabilities = chargingStationCapabilities?.filter((elem: Capability) => {
      return ChargingStationCapability[capability] === elem.id;
    });

    if (capabilities && capabilities.length > 0) {
      return capabilities[0].capableAndPermitted;
    } else {
      return false;
    }
  }

  private checkAllPermissions(permission: EPermissions): boolean {
    const permissionFound = this.store
      .getState()
      .backendData.userLogin.permissions.find(
        (userPermission: EPermissions) => userPermission === permission
      );

    return permissionFound !== undefined;
  }
}
