<div>
  <label *ngIf="showChipsHeadline" class="chips-headline bold">
    {{ customChipsHeadline }}
  </label>
  <div
    #chipsContainer
    [ngClass]="{
      'chip-container': chipListCollapsable && chipsData.length >= collapseLimit,
      'is-expanded': chipsExpanded
    }"
  >
    <div
      *ngIf="
        !chipsExpanded &&
        chipListCollapsable &&
        chipsData.length >= collapseLimit &&
        chipsData.length > 1
      "
      class="chips-expander"
      (click)="$event.stopPropagation(); expandChipList()"
      [matTooltip]="'GLOBAL.MORE_DETAILS' | translate"
    >
      <eop-icon
        [weight]="IconWeightEnum.BOLD"
        [color]="IconColorEnum.NORMAL"
        [size]="IconSizeEnum.SMALL"
        [clickable]="true"
        identifier="navigation-menu-horizontal"
      ></eop-icon>
    </div>
    <div
      *ngIf="chipsExpanded && chipListCollapsable"
      class="chips-expander"
      (click)="$event.stopPropagation(); collapseChipList()"
    >
      <eop-icon
        [weight]="IconWeightEnum.REGULAR"
        [color]="IconColorEnum.NORMAL"
        [size]="IconSizeEnum.SMALL"
        [clickable]="true"
        identifier="close"
      >
      </eop-icon>
    </div>
    <span *ngIf="!chipsData || chipsData.length === 0">{{ placeholderText }}</span>
    <mat-chip-listbox #chipList *ngIf="chipsData && chipsData.length > 0">
      <mat-basic-chip
        *ngFor="
          let chip of chipListCollapsable && chipsData.length >= collapseLimit && !chipsExpanded
            ? chipsData.slice(0, 5)
            : chipsData
        "
        [removable]="true"
        [value]="chip[chipKey]"
        [ngClass]="chip.chipColor ? chip.chipColor : chipColor"
        [class.is-emphasized]="emphasized"
        [class.without-margin]="!margin"
        [class.info-icon-inside]="infoText && !infoIconOutsideChip"
        (removed)="removeChip(chip)"
        [matTooltip]="chip.customTooltip ? chip.customTooltip : chip[chipKey]"
        [matTooltipShowDelay]="1000"
      >
        <span *ngIf="!shouldChipTextCrop">{{
          (chip[chipName] ? chip[chipName] : chip[chipKey]) | truncate : maxChipTextLength
        }}</span>
        <span
          *ngIf="shouldChipTextCrop"
          [eopDigitsCrop]="
            (chip[chipName] ? chip[chipName] : chip[chipKey]) | truncate : maxChipTextLength
          "
          [matTooltip]="chip[chipName] ? chip[chipName] : chip[chipKey]"
          [matTooltipPositionAtOrigin]="true"
        ></span>
        <span class="info-icon" *ngIf="infoText && !infoIconOutsideChip">
          <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
        </span>
        <img
          *ngIf="chipsRemovable"
          matChipRemove
          class="close-icon"
          src="/assets/img/regular/close.svg"
          alt="close icon"
        />
      </mat-basic-chip>
      <span *ngIf="infoText && infoIconOutsideChip">
        <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
      </span>
    </mat-chip-listbox>
  </div>
</div>

<ng-template #infoIcon>
  <eop-icon
    [identifier]="'info-circle-filled'"
    [tooltip]="infoText"
    [vaylensFont]="true"
    [color]="IconColorEnum.NORMAL"
    [size]="IconSizeEnum.SMALL"
  ></eop-icon>
</ng-template>
