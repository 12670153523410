import { Component, Input, OnInit } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
} from '@shared/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '@shared/google-analytics/google-analytics.service';
import { Icon } from '@widgets/eop-icon';
import { HighlightedColor } from '@widgets/innogy-data-table/data/data-table-row.data';

export interface LinkCellData {
  text: string;
  url?: string;
  showTooltip?: boolean;
  icon?: Icon;
}

@Component({
  selector: 'link-cell',
  templateUrl: './link-cell.component.html',
  styleUrls: ['./link-cell.component.scss'],
})
export class LinkCellComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  eventUrl: string;

  @Input()
  showTooltip: boolean;

  @Input()
  icon: Icon;

  @Input()
  shouldTextCrop = false;

  @Input()
  trackingCategory: GoogleAnalyticsCategory;
  defaultTrackingCategory = GoogleAnalyticsCategory.TABLE;

  @Input()
  id: string;

  @Input()
  highlightedColor: HighlightedColor;

  queryParams: { queryParams: Params };
  urlPart: string;

  constructor(private router: Router, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit() {
    if (this.eventUrl) {
      const urlTree = this.parseUrl(this.eventUrl);
      this.urlPart = urlTree.root.children.primary && urlTree.root.children.primary.toString();
      this.queryParams = { queryParams: urlTree.queryParams };
    }
  }

  sendGoogleTrackingEvent() {
    this.googleAnalyticsService.sendEvent(
      this.trackingCategory ? this.trackingCategory : this.defaultTrackingCategory,
      GoogleAnalyticsAction.NAVIGATE,
      this.id
    );
  }

  private parseUrl(url: string): UrlTree {
    const urlTree: UrlTree = this.router.parseUrl(url);
    return urlTree;
  }
}
