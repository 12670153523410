import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingIndicatorSpinnerComponent } from './loading-indicator-spinner/loading-indicator-spinner.component';

/**
 * This Module bundles all kind of loading indicators.
 * For now this is just a wrapper module for {@link LoadingIndicatorSpinnerComponent}
 * which might be improved/extended in the future.
 */
@NgModule({
  imports: [CommonModule],
  exports: [LoadingIndicatorSpinnerComponent],
  declarations: [LoadingIndicatorSpinnerComponent],
})
export class LoadingIndicatorModule {}
