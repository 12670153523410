import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

/**
 * @internal
 *
 * The ValidationErrors is a dynamic list of string: boolean pairs.
 * This pipe is used to return the first occured Validation error to API users.
 */
@Pipe({
  name: 'errorKey',
})
export class ErrorKeyPipe implements PipeTransform {
  transform(value?: ValidationErrors | null | undefined): string | undefined {
    if (!value) {
      return undefined;
    }
    return Object.keys(value)[0];
  }
}
