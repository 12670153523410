import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { FormService } from '@shared/services/util/form.service';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'eop-input-edit-mode',
  templateUrl: './input-edit-mode.component.html',
  styleUrls: ['./input-edit-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputEditModeComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  readonly IconSize = IconSize;
  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;

  @Input() id: string;
  @Input() testId: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() validators: ValidatorFn[];

  @Output() saveClicked = new EventEmitter<string>();

  form: FormGroup;
  valueBeforeEdit: string;
  errorMessage: string;

  get valueControl(): AbstractControl {
    return this.form.controls.value;
  }

  get isDisabled(): boolean {
    return this.valueControl.disabled;
  }

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(this.id), // this is needed for validation
      value: new FormControl({ value: this.value, disabled: true }, this.validators),
    });
    this.form.controls.value.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.errorMessage = this.formService.errorForControl('value', this.form);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onEnableEdit(): void {
    this.valueBeforeEdit = this.form.controls.value.value;
    this.form.controls.value.enable();
  }

  onDiscard(): void {
    this.form.controls.value.setValue(this.valueBeforeEdit);
    this.form.controls.value.disable();
  }

  onSave(): void {
    this.form.controls.value.disable();
    this.saveClicked.emit(this.form.controls.value.value);
  }
}
