import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';
/**
 * @ignore
 */
const timings = '150ms ease';
/**
 * @ignore
 */
const overlayAnimationBody = [
  transition(':enter', [style({ height: '0' }), animate(timings, style({ height: '180px' }))]),
  transition(':leave', [animate(timings, style({ height: '0' }))]),
];

/**
 * this is just used internal for the {@link DropdownComponent}
 * @ignore
 */
export const dropdownAnimations: {
  readonly overlay: AnimationTriggerMetadata;
} = {
  overlay: trigger('overlayTrigger', overlayAnimationBody),
};
