<div class="input-wrapper">
  <input
    type="number"
    class="pricing-set-time"
    [class]="{ error: error }"
    placeholder="HH"
    min="0"
    max="23"
    maxlength="2"
    [value]="hour"
    (input)="onHourChange($event)"
    [disabled]="disabled"
    data-test="hours"
  />
  <span class="pricing-set-time-separator">:</span>
  <input
    type="number"
    class="pricing-set-time"
    [class]="{ error: error }"
    placeholder="MM"
    min="0"
    max="59"
    maxlength="2"
    [value]="minute"
    (input)="onMinuteChange($event)"
    [disabled]="disabled"
    data-test="minutes"
  />
</div>
