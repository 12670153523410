<div>
  <eop-header-menu></eop-header-menu>
  <div class="expand-menu-button" *ngIf="menu?.menuCollapsed">
    <eop-icon
      identifier="chevron-right-sq-outlined"
      [clickable]="true"
      [color]="IconColor.INHERIT"
      [vaylensFont]="true"
      (click)="collapseMenu()"
    ></eop-icon>
  </div>
</div>

<div class="global-header-container" [class.full-width]="isFullWidth">
  <eop-show-notification></eop-show-notification>
  <eop-quick-access #searchBar></eop-quick-access>
</div>
