import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { PopoverRef } from '../../classes/popover-ref';
import { Item } from '../../services/filter-frontend.model';
import { FilterInputComponent } from '../filter-input.component';

@Component({
  selector: 'eop-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent extends FilterInputComponent {
  readonly MAX_CHARACTERS_ALLOWED = 1000;

  items: Item[];
  key: string;

  constructor(@Optional() private popoverRef: PopoverRef) {
    super(popoverRef);
    this.key = Object.keys(this.componentData.items[0].value)[0];
    this.componentData.items = this.componentData.items.map(i => {
      return {
        ...i,
        value: {
          [this.key]: i.selected ? i.value[this.key] : '',
        },
      };
    });
  }

  onInput(value: string) {
    const trimmedValue = value.substring(0, this.MAX_CHARACTERS_ALLOWED);
    this.componentData.items = this.componentData.items.map(i => {
      return {
        ...i,
        selected: trimmedValue !== '',
        chipValue: trimmedValue,
        value: {
          [this.key]: trimmedValue,
        },
      };
    });

    this.itemClick.emit(this.componentData);
    if (this.popoverRef) {
      this.popoverRef.callback(this.componentData);
      this.popoverRef.close();
    }
  }
}
