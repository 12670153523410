import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EPermissions } from '@auth/login';
import { Icon, IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { StreetSelect } from '@widgets/filter/address-new/data/filter.model';
import { merge, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { StreetSuggestion } from '../model/suggestions.model';
import { SuggestService } from '../services/suggest.service';

@Component({
  selector: 'eop-streets',
  templateUrl: './streets.component.html',
  styleUrls: ['./streets.component.scss'],
})
export class StreetsComponent implements OnInit, OnDestroy {
  @ViewChild('input', { read: MatAutocompleteTrigger }) autoCompleteTrigger: MatAutocompleteTrigger;

  readonly DEBOUNCE_TIME_FOR_SEARCH_IN_MS = 200;
  readonly MIN_CHARACTER_BEFORE_SEARCH = 1;
  readonly IconColor = IconColor;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private searchWithDebounce$: Subject<string> = new Subject<string>();
  private directSearch$: Subject<string> = new Subject<string>();

  @Input() permission: EPermissions;
  @Input() partnerId: string;
  @Input() locationId: string;
  @Input() country: string;
  @Input() city: string;
  @Input() zipCode: string;
  @Input() onlyIbetStations: boolean;

  @Output()
  selectionChange: EventEmitter<StreetSelect> = new EventEmitter<StreetSelect>();

  get street(): AbstractControl {
    return this.form.get('street');
  }

  form: FormGroup;
  suggestions: StreetSuggestion[];
  icon: Icon = {
    identifier: 'pin',
    size: IconSize.SMALL,
    weight: IconWeight.BOLD,
  };
  empty: StreetSelect = {
    streetSearchList: [],
    streetLabel: null,
  };
  noResults = false;
  loading = false;
  searchInputIsEmpty = true;
  searchValue: string;

  constructor(
    private suggestService: SuggestService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.form = this.formBuilder.group({ street: null });
    merge(
      this.directSearch$,
      this.searchWithDebounce$.pipe(debounceTime(this.DEBOUNCE_TIME_FOR_SEARCH_IN_MS))
    )
      .pipe(
        tap(search => {
          this.loading = search.length >= this.MIN_CHARACTER_BEFORE_SEARCH;
          this.noResults = false;
          this.searchValue = search;
          this.searchInputIsEmpty = search.length === 0;
          this.cdr.detectChanges();
        }),
        filter(search => search.length >= this.MIN_CHARACTER_BEFORE_SEARCH),
        switchMap(search =>
          this.suggestService
            .getStreetSuggestions(
              this.permission,
              search,
              this.partnerId,
              this.locationId,
              this.country,
              this.city,
              this.zipCode,
              this.onlyIbetStations
            )
            .pipe(
              catchError(() => {
                this.loading = false;
                this.noResults = true;
                this.setSuggestionValues([]);
                this.cdr.detectChanges();
                return [];
              })
            )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((suggestions: StreetSuggestion[]) => {
        this.loading = false;
        this.noResults = suggestions.length === 0;
        this.setSuggestionValues(suggestions);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  onEnter(value: string): void {
    const trimmedValue = value.trim();
    this.street.setValue(trimmedValue);
    const select: StreetSelect = {
      streetSearchList: ['*' + trimmedValue + '*'],
      streetLabel: trimmedValue,
    };
    this.selectionChange.emit(select);
  }

  onInput(value: string): void {
    const trimmedValue = value.trim();
    if (trimmedValue === '') {
      this.reset();
    }
    this.searchWithDebounce$.next(trimmedValue);
  }

  onFocus(value: string): void {
    if (value && !this.autoCompleteTrigger?.panelOpen) {
      const trimmedValue = value.trim();
      if (trimmedValue !== '') {
        this.directSearch$.next(trimmedValue);
      }
    }
  }

  onSelect(value: StreetSuggestion): void {
    this.street.setValue(value.suggestion);
    const select: StreetSelect = {
      streetSearchList: value.streetSearchList.map(s =>
        s
          .replace(/,/g, '_')
          .split('_')
          .map(s => s.trimEnd())
          .filter(s => s !== '')
          .join('_')
      ),
      streetLabel: value.suggestion,
    };
    this.selectionChange.emit(select);
  }

  reset(emitChange = true): void {
    this.street.reset();
    this.suggestions = [];
    if (emitChange) this.selectionChange.emit(this.empty);
  }

  private setSuggestionValues(suggestions: StreetSuggestion[]) {
    const searchValue = this.searchValue;
    const inputValue: StreetSuggestion = {
      suggestion: searchValue,
      streetSearchList: ['*' + searchValue + '*'],
    };
    this.suggestions = [inputValue, ...suggestions];
  }

  private scrollEvent = (event: Event): void => {
    if (this.autoCompleteTrigger.panelOpen) this.autoCompleteTrigger.updatePosition();
  };
}
