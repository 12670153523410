import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum SystemEmaid {
  MDP = 'MDP',
  VOUCHER = 'VOUCHER',
  GOODWILL = 'GOODWILL',
}

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'translateSystemEmaid',
})
export class TranslateSystemEmaidPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Returns translation of system emaid in case it actually is a system emaid
   */
  transform(emaid: string): string {
    switch (emaid) {
      case SystemEmaid.MDP:
        return this.translate.instant('GLOBAL.SYSTEM_EMAID_MDP');
      case SystemEmaid.VOUCHER:
        return this.translate.instant('GLOBAL.SYSTEM_EMAID_VOUCHER');
      case SystemEmaid.GOODWILL:
        return this.translate.instant('GLOBAL.SYSTEM_EMAID_GOODWILL');
      default:
        return emaid;
    }
  }
}
