import { createReducer, on } from '@ngrx/store';
import { clone } from 'lodash-es';
import { LocationWithChargingStations, ResponseEnum } from '../data/locations.data';
import { LocationsOverviewActions } from './locations-overview.action';
import { LocationsState } from './locations.state';

export const INITIAL_STATE: LocationsState = {
  partners: [],
  selectedPartner: null,
  locations: [],
  selectedLocation: {
    uuid: null,
    name: null,
    description: null,
    stations: null,
  },
  selectedLocationForGroupEdit: {
    uuid: null,
    name: null,
    stations: [],
  },
  allStationsFromPartner: null,
  requestResponse: { type: ResponseEnum.Initial, payload: null },
};

export const locationsOverviewReducer = createReducer(
  INITIAL_STATE,

  /**
   * Partners & Selected Partner
   */
  on(LocationsOverviewActions.getPartners, (state, {}): LocationsState => {
    return {
      ...state,
      partners: INITIAL_STATE.partners,
      selectedPartner: INITIAL_STATE.selectedPartner,
    };
  }),
  on(LocationsOverviewActions.setPartners, (state, { partners }): LocationsState => {
    return {
      ...state,
      partners: partners,
    };
  }),
  on(LocationsOverviewActions.selectPartner, (state, { partner }): LocationsState => {
    return {
      ...state,
      selectedPartner: partner,
    };
  }),

  /**
   * Locations
   */
  on(LocationsOverviewActions.getLocations, (state, {}): LocationsState => {
    return {
      ...state,
      locations: INITIAL_STATE.locations,
    };
  }),
  on(LocationsOverviewActions.setLocations, (state, { locations }): LocationsState => {
    return {
      ...state,
      locations: locations,
    };
  }),
  on(LocationsOverviewActions.deleteLocationSuccess, (state, { location }): LocationsState => {
    const newLocations = clone(state.locations);
    return {
      ...state,
      locations: newLocations.filter(existingLocations => existingLocations.uuid !== location.uuid),
    };
  }),
  on(
    LocationsOverviewActions.updateLocationAssignmentSuccess,
    (state, { location }): LocationsState => {
      const newLocations = clone(state.locations);
      const locationIndex = state.locations.findIndex(
        existingLocations => existingLocations.uuid === location.uuid
      );
      if (locationIndex !== -1) newLocations.splice(locationIndex, 1);
      newLocations.push(location);
      return {
        ...state,
        locations: newLocations,
      };
    }
  ),
  on(LocationsOverviewActions.resetLocations, (state, {}): LocationsState => {
    return {
      ...state,
      locations: INITIAL_STATE.locations,
    };
  }),

  /**
   * Selected Location
   */
  on(LocationsOverviewActions.getStationsByLocation, (state, { location }): LocationsState => {
    const newLocation: LocationWithChargingStations = {
      uuid: location.uuid,
      name: location.name,
      description: location.description,
      stations: INITIAL_STATE.selectedLocation.stations,
    };
    return {
      ...state,
      selectedLocation: newLocation,
    };
  }),
  on(LocationsOverviewActions.setStations, (state, { stations }): LocationsState => {
    return {
      ...state,
      selectedLocation: {
        ...state.selectedLocation,
        stations: stations,
      },
    };
  }),
  on(LocationsOverviewActions.closeSelectedLocation, (state, {}): LocationsState => {
    return {
      ...state,
      selectedLocation: INITIAL_STATE.selectedLocation,
    };
  }),

  /**
   * Selected Location For Group Edit
   */
  on(LocationsOverviewActions.getStationsForGroupEdit, (state, { location }): LocationsState => {
    const newLocation: LocationWithChargingStations = {
      uuid: location.uuid,
      name: location.name,
      stations: INITIAL_STATE.selectedLocationForGroupEdit.stations,
    };
    return {
      ...state,
      selectedLocationForGroupEdit: newLocation,
    };
  }),
  on(LocationsOverviewActions.setStationsForGroupEdit, (state, { stations }): LocationsState => {
    return {
      ...state,
      selectedLocationForGroupEdit: {
        ...state.selectedLocationForGroupEdit,
        stations: stations,
      },
    };
  }),
  on(LocationsOverviewActions.resetStationsForGroupEdit, (state, {}): LocationsState => {
    return {
      ...state,
      selectedLocationForGroupEdit: INITIAL_STATE.selectedLocationForGroupEdit,
    };
  }),

  /**
   * Add Stations For Partner
   */
  on(LocationsOverviewActions.setAllPartnerStations, (state, { stations }): LocationsState => {
    return {
      ...state,
      allStationsFromPartner: stations,
    };
  }),

  /**
   * Request response
   */
  on(
    LocationsOverviewActions.createLocation,
    LocationsOverviewActions.updateLocationAssignment,
    (state, {}): LocationsState => {
      return {
        ...state,
        requestResponse: {
          type: ResponseEnum.WaitingForResponse,
          payload: null,
        },
      };
    }
  ),
  on(LocationsOverviewActions.updateLocationAssignmentSuccess, (state, {}): LocationsState => {
    return {
      ...state,
      requestResponse: {
        type: ResponseEnum.UpdateLocationSuccess,
        payload: null,
      },
    };
  }),
  on(LocationsOverviewActions.updateLocationAssignmentError, (state, { error }): LocationsState => {
    return {
      ...state,
      requestResponse: {
        type: ResponseEnum.UpdateLocationError,
        payload: error,
      },
    };
  }),
  on(LocationsOverviewActions.resetRequest, (state, {}): LocationsState => {
    return {
      ...state,
      requestResponse: {
        type: ResponseEnum.Initial,
        payload: null,
      },
    };
  })
);
