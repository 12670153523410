import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { UserFilterOptions } from '@shared/store/data/user-filter-options.model';
import { IAppState } from '../models';

export enum UserFilterOptionsActionTypes {
  SAVE_USER_FILTER_OPTIONS = 'SAVE_USER_FILTER_OPTIONS',
  RESET_USER_FILTER_OPTIONS = 'RESET_USER_FILTER_OPTIONS',
}

@Injectable()
export class UserFilterOptionsActions {
  constructor(private store: NgRedux<IAppState>) {}

  public saveUserFilterOptions(options: UserFilterOptions) {
    return this.store.dispatch({
      type: UserFilterOptionsActionTypes.SAVE_USER_FILTER_OPTIONS,
      payload: options,
    });
  }

  public resetUserFilterOptions() {
    return this.store.dispatch({
      type: UserFilterOptionsActionTypes.RESET_USER_FILTER_OPTIONS,
    });
  }

  public getSavedUserFilterOptions(): UserFilterOptions {
    return this.store.getState().userFilterOptions;
  }

  public isAnythingSaved(): boolean {
    const options = this.getSavedUserFilterOptions();
    return Object.keys(options).length !== 0;
  }
}
