<div class="entry">
  <label>{{ amount || 0 }} {{ 'HEADER.NOTIFICATION' | translate }}</label>
  <eop-icon
    id="close"
    identifier="chevron-up-outlined"
    [color]="IconColor.NORMAL"
    (click)="closeMessages()"
    [vaylensFont]="true"
  ></eop-icon>
</div>
