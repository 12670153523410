import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ISettings, IUserLogin } from '@auth/login';
import { resetStores } from '@datorama/akita';
import { Store } from '@ngrx/store';
import { NgrxMetaActions } from '@shared/ngrx-meta-reducer/ngrx-meta.actions';
import userflow from 'userflow.js';
import { IActionWithPayload, IAppState } from '../models';

@Injectable()
export class BackendDataActions {
  public static START_LOGIN: string = 'START_LOGIN';
  public static LOGIN: string = 'LOGIN';
  public static LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';
  public static LOGOUT: string = 'LOGOUT';
  public static LOGOUT_SUCCESS: string = 'LOGOUT_SUCCESS';

  public static SET_USER_SETTINGS: string = 'SET_USER_SETTINGS';
  public static SAVE_SETTINGS_VALUE_LIST: string = 'SAVE_SETTINGS_VALUE_LIST';

  constructor(private store: NgRedux<IAppState>, private ngrxStore: Store) {}

  public startLogin(username: string, password: string): IActionWithPayload<any> {
    return this.store.dispatch({
      type: BackendDataActions.START_LOGIN,
      payload: { username, password },
    });
  }

  public login(username: string, password: string): IActionWithPayload<any> {
    return this.store.dispatch({ type: BackendDataActions.LOGIN, payload: { username, password } });
  }

  public loginExternal(userlogin: IUserLogin): IActionWithPayload<any> {
    return this.store.dispatch({ type: BackendDataActions.LOGIN_SUCCESS, payload: userlogin });
  }

  public logout(): IActionWithPayload<any> {
    this.ngrxStore.dispatch(NgrxMetaActions.resetStore());
    resetStores();
    userflow.reset();
    return this.store.dispatch({ type: BackendDataActions.LOGOUT, payload: {} });
  }

  public logoutExternal(): IActionWithPayload<any> {
    return this.store.dispatch({ type: BackendDataActions.LOGOUT_SUCCESS, payload: {} });
  }

  public setUserSettings(settings: ISettings): IActionWithPayload<any> {
    return this.store.dispatch({ type: BackendDataActions.SET_USER_SETTINGS, payload: settings });
  }
}
