import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from '@widgets/tooltip/tooltip.module';
import { NormalizedStackedBarChartComponent } from './normalized-stacked-bar-chart.component';

@NgModule({
  imports: [CommonModule, TooltipModule],
  declarations: [NormalizedStackedBarChartComponent],
  exports: [NormalizedStackedBarChartComponent],
})
export class NormalizedStackedBarChartModule {}
