import { select } from '@angular-redux/store';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Language } from '@auth/login';
import { TranslateService } from '@ngx-translate/core';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
  GoogleAnalyticsLabel,
} from '@shared/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '@shared/google-analytics/google-analytics.service';
import { TopNotificationService } from '@shared/services/notification';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { QuickSearchResult, SearchCategoryEnum, SearchResult } from './data/quick-access.model';
import { QuickAccessService } from './services/quick-access.service';

export enum KeyboardKeys {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  ENTER = 'Enter',
}

interface SearchPlaceholderKeys {
  'GLOBAL.SEARCH_FOR': string;
  'GLOBAL.CHARGING_STATION_ID': string;
  'GLOBAL.CHARGE_POINT_ID': string;
}

@Component({
  selector: 'eop-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  @select(['router'])
  route$: Observable<string>;

  @select(['backendData', 'userLogin', 'settings', 'locale'])
  userLanguage$: Observable<Language>;

  @Output() popoverVisible$ = new EventEmitter();
  popoverVisible: boolean = false;

  searchTerms$ = new Subject<string>();
  searchPlaceholderText: string;

  numOfMinCharsToStartSearch: number = 4;
  searchInProgress: boolean;

  minCharsReached = false;
  currentResults: QuickSearchResult;
  selectedResult: SearchResult;

  inititalResults: QuickSearchResult = {
    chargepointResults: 0,
    chargingStationResults: 0,
    searchResults: [],
  };

  private readonly unsubscribe$ = new Subject<void>();

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;

  constructor(
    private quickAccessService: QuickAccessService,
    private topNotificationService: TopNotificationService,
    private router: Router,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.resetSearchInputOnRouteChange();
    this.userLanguage$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.setInputPlaceholderText())
      )
      .subscribe();

    this.searchTerms$
      .pipe(
        distinctUntilChanged(),
        tap(() => (this.searchInProgress = true)),
        debounceTime(500),
        switchMap(term => {
          return term && this.minCharsReached
            ? this.quickAccessService.quickSearch(term).pipe(
                catchError(error => {
                  this.searchInProgress = false;
                  this.topNotificationService.showErrorInTopNotification(error);
                  return of<QuickSearchResult>(this.inititalResults);
                })
              )
            : of<QuickSearchResult>(this.inititalResults);
        }),
        startWith(this.inititalResults),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((results: QuickSearchResult) => {
        this.currentResults = results;
        if (results.searchResults.length === 1) {
          this.selectedResult = this.currentResults.searchResults[0];
        }
        this.searchInProgress = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setInputPlaceholderText() {
    return this.translate
      .get(['GLOBAL.SEARCH_FOR', 'GLOBAL.CHARGING_STATION_ID', 'GLOBAL.CHARGE_POINT_ID'])
      .pipe(
        tap(translatedKeys => {
          this.searchPlaceholderText = `${translatedKeys['GLOBAL.SEARCH_FOR']} ${translatedKeys['GLOBAL.CHARGING_STATION_ID']}, ${translatedKeys['GLOBAL.CHARGE_POINT_ID']}`;
        })
      );
  }

  onKeyUpWithinInput(event: KeyboardEvent): void {
    event.stopImmediatePropagation();

    if (this.popoverVisible && this.minCharsReached) {
      this.handleArrowNavigation(event);
    }

    const searchTerm = this.searchInput.nativeElement.value.trim();
    this.minCharsReached = searchTerm.length >= this.numOfMinCharsToStartSearch;
    this.searchTerms$.next(searchTerm);
  }

  setPopoverVisibility(isVisible: boolean): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.QUICK_SEARCH,
      GoogleAnalyticsAction.COLLAPSE,
      isVisible ? GoogleAnalyticsLabel.OPEN : GoogleAnalyticsLabel.CLOSE
    );
    this.popoverVisible = isVisible;
    this.popoverVisible$.emit(this.popoverVisible);
  }

  onKeyDownWithinInput(event: KeyboardEvent): void {
    if (event.key === KeyboardKeys.DOWN || event.key === KeyboardKeys.UP) {
      event.preventDefault();
    }
  }

  navigate(element: SearchResult): void {
    this.setPopoverVisibility(false);
    this.searchInput.nativeElement.blur();

    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.QUICK_SEARCH,
      GoogleAnalyticsAction.SEARCH,
      element.category === SearchCategoryEnum.CHARGINGSTATION
        ? GoogleAnalyticsLabel.CHARGING_STATION
        : GoogleAnalyticsLabel.CHARGE_PORT
    );

    this.router.navigate(['/operate/chargingInfrastructureDetail'], {
      queryParams: {
        uuid: element.stationUuid,
        ...(element.category === SearchCategoryEnum.CHARGEPOINT && {
          chargePort: element.value,
        }),
        ...(element.category === SearchCategoryEnum.CHARGINGSTATION && {
          chargingStation: element.value,
        }),
      },
    });
  }

  private resetSearchInputOnRouteChange(): void {
    this.route$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.searchInput.nativeElement.value = '';
      this.minCharsReached = false;
    });
  }

  private handleArrowNavigation(event: KeyboardEvent): void {
    let index = this.selectedResult
      ? this.currentResults.searchResults.findIndex(elm => elm.value === this.selectedResult.value)
      : -1;
    if (event.key === KeyboardKeys.DOWN) {
      index = this.mod(index + 1, this.currentResults.searchResults.length);
      this.selectedResult = this.currentResults.searchResults[index];
      return;
    }
    if (event.key === KeyboardKeys.UP) {
      index = this.mod(index - 1, this.currentResults.searchResults.length);
      this.selectedResult = this.currentResults.searchResults[index];
      return;
    }
    if (event.key === KeyboardKeys.ENTER && this.selectedResult) {
      this.navigate(this.selectedResult);
      return;
    }
  }

  /**
   * This is needed because default javascript '%' operator
   * cannot handle negative values as expected here.
   *
   * e.g. -1 % 3 returns -1 instead of 2
   */
  private mod(a: number, b: number): number {
    return ((a % b) + b) % b;
  }
}
