import { HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UriEncoderService implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  prepareUrlParams(parameters: { [key: string]: any }, compact?: boolean): string {
    let urlParams = '';
    for (const key in parameters) {
      if (parameters.hasOwnProperty(key)) {
        const paramValue = parameters[key];
        if (this.shouldDeleteParam(paramValue)) {
          delete parameters[key];
        } else {
          urlParams = this.setUrlParams(urlParams, key, paramValue, compact);
        }
      }
    }

    return urlParams.slice(0, -1);
  }

  private shouldDeleteParam(paramValue: any): boolean {
    if (
      paramValue === null ||
      paramValue === undefined ||
      paramValue === '' ||
      paramValue.length === 0
    ) {
      return true;
    }

    return false;
  }

  private setUrlParams(urlParams: string, key: string, paramValue: any, compact: boolean): string {
    if (Array.isArray(paramValue)) {
      urlParams = this.handleArrayParamValues(compact, urlParams, key, paramValue);
    } else {
      urlParams = urlParams + key + '=' + encodeURIComponent(paramValue) + '&';
    }

    return urlParams;
  }

  private handleArrayParamValues(
    compact: boolean,
    urlParams: string,
    key: string,
    paramValue: any[]
  ): string {
    if (compact) {
      urlParams = urlParams + key + '=';
      paramValue.forEach(value => {
        urlParams = urlParams + encodeURIComponent(value) + ',';
      });
      urlParams = urlParams.slice(0, -1) + '&';
    } else {
      paramValue.forEach(
        value => (urlParams = urlParams + key + '=' + encodeURIComponent(value) + '&')
      );
    }
    return urlParams;
  }
}
