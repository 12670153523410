import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoNumericOptions } from '@widgets/directives/auto-numeric/auto-numeric-options';
import AutoNumeric from 'autonumeric';

@Directive({
  selector: '[eopAutoNumeric]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoNumericDirective),
      multi: true,
    },
  ],
})
export class AutoNumericDirective implements ControlValueAccessor {
  private autoNumeric: AutoNumeric;

  @Input()
  public set options(options: AutoNumericOptions) {
    this.autoNumeric.update(options);
  }

  private onChangeCallback: (value: number) => void = () => {};
  private onTouchedCallback: () => void = () => {};

  constructor(private elementRef: ElementRef) {
    this.autoNumeric = new AutoNumeric(this.elementRef.nativeElement);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.elementRef.nativeElement.disabled = isDisabled;
  }

  writeValue(value: number): void {
    this.autoNumeric.set(value);
  }

  @HostListener('input')
  onInput() {
    this.onChangeCallback(this.autoNumeric.getNumber());
    this.onTouchedCallback();
  }
}
