import { NgModule } from '@angular/core';
import { ActionsModule } from './actions/module';
import { EpicsModule } from './epics/module';

/**
 * @deprecated
 */
@NgModule({
  imports: [ActionsModule, EpicsModule],
})
export class OldStoreModule {}
