import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ILoginMessagesResult,
  LoginMessagesEntry,
} from '@portal/login-messages/data/login-message.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { CompressionService } from '@shared/services/util/compression.service';

@Injectable({
  providedIn: 'root',
})
export class LoginMessagesPollerService {
  storageKey = 'notificationIds';

  constructor(private http: HttpClient, private compressionService: CompressionService) {}

  public getActiveLoginMessages(): Observable<LoginMessagesEntry[]> {
    return this.http
      .get<ILoginMessagesResult>(environment.bffInnogyAdmin + '/announcements/platform/active')
      .pipe(
        map(response => {
          const notifications = response.data;

          if (notifications.length === 0) {
            localStorage.removeItem(this.storageKey);
          } else {
            notifications.forEach(n => {
              if (n.link?.includes('content/faq')) {
                n.link += '?expandReleaseNotes=true';
              }
            });
          }
          return notifications;
        })
      );
  }

  public markNotificationsSeen(notificationIds: number[]): void {
    localStorage.setItem(
      this.storageKey,
      this.compressionService.compress(JSON.stringify(notificationIds))
    );
  }

  public hasNewNotifications(activeMessagesEntries: LoginMessagesEntry[]): boolean {
    const seenIds = this.getCachedSeenNotificationIds();
    return activeMessagesEntries.some(notification => !seenIds.includes(notification.id));
  }

  private getCachedSeenNotificationIds(): number[] {
    const storedIds = localStorage.getItem(this.storageKey);
    return storedIds ? JSON.parse(this.compressionService.decompress(storedIds)) : [];
  }
}
