import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { TabComponent } from '@widgets/tabs/tab/tab.component';
import { TabsMode } from '@widgets/tabs/tabs.model';

@Component({
  selector: 'innogy-tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, OnChanges {
  /** @deprecated */
  @Input()
  transparent = false;

  @Input()
  line = false;

  @Input()
  mode: TabsMode = TabsMode.Tabs;

  @Input()
  activeIndex = 0;

  @Input()
  isDisabledAreHidden = false;

  @Input()
  isInnerTab = false;

  @Input()
  hideTextOverflow = false;

  @Input()
  shouldTitleCrop = false;

  @Input()
  useInlineFlex = false;

  @Input()
  useScrollbar = false;

  @Output()
  activeIndexChange = new EventEmitter<number>();

  @Output()
  tabClicked = new EventEmitter<void>();

  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  visitedIndexes = [];
  TabsMode = TabsMode;

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;
  readonly IconSize = IconSize;

  ngAfterContentInit(): void {
    this.select(this.activeIndex, false);
  }

  select(index, emitEvent = true) {
    let next: TabComponent;

    this.activeIndex = this.tabs.toArray()[index] ? index : 0;
    next = this.tabs.toArray()[this.activeIndex];
    next.active = true;
    if (!this.visitedIndexes.includes(index)) {
      this.visitedIndexes.push(index);
    }

    next.detectChanges();

    if (emitEvent) {
      this.activeIndexChange.emit(this.activeIndex);
    }
  }

  unselect(index) {
    const previous = this.tabs.toArray()[index];
    if (!!previous) previous.active = false;
  }

  checkedSelect(prev: number, next: number, bubble?: boolean) {
    if (this.tabs) {
      if (
        !this.tabs.toArray()[next].disabled &&
        ((this.mode !== TabsMode.Steps && this.mode !== TabsMode.DynamicSteps) ||
          !bubble ||
          this.visitedIndexes.includes(next))
      ) {
        this.unselect(prev);
        this.select(next);
      } else {
        this.activeIndex = prev;
      }
    } else {
      this.activeIndex = next;
    }
  }

  onTabClick(): void {
    this.tabClicked.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeIndex && changes.activeIndex.currentValue !== undefined) {
      this.checkedSelect(changes.activeIndex.previousValue, changes.activeIndex.currentValue);
    }
  }
}
