import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { EopIconWithTooltipComponent } from './eop-icon-with-tooltip.component';

@NgModule({
  declarations: [EopIconWithTooltipComponent],
  imports: [CommonModule, MatTooltipModule, EopIconModule],
  exports: [EopIconWithTooltipComponent],
})
export class EopIconWithTooltipModule {}
