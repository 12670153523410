import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * A Compontent for the loading Spinner.
 */
@Component({
  selector: 'spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
  @Input()
  public spinnerSize: number = 44;

  constructor() {}
}
