import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Item } from '@widgets/innogy-dropdown';

@Component({
  selector: 'eop-client-location',
  templateUrl: './client-location.component.html',
  styleUrls: ['./client-location.component.scss'],
})
export class ClientLocationComponent {
  lengthToActivateSearchable = 25;

  @Input()
  locationItems: Item[] = [];

  @Input()
  clientItems: Item[] = [];

  @Input()
  groupItems: Item[] = [];

  @Input()
  showOnlyClient = false;

  /**
   * By default dropdown is not visible if only one client/partner is available
   * but with this flag we can still show dropdown in this case
   */
  @Input()
  showSingleClient = false;

  @Input()
  selectedLocation: Item;

  @Input()
  selectedClient: Item;

  @Input()
  selectedGroup: Item;

  @Input()
  disableClient: boolean;

  @Input()
  clientDeselectable = true;

  @Input()
  disableLocation: boolean;

  @Input()
  showGroup = false;

  @Input()
  disableGroup: boolean;

  @Input()
  groupDeselectable = false;

  @Input()
  groupItemTemplate: TemplateRef<any>;

  @Output()
  selectedLocationChange = new EventEmitter<Item<any>>();

  @Output()
  selectedClientChange = new EventEmitter<Item<any>>();

  @Output()
  selectedGroupChange = new EventEmitter<Item>();

  onSelectedClientChange(selectedItem: Item) {
    this.selectedClient = selectedItem;
    if (!this.showOnlyClient) {
      this.selectedLocation = undefined;
      this.locationItems = [];
    }
    this.selectedClientChange.emit(selectedItem);
  }

  onSelectedLocationChanged(selectedLocation: Item) {
    this.selectedLocation = selectedLocation;
    this.selectedLocationChange.emit(selectedLocation);
  }

  onSelectedGroupChanged(selectedGroup: Item) {
    this.selectedGroup = selectedGroup;
    this.selectedGroupChange.emit(selectedGroup);
  }
}
