import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-053113}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Compleo_Charging_Software_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{eOperate_Portal}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{eOperate_Portal}_need_to_be_licensed___{eOperate_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{9_January_2025}____[v3]_[01]_MTczNjM4MDgwMDAwMA==5609d70d48db4f6428195fb76773e926'
  );
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
