<innogy-dropdown
  *ngIf="clientItems && (showSingleClient || clientItems.length > 1)"
  [items]="clientItems"
  [transparent]="true"
  [searchable]="true"
  [selectedItem]="selectedClient"
  [singleSelectDeselactable]="clientDeselectable"
  (selectedItemChange)="onSelectedClientChange($event)"
  [disabled]="disableClient"
  [attr.data-test]="'clientDropdown'"
  [placeholderText]="'GLOBAL.PROVIDER_SELECT' | translate"
>
</innogy-dropdown>

<innogy-dropdown
  *ngIf="!showOnlyClient && !showGroup"
  [disabled]="disableLocation"
  [items]="locationItems"
  [selectedItem]="selectedLocation"
  [searchable]="locationItems && locationItems.length > lengthToActivateSearchable"
  [transparent]="true"
  [multiSelect]="false"
  [attr.data-test]="'locationDropdown'"
  [placeholderText]="'GLOBAL.LOCATION_SELECT' | translate"
  (selectedItemChange)="onSelectedLocationChanged($event)"
>
</innogy-dropdown>

<innogy-dropdown
  *ngIf="!showOnlyClient && showGroup"
  [disabled]="disableGroup"
  [items]="groupItems"
  [selectedItem]="selectedGroup"
  [singleSelectDeselactable]="groupDeselectable"
  [searchable]="groupItems && groupItems.length > lengthToActivateSearchable"
  [transparent]="true"
  [multiSelect]="false"
  [itemTemplate]="groupItemTemplate"
  [attr.data-test]="'groupDropdown'"
  [placeholderText]="'CONTRACTS.PLEASE_SELECT' | translate"
  (selectedItemChange)="onSelectedGroupChanged($event)"
>
</innogy-dropdown>
