import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { mapTo, take, tap } from 'rxjs/operators';

import { SharedRefService } from '../shared-ref-service/shared-ref-service.service';
import { ModalContainerComponent } from './modal-container/modal-container.component';

/**
 * The ModalRef class is used as operating instance for
 * interacting with a running modal dialog.
 */
// tslint:disable-next-line:no-any
export class ModalRef<T = any> {
  /**
   * This property stores the passed componentInstance and enable
   * you to interact with the componentInstance.
   */
  public componentInstance: T | undefined;

  private readonly closeSubject$ = new Subject<boolean | string>();
  /**
   * @ignore
   */
  constructor(
    private readonly overlayRef: OverlayRef,
    private readonly containerInstance: ModalContainerComponent,
    private readonly activeModalService: SharedRefService
  ) {}

  /**
   * This will close the belonging modal instance. It aditionally
   * triggers the closing animation and emits a notification to the [close$]{@link ModalRef#close$} observable
   * as soon as the animation is done.
   */
  public close(value?: any): void {
    this.containerInstance.animationDone$
      .pipe(
        take(1),
        tap(() => {
          this.activeModalService.closeModal();
          this.overlayRef.dispose();
        }),
        mapTo(value !== undefined ? value : true)
      )
      .subscribe(this.closeSubject$);
    this.containerInstance._startExitAnimation();
  }

  /**
   * This will close the modal without animation
   */
  public closeWithoutAnimation(): void {
    this.overlayRef.dispose();
  }

  /**
   * Emits a next Notification after the close animation is done
   * and a complete notification immediatly afterwards.
   */
  public get close$(): Observable<any> {
    return this.closeSubject$.asObservable();
  }
}
