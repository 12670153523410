import { Component, OnInit } from '@angular/core';
import { PopoverContent, PopoverRef } from '../classes/popover-ref';

@Component({
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  content: PopoverContent;

  constructor(private popoverRef: PopoverRef) {}

  ngOnInit() {
    this.content = this.popoverRef.content;
  }
}
