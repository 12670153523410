<div [style.height]="height" class="map-component">
  <div *ngIf="!mapLoaded" class="spinner-wrapper">
    <spinner></spinner>
  </div>
  <div class="map-wrapper">
    <div [style.height]="height" [style.width]="'100%'" class="map" id="map-{{ mapId }}"></div>
    <div *ngIf="processingPoints && mapLoaded" class="spinner-wrapper transparent">
      <spinner class="spinner"></spinner>
    </div>
  </div>
</div>
