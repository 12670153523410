<div class="initialization_overlay" *ngIf="!languagesLoaded"></div>
<div class="app-container" *ngIf="languagesLoaded" [class.homepage]="inPage('/monitor/dashboard')">
  <div class="emob-backdrop-blur-filter" emobModalActive></div>
  <div
    *ngIf="notification$ | async as notification"
    [ngClass]="{ 'top-notification-margin': !isAnonymousUserPages() }"
  >
    <notification-top
      [level]="notification.notificationLevel"
      [message]="notification.message"
      [traceId]="notification.traceId"
      [closeButtonText]="'GLOBAL.NOTIFICATION_BUTTON_TEXT_UNDERSTAND' | translate"
      [reloadButtonText]="notification.reloadButton ? ('CHARG_INF_DETAIL.REFRESH' | translate) : ''"
      (closeNotification)="closeNotification()"
    >
    </notification-top>
  </div>

  <div
    [class.container-fluid]="!isAnonymousUserPages()"
    [class.no-sidebar-menu]="isPageWithoutMenu() || isAnonymousUserPages()"
    [class.sidebar-collapsed]="sidebarCollapsed"
  >
    <eop-global-header
      class="app-header"
      [isFullWidth]="isFullWidthPage()"
      *ngIf="!(isPageWithoutMenu() || isAnonymousUserPages())"
    ></eop-global-header>

    <div class="app-content" [class.full-width]="isFullWidthPage()">
      <router-outlet></router-outlet>
      <eop-scroll-to-top></eop-scroll-to-top>
    </div>
  </div>
  <footer *ngIf="isPageWithoutMenu() || isAnonymousUserPages()">
    <eop-footer> </eop-footer>
  </footer>
</div>
