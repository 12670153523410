<a
  class="link-element"
  [class.link-element--expanded]="isExpanded"
  [href]="url"
  target="_blank"
  rel="noopener"
>
  <eop-icon
    [vaylensFont]="true"
    [identifier]="iconName"
    [color]="IconColor.INHERIT"
    [tooltip]="isExpanded ? '' : linkText"
    [tooltipPosition]="'right'"
  ></eop-icon>
  <span class="link-element--text body-bold-l">
    {{ linkText }}
  </span>
</a>
