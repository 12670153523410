import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';

export interface NavigationItem {
  id: string;
  title: string;
  disabled: boolean;
  icon?: string;
}

@Component({
  selector: 'eop-navigation-list',
  templateUrl: './eop-navigation-list.component.html',
  styleUrls: ['./eop-navigation-list.component.scss'],
})
export class EopNavigationListComponent {
  @Input() navigationItems: NavigationItem[];
  @Input() currentItem: NavigationItem;
  @Output() selectItem = new EventEmitter<NavigationItem>();

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;
  readonly IconSize = IconSize;

  onClick(selectedItem: NavigationItem) {
    if (!selectedItem.disabled) {
      this.selectItem.emit(selectedItem);
    }
  }
}
