import { Component, Input } from '@angular/core';
import { IconSize, IconWeight } from '@widgets/eop-icon';

@Component({
  selector: 'page-headline',
  templateUrl: 'page-headline.component.html',
  styleUrls: ['./page-headline.component.scss'],
})
export class PageHeadlineComponent {
  // Display order: Title - boldTitle

  @Input()
  title: string;

  @Input()
  boldTitle: string;

  @Input()
  shouldBoldTitleCrop = false;

  readonly IconWeight = IconWeight;
  readonly IconSize = IconSize;
}
