import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subject, fromEvent, merge, of } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DropdownItem } from '../dropdown-type';
import { OptionComponent } from '../option/option.component';
import { DropdownOverlayComponent } from './dropdown-overlay.component';
import { KeyEventEnum } from './keyboard-key-events';
import { PopoverService } from './popover.service';

@Directive({
  selector: '[emobDropdownOverlay]',
})
export class DropdownOverlayDirective implements OnDestroy, AfterContentInit, AfterViewInit {
  @Input() emobDropdownOverlay!: DropdownOverlayComponent;
  @Input() multiSelect = false;
  @Output() openOverlay = new EventEmitter<DropdownItem | boolean>();
  @Input() disabled = false;

  overlayRef: OverlayRef | null = null;
  keyManager: ActiveDescendantKeyManager<OptionComponent> = new ActiveDescendantKeyManager([]);
  private readonly unsubscribe$ = new Subject<void>();
  private readonly openSubject$ = new Subject<void>();

  constructor(
    private readonly host: ElementRef<HTMLInputElement>,
    private readonly vcr: ViewContainerRef,
    private readonly popover: PopoverService,
    @Inject(DOCUMENT) public document: any,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this.keyManager = new ActiveDescendantKeyManager(this.emobDropdownOverlay.options)
      .withWrap()
      .withTypeAhead();

    merge(
      fromEvent(this.origin, 'mousedown').pipe(map((e: Event) => this.stopEventPropagation(e))),
      fromEvent<FocusEvent>(this.origin, 'focusin').pipe(
        filter((e: FocusEvent) => e.bubbles),
        filter((event: FocusEvent) => {
          const isOnChild = Array.from(this.origin.children).some(
            element => event.relatedTarget === element
          );
          return !isOnChild;
        }),
        map((e: Event) => this.stopEventPropagation(e))
      ),
      this.openSubject$.asObservable()
    )
      .pipe(
        filter(() => !this.disabled && !this.overlayRef?.hasAttached()),
        switchMap(() => {
          if (
            this.emobDropdownOverlay.activeItemIndex ||
            this.emobDropdownOverlay.activeItemIndex === 0
          ) {
            const activeItemIndex = +this.emobDropdownOverlay.activeItemIndex;
            return this.openDropdown$(activeItemIndex);
          }
          return this.openDropdown$(<OptionComponent>this.keyManager.activeItem);
        }),
        switchMap((overlayRef: OverlayRef) => {
          return merge(
            this.registerOptionsClickEvent$(overlayRef),
            this.registerKeydownEvents$(overlayRef)
          ).pipe(
            finalize(() => {
              this.overlayRef = null;
              this.openOverlay.emit(false);
            }),
            takeUntil(overlayRef.detachments())
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.setTooltipIfEllipsis();
  }

  get origin(): HTMLElement {
    return this.host.nativeElement;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public emitOverlayOpenSignal(): void {
    this.openSubject$.next();
  }

  private stopEventPropagation(e: Event): Event {
    if (this.overlayRef === null || !this.overlayRef.hasAttached()) {
      e.stopImmediatePropagation();
    }
    return e;
  }

  private openDropdown$(activeItem: OptionComponent | number): Observable<OverlayRef> {
    this.overlayRef = this.popover.open({
      content: this.emobDropdownOverlay.rootTemplate,
      origin: this.origin,
      vcr: this.vcr,
    });
    this.openOverlay.emit(true);
    this.scrollIntoSelectedOption(activeItem);
    return of(this.overlayRef);
  }

  private onSelectedOption(selectedItem: DropdownItem, overlayRef: OverlayRef): void {
    this.emobDropdownOverlay.selectedItemChange.emit(selectedItem);
    const optionComponent: OptionComponent | undefined = this.emobDropdownOverlay.options.find(
      option => option.item.value === selectedItem.value
    );

    if (optionComponent) {
      this.keyManager.setActiveItem(optionComponent);
    }

    if (!this.multiSelect) {
      // Only close the overlay if it's not multi-select
      this.popover.close(overlayRef);
      this.overlayRef = null;
      this.openOverlay.emit({ ...selectedItem });
    }

    this.setTooltipIfEllipsis();
  }

  private registerKeydownEvents$(overlayRef: OverlayRef): Observable<KeyboardEvent> {
    return overlayRef.keydownEvents().pipe(
      tap((event: KeyboardEvent) => {
        if (
          event.key === KeyEventEnum.ENTER &&
          this.keyManager.activeItem &&
          !this.keyManager.activeItem.isNoResult
        ) {
          this.onSelectedOption((<OptionComponent>this.keyManager.activeItem).item, overlayRef);
        } else if (
          event.key === KeyEventEnum.ARROW_UP ||
          event.key === KeyEventEnum.ARROW_DOWN ||
          event.key === KeyEventEnum.DOWN ||
          event.key === KeyEventEnum.UP
        ) {
          if (!this.keyManager.activeItem) {
            const optionComponentIndex: number = this.emobDropdownOverlay.options
              .toArray()
              .findIndex(option => option.index === this.emobDropdownOverlay.activeItemIndex);

            if (optionComponentIndex >= 0) {
              this.keyManager.setActiveItem(optionComponentIndex);
            }
          }
          this.keyManager.onKeydown(event);
          if (this.keyManager.activeItem) {
            this.scrollIntoView((<OptionComponent>this.keyManager.activeItem).index);
          }
          this.cdr.detectChanges();
        }
      })
    );
  }

  private registerOptionsClickEvent$(overlayRef: OverlayRef): Observable<DropdownItem> {
    return this.emobDropdownOverlay.optionsClick$().pipe(
      tap((selectedItem: { item: DropdownItem; index: number }) => {
        this.onSelectedOption(selectedItem.item, overlayRef);
      }),
      map(selectedItem => selectedItem.item)
    );
  }

  private scrollIntoSelectedOption(activeItem: OptionComponent | number): void {
    if (typeof activeItem === 'number') {
      this.keyManager.setActiveItem(activeItem);
      this.scrollIntoView(activeItem);
    } else if (activeItem && activeItem.item.value !== '') {
      this.scrollIntoView(activeItem.index);
    }
  }

  private scrollIntoView(index: number): void {
    setTimeout(() => {
      if (this.emobDropdownOverlay && this.emobDropdownOverlay.viewport && index !== undefined) {
        this.emobDropdownOverlay.viewport.scrollToIndex(index, 'auto');
      }
    }, 0);
  }

  private setTooltipIfEllipsis(): void {
    const inputElement = <HTMLInputElement>this.origin.firstElementChild;
    if (inputElement.offsetWidth < inputElement.scrollWidth) {
      this.origin.title = inputElement.value;
    } else {
      this.origin.title = '';
    }
  }
}
