import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { GridAlgorithm, MarkerClusterer } from '@googlemaps/markerclusterer';
import { MapConfig, PoiStatus } from '@widgets/map/data/map.model';
import { MapButtonService } from '@widgets/map/services/map-button.service';
import { MapLoaderService } from '@widgets/map/services/map-loader.service';
import { environment } from 'environments/environment';
import { isNaN, uniqBy } from 'lodash-es';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MarkerPopupComponent } from './components/marker-popup/marker-popup.component';
import {
  AdaptViewType,
  CenterToPoiButton,
  Coordinate,
  MapPosition,
  POI,
  PoiType,
} from './data/map.model';
import InfoWindow = google.maps.InfoWindow;
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;

@Component({
  selector: 'eop-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  mapId = crypto.randomUUID();
  mapLoaded = false;
  processingPoints = false;
  @Input() mapConfig: MapConfig;
  @Input() height: string = '600px';
  @Input() mapZoom: number = 6;
  @Input() minZoom: number;
  @Input() fitBounds = false;
  @Input() adaptView: AdaptViewType = AdaptViewType.PAN_TO_CENTER_BY_POINTS;
  @Input() mapCenterPosition: MapPosition;
  @Input() centerPoiId: string;
  @Input() draggableMarker: boolean = false;
  @Input() allPointsHasCustomIcons = true;
  @Input() centerToPoiButton: CenterToPoiButton;
  @Input() lazyLoadedPoints = false;
  @Input() disableMarkerPopup = false;
  @Input() cropTextInPopup = true;
  @Output() onMapInit = new EventEmitter<void>();
  @Output() onMapMoved = new EventEmitter<google.maps.LatLngBounds>();
  @Output() onMarkerMoved = new EventEmitter<google.maps.LatLngLiteral>();
  mapOptions: MapOptions = {
    zoom: this.mapZoom,
    minZoom: 6,
    maxZoom: 22,
    mapId: environment.googleMapId.toString(),
    streetViewControl: false,
    mapTypeControl: true,
    fullscreenControl: false,
    clickableIcons: false,
  };
  private readonly unsubscribe$ = new Subject<void>();
  private readonly setPositionAfterMapAtLoad$ = new Subject<void>();

  private readonly minClusterZoom = 14;
  private map: Map;
  private infoWindow: InfoWindow;
  private markers: AdvancedMarkerElement[] = [];
  private markerCluster: MarkerClusterer;

  private compPopupRef: ComponentRef<any>[] = [];
  private centerToPoiButtonElement: HTMLButtonElement;
  private markerEventListeners: google.maps.MapsEventListener[] = [];

  private allPOIs: POI[] = [];
  private allMarkers: AdvancedMarkerElement[] = [];
  private lastBounds: google.maps.LatLngBounds;
  private lastZoom: number;

  private mapIdleListener: google.maps.MapsEventListener;
  private boundsChangedListener: google.maps.MapsEventListener;
  private clusterClickListener: google.maps.MapsEventListener;

  constructor(
    private mapLoaderService: MapLoaderService,
    private mapButtonService: MapButtonService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.mapLoaderService.initMapLoader();
    await this.initMap();
    this.setPositionAfterMapAtLoad$.pipe(take(1)).subscribe(() => {
      if (this.centerPoiId) {
        this.tryOpenPopupByPOI();
      }
      if (this.centerToPoiButton) {
        this.createCenterToPoiButton();
      }

      if (this.minZoom) {
        this.map?.setOptions({
          minZoom: this.minZoom,
        });
      }

      switch (this.adaptView) {
        case AdaptViewType.PAN_TO_CENTER_BY_POINTS:
          this.setCenterOnMapByMarkers();
          return;
        case AdaptViewType.PAN_TO_CENTER_MANUALY:
          this.setCenterOnMap(this.mapCenterPosition);
          this.map?.setZoom(this.mapZoom);
          return;
        case AdaptViewType.FIT_BOUNDS:
          this.setBoundsToMap();
          this.map?.setOptions({
            minZoom: 3,
          });
          return;
        case AdaptViewType.USERS_GEO_POSITION:
          this.mapLoaded = false;
          this.cdr.detectChanges();
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position: GeolocationPosition) => {
                const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
                this.map?.setCenter(pos);
                this.map?.setZoom(this.mapZoom);
                this.mapLoaded = true;
                this.cdr.detectChanges();
              },
              () => {
                console.error('Could not set users location');
                this.mapLoaded = true;
                this.setDefaultCenter();
                this.cdr.detectChanges();
              },
              { enableHighAccuracy: false, maximumAge: Infinity }
            );
          } else {
            this.mapLoaded = true;
            this.setDefaultCenter();
            this.cdr.detectChanges();
          }
          return;
      }
    });
  }

  ngOnDestroy() {
    this.compPopupRef?.forEach(c => c.destroy());
    this.removeAllEventListeners();
    this.setPositionAfterMapAtLoad$.next();
    this.setPositionAfterMapAtLoad$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.mapConfig && changes.mapConfig.currentValue) {
      let POIsToAdd = changes.mapConfig.currentValue.POIs as POI[];
      if (changes.mapConfig.previousValue) {
        const currentPOIs = changes.mapConfig.currentValue.POIs as POI[];
        const alreadyAddedPOIsIds = this.allPOIs.map(p => p.info.uuid);
        POIsToAdd = currentPOIs.filter(p => !alreadyAddedPOIsIds.includes(p.info.uuid));
      }
      this.allPOIs = uniqBy(this.allPOIs.concat(POIsToAdd), p => p.info.uuid);
      await this.addMarkersToMap(POIsToAdd);
    }
  }

  private createCenterToPoiButton() {
    this.centerToPoiButtonElement = document.createElement('button');
    this.centerToPoiButtonElement = this.mapButtonService.setupCenterMapButton(
      this.centerToPoiButtonElement,
      this.centerToPoiButton
    );

    this.centerToPoiButtonElement.addEventListener('click', () => {
      this.setCenterOnMap(this.mapCenterPosition);
      this.map.setZoom(this.mapZoom);
      this.tryOpenPopupByPOI();
    });

    // Create the DIV to hold the control.
    const centerControlDiv = document.createElement('div');
    // Append the control to the DIV.
    centerControlDiv.appendChild(this.centerToPoiButtonElement);
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  }

  private findPoiByMapPoiCenterId(): POI {
    return this.allPOIs.find(p => p.info.uuid === this.centerPoiId);
  }

  private async initMap(): Promise<void> {
    this.mapLoaderService.importLibrary('maps').then(({ Map, InfoWindow }) => {
      this.map = new Map(document.getElementById(`map-${this.mapId}`), this.mapOptions);
      this.addMapEventListeners();
      this.infoWindow = new InfoWindow();
      this.onMapInit.emit();
    });
  }

  private async addMarkersToMap(POIs: POI[]): Promise<void> {
    this.mapLoaderService
      .importLibrary('marker')
      .then(({ AdvancedMarkerElement }) => {
        this.markers = POIs.map((POI: POI) => {
          const markersWithSameLocationCount: number = this.countMarkersWithSameLocation(POI);

          let groupIcon = {};
          if (markersWithSameLocationCount > 1) {
            groupIcon = this.getGroupMarkerIcon(
              markersWithSameLocationCount > 1 ? markersWithSameLocationCount.toString() : ''
            );
          }

          const customMarkerIcon = this.getCustomMarkerIcon(POI.info.status, POI.type);
          const markerHasCustomIcon =
            (customMarkerIcon &&
              POI.info.uuid === this.centerPoiId &&
              markersWithSameLocationCount === 1) ||
            (customMarkerIcon &&
              this.allPointsHasCustomIcons &&
              markersWithSameLocationCount === 1);

          const marker = new AdvancedMarkerElement({
            position: this.getCoordinations(POI.coordinate),
            content: markerHasCustomIcon ? customMarkerIcon : groupIcon,
            collisionBehavior: google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL,
            gmpDraggable: this.draggableMarker,
          });
          this.addClickListenerToMarker(marker, POI);
          marker.setAttribute('pointId', POI.info.uuid);
          return marker;
        });
        this.allMarkers = uniqBy(this.allMarkers.concat(this.markers), m => {
          return m.getAttribute('pointId');
        });
        this.setMarkerClusters();
        this.addClickEventListenerToCluster();
        // it is triggered only once when loaded first
        this.setPositionAfterMapAtLoad$.next();
      })
      .finally(() => {
        this.processingPoints = false;
        this.cdr.detectChanges();
        setTimeout(() => {
          if (this.adaptView !== AdaptViewType.USERS_GEO_POSITION) {
            this.mapLoaded = true;
          }
          this.cdr.detectChanges();
        }, 1000);
      });
  }

  private getCoordinations(coordinate: Coordinate): Coordinate {
    return {
      lat: coordinate.lat === 0 ? coordinate.lat + 0.000001 : coordinate.lat,
      lng: coordinate.lng === 0 ? coordinate.lng + 0.000001 : coordinate.lng,
    };
  }

  private getCustomMarkerIcon(suffix: PoiStatus, type: PoiType): HTMLImageElement {
    if (type) {
      const iconImg = document.createElement('img');
      iconImg.width = 32;
      switch (type) {
        case PoiType.TERMINAL:
          iconImg.src = suffix
            ? `/assets/img/map/pin-terminal-${suffix}.png`
            : `/assets/img/map/pin-terminal.png`;
          return iconImg;
        case PoiType.STANDALONE_TERMINAL:
          iconImg.src = suffix
            ? `/assets/img/map/pin-standalone-terminal-${suffix}.png`
            : `/assets/img/map/pin-standalone-terminal.png`;
          return iconImg;
        case PoiType.STATION:
          iconImg.src = suffix
            ? `/assets/img/map/pin-station-${suffix}.png`
            : `/assets/img/map/pin-station.png`;
          return iconImg;
        case PoiType.GROUP:
          iconImg.src = suffix
            ? `/assets/img/map/pin-group-${suffix}.png`
            : `/assets/img/map/pin-group.png`;
          return iconImg;
        case PoiType.ADDRESS:
          iconImg.src = suffix
            ? `/assets/img/map/pin-address-${suffix}.png`
            : `/assets/img/map/pin-address.png`;
          return iconImg;
      }
    }
    return undefined;
  }

  private getGroupMarkerIcon(count: string): HTMLDivElement {
    const imgContainer = document.createElement('div');
    imgContainer.style.position = 'relative';
    imgContainer.style.textAlign = 'center';
    imgContainer.style.color = 'white';

    const iconImg = document.createElement('img');
    iconImg.src = `/assets/img/map/pin-group.png`;
    iconImg.width = 32;
    iconImg.height = 39;

    imgContainer.appendChild(iconImg);

    const labelContainer = document.createElement('div');
    labelContainer.style.position = 'absolute';
    labelContainer.style.top = '40%';
    labelContainer.style.left = '50%';
    labelContainer.style.transform = 'translate(-50%, -50%)';
    labelContainer.innerText = count;

    imgContainer.appendChild(labelContainer);

    return imgContainer;
  }

  private countMarkersWithSameLocation(POI: POI): number {
    return this.allPOIs.filter(
      p => p.coordinate.lat === POI.coordinate.lat && p.coordinate.lng === POI.coordinate.lng
    ).length;
  }

  private setMarkerClusters() {
    const renderer = {
      render: ({ count, position }) =>
        new google.maps.Marker({
          label: { text: String(count), color: 'white', fontSize: '12px' },
          position,
          icon: {
            url: '/assets/img/map/cluster-brandColor.png',
            scaledSize: new google.maps.Size(44, 44),
          },
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        }),
    };
    this.markerCluster = new MarkerClusterer({
      markers: this.markers,
      map: this.map,
      algorithm: new GridAlgorithm({
        gridSize: 50,
        maxZoom: this.minClusterZoom,
      }),
      renderer: renderer,
    });
  }

  private setCenterOnMapByMarkers() {
    let latSum = 0;
    let lngSum = 0;
    this.markers.forEach(point => {
      latSum += point.position.lat as number;
      lngSum += point.position.lng as number;
    });
    const latAvg = !isNaN(latSum / this.markers.length) ? latSum / this.markers.length : 0;
    const lngAvg = !isNaN(lngSum / this.markers.length) ? lngSum / this.markers.length : 0;
    this.map.setCenter(new google.maps.LatLng(latAvg, lngAvg));
    this.map.setZoom(this.mapZoom);
  }

  private setCenterOnMap(position: MapPosition) {
    if (position) {
      this.map?.setCenter(new google.maps.LatLng(position));
    }
  }

  private tryOpenPopupByPOI() {
    if (this.disableMarkerPopup) return;
    this.infoWindow.close();
    const openInterval = setInterval(() => {
      const popupIsOpened =
        document.getElementsByClassName('popup-wrapper')[0]?.childNodes?.length > 0;
      this.openPopupByPOI(this.findPoiByMapPoiCenterId());
      this.hideGooglePopupPlaceholder();
      if (popupIsOpened) {
        this.showGooglePopupPlaceholder();
        clearInterval(openInterval);
        // Timeout is needed because of focus popup animation
        setTimeout(() => {
          this.processingPoints = false;
          this.cdr.detectChanges();
        }, 1000);
      }
    }, 100);
  }

  private openPopupByPOI(POI: POI) {
    if (POI) {
      const marker = this.allMarkers?.filter(
        m => m.position.lng === POI.coordinate.lng && m.position.lat === POI.coordinate.lat
      )[0];
      if (marker) {
        this.openPopupAndFocus(POI, marker);
        this.cdr.detectChanges();
      }
    }
  }

  private openPopupAndFocus(POI: POI, marker: AdvancedMarkerElement) {
    if (this.disableMarkerPopup) return;
    this.infoWindow.close();
    this.infoWindow.setContent(this.getPopup(POI));
    this.infoWindow.setOptions({ disableAutoPan: false });
    this.infoWindow.open({ anchor: marker, map: this.map, shouldFocus: true });
    setTimeout(() => {
      this.infoWindow.setOptions({ disableAutoPan: true });
    }, 1000);
  }

  private hideGooglePopupPlaceholder() {
    document.getElementsByClassName('gm-style-iw')[0]?.setAttribute('hidden', 'true');
    document.getElementsByClassName('gm-style-iw-tc')[0]?.setAttribute('hidden', 'true');
  }

  private showGooglePopupPlaceholder() {
    document.getElementsByClassName('gm-style-iw')[0]?.removeAttribute('hidden');
    document.getElementsByClassName('gm-style-iw-tc')[0]?.removeAttribute('hidden');
  }

  private addMapEventListeners() {
    this.boundsChangedListener = google.maps.event.addListener(this.map, 'bounds_changed', () => {
      if (
        this.lazyLoadedPoints &&
        this.lastBounds &&
        this.lastBounds.toString() !== this.map.getBounds().toString() &&
        this.lastZoom >= this.map.getZoom()
      ) {
        this.processingPoints = true;
        this.cdr.detectChanges();
      }
      this.lastBounds = this.map.getBounds();
      this.lastZoom = this.map.getZoom();
      this.onMapMoved.emit(this.map.getBounds());
    });
  }

  private addClickEventListenerToCluster() {
    this.clusterClickListener = google.maps.event.addListener(
      this.markerCluster,
      'clusterclick',
      cluster => {
        this.map.fitBounds(cluster.getBounds()); // Fit the bounds of the cluster clicked on
        this.processingPoints = false;
        this.cdr.detectChanges();

        if (this.map.getZoom() > this.minClusterZoom + 1)
          // If zoomed in past 15 (first level without clustering)
          this.map.setZoom(this.minClusterZoom + 1);
      }
    );
  }

  private addClickListenerToMarker(marker: AdvancedMarkerElement, POI: POI) {
    this.markerEventListeners.push(
      marker.addListener('click', () => {
        this.openPopupAndFocus(POI, marker);
      }),
      marker.addListener('dragend', event => {
        const position = marker.position as google.maps.LatLng;
        this.onMarkerMoved.emit(position.toJSON());
      })
    );
  }

  private getPopup(point: POI): HTMLElement {
    const element = document.createElement('div');
    element.classList.add('popup-wrapper');
    const pointsWithSameLocation = this.allPOIs.filter(
      p => p.coordinate.lat === point.coordinate.lat && p.coordinate.lng === point.coordinate.lng
    );

    this.compPopupRef?.forEach(c => c.destroy());
    this.compPopupRef = [];

    pointsWithSameLocation.forEach((p: POI, i: number, array: POI[]) => {
      this.compPopupRef.push(this.viewContainerRef.createComponent(MarkerPopupComponent));
      this.compPopupRef[i].instance.point = p;
      this.compPopupRef[i].instance.should = p;
      this.compPopupRef[i].instance.cropText = this.cropTextInPopup;
      this.compPopupRef[i].instance.ngOnInit();
      if (array.length > 1) {
        this.compPopupRef[i].location.nativeElement
          .getElementsByClassName('marker-popup')[0]
          .setAttribute('style', 'margin-bottom: 20px');
      }
      element.appendChild(this.compPopupRef[i].location.nativeElement);
    });
    this.cdr.detectChanges();

    return element;
  }

  private setBoundsToMap() {
    const bounds = new google.maps.LatLngBounds();
    this.mapConfig.POIs.forEach(p => {
      bounds.extend(new google.maps.LatLng(p.coordinate.lat, p.coordinate.lng));
    });
    this.map.fitBounds(bounds);
  }

  private removeAllEventListeners() {
    if (this.boundsChangedListener) {
      google.maps?.event?.clearInstanceListeners(this.boundsChangedListener);
    }
    if (this.mapIdleListener) {
      google.maps?.event?.clearInstanceListeners(this.mapIdleListener);
    }
    if (this.clusterClickListener) {
      google.maps?.event?.clearInstanceListeners(this.clusterClickListener);
    }
    this.centerToPoiButtonElement?.removeEventListener('click', () => this.setCenterOnMap);
    this.markerEventListeners.forEach(listener =>
      google.maps?.event?.clearInstanceListeners(listener)
    );
  }

  /**
   * In case user doesn't give permission we use center of germany
   * as default center of the map
   */
  private setDefaultCenter(): void {
    const germanyCenter = {
      lat: 51.029743,
      lng: 10.447106,
    };
    this.map.setCenter(germanyCenter);
    this.map.setZoom(this.mapZoom);
  }
}
