import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Language } from '@auth/login';
import { CompressionService } from '@shared/services/util/compression.service';
import { IAppState } from '@shared/store/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CmmService } from './cmm.service';
import { ILanguagesData, ITranslationsData } from './translation.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private supportedLanguages = ['de', 'en', 'fr', 'it', 'nl', 'es', 'hu', 'sv', 'da-DK'];

  storageKey = 'translations';

  constructor(
    private cmm: CmmService,
    private store: NgRedux<IAppState>,
    private compressionService: CompressionService
  ) {}

  loadLanguagesFromCMMCached(): Observable<ITranslationsData> {
    const storedTranslations = this.getCachedLanguages();
    if (storedTranslations !== null) {
      return of(storedTranslations);
    } else {
      return this.cmm.getTranslations().pipe(
        map((data: ILanguagesData) => {
          const translations: ITranslationsData = {
            languagesData: data,
            timestampIsoFormat: new Date().toISOString(),
          };
          this.saveTranslationsToLocalStorage(translations);
          return translations;
        }),
        catchError(error => {
          const emptyTranslations: ITranslationsData = {
            languagesData: {},
            timestampIsoFormat: new Date(0).toISOString(), // date from the past to make sure this gets overwritten
          };
          return of(emptyTranslations);
        })
      );
    }
  }

  cmmTranslationsHasChangedSince(timestampIsoFormat: string): Observable<boolean> {
    return this.cmm
      .doTranslationsHaveChangedSince(timestampIsoFormat)
      .pipe(catchError(error => of(true)));
  }

  removeCachedLanguage(): void {
    localStorage.removeItem(this.storageKey);
  }

  getUserLanguage(): Language {
    const userLogin = this.store.getState().backendData.userLogin;
    return userLogin && userLogin.settings ? userLogin.settings.locale : this.getBrowserLanguage();
  }

  getUserLanguageWithFallback(): Language {
    const language = this.getUserLanguage();
    if (language === Language.Empty) {
      return Language.English;
    }
    return language;
  }

  userHasLanguage(language: Language): boolean {
    return this.getUserLanguage() === language;
  }

  getSupportedLanguages(): { id: string; name: string }[] {
    return this.supportedLanguages.sort().map(lang => {
      return { id: lang, name: lang.substr(0, 2).toUpperCase() };
    });
  }

  private getCachedLanguages(): ITranslationsData {
    const storedTranslations = localStorage.getItem(this.storageKey);
    if (!storedTranslations) return null;
    return JSON.parse(this.compressionService.decompress(storedTranslations));
  }

  private getBrowserLanguage(): Language {
    const possibleBrowserLanguages: Language[] = Object.values(Language).filter(
      value => value === navigator.language.substring(0, 2)
    );
    if (possibleBrowserLanguages && possibleBrowserLanguages.length > 0) {
      return possibleBrowserLanguages[0];
    } else {
      return Language.English; // English as fallback
    }
  }

  private saveTranslationsToLocalStorage(translations: ITranslationsData): void {
    localStorage.setItem(
      this.storageKey,
      this.compressionService.compress(JSON.stringify(translations))
    );
  }
}
